import React from "react";

const WebAuthContext = React.createContext({
  isLoaded: false,
  isLoading: process.env.BROWSER,
  isLoadingFailed: false,
  browserSupportsWebAuthn: false,
  platformAuthenticatorIsAvailable: false,
  register: async () => {},
  login: async () => {},
});

export default WebAuthContext;
