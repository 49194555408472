import enLocale from "date-fns/locale/en-GB";
import React from "react";
import { Provider } from "react-redux";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router";
import { CacheProvider } from "@emotion/react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Body from "./App/Body.js";
import favicon from "./favicon.ico";
import ConfigProvider from "../contexts/Config/ConfigProvider.js";
import AppInstallationProvider from "../contexts/AppInstallation/AppInstallationProvider.js";
import AccountProvider from "../contexts/Account/AccountProvider.js";
import ProfileProvider from "../contexts/Profile/ProfileProvider.js";
import LanguageProvider from "../contexts/Language/LanguageProvider.js";
import LoadAppData from "./LoadAppData.js";
import LoadStartupData from "./LoadStartupData.js";
import SessionProvider from "../contexts/Session/SessionProvider.js";
import ToastProvider from "../contexts/Toast/ToastProvider.js";
import MenuOpenProvider from "../contexts/MenuOpen/MenuOpenProvider.js";
import ScrollToTop from "./ScrollToTop.js";
import darkTheme from "./App/darkTheme.js";
import Login from "./Login.js";
import VerifyEmailPage from "./VerifyEmailPage.js";
import DialogProvider from "../contexts/Dialog/DialogProvider.js";
import WebAuthProvider from "../contexts/WebAuth/WebAuthProvider.js";

export const getFavicon = () => favicon;

const theme = darkTheme();
/**
 * The top level component that will represent the entire application stack.
 */
export default function MacroMealPrepApp({
  title,
  applicationUrl,
  store,
  onLanguageDetermined,
  cookieLanguage,
  browserLanguages,
  isShellPage,
  serviceWorkerConnector,
  cache,
}) {
  // this fake favicon call is here because we need to import it AND use it, otherwise the build system will ignore it
  getFavicon();
  return (
    <ConfigProvider title={title} url={applicationUrl} isShellPage={isShellPage}>
      <ToastProvider>
        <AppInstallationProvider serviceWorkerConnector={serviceWorkerConnector}>
          <AccountProvider>
            <ProfileProvider>
              <SessionProvider>
                <CacheProvider value={cache}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                    <WebAuthProvider>
                      <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <DialogProvider>
                          <Provider store={store}>
                            <LanguageProvider
                              browserLanguages={browserLanguages}
                              cookieLanguage={cookieLanguage}
                              onLanguageDetermined={onLanguageDetermined}
                            >
                              <LoadAppData />
                              <LoadStartupData />
                              <ScrollToTop />
                              <MenuOpenProvider>
                                <Routes>
                                  <Route path="/:prefix?/login" element={<Login />} />
                                  <Route path="/:prefix?/verify-email" element={<VerifyEmailPage />} />
                                  <Route path="*" element={<Body isShellPage={isShellPage} />} />
                                </Routes>
                              </MenuOpenProvider>
                            </LanguageProvider>
                          </Provider>
                        </DialogProvider>
                      </ThemeProvider>
                    </WebAuthProvider>
                  </LocalizationProvider>
                </CacheProvider>
              </SessionProvider>
            </ProfileProvider>
          </AccountProvider>
        </AppInstallationProvider>
      </ToastProvider>
    </ConfigProvider>
  );
}
