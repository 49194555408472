export default function getLocaleInformation() {
  let result;

  try {
    result = Intl.DateTimeFormat().resolvedOptions();
  } catch (err) {
    // ignore
  }

  return {
    locale: result?.locale,
    timeZone: result?.timeZone,
  };
}
