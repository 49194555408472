import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startOfWeek, addWeeks, isValid, isEqual } from "date-fns";
import { useParams } from "react-router";
import { updateDay } from "../../actions/day.js";
import dateToId from "../../lib/dateToId.js";
import Calendar from "./Calendar.js";
import { deleteLogEntry, updateLogEntry } from "../../actions/logEntry.js";
import { updateStorage } from "../../actions/storage.js";
import parseNumericInput from "../../lib/parseNumericInput.js";
import ProfileContext from "../../contexts/Profile/ProfileContext.js";
import SessionContext from "../../contexts/Session/SessionContext.js";
import useSendPageView from "../../lib/useSendPageView.js";
import getDaysToDisplay from "./getDaysToDisplay.js";

const verifyInputDate = (year, month, day) => {
  const validatedYear = parseNumericInput(year, true);
  const validatedMonth = parseNumericInput(month, true);
  const validatedDay = parseNumericInput(day, true);

  if (
    !validatedDay.valid ||
    validatedDay.empty ||
    !validatedMonth.valid ||
    validatedMonth.empty ||
    !validatedYear.valid ||
    validatedYear.empty
  ) {
    return null;
  }

  if (validatedYear.number < 2020 || validatedYear.number > 2040) {
    // Sanity checking dates.
    return null;
  }

  let date;

  try {
    date = new Date(validatedYear.number, validatedMonth.number - 1, validatedDay.number);
  } catch (ex) {
    return null;
  }

  if (!isValid(date)) {
    return null;
  }

  return date;
};

const getDateFromUrl = (year, month, day) => {
  let currentDate = verifyInputDate(year, month, day);
  if (!currentDate) currentDate = new Date();
  return currentDate;
};

const mapStateToProps = (state, currentDate, weekStartsOn) => {
  const { days, isLoaded } = state.days;
  const totalDaysToDisplay = 7;
  const startDate = startOfWeek(currentDate, { weekStartsOn });
  const thisWeekActual = startOfWeek(new Date(), { weekStartsOn });
  const lastWeek = addWeeks(startDate, -1);
  const nextWeek = addWeeks(startDate, 1);
  const daysToDisplayDates = getDaysToDisplay(totalDaysToDisplay, startDate);

  const daysToDisplay = daysToDisplayDates.map((daysToDisplayDate) => {
    const diaryDay = days.find((dayFromLog) => isEqual(dayFromLog.date, daysToDisplayDate.date));

    return {
      ...daysToDisplayDate,
      diary: diaryDay,
    };
  });

  return {
    days: daysToDisplay,
    daysIsLoaded: isLoaded,
    ingredients: state.ingredients.ingredients,
    recipes: [],
    nextWeek,
    lastWeek,
    thisWeek: startDate,
    thisWeekActual,
  };
};

function CalendarContainerInterior({ date, mode }) {
  const dispatch = useDispatch();
  const { profile } = useContext(ProfileContext);
  const mappedState = useSelector((state) => mapStateToProps(state, date, profile.weekStartsOn));
  useSendPageView("Calendar", `/calendar${mode ? `/${mode}-week` : ""}`, [date]);

  if (!mappedState.daysIsLoaded) {
    return null;
  }

  const onDeleteLogEntry = (logEntry, dayReal) => {
    const item = { ...logEntry };

    if (!item.date && dayReal?.date) {
      item.date = dayReal.date;
    }

    dispatch(deleteLogEntry(logEntry));
    dispatch(updateStorage());
  };

  const onUpdateLogEntry = (logEntry, dayReal) => {
    const item = { ...logEntry };

    if (!item.date && dayReal?.date) {
      item.date = dayReal.date;
    }
    dispatch(updateLogEntry(item));
    dispatch(updateStorage());
  };

  const onToggleLogEntryConsumed = (logEntry, dayReal) => {
    const item = { ...logEntry, consumed: !logEntry.consumed };

    if (!item.date && dayReal?.date) {
      item.date = dayReal.date;
    }
    dispatch(updateLogEntry(item));
    dispatch(updateStorage());
  };

  const toggleMarkAsComplete = (updatedDay) => {
    dispatch(updateDay(updatedDay));
    dispatch(updateStorage());
  };

  return (
    <Calendar
      lastWeek={mappedState.lastWeek}
      nextWeek={mappedState.nextWeek}
      recipes={mappedState.recipes}
      deleteLogEntry={onDeleteLogEntry}
      days={mappedState.days}
      ingredients={mappedState.ingredients}
      thisWeek={mappedState.thisWeek}
      thisWeekActual={mappedState.thisWeekActual}
      mode={mode}
      updateLogEntry={onUpdateLogEntry}
      toggleLogEntryConsumed={onToggleLogEntryConsumed}
      toggleMarkAsComplete={toggleMarkAsComplete}
    />
  );
}

export function ThisWeek() {
  const { startDayThisWeek } = useContext(SessionContext);
  return useMemo(() => <CalendarContainerInterior date={startDayThisWeek} mode="this" />, [startDayThisWeek]);
}

export function NextWeek() {
  const { startDayNextWeek } = useContext(SessionContext);
  return <CalendarContainerInterior date={startDayNextWeek} mode="next" />;
}

export default function CalendarContainer() {
  const { year, month, day } = useParams();

  return useMemo(() => {
    const date = getDateFromUrl(year, month, day);
    return <CalendarContainerInterior date={date} />;
  }, [year, month, day]);
}
