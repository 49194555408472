import Box from "@mui/material/Box";
import NumberFlow, { NumberFlowGroup } from "@number-flow/react";
import React from "react";

export default function WidgetRangeText({ value, hasPercentValue, percentValue, outsideLimits, unit = "gram" }) {
  let format = { unitDisplay: "narrow" };

  if (unit === "gram") {
    format = { style: "unit", unit: unit ?? "gram", unitDisplay: "narrow" };
  }

  const primaryNumber = (
    <NumberFlow
      value={value}
      format={format} // Intl.NumberFormat() options
      locales="en-GB" // Intl.NumberFormat() locales
    />
  );

  if (!hasPercentValue) {
    return primaryNumber;
  }
  // const displayPercent = hasPercentValue ? (
  //   <NumberFlow
  //     value={percentValue / 100}
  //     format={{ style: "percent", maximumFractionDigits: 0 }}
  //     locales="en-GB"
  //     animate={{ backgroundColor: outsideLimits ? "#ef4444" : "#34d399" }}
  //     style={{ borderRadius: 999, marginLeft: 16, fontSize: "0.9rem", padding: 6, fontWeight: 400 }}
  //   />
  // ) : undefined;

  const displayPercent = (
    <Box
      component={NumberFlow}
      value={percentValue / 100}
      locales="en-GB"
      format={{ style: "percent", maximumFractionDigits: 2, signDisplay: "always" }}
      sx={{
        color: outsideLimits ? "#ef4444" : "#34d399",
        fontWeight: "normal",
        fontSize: "0.9rem",
      }}
      // className={clsx(
      //   '~text-lg/2xl transition-colors duration-300',
      //   diff < 0 ? 'text-red-500' : 'text-emerald-500'
      // )}
    />
  );

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <NumberFlowGroup>
        {primaryNumber}
        {displayPercent}
      </NumberFlowGroup>
    </Box>
  );
}
