import React from "react";

export default function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 481.15 78" style={{ width: "145px", height: "23.5px" }}>
      <path
        fill="currentColor"
        d="M210.5 71.4V8h18.61l5.11 38.69L239.33 8h18.74v63.4h-11.14V25.7l-7 45.7H229l-7.45-45.7v45.7zM263.4 71.4V8h24.42v12.23h-12v12.33h11.51v12h-11.51v14.51h12.78V71.4zM291 71.4L296.84 8h20.66l5.82 63.42h-11.57l-.85-10.26h-7.39l-.71 10.24zM304.43 51H310l-2.7-32.26h-.56zM326.94 71.4V8h12.21v52.62h12.57V71.4zM355.06 8h16.11q7.32 0 10.44 4.91t3.12 14.36q0 9.45-2.76 14.25T372 46.3h-5v25.1h-12zm12.71 26.87A5 5 0 00371 34a4 4 0 001.34-2.43 23.89 23.89 0 00.32-4.54 14.19 14.19 0 00-.85-5.76 3.41 3.41 0 00-3.41-1.78h-1.34v15.36zM388.5 8h18.74q4.47 0 6.92 2.1a10.5 10.5 0 013.3 5.91 45.4 45.4 0 01.86 9.71 21.21 21.21 0 01-1.32 8.4 7.69 7.69 0 01-4.69 4.2 5.68 5.68 0 014 2.88 13.08 13.08 0 011.25 6.21l-.15 24h-11.95V46.59q0-2.66-1-3.39a6.4 6.4 0 00-3.62-.74V71.4H388.5zm15.33 23.48q2.57 0 2.56-5.76a23.32 23.32 0 00-.22-3.77 2.83 2.83 0 00-.78-1.73 2.46 2.46 0 00-1.63-.48h-2.84v11.72zM422.29 71.4V8h24.43v12.23h-12v12.33h11.5v12h-11.5v14.51h12.78V71.4zM451.47 8h16.12q7.31 0 10.41 4.89t3.12 14.36q0 9.45-2.77 14.25t-9.94 4.8h-5v25.1h-12zm12.71 26.87a5 5 0 003.23-.85 4 4 0 001.35-2.43 24.76 24.76 0 00.32-4.54 14.19 14.19 0 00-.85-5.76 3.42 3.42 0 00-3.41-1.78h-1.35v15.34zM74.92 18.76l-2.28 32.26h5.54l-2.7-32.26h-.56zM145.63 20.2a2.46 2.46 0 00-1.63-.48h-2.84v11.74h2.91q2.55 0 2.55-5.76a24.82 24.82 0 00-.21-3.77 2.83 2.83 0 00-.78-1.73z"
      />
      <path
        fill="currentColor"
        d="M0 0v78h198V0zm55.46 71.4H44.31V25.7l-7 45.7H26.35L18.9 25.7v45.7h-11V8h18.6l5.12 38.69L36.72 8h18.74zm24.5 0l-.86-10.26h-7.38L71 71.4H59.15L65.05 8h20.66l5.82 63.4zm44.8-15.4q0 16-15.41 16a13.86 13.86 0 01-10.69-4.36q-4-4.36-4-12v-29.2q0-9.47 3.62-14.26c2.42-3.19 6.2-4.8 11.36-4.8 4.5 0 8.12 1.29 10.87 3.84s4.11 6.26 4.11 11.08v10.93h-12.15v-9.38a7.7 7.7 0 00-.64-3.76 2.37 2.37 0 00-2.2-1 2.28 2.28 0 00-2.23 1.18 7.76 7.76 0 00-.61 3.47v31.79a7.19 7.19 0 00.71 3.65 2.35 2.35 0 002.13 1.15c1.9 0 2.84-1.6 2.84-4.8V44.08h12.29zm31.77-14.8a13.09 13.09 0 011.24 6.21l-.14 24H145.7V46.59q0-2.66-1-3.39a6.43 6.43 0 00-3.62-.74V71.4h-12.35V8h18.75q4.47 0 6.92 2.1a10.56 10.56 0 013.3 5.9 45.48 45.48 0 01.85 9.71 21.21 21.21 0 01-1.35 8.42 7.67 7.67 0 01-4.68 4.2 5.7 5.7 0 014.01 2.87zm35.6 12.93q0 8.57-3.83 13.21T177.08 72q-7.38 0-11.22-4.69T162 54.13v-30.2q0-8.2 3.83-12.37t11.22-4.18q7.38 0 11.22 4.18t3.83 12.37z"
      />
      <path
        fill="currentColor"
        d="M177.15 19.05q-3 0-3.05 4.8v30.94a10.35 10.35 0 00.64 4.14 2.36 2.36 0 002.34 1.4 2.15 2.15 0 002.2-1.48 11.85 11.85 0 00.57-4.13v-31a9.1 9.1 0 00-.53-3.36 2.11 2.11 0 00-2.17-1.31z"
      />
    </svg>
  );
}
