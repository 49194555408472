import React from "react";
import Box from "@mui/material/Box";

export function HeaderTitle({ title }) {
  return (
    <Box
      component="span"
      sx={{
        fontSize: "32px",
        fontWeight: "600",
      }}
    >
      {title}
    </Box>
  );
}

export function HeaderLeft({ children }) {
  return (
    <Box
      display="flex"
      sx={{
        flexDirection: "column",
        flex: "1 1 auto",
      }}
    >
      {children}
    </Box>
  );
}

export function HeaderRight({ children }) {
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: "center",
        flexDirection: "row",
        flex: "0 0 auto",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
}

export default function Header({ children }) {
  return (
    <Box
      display="flex"
      sx={{
        minHeight: "64px",
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      {children}
    </Box>
  );
}
