import {
  goalCompletion,
  goalCompletionDisplay,
  combineNutritionalProfile,
  getEmptyNutritionalProfile,
} from "../../data/calculateMacros.js";
import { displayMacrosUsingLocale } from "../../data/displayMacros.js";
import calculateRunningDailyTotal from "./calculateRunningDailyTotal.js";
import applyItemSort from "./applyItemSort.js";
import applyItemByTimeGrouping from "./applyItemByTimeGrouping.js";
import applySortableNutritionalProfiles from "./applySortableNutritionalProfiles.js";

function extractDayProfiles(days, isMealPlan) {
  if (isMealPlan) {
    return days.map((day) => day.total);
  }
  return days.map((day) => (day.diary ? day.diary.total : getEmptyNutritionalProfile()));
}

export default function calculateItemsDependentStateValues(state, locale, longDateFormat, formatNumber) {
  const isMealPlan = Boolean(state.mealPlan);
  const days = state.days || state.mealPlan.days;
  const goal = state.goal || state.mealPlan.goal;

  const consumedMacrosAllDays = combineNutritionalProfile(extractDayProfiles(days, isMealPlan));
  const consumedMacrosAllDaysDisplay = displayMacrosUsingLocale(consumedMacrosAllDays, formatNumber);

  const goalAllDaysCompletionResult = goalCompletion(consumedMacrosAllDays, state.goalAllDays);
  const goalAllDaysCompletionResultDisplay = goalCompletionDisplay(goalAllDaysCompletionResult);

  // runningDailyTotal becomes the main object that powers the list and totals
  const daysWithRunningTotal = calculateRunningDailyTotal({
    days,
    locale,
    goal,
    startDayThisWeek: state.startDayThisWeek,
    longDateFormat,
    formatNumber,
  });
  const daysWithSortableNutritionalProfiles = applySortableNutritionalProfiles(daysWithRunningTotal);
  const daysWithGroups = applyItemByTimeGrouping(daysWithSortableNutritionalProfiles);
  const runningDailyTotal = applyItemSort({ days: daysWithGroups, order: state.order, orderBy: state.orderBy });

  const remainingMacrosAllDays = combineNutritionalProfile(runningDailyTotal.map((day) => day.remaining));
  const remainingMacrosAllDaysDisplay = displayMacrosUsingLocale(remainingMacrosAllDays, formatNumber);

  return {
    consumedMacrosAllDaysDisplay,
    goalAllDaysCompletionResult,
    goalAllDaysCompletionResultDisplay,
    runningDailyTotal,
    remainingMacrosAllDaysDisplay,
  };
}
