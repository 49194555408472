import React from "react";
import { useIntl, defineMessages } from "react-intl";
import Card from "../Card/Card.js";
import useTitle from "../../lib/useTitle.js";

const messages = defineMessages({
  notFoundTitle: {
    id: "error.notFoundTitle",
    defaultMessage: "Not Found",
    description: "Page Title",
  },
  notFoundDescription: {
    id: "error.notFoundDescription",
    defaultMessage: "The page you were looking for was not found.",
    description: "Page Description",
  },
  errorTitle: {
    id: "error.errorTitle",
    defaultMessage: "Error",
    description: "Page Title",
  },
  errorDescription: {
    id: "error.errorDescription",
    defaultMessage: "There was an error showing this page.",
    description: "Page Description",
  },
});

export default function NotFound({ statusCode = 404 }) {
  const { formatMessage: t } = useIntl();
  const notFoundMessage = {
    title: messages.notFoundTitle,
    message: messages.notFoundDescription,
  };

  const systemErrorMessage = {
    title: messages.errorTitle,
    message: messages.errorDescription,
  };

  const messageToDisplay = statusCode === 404 ? notFoundMessage : systemErrorMessage;
  useTitle(t(messageToDisplay.title));
  return (
    <div>
      <Card title={t(messageToDisplay.title)}>{t(messageToDisplay.message)}</Card>
    </div>
  );
}
