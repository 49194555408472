import TableSortLabel from "@mui/material/TableSortLabel";
import React from "react";

export default function SortableLabel({ orderBy, order, label, id, onChange }) {
  const onClick = () => {
    let newOrderBy = orderBy;
    let newOrder;

    if (orderBy === id) {
      newOrder = order === "asc" ? "desc" : "asc";
    } else {
      newOrderBy = id;
      newOrder = "asc";
    }

    onChange({
      orderBy: newOrderBy,
      order: newOrder,
    });
  };
  return (
    <TableSortLabel active={orderBy === id} direction={orderBy === id ? order : "asc"} onClick={onClick}>
      {label}
      {orderBy === id ? (
        <span style={{ display: "none" }}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
      ) : null}
    </TableSortLabel>
  );
}
