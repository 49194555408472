import { useEffect } from "react";
import { sendPageView } from "./sendMetric.js";

export default function useSendPageView(title, location, inputs = []) {
  useEffect(() => {
    if (!process.env.BROWSER) {
      return;
    }

    sendPageView({
      title: title || document.title,
      location: location || window.location.href,
    });
  }, inputs);
}
