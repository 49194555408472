import { useSelector } from "react-redux";

export const convertIngredientToIngredientItem = (ingredient) => ({
  id: ingredient.id,
  title: ingredient.brand ? `${ingredient.name} (${ingredient.brand})` : ingredient.name,
  type: "Ingredient",
  item: ingredient,
});

export function combineFoodList({ ingredients, recipes }) {
  const ingredientListItems = !ingredients ? [] : ingredients.map(convertIngredientToIngredientItem);

  const recipeListItems = !recipes
    ? []
    : recipes.map((recipe) => ({
        id: recipe.id,
        title: recipe.name,
        type: "Recipe",
        item: recipe,
      }));

  return [...ingredientListItems, ...recipeListItems].sort((item) => item.title);
}

export default function useFoodList({ ingredientsOnly = false } = {}) {
  const data = useSelector((state) => ({
    recipes: [],
    ingredients: state.ingredients.ingredients,
  }));

  const items = combineFoodList({ ingredients: data.ingredients, recipes: ingredientsOnly ? [] : data.recipes });

  return {
    items,
    findIngredientById: (itemId) => data.ingredients.find((i) => i.id === itemId),
    findItem: (itemId) => items.find((i) => i.id === itemId),
  };
}
