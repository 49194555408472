import MenuItem from "@mui/material/MenuItem";
import React from "react";
import GradingIcon from "@mui/icons-material/Grading";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import useAccount from "../../contexts/Account/useAccount.js";

export default function PrintStateMenuOption({ onClick }) {
  const { account } = useAccount();
  if (!account?.isAdmin) return null;
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <GradingIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Show State" />
    </MenuItem>
  );
}
