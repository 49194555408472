import Big from "big.js";

export function profileIncludesValue(profile) {
  return (name) => {
    const hasKey = Object.prototype.hasOwnProperty.call(profile, name);
    if (!hasKey) return false;
    const value = profile[name];
    return !(value === null || value === undefined);
  };
}

export function convertToNumber(value) {
  if (!value) return new Big(0);
  if (value[1] === 0) return new Big(value[0]);
  const multiplier = 10 ** value[1];
  return new Big(value[0]).div(multiplier);
}

export function convertToDisplay(nutrientMeasurement, excludeUnits = false, unitValue = "g") {
  const unitValueToUse = excludeUnits ? "" : unitValue;
  const originalValue = convertToNumber(nutrientMeasurement);
  return `${originalValue.toString()}${unitValueToUse}`;
}

/**
 * Generate a display object using locale
 * @param {Object} nutritionalProfile - The nutritional profile with raw array numbers
 * @param {Function} formatNumber - The locale number formatter
 * @param {Boolean} [inverted] - If the negative numbers should be marked as positive
 * @return {{saturatedFat: string, fiber: string, sugars: string, salt: string, protein: string, fat: string, calories: string, carbohydrate: string, energy: string}}
 */
export function displayMacrosUsingLocale(nutritionalProfile, formatNumber, inverted) {
  const hasValue = profileIncludesValue(nutritionalProfile);
  const noValueMarker = "-";
  const formatUnits = {
    style: "unit",
    unit: "gram",
    unitDisplay: "narrow",
  };

  const result = {
    energy: noValueMarker,
    calories: noValueMarker,
    fat: noValueMarker,
    saturatedFat: noValueMarker,
    carbohydrate: noValueMarker,
    sugars: noValueMarker,
    fiber: noValueMarker,
    protein: noValueMarker,
    salt: noValueMarker,
  };

  const applyInverstion = (value) => {
    if (inverted && value < 0) {
      return `+${formatNumber(-value, formatUnits)}`;
    }
    return formatNumber(value, formatUnits);
  };

  if (hasValue("energy")) {
    result.energy = formatNumber(nutritionalProfile.energy);
    result.energyValue = nutritionalProfile.energy;
  }

  if (hasValue("calories")) {
    result.calories = formatNumber(nutritionalProfile.calories);
    result.caloriesValue = nutritionalProfile.calories;
  }

  if (hasValue("fat")) {
    result.fatValue = convertToNumber(nutritionalProfile.fat).toNumber();
    result.fat = applyInverstion(result.fatValue);
  }

  if (hasValue("saturatedFat")) {
    result.saturatedFatValue = convertToNumber(nutritionalProfile.saturatedFat).toNumber();
    result.saturatedFat = applyInverstion(result.saturatedFatValue);
  }

  if (hasValue("carbohydrate")) {
    result.carbohydrateValue = convertToNumber(nutritionalProfile.carbohydrate).toNumber();
    result.carbohydrate = applyInverstion(result.carbohydrateValue);
  }

  if (hasValue("sugars")) {
    result.sugarsValue = convertToNumber(nutritionalProfile.sugars).toNumber();
    result.sugars = applyInverstion(result.sugarsValue);
  }

  if (hasValue("fiber")) {
    result.fiberValue = convertToNumber(nutritionalProfile.fiber).toNumber();
    result.fiber = applyInverstion(result.fiberValue);
  }

  if (hasValue("protein")) {
    result.proteinValue = convertToNumber(nutritionalProfile.protein).toNumber();
    result.protein = applyInverstion(result.proteinValue);
  }

  if (hasValue("salt")) {
    result.saltValue = convertToNumber(nutritionalProfile.salt).toNumber();
    result.salt = applyInverstion(result.saltValue);
  }

  return result;
}

export function addSortableRadioNumbers(sortableNutritionalProfile, item) {
  if (item?.breakdown?.available !== true) return sortableNutritionalProfile;

  return {
    ...sortableNutritionalProfile,
    proteinRatio: item.breakdown.ratio.protein,
    fatRatio: item.breakdown.ratio.fat,
    carbohydrateRatio: item.breakdown.ratio.carbohydrate,
  };
}

export function castToSortableNumbers(nutritionalProfile) {
  const hasValue = profileIncludesValue(nutritionalProfile);
  const noValueMarker = -1;
  return {
    energy: hasValue("energy") ? nutritionalProfile.energy : noValueMarker,
    calories: hasValue("calories") ? nutritionalProfile.calories : noValueMarker,
    fat: hasValue("fat") ? convertToNumber(nutritionalProfile.fat).toNumber() : noValueMarker,
    saturatedFat: hasValue("saturatedFat")
      ? convertToNumber(nutritionalProfile.saturatedFat).toNumber()
      : noValueMarker,
    carbohydrate: hasValue("carbohydrate")
      ? convertToNumber(nutritionalProfile.carbohydrate).toNumber()
      : noValueMarker,
    sugars: hasValue("sugars") ? convertToNumber(nutritionalProfile.sugars).toNumber() : noValueMarker,
    fiber: hasValue("fiber") ? convertToNumber(nutritionalProfile.fiber).toNumber() : noValueMarker,
    protein: hasValue("protein") ? convertToNumber(nutritionalProfile.protein).toNumber() : noValueMarker,
    salt: hasValue("salt") ? convertToNumber(nutritionalProfile.salt).toNumber() : noValueMarker,
  };
}

export default function displayMacros(nutritionalProfile, excludeUnits = false, excludeNumberFormatting = false) {
  const hasValue = profileIncludesValue(nutritionalProfile);
  const noValueMarker = excludeUnits ? "" : "-";
  const numberFormatter = new Intl.NumberFormat();
  const formatNumber = excludeNumberFormatting ? (number) => number : numberFormatter.format;
  return {
    energy: hasValue("energy") ? formatNumber(nutritionalProfile.energy) : noValueMarker,
    calories: hasValue("calories") ? formatNumber(nutritionalProfile.calories) : noValueMarker,
    fat: hasValue("fat") ? convertToDisplay(nutritionalProfile.fat, excludeUnits) : noValueMarker,
    saturatedFat: hasValue("saturatedFat")
      ? convertToDisplay(nutritionalProfile.saturatedFat, excludeUnits)
      : noValueMarker,
    carbohydrate: hasValue("carbohydrate")
      ? convertToDisplay(nutritionalProfile.carbohydrate, excludeUnits)
      : noValueMarker,
    sugars: hasValue("sugars") ? convertToDisplay(nutritionalProfile.sugars, excludeUnits) : noValueMarker,
    fiber: hasValue("fiber") ? convertToDisplay(nutritionalProfile.fiber, excludeUnits) : noValueMarker,
    protein: hasValue("protein") ? convertToDisplay(nutritionalProfile.protein, excludeUnits) : noValueMarker,
    salt: hasValue("salt") ? convertToDisplay(nutritionalProfile.salt, excludeUnits) : noValueMarker,
  };
}
