import { format } from "date-fns";

/**
 * Generate a ID string from a date.
 * @param {Date} date - The date to format
 * @return {string} - An ID string like "20240812"
 */
export default function dateToId(date) {
  return format(date, "yyyyMMdd");
}
