import Checkbox from "@mui/material/Checkbox";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { TextField, Grid, Dialog, DialogActions, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Divider from "@mui/material/Divider";
import useTitle from "../../lib/useTitle.js";
import GlobalMessages from "../messages/Global.js";
import AppMessages from "../messages/App.js";
import ActionMessages from "../messages/Actions.js";
import ProfileContext from "../../contexts/Profile/ProfileContext.js";
import MacroGoal from "../Settings/MacroGoal.js";
import { getEmptyNutritionalProfile } from "../../data/calculateMacros.js";

export default function EditMealPlanModalContent({ updateMealPlan, open, onClose, mealPlan }) {
  const { formatMessage: t } = useIntl();
  const { profile } = useContext(ProfileContext);
  const [goal, setGoal] = useState(mealPlan.goal || profile.goal || getEmptyNutritionalProfile());
  const title = `${mealPlan.name} - ${t(AppMessages.mealPlan)}`;
  useTitle(title);
  const [mealPlanName, setMealPlanName] = useState(mealPlan.name);
  const [notes, setNotes] = useState(mealPlan.notes || "");
  const [isActive, setIsActive] = useState(mealPlan.isActive || false);

  const handleSave = () => {
    updateMealPlan({
      name: mealPlanName,
      goal,
      notes,
      isActive,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t(AppMessages.mealPlan)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ marginBottom: 1 }}>
          <Grid item xs={8}>
            <TextField
              value={mealPlanName}
              onChange={(event) => {
                setMealPlanName(event.target.value);
              }}
              variant="outlined"
              label={t(GlobalMessages.name)}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <FormControlLabel
              control={<Checkbox checked={isActive} size="small" onChange={() => setIsActive(!isActive)} />}
              label={t(GlobalMessages.defaultPlan)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={notes}
              onChange={(event) => {
                setNotes(event.target.value);
              }}
              variant="outlined"
              label={t(GlobalMessages.notes)}
              multiline
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              rows={3}
              placeholder={t(GlobalMessages.notesPlaceholder)}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 1 }} />
        <MacroGoal goal={goal} onChange={setGoal} onlyForm />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          {t(ActionMessages.cancel)}
        </Button>
        <Button
          startIcon={<CheckIcon fontSize="small" />}
          variant="contained"
          color="primary"
          onClick={handleSave}
          size="small"
        >
          {t(ActionMessages.save)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
