import Box from "@mui/material/Box";
import React, { useContext } from "react";
import { createPortal } from "react-dom";
import cx from "classnames";
import { useIntl } from "react-intl";
import useStyles from "../../isomorphic-style-loader/useStyles.js";
import MenuOpenContext from "../../contexts/MenuOpen/MenuOpenContext.js";
import styles from "./MobileMenu.css";
import Link from "../Link.js";
import AppMessages from "../messages/App.js";
import usePortal from "./usePortal.js";

const MobileMenuPortal = ({ children }) => {
  const target = usePortal("mnu");
  return createPortal(children, target);
};

export default function MobileMenu() {
  useStyles(styles);
  const { formatMessage: t } = useIntl();

  const { isOpen, toggle } = useContext(MenuOpenContext);

  return (
    <MobileMenuPortal>
      <Box
        sx={{
          overflow: "hidden",
          top: 0,
          width: 0,
          height: "100%",
          position: "fixed",
          zIndex: 1000,
          transition: "transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1)",
        }}
        className={cx({
          [styles.containerOpen]: isOpen,
        })}
      >
        <div
          onClick={toggle}
          className={cx(styles.overlay, {
            [styles.overlayOpen]: isOpen,
          })}
        />
        <div
          onClick={toggle}
          className={cx(styles.menu, {
            [styles.open]: isOpen,
          })}
        >
          <div>
            <Link to="/">{t(AppMessages.home)}</Link>
          </div>
          <div>
            <Link to="/ingredient">{t(AppMessages.foods)}</Link>
          </div>
          <div>
            <Link to="/meals">{t(AppMessages.meals)}</Link>
          </div>
          <div>
            <Link to="/calendar/this-week">{t(AppMessages.thisWeek)}</Link>
          </div>
          <div>
            <Link to="/calendar/next-week">{t(AppMessages.nextWeek)}</Link>
          </div>
          <div>
            <Link to="/calendar">{t(AppMessages.calendar)}</Link>
          </div>
          <div>
            <Link to="/plan">{t(AppMessages.mealPlan)}</Link>
          </div>
          <div>
            <Link to="/settings">{t(AppMessages.settings)}</Link>
          </div>
        </div>
      </Box>
    </MobileMenuPortal>
  );
}
