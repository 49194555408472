import React from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router";
import AppHeaderBar from "./AppHeaderBar.js";
import Offset from "./Offset.js";
import ToastContainer from "./ToastContainer.js";
import useConfig from "../../contexts/Config/useConfig.js";
import useAccount from "../../contexts/Account/useAccount.js";
import MobileMenu from "./MobileMenu.js";
import Footer from "./Footer.js";

export default function AppLayout() {
  const { isAuthenticated, isLoadingFailed, isLoaded, isLoading, profile, lastChecked, lastLoadAttempted } =
    useAccount();
  const { isShellPage } = useConfig();
  const isReady = isLoaded && !isLoadingFailed;

  const isReadyAndLoggedIn = isReady && isAuthenticated;
  const hasName = isReadyAndLoggedIn && profile.name && profile.name.length;

  return (
    <>
      {isReady && <MobileMenu />}
      {isReady && <ToastContainer />}
      <AppHeaderBar />
      <Offset />
      <Box
        component="main"
        sx={{
          // marginTop: "72px",
          paddingBottom: "env(safe-area-inset-bottom, 0)",
          paddingLeft: "env(safe-area-inset-left, 0)",
          paddingRight: "env(safe-area-inset-right, 0)",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "22px 22px 0",
            flex: 1,
            color: "white",
            // backgroundColor: "pink",
          }}
        >
          <Outlet />
        </Box>
        <Footer
          isAuthenticated={isAuthenticated}
          isReady={isReady}
          isLoading={isLoading}
          isShellPage={isShellPage}
          name={hasName ? profile.name : null}
          lastChecked={lastChecked}
          lastLoadAttempted={lastLoadAttempted}
        />
      </Box>
    </>
  );
}
