import Box from "@mui/material/Box";
import React, { useContext } from "react";
import { createPortal } from "react-dom";
import Toast from "../Toast/Toast.js";
import usePortal from "./usePortal.js";
import ToastContext from "../../contexts/Toast/ToastContext.js";

const ToastContainerPortal = ({ children }) => {
  const target = usePortal("tst");
  return createPortal(children, target);
};

export default function ToastContainer() {
  const { toast, deleteToast } = useContext(ToastContext);
  return (
    <ToastContainerPortal>
      <Box
        sx={{
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          color: "#545454",
          fontSize: "14px",
          fontVariant: "tabular-nums",
          lineHeight: 1.3,
          listStyle: "none",
          fontFeatureSettings: "'tnum'",
          position: "fixed",
          top: "16px",
          left: 0,
          zIndex: 1010,
          width: "100%",
          pointerEvents: "none",
        }}
      >
        {toast.map((toastToDisplay) => (
          <Toast text={toastToDisplay} key={`${toastToDisplay}`} onDelete={() => deleteToast(toastToDisplay)} />
        ))}
      </Box>
    </ToastContainerPortal>
  );
}
