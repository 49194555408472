import Big from "big.js";
import { precision } from "../data/calculateMacros.js";

export default function parseNumericInput(input, asAbsolute) {
  const inputAsString = `${input}`;
  const empty = input === undefined || input === null || !inputAsString || !inputAsString.length;

  if (empty) {
    return {
      empty: true,
      value: "",
      number: undefined,
      valid: true,
    };
  }

  const inputAsNumber = Number.parseFloat(inputAsString);
  const inputIsNumber = !Number.isNaN(inputAsNumber);

  if (!inputIsNumber) {
    return {
      empty: false,
      value: inputAsString,
      number: undefined,
      valid: false,
    };
  }

  const inputPrecision = precision(inputAsNumber);

  let number;

  if (asAbsolute) {
    if (inputPrecision === 0) {
      number = inputAsNumber;
    }
  } else {
    if (inputPrecision === 0) {
      number = [inputAsNumber, 0];
    }

    const resultMultiplier = 10 ** inputPrecision;
    number = [new Big(inputAsNumber).times(resultMultiplier).toNumber(), inputPrecision];
  }
  const valid = (asAbsolute && number >= 0) || (!asAbsolute && number[0] >= 0);

  return {
    empty: false,
    value: valid ? `${inputAsNumber}` : `${inputAsString}`,
    number,
    valid,
  };
}

export function parseValidNumber(input, asAbsolute) {
  const result = parseNumericInput(input, asAbsolute);
  if (result.valid && !result.empty) {
    return result.number;
  }
  return undefined;
}
