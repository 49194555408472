import { startOfWeek } from "date-fns";
import { combineNutritionalProfile } from "../../data/calculateMacros.js";
import displayMacros, { displayMacrosUsingLocale } from "../../data/displayMacros.js";
import calculateItemsDependentStateValues from "./calculateItemsDependentStateValues.js";

export default function mealPlanEditorReducerInitializer(locale, longDateFormat, formatNumber) {
  return function mealPlanEditorReducerInitializerWithLocale(state) {
    const goal = state.goal || state.mealPlan.goal;
    const startDayThisWeek = startOfWeek(new Date(), { weekStartsOn: state.weekStartsOn });
    const goalMacrosDisplay = displayMacros(goal);
    const goalAllDays = combineNutritionalProfile([goal, goal, goal, goal, goal, goal, goal]);
    const goalAllDaysDisplay = displayMacrosUsingLocale(goalAllDays, formatNumber);

    const stateProgress = {
      ...state,
      startDayThisWeek,
      goalMacrosDisplay,
      goalAllDays,
      goalAllDaysDisplay,
    };

    const {
      consumedMacrosAllDaysDisplay,
      goalAllDaysCompletionResult,
      goalAllDaysCompletionResultDisplay,
      runningDailyTotal,
      remainingMacrosAllDaysDisplay,
    } = calculateItemsDependentStateValues(stateProgress, locale, longDateFormat, formatNumber);

    return {
      ...state,
      locale,
      longDateFormat,
      formatNumber,
      startDayThisWeek,
      goalMacrosDisplay,
      goalAllDays,
      goalAllDaysDisplay,
      consumedMacrosAllDaysDisplay,
      goalAllDaysCompletionResult,
      goalAllDaysCompletionResultDisplay,
      runningDailyTotal,
      remainingMacrosAllDaysDisplay,
    };
  };
}
