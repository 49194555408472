import React from "react";
import { useIntl } from "react-intl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ActionMessages from "../messages/Actions.js";
import SettingsMessages from "../messages/Settings.js";
import withingsBlk from "./withings_blk.png";
import { WidgetBody } from "./WidgetWrapper.js";
import { WidgetWrapperCard } from "./Widget.js";

export default function WithingsConnectCard({ sx, showIcon = false }) {
  const { formatMessage: t } = useIntl();
  return (
    <WidgetWrapperCard sx={sx}>
      <WidgetBody noPadding>
        {showIcon && (
          <Box
            sx={{
              flex: "0 0 auto",
              padding: "14px 0 14px 14px",
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            <Box
              component="img"
              sx={{
                width: "60px",
                height: "60px",
              }}
              src={withingsBlk}
              alt="Withings"
            />
          </Box>
        )}
        <Box
          sx={{
            flex: "1 1 auto",
            padding: "24px",
            fontSize: "14px",
          }}
        >
          {t(SettingsMessages.withingsPrompt)}
        </Box>
        <Box
          sx={{
            flex: "0 0 auto",
            padding: "24px 24px 24px 0",
          }}
        >
          <Button disabled component="a" href="/api/withings/start" variant="contained">
            {t(ActionMessages.connect)}
          </Button>
        </Box>
      </WidgetBody>
    </WidgetWrapperCard>
  );
}
