import { startOfDay, format } from "date-fns";
import { DAY_DELETE, DAY_UPDATE, DAY_LOAD_FROM_STORAGE, DAY_CREATE } from "../actions/day.js";
import { LOG_ENTRY_CREATE, LOG_ENTRY_DELETE, LOG_ENTRY_UPDATE } from "../actions/logEntry.js";
import {
  addNutritionalProfile,
  subtractNutritionalProfile,
  getEmptyNutritionalProfile,
} from "../data/calculateMacros.js";
import getDay from "./getDay.js";

const initialState = {
  days: [],
};

function hasAnyItems(entryDay) {
  return entryDay?.items?.length;
}

function hasAnyConsumedItems(entryDay) {
  const onlyConsumed = (item) => item.consumed;
  return entryDay?.items?.filter(onlyConsumed).length;
}

function convertLogEntryForDiaryDay(logEntryToTransform) {
  const entry = {
    consumed: logEntryToTransform.consumed,
    nutritionalProfile: logEntryToTransform.nutritionalProfile,
    id: logEntryToTransform.id,
    time: logEntryToTransform.time,
  };

  if (logEntryToTransform.ingredientId) entry.ingredientId = logEntryToTransform.ingredientId;
  if (logEntryToTransform.recipeId) entry.recipeId = logEntryToTransform.recipeId;
  if (logEntryToTransform.grams) entry.grams = logEntryToTransform.grams;
  if (logEntryToTransform.quantity) entry.quantity = logEntryToTransform.quantity;

  return entry;
}

export function DayReducer(day, action) {
  if (day.id !== action.id) {
    return day;
  }
  switch (action.type) {
    case DAY_UPDATE:
      return { ...day, ...action.day };
    default:
      return day;
  }
}

function logEntry(days, action) {
  const now = action.logEntry.date;
  if (!now) {
    console.warn("Missing Date", action.logEntry);
  }
  const todayStartOfDay = startOfDay(now);

  const today = getDay(days, todayStartOfDay);

  if (!today && (action.type === LOG_ENTRY_DELETE || action.type === LOG_ENTRY_UPDATE)) {
    // We did not find a day that matches the updated/deleted log entry, throw?
    return days;
  }

  if (!today && action.type === LOG_ENTRY_CREATE) {
    // We're creating a day, and the new entry for it straight away.
    const newDay = {
      id: format(todayStartOfDay, "yyyyMMdd"),
      date: todayStartOfDay,
      total: {
        ...action.logEntry.nutritionalProfile,
      },
      consumed: action.logEntry.consumed ? { ...action.logEntry.nutritionalProfile } : getEmptyNutritionalProfile(),
      items: [convertLogEntryForDiaryDay(action.logEntry)],
    };

    return [...days, newDay];
  }

  // We're going to add/update/delete a log entry on an existing day.
  return days.map((dayToUpdate) => {
    if (dayToUpdate.id !== today.id) {
      return dayToUpdate;
    }

    // Remove the entry
    if (action.type === LOG_ENTRY_DELETE) {
      const entryToRemoveFromArray = dayToUpdate.items.find((logEntryToRemove) => logEntryToRemove.id === action.id);
      if (!entryToRemoveFromArray) {
        return dayToUpdate;
      }
      const updatedEntryDay = {
        ...dayToUpdate,
        total: subtractNutritionalProfile(dayToUpdate.total, entryToRemoveFromArray.nutritionalProfile),
        consumed: action.logEntry.consumed
          ? subtractNutritionalProfile(dayToUpdate.consumed, entryToRemoveFromArray.nutritionalProfile)
          : dayToUpdate.consumed,
        items: dayToUpdate.items.filter((logEntryToRemove) => logEntryToRemove.id !== action.id),
      };

      if (!hasAnyItems(updatedEntryDay)) {
        updatedEntryDay.total = getEmptyNutritionalProfile();
        updatedEntryDay.consumed = getEmptyNutritionalProfile();
      } else if (!hasAnyConsumedItems(updatedEntryDay)) {
        updatedEntryDay.consumed = getEmptyNutritionalProfile();
      }

      return updatedEntryDay;
    }

    // Update the entry
    if (action.type === LOG_ENTRY_UPDATE) {
      const existingLogEntry = dayToUpdate.items.find((existingLogEntries) => existingLogEntries.id === action.id);
      if (!existingLogEntry) {
        // We're trying to update an entry, but it was not found. Throw?
        return dayToUpdate;
      }

      let updatedEntryDayConsumed = dayToUpdate.consumed;

      if (existingLogEntry.consumed && !action.logEntry.consumed) {
        // The entry is being unmarked as consumed.
        updatedEntryDayConsumed = subtractNutritionalProfile(
          updatedEntryDayConsumed,
          action.logEntry.nutritionalProfile,
        );
      } else if (!existingLogEntry.consumed && action.logEntry.consumed) {
        // The entry is being marked as consumed.
        updatedEntryDayConsumed = addNutritionalProfile(updatedEntryDayConsumed, action.logEntry.nutritionalProfile);
      }

      const updatedEntryDay = {
        ...dayToUpdate,
        consumed: updatedEntryDayConsumed,
        items: dayToUpdate.items.map((updatedEntryDayTimedEntry) => {
          if (updatedEntryDayTimedEntry.id !== action.id) return updatedEntryDayTimedEntry;
          return convertLogEntryForDiaryDay(action.logEntry);
        }),
      };

      if (!hasAnyItems(updatedEntryDay)) {
        updatedEntryDay.total = getEmptyNutritionalProfile();
        updatedEntryDay.consumed = getEmptyNutritionalProfile();
      } else if (!hasAnyConsumedItems(updatedEntryDay)) {
        updatedEntryDay.consumed = getEmptyNutritionalProfile();
      }

      return updatedEntryDay;
    }

    // Add the entry
    if (action.type === LOG_ENTRY_CREATE) {
      return {
        ...dayToUpdate,
        total: addNutritionalProfile(dayToUpdate.total, action.logEntry.nutritionalProfile),
        consumed: action.logEntry.consumed
          ? addNutritionalProfile(dayToUpdate.consumed, action.logEntry.nutritionalProfile)
          : dayToUpdate.consumed,
        items: [...(dayToUpdate.items || []), convertLogEntryForDiaryDay(action.logEntry)],
      };
    }

    // No updates apply.
    return dayToUpdate;
  });
}

function days(state = initialState, action) {
  switch (action.type) {
    case DAY_DELETE:
      return {
        ...state,
        days: state.days.filter((day) => day.id !== action.id),
      };
    case DAY_UPDATE:
      return {
        ...state,
        days: state.days.map((day) => DayReducer(day, action)),
      };
    case DAY_CREATE:
      return {
        ...state,
        days: [action.day, ...state.days],
      };
    case DAY_LOAD_FROM_STORAGE:
      return {
        ...state,
        isLoaded: true,
        days: [...action.days],
      };
    case LOG_ENTRY_CREATE:
    case LOG_ENTRY_UPDATE:
    case LOG_ENTRY_DELETE:
      return {
        ...state,
        days: logEntry(state.days, action),
      };
    default:
      return state;
  }
}

export default days;
