import GlobalMessages from "../components/messages/Global.js";

export const DAY_MEALS = {
  BREAKFAST: "breakfast",
  LUNCH: "lunch",
  DINNER: "dinner",
  SNACKS: "snacks",
  PREWORKOUT: "preworkout",
  POSTWORKOUT: "postworkout",
};

export const DayMeals = {};
DayMeals[DAY_MEALS.BREAKFAST] = GlobalMessages.breakfast;
DayMeals[DAY_MEALS.LUNCH] = GlobalMessages.lunch;
DayMeals[DAY_MEALS.DINNER] = GlobalMessages.dinner;
DayMeals[DAY_MEALS.SNACKS] = GlobalMessages.snacks;
DayMeals[DAY_MEALS.PREWORKOUT] = GlobalMessages.preworkout;
DayMeals[DAY_MEALS.POSTWORKOUT] = GlobalMessages.postworkout;
