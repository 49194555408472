import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { WidgetBody } from "./WidgetWrapper.js";

export function WidgetInternal({ icon, title, body }) {
  return (
    <>
      {icon && (
        <Box
          sx={{
            width: "45px",
            height: "45px",
            marginRight: 2,
            flex: "0 0 45px",
            "@media screen and (max-width: 991px)": {
              display: "none",
            },
          }}
        >
          {icon}
        </Box>
      )}
      <Box
        sx={{
          flex: "1 1 auto",
          // textTransform: "capitalize",
          "@media screen and (max-width: 991px)": {
            lineHeight: "14px",
          },
        }}
      >
        <Typography
          component="span"
          variant="h5"
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            display: "block",
            "@media screen and (max-width: 991px)": {
              fontSize: "14px",
            },
          }}
        >
          {title}
        </Typography>
        <Typography
          component="span"
          variant="body2"
          sx={{
            fontSize: "14px",
            "@media screen and (max-width: 991px)": {
              fontSize: "10px",
            },
          }}
        >
          {body}
        </Typography>
      </Box>
    </>
  );
}

export function WidgetWrapperCard({ sx, selected, children, color }) {
  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{
        ...(sx || {}),
        display: "flex",
        width: "100%",
        flex: "1",
        borderColor: (theme) => {
          if (color) {
            // theme.palette.warning.dark
            const paletteColor = theme.palette[color];
            if (!paletteColor) return theme.palette.action.active;
            if (selected) {
              return paletteColor.light;
            }
            return paletteColor.dark;
          }

          if (selected) return theme.palette.action.active;
          return theme.palette.divider;
        },
      }}
    >
      {children}
    </Card>
  );
}

export default function Widget({ sx, icon, title, body, altText, noPadding }) {
  return (
    <WidgetWrapperCard sx={sx} title={altText} noPadding={noPadding}>
      <WidgetBody noPadding={noPadding}>
        <WidgetInternal icon={icon} title={title} body={body} />
      </WidgetBody>
    </WidgetWrapperCard>
  );
}
