import React, { useMemo } from "react";
import Button from "@mui/material/Button";
import { Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useHotkeys } from "react-hotkeys-hook";

export default function UserConfirmationModal({
  isOpen,
  title,
  text,
  cancelButtonLabel = "Cancel",
  submitButtonLabel = "Continue",
  onSubmit,
  onClose,
}) {
  const onPrimaryClick = async () => {
    onSubmit();
  };

  const onDialogClose = () => {
    onClose(false);
  };

  useHotkeys(
    "enter",
    () => {
      if (isOpen) return onPrimaryClick();
      return null;
    },
    [isOpen],
  );
  return useMemo(
    () => (
      <Dialog open={isOpen} onClose={onDialogClose}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary">
            {cancelButtonLabel}
          </Button>
          <Button onClick={onPrimaryClick} color="primary" variant="contained">
            {submitButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [isOpen, title, text],
  );
}
