import React from "react";
import DayCard from "./DayCard.js";

export default function DayCardsList({
  goal,
  days,
  ingredients,
  recipes,
  deleteLogEntry,
  editLogEntry,
  allowNewItems,
  isCalendarDate,
  onSort,
  orderBy,
  order,
  groupByMeal,
  onAddItem,
  showConsumeButton,
  toggleLogEntryConsumed,
  toggleMarkAsComplete,
}) {
  return (
    <>
      {days.map((day) => (
        <DayCard
          goal={goal}
          ingredients={ingredients}
          recipes={recipes}
          deleteLogEntry={deleteLogEntry}
          editLogEntry={editLogEntry}
          allowNewItems={allowNewItems}
          key={day.id}
          day={day}
          isCalendarDate={isCalendarDate}
          order={order}
          orderBy={orderBy}
          onSort={onSort}
          groupByMeal={groupByMeal}
          onAddItem={onAddItem}
          showConsumeButton={showConsumeButton}
          toggleLogEntryConsumed={toggleLogEntryConsumed}
          toggleMarkAsComplete={toggleMarkAsComplete}
        />
      ))}
    </>
  );
}
