import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Card from "../Card/Card.js";
import DayCardSortableHeaderRow from "../Calendar/DayCardSortableHeaderRow.js";
import GlobalMessages from "../messages/Global.js";
import ArrowIndicator from "./ArrowIndicator.js";

function ValueText({ value, ratio, ratioValue, macro }) {
  const label = (
    <Typography component="span" variant="body2" color="text.disabled" paddingLeft={1} display="inline-block">
      {ratio}
    </Typography>
  );
  return (
    <>
      <Typography component="span" variant="body1">
        {value}
      </Typography>
      <ArrowIndicator value={ratioValue} label={label} macro={macro} />
    </>
  );
}
export default function WeekSummaryCard({
  onSort,
  runningDailyTotal,
  order,
  orderBy,
  consumedMacrosAllDaysDisplay,
  goalAllDaysCompletionResultDisplay,
  goalAllDaysDisplay,
  remainingMacrosAllDaysDisplay,
  title,
  hideToolbar,
  goalAllDaysCompletionResult,
  onDaySelected,
  showMarkedAsComplete,
}) {
  const { formatMessage: t } = useIntl();

  return (
    <Card title={hideToolbar ? undefined : title || `${t(GlobalMessages.total)}`} dense>
      <TableContainer>
        <Table size="small" sx={hideToolbar ? { marginTop: 1, marginBottom: 1 } : undefined}>
          <TableHead>
            <DayCardSortableHeaderRow order={order} orderBy={orderBy} onSort={onSort} disableSort disableActions />
          </TableHead>
          <TableBody>
            {runningDailyTotal.map(
              ({ title: dayTitle, id, consumedDisplay, completionDisplay, allItems, completion, day }) => {
                const referenceDay = day.diary ? day.diary : null;

                const onDayClick = () => {
                  onDaySelected(id, day);
                };
                return (
                  <TableRow hover key={id} onClick={onDayClick}>
                    <TableCell
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                        component="span"
                      >
                        {showMarkedAsComplete && referenceDay?.markedAsComplete === true && (
                          <CheckCircleIcon fontSize="small" color="success" sx={{ marginRight: 2 }} />
                        )}
                        {showMarkedAsComplete && referenceDay?.markedAsComplete !== true && (
                          <CheckCircleOutlineIcon fontSize="small" color="disabled" sx={{ marginRight: 2 }} />
                        )}

                        <span>{dayTitle}</span>
                      </Box>

                      <Typography
                        component="span"
                        variant="body2"
                        color="text.disabled"
                        paddingLeft={1}
                        display="inline-block"
                      >
                        {t(GlobalMessages.itemCount, { itemCount: allItems.length })}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={`${consumedDisplay.energy} kj / ${consumedDisplay.calories} kcal`}
                        arrow
                        placement="right"
                      >
                        <span>
                          <ValueText
                            value={consumedDisplay.calories}
                            ratio={completionDisplay.calories}
                            ratioValue={completion.calories}
                            macro="calories"
                          />
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <ValueText
                        value={consumedDisplay.protein}
                        ratio={completionDisplay.protein}
                        ratioValue={completion.protein}
                        macro="protein"
                      />
                    </TableCell>
                    <TableCell>
                      <ValueText
                        value={consumedDisplay.fat}
                        ratio={completionDisplay.fat}
                        ratioValue={completion.fat}
                        macro="fat"
                      />
                    </TableCell>
                    <TableCell>
                      <ValueText
                        value={consumedDisplay.sugars}
                        ratio={completionDisplay.sugars}
                        ratioValue={completion.sugars}
                        macro="sugars"
                      />
                    </TableCell>
                    <TableCell>
                      <ValueText
                        value={consumedDisplay.carbohydrate}
                        ratio={completionDisplay.carbohydrate}
                        ratioValue={completion.carbohydrate}
                        macro="carbohydrate"
                      />
                    </TableCell>
                    <TableCell>
                      <ValueText
                        value={consumedDisplay.salt}
                        ratio={completionDisplay.salt}
                        ratioValue={completion.salt}
                        macro="salt"
                      />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableBody>
          <TableFooter>
            <DayCardSortableHeaderRow order={order} orderBy={orderBy} onSort={onSort} disableSort disableActions />

            <TableRow hover>
              <TableCell>{t(GlobalMessages.total)}</TableCell>
              <TableCell>
                <Tooltip
                  title={`${consumedMacrosAllDaysDisplay.energy} kj / ${consumedMacrosAllDaysDisplay.calories} kcal`}
                  arrow
                  placement="right"
                >
                  <span>{consumedMacrosAllDaysDisplay.calories}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{consumedMacrosAllDaysDisplay.protein}</TableCell>
              <TableCell>{consumedMacrosAllDaysDisplay.fat}</TableCell>
              <TableCell>{consumedMacrosAllDaysDisplay.sugars}</TableCell>
              <TableCell>{consumedMacrosAllDaysDisplay.carbohydrate}</TableCell>
              <TableCell>{consumedMacrosAllDaysDisplay.salt}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>{t(GlobalMessages.goal)}</TableCell>
              <TableCell>
                <Tooltip
                  title={`${goalAllDaysDisplay.energy} kj / ${goalAllDaysDisplay.calories} kcal`}
                  arrow
                  placement="right"
                >
                  <span> {goalAllDaysDisplay.calories}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{goalAllDaysDisplay.protein}</TableCell>
              <TableCell>{goalAllDaysDisplay.fat}</TableCell>
              <TableCell>{goalAllDaysDisplay.sugars}</TableCell>
              <TableCell>{goalAllDaysDisplay.carbohydrate}</TableCell>
              <TableCell>{goalAllDaysDisplay.salt}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>{t(GlobalMessages.completion)}</TableCell>
              <TableCell>
                <ArrowIndicator
                  value={goalAllDaysCompletionResult.calories}
                  label={goalAllDaysCompletionResultDisplay.calories}
                  macro="calories"
                />
              </TableCell>
              <TableCell>
                <ArrowIndicator
                  value={goalAllDaysCompletionResult.protein}
                  label={goalAllDaysCompletionResultDisplay.protein}
                  macro="protein"
                />
              </TableCell>
              <TableCell>
                <ArrowIndicator
                  value={goalAllDaysCompletionResult.fat}
                  label={goalAllDaysCompletionResultDisplay.fat}
                  macro="fat"
                />
              </TableCell>
              <TableCell>
                <ArrowIndicator
                  value={goalAllDaysCompletionResult.sugars}
                  label={goalAllDaysCompletionResultDisplay.sugars}
                  macro="sugars"
                />
              </TableCell>
              <TableCell>
                <ArrowIndicator
                  value={goalAllDaysCompletionResult.carbohydrate}
                  label={goalAllDaysCompletionResultDisplay.carbohydrate}
                  macro="carbohydrate"
                />
              </TableCell>
              <TableCell>
                <ArrowIndicator
                  value={goalAllDaysCompletionResult.salt}
                  label={goalAllDaysCompletionResultDisplay.salt}
                  macro="salt"
                />
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>{t(GlobalMessages.remaining)}</TableCell>
              <TableCell>{remainingMacrosAllDaysDisplay.calories}</TableCell>
              <TableCell>{remainingMacrosAllDaysDisplay.protein}</TableCell>
              <TableCell>{remainingMacrosAllDaysDisplay.fat}</TableCell>
              <TableCell>{remainingMacrosAllDaysDisplay.sugars}</TableCell>
              <TableCell>{remainingMacrosAllDaysDisplay.carbohydrate}</TableCell>
              <TableCell>{remainingMacrosAllDaysDisplay.salt}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Card>
  );
}
