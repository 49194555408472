import { defineMessages } from "react-intl";

const Support = defineMessages({
  title: {
    id: "support.title",
    defaultMessage: "Support",
    description: "Page title.",
  },
  versionInfo: {
    id: "support.versionInfo",
    defaultMessage: "You are running version {version} which was built {buildDate}.",
    description: "The version of the application, and when it was made.",
  },
  contact: {
    id: "support.contact",
    defaultMessage: "Contact Us",
    description: "Section title.",
  },
  messageSent: {
    id: "support.messageSent",
    defaultMessage: "Thank you, your message has been sent.",
    description: "Response text.",
  },
  isInstalled: {
    id: "support.isInstalled",
    defaultMessage:
      "This app will continue to work offline. Saving your changes, and syncing them when you go back online.",
    description: "Message shown when the application is installed.",
  },
  installAvailable: {
    id: "support.installAvailable",
    defaultMessage: "You can install this app to your desktop for easy access and online functionality.",
    description: "Message shown when the application is installable.",
  },
});

export default Support;
