import React, { useState } from "react";
import { useIntl } from "react-intl";
import Grid from "@mui/material/Grid";
import useTitle from "../../lib/useTitle.js";
import useAccount from "../../contexts/Account/useAccount.js";
import useSendPageView from "../../lib/useSendPageView.js";
import AppMessages from "../messages/App.js";
import AccountSection from "./AccountSection.js";
import MacroSplitGoal from "./MacroSplitGoal.js";
import MacroGoal from "./MacroGoal.js";
import WithingsSettingsCard from "./WithingsSettingsCard.js";

export default function Settings() {
  const { account, profile } = useAccount();
  const { formatMessage: t } = useIntl();
  useTitle(t(AppMessages.settings));
  useSendPageView("Settings", "/settings");

  const [goalSplit, setGoalSplit] = useState(profile.goalSplit);
  const [goal, setGoal] = useState(profile.goal);

  const onMacroSplitGoalChange = (newMacroSplitGoal) => {
    setGoalSplit(newMacroSplitGoal);
  };

  const onGoalChange = (newGoal) => {
    setGoal(newGoal);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <AccountSection />
        <WithingsSettingsCard withings={account.withings} />
      </Grid>
      <Grid item xs={6}>
        <MacroGoal goal={goal} onChange={onGoalChange} />

        <MacroSplitGoal goalSplit={goalSplit} onChange={onMacroSplitGoalChange} />
      </Grid>
    </Grid>
  );
}
