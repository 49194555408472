import getTimeOfDay from "../../data/getTimeOfDay.js";

export default function mealPlanEditorReducerInitialState({ mealPlan, weekStartsOn, days, goal }) {
  return {
    addToPlanModalOpen: false,
    mealPlan,
    weekStartsOn,
    order: "asc",
    orderBy: "calories",
    groupByMeal: true,
    showAsTabs: true,
    addToPlanModalOpenTime: getTimeOfDay(new Date()),
    days, // calendar override
    goal, // calendar override
  };
}
