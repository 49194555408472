export default function canAddCalorieBreakdown(nutritionalProfile) {
  return (
    nutritionalProfile.protein.valid &&
    nutritionalProfile.protein.number !== undefined &&
    nutritionalProfile.fat.valid &&
    nutritionalProfile.fat.number !== undefined &&
    nutritionalProfile.carbohydrate.valid &&
    nutritionalProfile.carbohydrate.number !== undefined
  );
}
