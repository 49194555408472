import React, { useContext } from "react";
import ProfileContext from "./ProfileContext.js";
import AccountContext from "../Account/AccountContext.js";

export default function ProfileProvider({ children }) {
  const { profile } = useContext(AccountContext);

  const value = {
    profile,
  };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
}
