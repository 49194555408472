import { messageSW, Workbox } from "workbox-window";
import { createNanoEvents } from "nanoevents";
import isServiceWorkerSupported from "./isServiceWorkerSupported.js";
import shouldHaveServiceWorker from "./shouldHaveServiceWorker.js";
import urlB64ToUint8Array from "./urlB64ToUint8Array.js";
import getPushSubscriptionObject from "./getPushSubscriptionObject.js";

export async function unregister() {
  return navigator.serviceWorker.ready.then((registration) => registration.unregister());
}

export async function clearAllCaches() {
  const keys = await caches.keys();
  if (!keys.length) return;

  const deletions = keys.map((cacheName) => caches.delete(cacheName));

  await Promise.allSettled(deletions);
}

export async function uninstall() {
  await Promise.allSettled([unregister(), clearAllCaches()]);
}

export async function getCachedPayload({ updatedURL, cacheName }) {
  try {
    const cache = await caches.open(cacheName);
    const updatedResponse = await cache.match(updatedURL);
    return await updatedResponse.json();
  } catch (ex) {
    // ignore
    return null;
  }
}

export function reloadTriggerOnce() {
  let triggered = false;
  return (eventName) => {
    if (triggered) return;
    triggered = true;

    if (__DEV__) console.log(`Reloading page triggered by ${eventName}`);
    window.location.reload();
  };
}

/**
 * Register a Service worker, if it is supported and desired. The returned object serves as a connector
 * between the application and the worker, with methods to control it and subscribe to its updates.
 */
export default function setupServiceWorker() {
  const eventLog = [];
  const emitter = createNanoEvents();
  let registration = null;
  let workbox = null;
  let deferredInstallPrompt;
  const reloadPage = reloadTriggerOnce();
  const callbacks = {
    logEvent: (type, event, skipBuffer) => {
      emitter.emit("subscribe", { type, event });
      emitter.emit(type, event);
      if (!skipBuffer) eventLog.push({ type, event });
    },
    logServiceEvent: (type, event) => {
      emitter.emit("subscribe", { type, event, service: true });
      emitter.emit(type, event);
    },
    eventLog,
    emitter,
    messageSW,
    registration,
    workbox,
    subscribe: (handler) => emitter.on("subscribe", handler),
    subscribeService: (event, handler) => emitter.on(event, handler),
    skipWait: () => {
      if (!registration?.waiting) return false;

      workbox.addEventListener("controlling", () => {
        const type = "skipWait:controlling";
        callbacks.logEvent(type, {});
        reloadPage(type);
      });

      workbox.addEventListener("activated", (event) => {
        if (!event.isUpdate) return;
        const type = "skipWait:activated";
        callbacks.logEvent(type, {});
        reloadPage(type);
      });

      messageSW(registration.waiting, { type: "SKIP_WAITING" })
        .then((success) => {
          callbacks.logEvent("skipWait:request-acknowledged", { success });
        })
        .catch(() => {
          callbacks.logEvent("skipWait:request-send-error", {});
        });
      return true;
    },
    checkAndInstallAnyUpdated: async () => {
      if (!registration) return null;
      registration = await registration.update();
      return registration;
    },
    getWorkerVersion: async () => {
      if (!callbacks.workbox) return null;
      return callbacks.workbox.messageSW({ type: "GET_VERSION" });
    },
    uninstall,
    recachePreloads: async () => {
      if (!callbacks.workbox) return false;

      if (__DEV__) console.log(`Recache Preloads Started`);
      const success = await callbacks.workbox.messageSW({
        type: "CACHE_URLS",
        payload: { urlsToCache: ["/api/viewer"] },
      });

      if (__DEV__) console.log(`Recache Preloads Complete:`, success);
      return success;
    },
    triggerDesktopInstallPrompt: () => {
      if (!deferredInstallPrompt) return false;
      // Show the install prompt
      deferredInstallPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredInstallPrompt.userChoice.then((choiceResult) => {
        callbacks.logEvent("desktop-install-prompted", { accepted: choiceResult.outcome === "accepted" });
      });

      return true;
    },
    subscribeToPushNotifications: async (pushKey) => {
      if (!registration) {
        callbacks.logEvent("push-notifications-subscription-error", {
          error: new Error("No Service Worker Registered"),
        });
        return null;
      }
      let subscription = null;
      if (!pushKey) {
        callbacks.logEvent("push-notifications-subscription-error", {
          error: new Error("pushKey not set"),
        });
        return null;
      }
      try {
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(pushKey),
        });
      } catch (error) {
        callbacks.logEvent("push-notifications-subscription-error", {
          error,
        });
        return null;
      }
      if (subscription) {
        callbacks.logEvent("push-notifications-subscribed", {
          subscribed: true,
          pushSubscription: subscription,
          subscription: getPushSubscriptionObject(subscription),
        });
      }
      return subscription;
    },
    unsubscribeToPushNotifications: async () => {
      if (!registration) return null;
      const subscription = await registration.pushManager.getSubscription();
      if (!subscription) return null;
      const success = await subscription.unsubscribe(); // returns true or false
      callbacks.logEvent("push-notifications-unsubscribed", { success });
      return success;
    },
    detectPushNotificationSubscription: async () => {
      if (!registration) return null;
      const pushSubscription = await registration.pushManager.getSubscription();
      if (pushSubscription) {
        callbacks.logEvent("push-notifications-subscribed", {
          subscribed: true,
          pushSubscription,
          subscription: getPushSubscriptionObject(pushSubscription),
        });
      } else {
        callbacks.logEvent("push-notifications-not-subscribed", { subscribed: false });
      }
      return pushSubscription;
    },
  };

  callbacks.debufferEvents = () => {
    while (eventLog.length) {
      const { type, event } = eventLog.shift();
      callbacks.logEvent(type, event, true);
    }
  };

  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      callbacks.logEvent("window-hidden", {});
    } else {
      callbacks.logEvent("window-visible", {});
    }
  });

  const isSupported = isServiceWorkerSupported();
  if (!shouldHaveServiceWorker() || !isSupported) {
    if (isSupported) callbacks.uninstall();
    callbacks.logEvent("unsupported", { isSupported });
    return callbacks;
  }

  workbox = new Workbox("/sw.js");

  callbacks.workbox = workbox;

  callbacks.performRegistration = async () => {
    registration = await workbox.register();
    callbacks.logEvent("registration-complete", registration);
    await callbacks.detectPushNotificationSubscription();
  };

  workbox.addEventListener("installed", (event) => {
    callbacks.logEvent("installed", event);
  });

  workbox.addEventListener("waiting", (event) => {
    callbacks.logEvent("waiting", event);
  });

  workbox.addEventListener("externalwaiting", (event) => {
    callbacks.logEvent("externalwaiting", event);
  });

  workbox.addEventListener("controlling", (event) => {
    callbacks.logEvent("controlling", event);
  });

  workbox.addEventListener("activated", (event) => {
    // An newly activated worker will shortly trigger a page reload once complete
    if (event.isUpdate) {
      callbacks.logEvent("activated:update", event);
      return;
    }

    callbacks.logEvent("activated", event);

    // The first time the worker is activated, we refetch the preloaded data
    // so it'll capture it in the cache.
    callbacks
      .recachePreloads()
      .then(() => {
        // Ignore
      })
      .catch(() => {
        // Ignore
      });
  });

  workbox.addEventListener("message", async (event) => {
    if (event.data.type === "CACHE_UPDATED") {
      const { updatedURL, system, cacheName } = event.data.payload;
      if (system) {
        // An update to a serviceable request
        const data = await getCachedPayload({ cacheName, updatedURL });
        if (data) callbacks.logServiceEvent(`update:${system}`, { data });
        return;
      }
      // Ignore any other updates
      return;
    }
    if (event.data.type === "REVALIDATION_FAILED") {
      const { system } = event.data.payload;
      if (system) {
        // An failure to update a serviceable request
        callbacks.logServiceEvent(`update:failure:${system}`, event.data.payload);
        return;
      }
      return;
    }
    if (event.data.type === "PUSH_NOTIFICATION") {
      callbacks.logEvent("push-notification", event.data.payload);
      return;
    }
    callbacks.logEvent("message", event);
  });

  window.addEventListener("load", () => {
    // This will detect if we launched from an install.
    callbacks.logEvent("desktop-install-status", {
      installed: Boolean(navigator.standalone || matchMedia("(display-mode: standalone)").matches),
    });
  });

  window.addEventListener("beforeinstallprompt", (event) => {
    deferredInstallPrompt = event;
    callbacks.logEvent("desktop-install-available", {});
  });

  callbacks
    .performRegistration()
    .then(() => {})
    .catch(() => {});

  return callbacks;
}
