import { Stack, Typography } from "@mui/material";
import React, { useReducer } from "react";
import { useIntl, defineMessages } from "react-intl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import useSendPageView from "../../lib/useSendPageView.js";
import Header, { HeaderLeft, HeaderRight } from "../App/Header.js";
import Card from "../Card/Card.js";
import editorReducer from "./editorReducer.js";
import toState from "./toState.js";
import fromState from "./fromState.js";
import useTitle from "../../lib/useTitle.js";
import GlobalMessages from "../messages/Global.js";
import ActionMessages from "../messages/Actions.js";
import MacroEditorInputRow from "./MacroEditorInputRow.js";

const messages = defineMessages({
  containerDescription: {
    id: "IngredientDetailsEditor.containerDescription",
    defaultMessage: 'The name of the container, example "a PACK of pasta", "a TIN of beans". Not the consumable name.',
    description: "Help text.",
  },
  kcalEstimate: {
    id: "IngredientDetailsEditor.kcalEstimate",
    defaultMessage: "{kcal} kcal estimated - {kj}",
    description: "Help text.",
  },
  kcalFatEstimate: {
    id: "IngredientDetailsEditor.kcalFatEstimate",
    defaultMessage: "{kcal} kcal estimated from fat ({ratio}%)",
    description: "Help text.",
  },
  kcalCarbEstimate: {
    id: "IngredientDetailsEditor.kcalCarbEstimate",
    defaultMessage: "{kcal} kcal estimated from carbohydrate ({ratio}%)",
    description: "Help text.",
  },
  kcalProteinEstimate: {
    id: "IngredientDetailsEditor.kcalProteinEstimate",
    defaultMessage: "{kcal} kcal estimated from protein ({ratio}%)",
    description: "Help text.",
  },
  packSizeGramsHelp: {
    id: "IngredientDetailsEditor.packSizeGramsHelp",
    defaultMessage: "The total size of the container, for example, the entire tin of beans is 250g.",
    description: "Help text.",
  },
});

function modelIsValid(model) {
  if (!model.name) return false;
  return true;
}

export default function IngredientDetailsEditor({ ingredient, onCancel, updateIngredient }) {
  const { formatMessage: t } = useIntl();
  useSendPageView(
    ingredient.id ? "Ingredients Editor" : "Ingredients Creator",
    ingredient.id ? `/ingredient/edit` : "/ingredient/new",
  );
  const [state, dispatch] = useReducer(editorReducer, toState(ingredient));

  const handleSave = () => {
    const updatedIngredient = fromState(ingredient, state);
    if (!modelIsValid(updatedIngredient)) {
      return;
    }
    updateIngredient(updatedIngredient, t);
  };

  const {
    name,
    description,
    notes,
    packSize,
    nutritionalProfile,
    suggestedServingSizeNutritionalProfile,
    individualServingSizeNutritionalProfile,
    hasUnits,
    brand,
    isLiquid,
    suggestedServingSizeGrams,
    suggestedServingSizeQuantity,
    individualServingSizeGrams,
    individualServingSizeQuantity,
    id,
    breakdown,
  } = state;
  useTitle(ingredient.id ? `${t(ActionMessages.edit)} - ${name}` : t(ActionMessages.newIngredient));

  const unit = isLiquid ? t(GlobalMessages.ml) : t(GlobalMessages.grams);

  const kCalEstimateText = breakdown.available
    ? t(messages.kcalEstimate, { kcal: breakdown.total, kj: nutritionalProfile.energy.value })
    : `${nutritionalProfile.energy.value} kj`;

  const fatEstimateText = breakdown.available
    ? t(messages.kcalFatEstimate, { kcal: breakdown.fat, ratio: breakdown.ratio.fat })
    : "";

  const carbEstimateText = breakdown.available
    ? t(messages.kcalCarbEstimate, { kcal: breakdown.carbohydrate, ratio: breakdown.ratio.carbohydrate })
    : "";

  const calorieEstimateText = breakdown.available
    ? t(messages.kcalProteinEstimate, { kcal: breakdown.protein, ratio: breakdown.ratio.protein })
    : "";
  return (
    <>
      <Header>
        <HeaderLeft>
          <Typography variant="h1" display="block">
            {name}
          </Typography>
          {brand && <Typography variant="subtitle1">{brand}</Typography>}
        </HeaderLeft>
        <HeaderRight>
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              startIcon={<ClearIcon fontSize="small" />}
              variant="outlined"
              onClick={() => onCancel()}
            >
              {t(ActionMessages.cancel)}
            </Button>

            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleSave}
              startIcon={<CheckIcon fontSize="small" />}
            >
              {t(ActionMessages.save)}
            </Button>
          </Stack>
        </HeaderRight>
      </Header>
      <Grid>
        <Card>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                value={name}
                onChange={(event) => dispatch({ type: "edit", value: { name: event.target.value } })}
                variant="outlined"
                label={t(GlobalMessages.name)}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={brand}
                onChange={(event) => dispatch({ type: "edit", value: { brand: event.target.value } })}
                variant="outlined"
                label={t(GlobalMessages.brand)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                value={description}
                onChange={(event) => dispatch({ type: "edit", value: { description: event.target.value } })}
                variant="outlined"
                label={t(GlobalMessages.description)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={notes}
                onChange={(event) => dispatch({ type: "edit", value: { notes: event.target.value } })}
                variant="outlined"
                label={t(GlobalMessages.notes)}
                fullWidth
                placeholder={t(GlobalMessages.notesPlaceholder)}
                multiline
                lines={2}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Card>
        <Card
          title={t(isLiquid ? GlobalMessages.macrosPer100ml : GlobalMessages.macrosPer100g)}
          titleAction={
            <FormControlLabel
              control={
                <Switch
                  checked={isLiquid}
                  size="small"
                  onChange={() => {
                    dispatch({ type: "edit", value: { isLiquid: !isLiquid } });
                  }}
                />
              }
              label="Liquid"
            />
          }
        >
          <MacroEditorInputRow dispatch={dispatch} nutritionalProfile={nutritionalProfile} name="nutrition" />
        </Card>
        <Card title={t(GlobalMessages.macrosPerServing)}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasUnits}
                    size="small"
                    onChange={() => dispatch({ type: "edit", value: { hasUnits: !hasUnits } })}
                  />
                }
                label={t(GlobalMessages.servingHasUnits)}
              />
            </Grid>
            <Grid item xs={3}>
              {!hasUnits && (
                <TextField
                  autoComplete="off"
                  error={!suggestedServingSizeGrams.valid}
                  type="number"
                  min={0}
                  step={1}
                  value={suggestedServingSizeGrams.value}
                  onChange={(event) => dispatch({ type: "edit_suggestedServingSizeGrams", value: event.target.value })}
                  placeholder={suggestedServingSizeGrams.placeholder.value}
                  variant="outlined"
                  label={t(GlobalMessages.servingSizeGrams, { unit })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              {hasUnits && (
                <TextField
                  autoComplete="off"
                  error={!suggestedServingSizeQuantity.valid}
                  type="number"
                  min={0}
                  step={1}
                  value={suggestedServingSizeQuantity.value}
                  onChange={(event) =>
                    dispatch({ type: "edit_suggestedServingSizeQuantity", value: event.target.value })
                  }
                  placeholder={suggestedServingSizeQuantity.placeholder.value}
                  variant="outlined"
                  label={t(GlobalMessages.servingSizeUnits)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                error={!packSize.valid}
                type="number"
                pattern="[0-9]*"
                min={0}
                value={packSize.value}
                onChange={(event) => dispatch({ type: "edit_packSize", value: event.target.value })}
                placeholder={t(GlobalMessages.packSizeGrams, { unit })}
                variant="outlined"
                label={t(GlobalMessages.packSizeGrams, { unit })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <MacroEditorInputRow
                disabled={!hasUnits}
                dispatch={dispatch}
                nutritionalProfile={suggestedServingSizeNutritionalProfile}
                name="suggestedServingSizeNutrition"
              />
            </Grid>

            <Grid item xs={12}>
              {!hasUnits && (
                <TextField
                  autoComplete="off"
                  error={!individualServingSizeGrams.valid}
                  type="number"
                  min={0}
                  step={1}
                  value={individualServingSizeGrams.value}
                  onChange={(event) => dispatch({ type: "edit_individualServingSizeGrams", value: event.target.value })}
                  placeholder={individualServingSizeGrams.placeholder.value}
                  variant="outlined"
                  label={t(GlobalMessages.individualSizeGrams, { unit })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              {hasUnits && (
                <TextField
                  autoComplete="off"
                  error={!individualServingSizeQuantity.valid}
                  type="number"
                  min={0}
                  step={1}
                  value={individualServingSizeQuantity.value}
                  onChange={(event) =>
                    dispatch({ type: "edit_individualServingSizeQuantity", value: event.target.value })
                  }
                  placeholder={individualServingSizeQuantity.placeholder.value}
                  variant="outlined"
                  label={`Individual Size (unit${
                    individualServingSizeGrams.valid
                      ? `, ${individualServingSizeGrams.value}${isLiquid ? "ml" : "g"}`
                      : ""
                  })`}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Grid>

            <Grid item>
              <MacroEditorInputRow
                disabled
                dispatch={dispatch}
                nutritionalProfile={individualServingSizeNutritionalProfile}
                name="individualServingSizeNutrition"
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}
