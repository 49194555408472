import React, { useState, useEffect, lazy, Suspense } from "react";
import { Navigate, Route } from "react-router";
import useAccount from "../../contexts/Account/useAccount.js";

const AdminLandingPage = lazy(() => import(/* webpackChunkName: "admin" */ "./AdminLandingPage.js"));

export default function AdminApp() {
  const { account } = useAccount();
  const [inClient, setInClient] = useState(false);

  useEffect(() => setInClient(true), []);

  if (!inClient) return null;

  if (!account || !account.isAdmin) return <Route render={() => <Navigate to="/" />} />;

  return (
    <Suspense fallback={<span />}>
      <AdminLandingPage />
    </Suspense>
  );
}
