import { enGB } from "date-fns/locale/en-GB";
import { ptBR } from "date-fns/locale/pt-BR";
import ptBrLang from "../../lang/pt-br.json";

const defaultLanguage = "en-GB";
const languages = ["pt", "en"];

const longDateFormat = "EEEE, d LLLL";

export function getLanguage(language) {
  if (language === "en" || language === "en-GB") {
    return {
      language: "en-GB",
      prefix: "",
      locale: enGB,
      longDateFormat,
      longDateTimeFormat: "EEEE, d LLLL h:mm a",
      monthDayFormat: "LLLL do",
      messages: undefined, // Use built-in defaultMessage
    };
  }
  if (language === "pt") {
    return {
      language: "pt",
      prefix: "/pt",
      locale: ptBR,
      longDateFormat,
      longDateTimeFormat: "EEEE, d LLLL HH:mm",
      monthDayFormat: "d LLLL",
      messages: ptBrLang,
    };
  }
  return undefined;
}

const isLanguageSupported = (languageToCheck) => languages.indexOf(languageToCheck) !== -1;

export default function determineLanguage({ browserLanguages, cookieLanguage, userLanguage, urlLanguage } = {}) {
  let language;
  let redirectTo;
  let notFound = false;
  let setCookie = false;
  let offerRedirect;
  const urlLanguageSafe = urlLanguage || defaultLanguage;
  // 1. The user language takes the first preference. We only do this client side, where there is a concept of User.
  if (userLanguage) {
    const languageByUser = getLanguage(userLanguage);
    if (languageByUser) {
      language = languageByUser;
      if (urlLanguageSafe !== language.language) {
        // The account is in a different language to the one in the URL param.
        redirectTo = language.language;
      }
      if (cookieLanguage !== language.language) {
        // The cookie is different to the account, update it.
        setCookie = true;
      }
    }
  }

  // 2. The cookie language is used second.
  if (!language && cookieLanguage) {
    const languageByCookie = getLanguage(cookieLanguage);
    if (languageByCookie) {
      language = languageByCookie;
      if (urlLanguage !== language.language) {
        // The cookie is in a different language to the one in the URL param.
        redirectTo = language.language;
      }
    } else {
      // The cookie has an unknown language, force it to be reset.
      setCookie = true;
    }
  }

  let languageSetByUrl = false;
  // 3. The language from the URL structure.
  if (!language && urlLanguage) {
    const urlLanguageSupported = isLanguageSupported(urlLanguage);
    if (urlLanguageSupported) {
      language = getLanguage(urlLanguage);
      if (language) languageSetByUrl = true;
    } else {
      // There is a param, but not for a supported language
      // The subsequent routes will fail.
      notFound = true;
    }
  }

  // 3. The language specified by the browser.
  if (browserLanguages?.length > 0) {
    const supportedBrowserLanguage = browserLanguages.find((browserLanguage) => isLanguageSupported(browserLanguage));
    if (supportedBrowserLanguage) {
      const languageByBrowser = getLanguage(supportedBrowserLanguage);
      if (languageByBrowser) {
        if (!language) {
          language = languageByBrowser;
        } else if (languageSetByUrl && language.language !== languageByBrowser.language) {
          // The browser wants a language other than the URL, and they are not setting language by another means.
          // We should prompt the user with a way out in their own language.
          offerRedirect = languageByBrowser.language;
        }
      }
    }
  }

  // If we still got here and found no language...
  if (!language) {
    language = getLanguage(defaultLanguage);
  }

  return {
    language,
    redirectTo,
    notFound,
    setCookie: setCookie ? language.language : undefined,
    offerRedirect,
  };
}
