import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import React from "react";
import { useIntl } from "react-intl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as ReactRouterLink } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { parseISO } from "date-fns";
import useSendPageView from "../../lib/useSendPageView.js";
import displayMacros from "../../data/displayMacros.js";
import Card from "../Card/Card.js";
import useTitle from "../../lib/useTitle.js";
import GlobalMessages from "../messages/Global.js";
import ActionMessages from "../messages/Actions.js";
import AddToPlanButton from "./AddToPlanButton.js";
import Header, { HeaderLeft, HeaderRight } from "../App/Header.js";

export default function IngredientDetails({ ingredient, onDelete }) {
  const { formatMessage: t, formatDate, formatTime } = useIntl();
  useSendPageView("Ingredients Details", `/ingredients/details`);
  const {
    name,
    nutritionalProfile,
    suggestedServingSize,
    suggestedServingSizeNutritionalProfile,
    individualServingSizeGrams,
    individualServingSizeNutritionalProfile,
  } = ingredient;
  const ingredientMacrosDisplay = displayMacros(nutritionalProfile);
  const suggestedServingSizeNutritionalProfileDisplay = displayMacros(suggestedServingSizeNutritionalProfile);
  useTitle(name);

  const createdAt = parseISO(ingredient.createdAt);
  const updatedAt = parseISO(ingredient.updatedAt);

  return (
    <>
      <Header>
        <HeaderLeft>
          <Typography variant="h1" display="block">
            {name}
          </Typography>
          {ingredient.brand && <Typography variant="subtitle1">{ingredient.brand}</Typography>}
        </HeaderLeft>
        <HeaderRight>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              component={ReactRouterLink}
              to={`/ingredient/${ingredient.id}/edit`}
              startIcon={<EditIcon fontSize="small" />}
              size="small"
            >
              {t(ActionMessages.edit)}
            </Button>

            <Button
              variant="outlined"
              onClick={() => {
                onDelete(ingredient.id, ingredient.name, t);
              }}
              startIcon={<DeleteIcon fontSize="small" />}
              size="small"
            >
              {t(ActionMessages.delete)}
            </Button>

            <AddToPlanButton ingredient={ingredient} />
          </Stack>
        </HeaderRight>
      </Header>

      <Card dense>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t(GlobalMessages.portion)}</TableCell>
                <TableCell>{t(GlobalMessages.calories)}</TableCell>
                <TableCell>{t(GlobalMessages.fat)}</TableCell>
                <TableCell>{t(GlobalMessages.saturatedFat)}</TableCell>
                <TableCell>{t(GlobalMessages.carbohydrate)}</TableCell>
                <TableCell>{t(GlobalMessages.sugar)}</TableCell>
                <TableCell>{t(GlobalMessages.fiber)}</TableCell>
                <TableCell>{t(GlobalMessages.protein)}</TableCell>
                <TableCell>{t(GlobalMessages.salt)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {t(GlobalMessages.perXg, { value: "100", unit: ingredient.isLiquid ? "ml" : "g" })}
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={`${ingredientMacrosDisplay.energy} kj / ${ingredientMacrosDisplay.calories} kcal`}
                    placement="right"
                  >
                    <span>{ingredientMacrosDisplay.calories}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{ingredientMacrosDisplay.fat}</TableCell>
                <TableCell>{ingredientMacrosDisplay.saturatedFat}</TableCell>
                <TableCell>{ingredientMacrosDisplay.carbohydrate}</TableCell>
                <TableCell>{ingredientMacrosDisplay.sugars}</TableCell>
                <TableCell>{ingredientMacrosDisplay.fiber}</TableCell>
                <TableCell>{ingredientMacrosDisplay.protein}</TableCell>
                <TableCell>{ingredientMacrosDisplay.salt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t(GlobalMessages.suggestedXg, {
                    value: suggestedServingSize.grams,
                    unit: ingredient.isLiquid ? "ml" : "g",
                  })}
                </TableCell>
                <TableCell>
                  <Tooltip
                    placement="right"
                    title={`${suggestedServingSizeNutritionalProfileDisplay.energy} kj / ${suggestedServingSizeNutritionalProfileDisplay.calories} kcal`}
                  >
                    <span>{suggestedServingSizeNutritionalProfileDisplay.calories}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{suggestedServingSizeNutritionalProfileDisplay.fat}</TableCell>
                <TableCell>{suggestedServingSizeNutritionalProfileDisplay.saturatedFat}</TableCell>
                <TableCell>{suggestedServingSizeNutritionalProfileDisplay.carbohydrate}</TableCell>
                <TableCell>{suggestedServingSizeNutritionalProfileDisplay.sugars}</TableCell>
                <TableCell>{suggestedServingSizeNutritionalProfileDisplay.fiber}</TableCell>
                <TableCell>{suggestedServingSizeNutritionalProfileDisplay.protein}</TableCell>
                <TableCell>{suggestedServingSizeNutritionalProfileDisplay.salt}</TableCell>
              </TableRow>
              {individualServingSizeGrams && individualServingSizeGrams.grams > 0 && (
                <TableRow>
                  <TableCell>
                    {t(GlobalMessages.suggestedXg, {
                      value: individualServingSizeGrams.grams,
                      unit: ingredient.isLiquid ? "ml" : "g",
                    })}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      placement="right"
                      title={`${individualServingSizeNutritionalProfile.energy} kj / ${individualServingSizeNutritionalProfile.calories} kcal`}
                    >
                      <span>{individualServingSizeNutritionalProfile.calories}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{individualServingSizeNutritionalProfile.fat}</TableCell>
                  <TableCell>{individualServingSizeNutritionalProfile.saturatedFat}</TableCell>
                  <TableCell>{individualServingSizeNutritionalProfile.carbohydrate}</TableCell>
                  <TableCell>{individualServingSizeNutritionalProfile.sugars}</TableCell>
                  <TableCell>{individualServingSizeNutritionalProfile.fiber}</TableCell>
                  <TableCell>{individualServingSizeNutritionalProfile.protein}</TableCell>
                  <TableCell>{individualServingSizeNutritionalProfile.salt}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <CardContent>
            {ingredient.notes && <Typography>{ingredient.notes}</Typography>}
            <Stack useFlexGap direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <span>
                <Typography>{`${formatDate(createdAt)} ${formatTime(createdAt)}`}</Typography>
                <Typography component="span" variant="body2" color="text.disabled">
                  {t(GlobalMessages.dateCreated)}
                </Typography>
              </span>

              <span>
                <Typography>{`${formatDate(updatedAt)} ${formatTime(updatedAt)}`}</Typography>
                <Typography component="span" variant="body2" color="text.disabled">
                  {t(GlobalMessages.dateUpdated)}
                </Typography>
              </span>
            </Stack>
          </CardContent>
        </TableContainer>
      </Card>
    </>
  );
}
