import React from "react";
import Box from "@mui/material/Box";
import {
  WidgetPieChartSalt,
  WidgetPieChartSugar,
  WidgetPieChartCalories,
  WidgetPieChartFat,
  WidgetPieChartProtein,
  WidgetPieChartCarbohydrate,
} from "../Home/WidgetPieChartMacros.js";
import WidgetRangeText from "../Home/WidgetRangeText.js";

export default function MacroWidgets({ consumedMacrosDisplay, completionResult, onClick, value, noTopMargin }) {
  const caloriesDisplay = (
    <WidgetRangeText
      value={consumedMacrosDisplay.caloriesValue}
      hasPercentValue
      percentValue={completionResult.calories}
      unit={"unit"}
    />
  );

  const proteinDisplay = (
    <WidgetRangeText
      value={consumedMacrosDisplay.proteinValue}
      hasPercentValue
      percentValue={completionResult.protein}
    />
  );

  const fatDisplay = (
    <WidgetRangeText value={consumedMacrosDisplay.fatValue} hasPercentValue percentValue={completionResult.fat} />
  );

  const sugarDisplay = (
    <WidgetRangeText value={consumedMacrosDisplay.sugarsValue} hasPercentValue percentValue={completionResult.sugars} />
  );

  const carbohydrateDisplay = (
    <WidgetRangeText
      value={consumedMacrosDisplay.carbohydrateValue}
      hasPercentValue
      percentValue={completionResult.carbohydrate}
    />
  );

  const saltDisplay = (
    <WidgetRangeText value={consumedMacrosDisplay.saltValue} hasPercentValue percentValue={completionResult.salt} />
  );

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateAreas: `"calories protein fat sugar carb salt"`,
        gridRowGap: "16px",
        gridColumnGap: "16px",
        marginBottom: 2,
        marginTop: noTopMargin ? 0 : 2,
      }}
    >
      <WidgetPieChartCalories
        sx={{
          gridArea: "calories",
        }}
        title={caloriesDisplay}
        percentage={completionResult.calories}
        onClick={() => onClick("calories")}
        selected={value === "calories"}
      />
      <WidgetPieChartProtein
        sx={{
          gridArea: "protein",
        }}
        title={proteinDisplay}
        percentage={completionResult.protein}
        onClick={() => onClick("protein")}
        selected={value === "protein"}
      />
      <WidgetPieChartFat
        sx={{
          gridArea: "fat",
        }}
        title={fatDisplay}
        percentage={completionResult.fat}
        onClick={() => onClick("fat")}
        selected={value === "fat"}
      />
      <WidgetPieChartSugar
        sx={{
          gridArea: "sugar",
        }}
        title={sugarDisplay}
        percentage={completionResult.sugars}
        onClick={() => onClick("sugars")}
        selected={value === "sugars"}
      />
      <WidgetPieChartCarbohydrate
        sx={{
          gridArea: "carb",
        }}
        title={carbohydrateDisplay}
        percentage={completionResult.carbohydrate}
        onClick={() => onClick("carbohydrate")}
        selected={value === "carbohydrate"}
      />
      <WidgetPieChartSalt
        sx={{
          gridArea: "salt",
        }}
        title={saltDisplay}
        percentage={completionResult.salt}
        onClick={() => onClick("salt")}
        selected={value === "salt"}
      />
    </Box>
  );
}
