import React, { useState, useEffect, useReducer } from "react";
import AppInstallationContext from "./AppInstallationContext.js";
import ServiceWorkerEventReducer from "./ServiceWorkerEventReducer.js";
import useToast from "../Toast/useToast.js";
import useNonInitialEffect from "../../lib/useNonInitialEffect.js";
import subscribeWebPush from "../../api/subscribeWebPush.js";
import unsubscribeWebPush from "../../api/unsubscribeWebPush.js";
import ServiceWorkerStateInitializer from "./ServiceWorkerStateInitializer.js";

export default function AppInstallationProvider({ children, serviceWorkerConnector }) {
  const { displayToast } = useToast();

  const [serverAdvertisedVersion, setServerAdvertisedVersion] = useState({
    version: undefined, // "v1.2.3"
    buildDate: undefined, // ISO-8601 Date, as string
    hash: undefined, // "abcd1234",
    pushKey: null,
  });
  const [installState, dispatch] = useReducer(
    ServiceWorkerEventReducer,
    {
      history: [],
      installChecked: false,
      installed: false,
      updateAvailable: false,
      unsupported: false,
      updateInstalling: false,
      desktopInstallAvailable: false,
      desktopInstallPrompting: false,
      isDesktopInstallStatusKnown: false,
      isDesktopInstalled: false,
      pushNotificationSubscription: null,
      lastPushNotificationSubscription: null,
    },
    ServiceWorkerStateInitializer,
  );

  const handlePushNotification = (event) => {
    if (event.text) {
      displayToast(event.text);
    }
  };

  // On startup, start listening to service worker installation events
  useEffect(() => {
    const emitterDispatcher = (event) => {
      dispatch(event);
      if (event?.type === "push-notification") {
        handlePushNotification(event.event);
      }
    };

    const subscriber = serviceWorkerConnector.subscribe(emitterDispatcher);

    serviceWorkerConnector.debufferEvents();

    return () => {
      // App is being unmounted, so stop listening for service worker changes
      subscriber();
    };
  });

  useNonInitialEffect(() => {
    if (installState.pushNotificationSubscription) {
      console.log("SUBSCRIBE");
      subscribeWebPush(installState.pushNotificationSubscription);
    } else if (installState.lastPushNotificationSubscription) {
      console.log("UNSUBSCRIBE");
      unsubscribeWebPush(installState.lastPushNotificationSubscription);
    }
  }, [installState.pushNotificationSubscription, installState.lastPushNotificationSubscription]);

  const value = {
    installState,
    skipWait: () => {
      dispatch({
        type: "skipWait:started",
        event: {},
      });
      const success = serviceWorkerConnector.skipWait();
      dispatch({
        type: "skipWait:finished",
        event: {
          success,
        },
      });
    },
    triggerDesktopInstallPrompt: () => {
      dispatch({
        type: "desktop-install-prompting",
        event: {},
      });
      const success = serviceWorkerConnector.triggerDesktopInstallPrompt();
      dispatch({
        type: "desktop-install-prompting:finished",
        event: { success },
      });
    },
    checkAndInstallAnyUpdated: async () => serviceWorkerConnector.checkAndInstallAnyUpdated(),
    getWorkerVersion: async () => serviceWorkerConnector.getWorkerVersion(),
    serverAdvertisedVersion: serverAdvertisedVersion.version,
    serverAdvertisedHash: serverAdvertisedVersion.hash,
    serverAdvertisedBuildDate: serverAdvertisedVersion.buildDate,
    clientVersion: process.env.APP_VERSION,
    clientBuildDate: process.env.APP_BUILDDATE,
    setServerAdvertisedVersion: (version, buildDate, hash, pushKey) =>
      setServerAdvertisedVersion({ version, buildDate, hash, pushKey }),
    subscribeToService: (event, subscriber) => serviceWorkerConnector.subscribeService(event, subscriber),
    recachePreloads: async () => serviceWorkerConnector?.recachePreloads(),
    subscribeToPushNotifications: async () =>
      serviceWorkerConnector?.subscribeToPushNotifications(serverAdvertisedVersion.pushKey),
    unsubscribeToPushNotifications: async () => serviceWorkerConnector?.unsubscribeToPushNotifications(),
  };

  return <AppInstallationContext.Provider value={value}>{children}</AppInstallationContext.Provider>;
}
