import React, { StrictMode } from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import Cookies from "js-cookie";
import configureStores from "./stores/configureStores.js";
import MacroMealPrepApp from "./components/MacroMealPrepApp.js";
import setupServiceWorker from "./lib/serviceWorker/setupServiceWorker.js";
import createEmotionCache from "./lib/createEmotionCache.js";
import { BrowserRouter } from "react-router";

const LanguagePreferenceCookieName = "mmpl";
const store = configureStores(undefined);

const configFromWindow = window.__mmp_config__ || { title: "", url: "" };

delete window.__mmp_config__;
const browserLanguages = navigator.languages;
const cookieLanguage = Cookies.get(LanguagePreferenceCookieName);

const serviceWorkerConnector = setupServiceWorker();

const cache = createEmotionCache();

const resume = () => {
  const container = document.getElementById("mmp");

  const appTree = (
    <StrictMode>
      <BrowserRouter
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        <MacroMealPrepApp
          cache={cache}
          cookieLanguage={cookieLanguage}
          title={configFromWindow.title}
          applicationUrl={configFromWindow.url}
          store={store}
          browserLanguages={browserLanguages}
          isShellPage={configFromWindow.isShell || false}
          serviceWorkerConnector={serviceWorkerConnector}
        />
      </BrowserRouter>
    </StrictMode>
  );

  if (__DEV__) {
    const devRoot = createRoot(container);
    devRoot.render(appTree);
    return;
  }
  hydrateRoot(container, appTree);
};

resume();

//
// Hot Module Replacement
// -----------------------------------------------------------------------------
if (import.meta.webpackHot) {
  // import.meta.webpackHot.accept("/src/router.js", () => {
  //     onLocationChange(currentLocation);
  // });
  import.meta.webpackHot.accept();
}
