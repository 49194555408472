import Box from "@mui/material/Box";
import cx from "classnames";
import { format, isAfter, isSameDay, parseISO, subDays } from "date-fns";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import TodayIcon from "@mui/icons-material/Today";
import AccountContext from "../../contexts/Account/AccountContext.js";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import ProfileContext from "../../contexts/Profile/ProfileContext.js";
import { getAbsolutePercentageComplete, goalCompletion, goalCompletionDisplay } from "../../data/calculateMacros.js";
import { displayMacrosUsingLocale } from "../../data/displayMacros.js";
import { getFatMass, getWeight } from "../../data/getWeightMetricsDisplay.js";
import GlobalMessages from "../messages/Global.js";
import messages from "../messages/Home.js";
import styles from "./Home.css";
import WelcomeCard from "./WelcomeCard.js";
import Widget from "./Widget.js";
import { WidgetPieChartNext } from "./WidgetPieChart.js";
import {
  WidgetPieChartCalories,
  WidgetPieChartCarbohydrate,
  WidgetPieChartFat,
  WidgetPieChartProtein,
  WidgetPieChartSalt,
  WidgetPieChartSugar,
} from "./WidgetPieChartMacros.js";
import WidgetRangeText from "./WidgetRangeText.js";
import WithingsConnectCard from "./WithingsConnectCard.js";

const showWelcomeCardForDays = 7;

export default function WidgetContainer({ dayData, showMostRecentStats, isUserSelectedDate, displayDate }) {
  const { formatMessage: t, formatNumber } = useIntl();
  const { locale, monthDayFormat } = useContext(LanguageContext);
  const { profile } = useContext(ProfileContext);
  const { account } = useContext(AccountContext);
  const { goal } = profile;

  const consumedMacrosDisplay = displayMacrosUsingLocale(dayData.consumed, formatNumber);
  const goalCompletionResult = goalCompletion(dayData.consumed, goal);
  const goalCompletionDisplayResult = goalCompletionDisplay(goalCompletionResult);
  const totalItems = dayData.items.length;
  const totalItemsConsumed = dayData.items.filter((item) => item.consumed).length;
  const itemsConsumedPercent = totalItems > 0 ? getAbsolutePercentageComplete(totalItemsConsumed, totalItems) : 0;

  const { stats } = profile;

  let hasStats = stats && stats.date;
  let statsMatchesDisplay = false;
  const statsDate = hasStats ? parseISO(stats.date) : undefined;
  if (hasStats) {
    statsMatchesDisplay = isSameDay(statsDate, displayDate);
  }
  if (!showMostRecentStats && !statsMatchesDisplay) {
    hasStats = false;
  }

  const isAccountRecentlyCreated =
    account.dateCreated && isAfter(parseISO(account.dateCreated), subDays(new Date(), showWelcomeCardForDays));

  const showWelcomeCard = !isUserSelectedDate && isAccountRecentlyCreated;
  const showWithingsConnect = !isUserSelectedDate && !account.withings;

  const carbohydrateDisplay = (
    <WidgetRangeText
      value={consumedMacrosDisplay.carbohydrateValue}
      hasPercentValue={goalCompletionDisplayResult.carbohydrateHasValue}
      percentValue={goalCompletionDisplayResult.carbohydrateValue}
      outsideLimits={goalCompletionDisplayResult.carbohydrateOutsideLimits}
    />
  );

  const saltDisplay = (
    <WidgetRangeText
      value={consumedMacrosDisplay.saltValue}
      hasPercentValue={goalCompletionDisplayResult.saltHasValue}
      percentValue={goalCompletionDisplayResult.saltValue}
      outsideLimits={goalCompletionDisplayResult.saltOutsideLimits}
    />
  );

  const sugarDisplay = (
    <WidgetRangeText
      value={consumedMacrosDisplay.sugarsValue}
      hasPercentValue={goalCompletionDisplayResult.sugarsHasValue}
      percentValue={goalCompletionDisplayResult.sugarsValue}
      outsideLimits={goalCompletionDisplayResult.sugarsOutsideLimits}
    />
  );

  const proteinDisplay = (
    <WidgetRangeText
      value={consumedMacrosDisplay.proteinValue}
      hasPercentValue={goalCompletionDisplayResult.proteinHasValue}
      percentValue={goalCompletionDisplayResult.proteinValue}
      outsideLimits={goalCompletionDisplayResult.proteinOutsideLimits}
    />
  );

  const caloriesDisplay = (
    <WidgetRangeText
      value={consumedMacrosDisplay.caloriesValue}
      hasPercentValue={goalCompletionDisplayResult.caloriesHasValue}
      percentValue={goalCompletionDisplayResult.caloriesValue}
      outsideLimits={goalCompletionDisplayResult.caloriesOutsideLimits}
      unit={"unit"}
    />
  );

  const fatDisplay = (
    <WidgetRangeText
      value={consumedMacrosDisplay.fatValue}
      hasPercentValue={goalCompletionDisplayResult.fatHasValue}
      percentValue={goalCompletionDisplayResult.fatValue}
      outsideLimits={goalCompletionDisplayResult.fatOutsideLimits}
    />
  );

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gridColumnGap: "16px",
        gridRowGap: "16px",
        marginBottom: "16px",
        justifyItems: "stretch",
        alignItems: "stretch",
        ...(showWelcomeCard
          ? {
              gridTemplateColumns: "repeat(15, 1fr)",
              gridTemplateRows: "repeat(3, 1fr)",
              gridTemplateAreas: ` "welcome welcome welcome todaysDate todaysDate todaysDate todaysDate weighIn weighIn weighIn weighIn bodyFatMass bodyFatMass bodyFatMass bodyFatMass" "welcome welcome welcome protein protein protein protein fat fat fat fat carb carb carb carb" "welcome welcome welcome itemsEaten itemsEaten itemsEaten calories calories calories sugars sugars sugars salt salt salt" `,
            }
          : {}),
        ...(!showWelcomeCard
          ? {
              gridTemplateRows: "repeat(2, 1fr)",
              gridTemplateAreas: ` "todaysDate todaysDate todaysDate  protein     protein    protein    fat      fat      fat    carb   carb carb" "weighIn    weighIn    bodyFatMass bodyFatMass itemsEaten itemsEaten calories calories sugars sugars salt salt" `,
            }
          : {}),
      }}
      className={cx({
        [styles.standardRow]: !showWelcomeCard,
        [styles.withWelcomeRow]: showWelcomeCard,
      })}
    >
      {showWelcomeCard && (
        <WelcomeCard
          sx={{
            gridArea: "welcome",
          }}
        />
      )}
      <Widget
        sx={{
          gridArea: "todaysDate",
        }}
        icon={<TodayIcon fontSize="large" />}
        title={format(displayDate, "EEEE", { locale })}
        body={format(displayDate, monthDayFormat, { locale })}
      />

      <WidgetPieChartProtein
        sx={{
          gridArea: "protein",
        }}
        title={proteinDisplay}
        percentage={goalCompletionResult.protein}
      />

      <WidgetPieChartFat
        sx={{
          gridArea: "fat",
        }}
        title={fatDisplay}
        percentage={goalCompletionResult.fat}
      />

      <WidgetPieChartCarbohydrate
        sx={{
          gridArea: "carb",
        }}
        title={carbohydrateDisplay}
        percentage={goalCompletionResult.carbohydrate}
      />

      {showWithingsConnect && (
        <WithingsConnectCard
          sx={{
            gridArea: "weighIn / weighIn / bodyFatMass / bodyFatMass",
          }}
          showIcon={showWelcomeCard}
        />
      )}

      {!showWithingsConnect && (
        <Widget
          sx={{
            gridArea: "weighIn",
          }}
          title={hasStats ? `${getWeight(stats.values.weight)}` : t(GlobalMessages.unknown)}
          body={
            statsMatchesDisplay
              ? t(messages.weightAtX, { timeOfEntry: format(statsDate, "h:mm a", { locale }) })
              : t(GlobalMessages.weight)
          }
        />
      )}

      {!showWithingsConnect && (
        <Widget
          sx={{
            gridArea: "bodyFatMass",
          }}
          title={hasStats ? `${getFatMass(stats.values.fatRatio)}` : t(GlobalMessages.unknown)}
          body={t(GlobalMessages.fatMass)}
        />
      )}

      <WidgetPieChartNext
        sx={{
          gridArea: "itemsEaten",
        }}
        title={t(messages.xOfY, { x: totalItemsConsumed, y: totalItems })}
        body={t(messages.itemsEaten)}
        color="#fff"
        percentage={itemsConsumedPercent}
      />

      <WidgetPieChartCalories
        sx={{
          gridArea: "calories",
        }}
        title={caloriesDisplay}
        percentage={goalCompletionResult.calories}
      />

      <WidgetPieChartSugar
        sx={{
          gridArea: "sugars",
        }}
        title={sugarDisplay}
        percentage={goalCompletionResult.sugars}
      />

      <WidgetPieChartSalt
        sx={{
          gridArea: "salt",
        }}
        title={saltDisplay}
        percentage={goalCompletionResult.salt}
      />
    </Box>
  );
}
