import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import useAccount from "../../contexts/Account/useAccount.js";
import useDialog from "../../contexts/Dialog/useDialog.js";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import isValidEmail from "../../lib/isValidEmail.js";
import getAvailableLanguages from "../../lib/languages/getAvailableLanguages.js";
import Card from "../Card/Card.js";
import ActionMessages from "../messages/Actions.js";
import AppMessages from "../messages/App.js";
import GlobalMessages from "../messages/Global.js";
import messages from "../messages/Settings.js";

export default function AccountCard() {
  const { formatMessage: t } = useIntl();
  const { account, profile, updateAccount, requestVerificationResend } = useAccount();

  const [goalSplit] = useState(profile.goalSplit);
  const [goal, setGoal] = useState(profile.goal);
  const { language } = account;
  const [state, setState] = useState({ profileName: account.name, language, email: account.email || "" });
  const [editedWeekStartsOn, setWeekStartsOn] = useState(profile.weekStartsOn);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const isEmailValid = !state.email?.length || isValidEmail(state.email);
  const isProfileNameValid = state.profileName?.length > 2;
  const isEmailSet = Boolean(account.email);

  const { locale } = useContext(LanguageContext);
  const [isBusy, setIsBusy] = useState(false);
  const confirmAction = useDialog();
  const handleSave = async () => {
    setShowValidationErrors(true);
    if (!isEmailValid || !isProfileNameValid) return;
    setIsBusy(true);
    const setProfileResult = await updateAccount({
      profile: {
        name: state.profileName,
        id: profile.id,
        goal,
        weekStartsOn: editedWeekStartsOn,
        goalSplit,
      },
      account: {
        language: state.language,
        email: state.email,
      },
    });
    if (!setProfileResult.success) {
      // save request failed

      if (setProfileResult.code === "EMAIL_IN_USE") {
        await confirmAction({ text: t(messages.emailInUse) });
      } else {
        await confirmAction({ text: t(messages.errorSaving) });
      }

      setIsBusy(false);
      return;
    }
    if (account.language === setProfileResult.result.account.language) {
      setIsBusy(false);
    }
  };

  const onVerifyEmailClick = async () => {
    const proceed = await confirmAction({ text: t(messages.confirmEmailVerify) });
    if (!proceed) return null;
    return requestVerificationResend();
  };

  const availableSystemLanguages = getAvailableLanguages(t);

  return (
    <Card>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="name"
            value={state.profileName}
            onChange={(event) => {
              setState({ ...state, profileName: event.target.value });
            }}
            error={showValidationErrors && !isProfileNameValid}
            variant="outlined"
            label={t(GlobalMessages.name)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            type="email"
            value={state.email}
            onChange={(event) => {
              setState({ ...state, email: event.target.value.toLowerCase() });
            }}
            error={showValidationErrors && !isEmailValid}
            variant="outlined"
            label={t(GlobalMessages.email)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            variant="outlined"
            label={t(messages.language)}
            value={state.language.substring(0, 2)}
            fullWidth
            SelectProps={{
              MenuProps: {
                MenuListProps: {
                  dense: true,
                },
              },
            }}
            onChange={(event) => setState({ ...state, language: event.target.value })}
          >
            {availableSystemLanguages.map((availableLanguage) => (
              <MenuItem key={availableLanguage.id} value={availableLanguage.id}>
                {availableLanguage.displayOption}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            value={editedWeekStartsOn}
            onChange={(event) => {
              setWeekStartsOn(event.target.value * 1);
            }}
            variant="outlined"
            fullWidth
            SelectProps={{
              MenuProps: {
                MenuListProps: {
                  dense: true,
                },
              },
            }}
            label={t(messages.weekStartsOn)}
          >
            <MenuItem value={0}>{locale.localize.day(0)}</MenuItem>
            <MenuItem value={1}>{locale.localize.day(1)}</MenuItem>
            <MenuItem value={2}>{locale.localize.day(2)}</MenuItem>
            <MenuItem value={3}>{locale.localize.day(3)}</MenuItem>
            <MenuItem value={4}>{locale.localize.day(4)}</MenuItem>
            <MenuItem value={5}>{locale.localize.day(5)}</MenuItem>
            <MenuItem value={6}>{locale.localize.day(6)}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Box component="span" marginRight={2}>
            <Button
              disabled={isBusy}
              variant="contained"
              color="primary"
              onClick={handleSave}
              startIcon={<CheckIcon fontSize="small" />}
            >
              {t(ActionMessages.save)}
            </Button>
          </Box>
          <Button variant="contained" disabled={!isEmailSet || account.isEmailVerified} onClick={onVerifyEmailClick}>
            {t(ActionMessages.verifyEmail)}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
