import {
  addNutritionalProfile,
  getEmptyNutritionalProfile,
  subtractNutritionalProfile,
  combineLogEntries,
} from "../../data/calculateMacros.js";
import MEALPLAN_EDITOR_ACTIONS from "./mealPlanEditorReducerActions.js";
import applyItemSort from "./applyItemSort.js";
import calculateItemsDependentStateValues from "./calculateItemsDependentStateValues.js";

function removeLogEntry(day, plannedItem) {
  const isDiaryEntry = Object.prototype.hasOwnProperty.call(day, "diary");
  if (isDiaryEntry && !day.diary) {
    // This day has not been used
    return day;
  }
  const dayReal = day.diary ? day.diary : day;
  const items = dayReal.items.filter((item) => item.id !== plannedItem.id);
  const newDay = {
    ...dayReal,
    items,
    total:
      items.length === 0
        ? getEmptyNutritionalProfile()
        : subtractNutritionalProfile(dayReal.total, plannedItem.nutritionalProfile),
  };
  if (day.diary) {
    return {
      ...day,
      diary: newDay,
    };
  }
  return newDay;
}

function addLogEntry(day, plannedItem) {
  const dayReal = day.diary ? day.diary : day;
  const newDay = {
    ...dayReal,
    items: [plannedItem, ...day.items],
    total: addNutritionalProfile(dayReal.total, plannedItem.nutritionalProfile),
  };
  if (day.diary) {
    return {
      ...day,
      diary: newDay,
    };
  }
  return newDay;
}

function updateLogEntry(day, plannedItem) {
  const isDiaryEntry = Object.prototype.hasOwnProperty.call(day, "diary");
  if (isDiaryEntry && !day.diary) {
    // This day has not been used
    return day;
  }
  const dayReal = day.diary ? day.diary : day;
  const items = dayReal.items.map((existingLogEntry) => {
    if (existingLogEntry.id !== plannedItem.id) return existingLogEntry;
    return plannedItem;
  });
  const newDay = {
    ...dayReal,
    items,
    total: combineLogEntries(items),
  };
  if (day.diary) {
    return {
      ...day,
      diary: newDay,
    };
  }
  return newDay;
}

function mealPlanDay(day, action) {
  if (action.type === MEALPLAN_EDITOR_ACTIONS.CREATE_LOG_ENTRY) {
    const itemForThisDay = action.value.find((item) => item.day.position === day.position)?.logEntry;
    if (itemForThisDay) {
      return addLogEntry(day, itemForThisDay);
    }
    return day;
  }

  if (!day.diary && day.position !== action.day.position) return day;
  switch (action.type) {
    case MEALPLAN_EDITOR_ACTIONS.DELETE_LOG_ENTRY:
      return removeLogEntry(day, action.value);
    case MEALPLAN_EDITOR_ACTIONS.EDIT_LOG_ENTRY:
      return updateLogEntry(day, action.value);
    default:
      return day;
  }
}

function mealPlanDays(days, action) {
  const mealPlayDayWithAction = (day) => {
    switch (action.type) {
      case MEALPLAN_EDITOR_ACTIONS.DELETE_LOG_ENTRY:
      case MEALPLAN_EDITOR_ACTIONS.CREATE_LOG_ENTRY:
      case MEALPLAN_EDITOR_ACTIONS.EDIT_LOG_ENTRY:
        return mealPlanDay(day, action);
      default:
        return day;
    }
  };
  return days.map(mealPlayDayWithAction);
}

export default function mealPlanEditorReducer(state, action) {
  let newState = state;
  switch (action.type) {
    case MEALPLAN_EDITOR_ACTIONS.OPEN_ADD_TO_PLAN_MODAL:
      newState = {
        ...state,
        addToPlanModalOpen: true,
        addToPlanModalOpenDay: action.value.day,
        addToPlanModalOpenTime: action.value.time || state.addToPlanModalOpenTime,
        addToPlanModalDays: state.runningDailyTotal
          ? state.runningDailyTotal.map(({ id, name }) => ({ id, name }))
          : undefined,
      };
      break;
    case MEALPLAN_EDITOR_ACTIONS.CLOSE_ADD_TO_PLAN_MODAL:
      newState = {
        ...state,
        addToPlanModalOpen: false,
        addToPlanModalOpenDay: undefined,
      };
      break;
    case MEALPLAN_EDITOR_ACTIONS.RELOAD_MEALPLAN:
      newState = {
        ...state,
        mealPlan: action.value,
      };
      break;
    case MEALPLAN_EDITOR_ACTIONS.EDIT:
      newState = {
        ...state,
        mealPlan: {
          ...state.mealPlan,
          ...action.value,
          updatedAt: new Date().toISOString(),
          localUpdateCount: (state.mealPlan.localUpdateCount || state.mealPlan.updateCount) + 1,
        },
      };
      break;
    case MEALPLAN_EDITOR_ACTIONS.SET_STATE:
      newState = {
        ...state,
        ...action.value,
      };
      break;
    case MEALPLAN_EDITOR_ACTIONS.SORT:
      newState = {
        ...state,
        orderBy: action.value.orderBy,
        order: action.value.order,
      };
      break;
    case MEALPLAN_EDITOR_ACTIONS.CREATE_LOG_ENTRY:
    case MEALPLAN_EDITOR_ACTIONS.DELETE_LOG_ENTRY:
    case MEALPLAN_EDITOR_ACTIONS.EDIT_LOG_ENTRY:
      newState = {
        ...state,
        mealPlan: state.mealPlan
          ? {
              ...state.mealPlan,
              updatedAt: new Date().toISOString(),
              localUpdateCount: (state.mealPlan.localUpdateCount || state.mealPlan.updateCount) + 1,
              days: mealPlanDays(state.mealPlan.days, action),
            }
          : state.mealPlan,
        days: state.days ? mealPlanDays(state.days, action) : state.days,
      };
      break;
    case MEALPLAN_EDITOR_ACTIONS.RELOAD_DAYS:
      newState = {
        ...state,
        days: action.value,
      };
      break;
    default:
      break;
  }

  if (
    action.type === MEALPLAN_EDITOR_ACTIONS.CREATE_LOG_ENTRY ||
    action.type === MEALPLAN_EDITOR_ACTIONS.DELETE_LOG_ENTRY ||
    action.type === MEALPLAN_EDITOR_ACTIONS.EDIT_LOG_ENTRY ||
    action.type === MEALPLAN_EDITOR_ACTIONS.EDIT ||
    action.type === MEALPLAN_EDITOR_ACTIONS.RELOAD_DAYS ||
    action.type === MEALPLAN_EDITOR_ACTIONS.RELOAD_MEALPLAN
  ) {
    // recalculate totals and sorts due to items change
    newState = {
      ...newState,
      ...calculateItemsDependentStateValues(
        newState,
        newState.locale || action.locale,
        newState.longDateFormat || action.longDateFormat,
        newState.formatNumber || action.formatNumber,
      ),
    };
  }

  if (
    action.type === MEALPLAN_EDITOR_ACTIONS.CREATE_LOG_ENTRY ||
    action.type === MEALPLAN_EDITOR_ACTIONS.DELETE_LOG_ENTRY ||
    action.type === MEALPLAN_EDITOR_ACTIONS.EDIT_LOG_ENTRY ||
    action.type === MEALPLAN_EDITOR_ACTIONS.SORT ||
    action.type === MEALPLAN_EDITOR_ACTIONS.EDIT ||
    action.type === MEALPLAN_EDITOR_ACTIONS.RELOAD_DAYS ||
    action.type === MEALPLAN_EDITOR_ACTIONS.RELOAD_MEALPLAN
  ) {
    // Re-sort all the item lists
    newState.runningDailyTotal = applyItemSort({
      days: newState.runningDailyTotal,
      order: newState.order,
      orderBy: newState.orderBy,
    });
  }

  return newState;
}
