import Box from "@mui/material/Box";
import React, { useEffect } from "react";

export default function ToastHook({ text, onDelete }) {
  useEffect(() => {
    const timer = setTimeout(() => onDelete(), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        borderRadius: "5px",
        padding: "8px",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "inline-block",
          padding: "10px 16px",
          backgroundColor: "#51a351",
          color: "white",
          borderRadius: "6px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
          pointerEvents: "all",
          fontWeight: 400,
        }}
        onClick={onDelete}
      >
        <span>{text}</span>
      </Box>
    </Box>
  );
}
