import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { updateStorage } from "../../actions/storage.js";
import IngredientDetails from "./IngredientDetails.js";

function IngredientDetailsContainerContent({ idToLoad, ingredients, onDelete, error, newLogEntryDate }) {
  const ingredient = ingredients.find((r) => r.id === idToLoad);

  if (!ingredient) {
    error(`An ingredient with id "${idToLoad}" was not found.`);
    return <div />;
  }

  return <IngredientDetails ingredient={ingredient} onDelete={onDelete} newLogEntryDate={newLogEntryDate} />;
}

const mapStateToProps = (state, ownProps) => ({
  ingredients: state.ingredients.ingredients,
  idToLoad: ownProps.idToLoad,
  newLogEntryDate: new Date(),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDelete: (id) => {
    ownProps.navigate("/");
    dispatch(updateStorage());
  },
  error: () => {
    ownProps.navigate("/");
  },
});

const ConnectedIngredientDetailsContainerContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IngredientDetailsContainerContent);

export default function IngredientDetailsContainer() {
  const navigate = useNavigate();
  const { ingredient } = useParams();
  return <ConnectedIngredientDetailsContainerContent navigate={navigate} idToLoad={ingredient} />;
}
