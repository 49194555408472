import React from "react";
import { useIntl } from "react-intl";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import GlobalMessages from "../messages/Global.js";
import SortableLabel from "../MealPlanList/SortableLabel.js";

export default function DayCardSortableHeaderRow({ onSort, order, orderBy, disableSort, disableActions }) {
  const { formatMessage: t } = useIntl();
  const showActions = !disableActions;
  return (
    <TableRow hover>
      {showActions && <TableCell />}
      <TableCell>{showActions ? t(GlobalMessages.item) : null}</TableCell>
      <TableCell>
        {disableSort ? (
          t(GlobalMessages.calories)
        ) : (
          <SortableLabel
            id="calories"
            order={order}
            orderBy={orderBy}
            label={t(GlobalMessages.calories)}
            onChange={onSort}
          />
        )}
      </TableCell>
      <TableCell>
        {disableSort ? (
          t(GlobalMessages.protein)
        ) : (
          <SortableLabel
            id="protein"
            order={order}
            orderBy={orderBy}
            label={t(GlobalMessages.protein)}
            onChange={onSort}
          />
        )}
      </TableCell>
      <TableCell>
        {disableSort ? (
          t(GlobalMessages.fat)
        ) : (
          <SortableLabel id="fat" order={order} orderBy={orderBy} label={t(GlobalMessages.fat)} onChange={onSort} />
        )}
      </TableCell>
      <TableCell>
        {disableSort ? (
          t(GlobalMessages.sugar)
        ) : (
          <SortableLabel
            id="sugars"
            order={order}
            orderBy={orderBy}
            label={t(GlobalMessages.sugar)}
            onChange={onSort}
          />
        )}
      </TableCell>
      <TableCell>
        {disableSort ? (
          t(GlobalMessages.carbohydrate)
        ) : (
          <SortableLabel
            id="carbohydrate"
            order={order}
            orderBy={orderBy}
            label={t(GlobalMessages.carbohydrate)}
            onChange={onSort}
          />
        )}
      </TableCell>
      <TableCell>
        {disableSort ? (
          t(GlobalMessages.salt)
        ) : (
          <SortableLabel id="salt" order={order} orderBy={orderBy} label={t(GlobalMessages.salt)} onChange={onSort} />
        )}
      </TableCell>
      {showActions && <TableCell aria-label="Commands" />}
    </TableRow>
  );
}
