import React, { useMemo } from "react";
import AddToPlanDialog from "../Calendar/AddToPlanDialog.js";

export default function AddToPlanModal({ isOpen, onClose, day, time, days, createLogEntry }) {
  return useMemo(
    () => (
      <AddToPlanDialog
        createLogEntry={createLogEntry}
        open={isOpen}
        onClose={onClose}
        day={day}
        time={time}
        days={days}
      />
    ),
    [isOpen, day, time, days, createLogEntry],
  );
}
