import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useIntl } from "react-intl";
import useAppInstallation from "../../contexts/AppInstallation/useAppInstallation.js";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ActionMessages from "../messages/Actions.js";

export default function CheckForUpdatesMenuItem({ onClick }) {
  const { formatMessage: t } = useIntl();
  const { checkAndInstallAnyUpdated } = useAppInstallation();
  const performCheck = async () => {
    try {
      const result = await checkAndInstallAnyUpdated();
      // enqueueSnackbar({ message: `Update Check Started`, variant: "success" });
    } catch (ex) {
      // console.log(ex);
      // enqueueSnackbar({ message: `Update Check Failed.`, variant: "error" });
    }
  };

  const onClickHandler = async () => {
    onClick();
    await performCheck();
  };

  return (
    <MenuItem onClick={onClickHandler} disableRipple disableTouchRipple>
      <ListItemIcon>
        <SystemUpdateAltIcon />
      </ListItemIcon>
      <ListItemText primary={t(ActionMessages.checkForUpdates)} />
    </MenuItem>
  );
}
