import IngredientListReducerInitialiser from "./IngredientListReducerInitialiser.js";

export default function ingredientListReducer(state, action) {
  if (action.type === "INGREDIENTS") {
    return {
      ...state,
      ...IngredientListReducerInitialiser({ ...state, ingredients: action.value }),
    };
  }

  if (action.type === "SORT") {
    return IngredientListReducerInitialiser({
      ...state,
      orderBy: action.value.orderBy,
      order: action.value.order,
    });
  }
  return state;
}
