import fetchWithTimeout from "./fetchWithTimeout.js";

/**
 * Get the current viewer. This request is handled a little differently from regular API calls since
 * this call bootstraps the app startup, so since it's the first request, we want to handle any failures
 * well to offer specific information to the user.
 * @returns {Promise<{}>}
 */
export default async function getViewer() {
  const results = {
    json: null,
    isError: false,
    isSuccess: false,
    text: null,
    statusCode: null,
    statusText: null,
    response: null,
    networkError: null,
    parseError: null,
    redirectUrl: null,
  };

  try {
    results.response = await fetchWithTimeout("/api/viewer", true, 5000, "follow");
  } catch (err) {
    results.networkError = err;
    results.isError = true;
  }

  if (results.response) {
    results.statusCode = results.response?.status;
    results.statusText = results.response?.statusText;

    if (results.response.redirected) {
      results.redirectUrl = results.response.url;
      const redirectUrl = new URL(results.redirectUrl);
      results.redirectOrigin = redirectUrl.origin;
    }

    try {
      const contentType = results.response.headers.get("content-type");
      if (contentType?.includes("application/json")) {
        results.json = await results.response.json();
      } else {
        results.text = await results.response.text();
      }
    } catch (err) {
      results.parseError = err;
      results.isError = true;
    }
  }

  return results;
}
