import { Typography } from "@mui/material";
import React, { useContext } from "react";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import { useIntl } from "react-intl";
import { parseISO, format } from "date-fns";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import messages from "../messages/Settings.js";
import ActionMessages from "../messages/Actions.js";
import Card from "../Card/Card.js";
import GlobalMessages from "../messages/Global.js";

export default function WithingsSettingsCard({ withings }) {
  const { longDateTimeFormat } = useContext(LanguageContext);
  const { formatMessage: t } = useIntl();

  const isConnected = Boolean(withings);

  return (
    <Card title="Withings" dense>
      <CardContent>
        <Typography variant="body1">{t(messages.withingsPrompt)}</Typography>
      </CardContent>
      <List>
        <ListItem>
          <ListItemText
            primary={t(messages.status)}
            secondary={t(isConnected ? ActionMessages.connected : messages.disconnected)}
          />
          <ListItemSecondaryAction>
            <Button
              id="cmdConnectWithings"
              disabled
              variant="contained"
              onClick={() => {
                window.location = __DEV__ ? "/api/withings/start?simulate=true" : "/api/withings/start";
              }}
            >
              {t(!withings ? ActionMessages.connect : ActionMessages.reconnect)}
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(GlobalMessages.created)}
            secondary={isConnected ? format(parseISO(withings.dateCreated), longDateTimeFormat) : t(messages.never)}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(messages.lastUsed)}
            secondary={
              isConnected ? format(parseISO(withings.lastUsedSuccessfully), longDateTimeFormat) : t(messages.never)
            }
          />
        </ListItem>
      </List>
    </Card>
  );
}
