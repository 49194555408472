import { isDate, isValid, parseISO } from "date-fns";
import getDays from "../api/getDays.js";
import { loadAccountFromStorage, loadProfileFromStorage } from "./data.js";
import { loadFromStorage as loadIngredientsFromStorage } from "./ingredient.js";
import { loadFromStorage as loadDaysFromStorage } from "./day.js";
import { loadFromStorage as loadMealPlansFromStorage } from "./mealPlan.js";
import getDb from "../models/getDb.js";

export const STORAGE_UPDATE = "@@storage/UPDATE";
export const STORAGE_UPDATE_FAILURE = "@@storage/UPDATE_FAILURE";
export const STORAGE_UPDATE_SUCCESS = "@@storage/UPDATE_SUCCESS";

export const STORAGE_LOAD = "@@storage/LOAD";
export const STORAGE_LOAD_FAILURE = "@@storage/LOAD_FAILURE";
export const STORAGE_LOAD_SUCCESS = "@@storage/LOAD_SUCCESS";

export const STORAGE_DELETE = "@@storage/DELETE";
export const STORAGE_DELETE_FAILURE = "@@storage/DELETE_FAILURE";
export const STORAGE_DELETE_SUCCESS = "@@storage/DELETE_SUCCESS";

export const updateStorage = (args) => async (dispatch, getState) => {
  dispatch({ type: STORAGE_UPDATE });

  if (args?.day) {
    const db = getDb();
    await db.days.put(args.day);
    console.log("STORE DAY", args?.day);
  }

  if (args?.mealPlan) {
    const db = getDb();
    await db.mealPlans.put(args.mealPlan);
    console.log("STORE MEALPLAN", args?.mealPlan);
  }

  if (args?.ingredient) {
    const db = getDb();
    await db.ingredients.put(args.ingredient);
    console.log("STORE INGREDIENT", args?.ingredient);
  }
  // const currentState = getState();
  // const store = {
  //   ingredients: currentState.ingredients.ingredients,
  //   mealPlans: currentState.mealPlans.mealPlans,
  //   days: currentState.days.days,
  //   account: currentState.data.account,
  //   profile: currentState.data.profile,
  // };
  try {
    // await set(storeName, store, applicationPersistantStore());
    dispatch({ type: STORAGE_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({ type: STORAGE_UPDATE_FAILURE, error });
  }
};

export const loadStorage = () => async (dispatch, getState) => {
  dispatch({ type: STORAGE_LOAD });
  try {
    const db = getDb();

    /**
     * @deprecated
     */
    const store = null;

    let loadedProfile = false;
    let daysFromDexie = await db.days.toArray();
    if (daysFromDexie.length) {
      dispatch(loadDaysFromStorage(daysFromDexie));
    } else {
      dispatch(loadDaysFromStorage([]));
    }

    let ingredientsFromDexie = await db.ingredients.toArray();
    if (ingredientsFromDexie.length) {
      dispatch(loadIngredientsFromStorage(ingredientsFromDexie));
    } else {
      dispatch(loadIngredientsFromStorage([]));
    }

    let mealPlanFromDexie = await db.mealPlans.get("default");

    if (mealPlanFromDexie) {
      dispatch(loadMealPlansFromStorage([mealPlanFromDexie]));
    } else {
      dispatch(loadMealPlansFromStorage([]));
    }

    let profileFromDexie = await db.profile.get("default");
    let accountFromDexie = await db.account.get("default");

    if (profileFromDexie && accountFromDexie) {
      loadedProfile = true;
      dispatch(loadProfileFromStorage(profileFromDexie));
      dispatch(loadAccountFromStorage(accountFromDexie));
    }

    dispatch({ type: STORAGE_LOAD_SUCCESS });

    // let mealPlans;
    // try {
    //   mealPlans = await getPlans();
    // } catch (error) {
    //   console.log(error);
    // }
    //
    // if (mealPlans) {
    //   dispatch(loadMealPlansFromStorage(mealPlans));
    // }

    // setAccount(response.account, response.profile, null, response.lastChecked);

    // let ingredients;
    // try {
    //   ingredients = await getIngredients();
    // } catch (error) {
    //   console.log(error);
    // }
    // if (ingredients) {
    //   dispatch(loadIngredientsFromStorage(ingredients));
    // }

    let saveData = false;

    // if (!store?.days?.length) {
    //   let days;
    //   try {
    //     days = await getDays();
    //   } catch (error) {
    //     console.log(error);
    //   }
    //
    //   const withDate = (item) => {
    //     const result = {
    //       ...item,
    //     };
    //
    //     if (item.date) {
    //       result.date = parseISO(item.date);
    //     }
    //
    //     if (result.date && (!isDate(result.date) || !isValid(result.date))) {
    //       console.warn(`${item.date} was not a valid date`, item);
    //       delete result.date;
    //     }
    //
    //     return result;
    //   };
    //
    //   if (days) {
    //     days = days.map((item) => {
    //       const safeData = withDate(item);
    //
    //       if (safeData.items) {
    //         safeData.items = safeData.items.map(withDate);
    //       } else {
    //         safeData.items = [];
    //       }
    //
    //       return safeData;
    //     });
    //     dispatch(loadDaysFromStorage(days));
    //     saveData = true;
    //   }
    // }

    // if (!loadedProfile) {
    //   // We are logged in, sync other data.
    //   // First time users will have synced empty collections.
    //   const account = await getViewer();
    //
    //   if (account.json?.account) {
    //     dispatch(loadAccountFromStorage(account.json.account));
    //   }
    //
    //   if (account.json?.profile) {
    //     dispatch(loadProfileFromStorage(account.json.profile));
    //   }
    //
    //   saveData = true;
    // }

    // if (saveData) {
    //   // Write the loaded API data to local.
    //   await updateStorage()(dispatch, getState);
    // }

    // dispatch({ type: STORAGE_LOAD_SUCCESS });
  } catch (error) {
    dispatch({ type: STORAGE_LOAD_FAILURE, error });
  }
};

export const deleteStorage = () => async (dispatch) => {
  dispatch({ type: STORAGE_DELETE });
  try {
    // await del(storeName, applicationPersistantStore());
    dispatch({ type: STORAGE_DELETE_SUCCESS });
  } catch (error) {
    dispatch({ type: STORAGE_DELETE_FAILURE, error });
  }
};
