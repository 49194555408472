import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useIntl } from "react-intl";
import GlobalMessages from "../messages/Global.js";
import { UpArrowIndicator } from "../MealPlanList/ArrowIndicator.js";

export default function RemainingRow({ remainingDisplay }) {
  const { formatMessage: t } = useIntl();
  return (
    <TableRow hover>
      <TableCell />
      <TableCell>{t(GlobalMessages.remaining)}</TableCell>

      <TableCell>
        {remainingDisplay.caloriesValue < 0 ? (
          <UpArrowIndicator label={remainingDisplay.calories} />
        ) : (
          remainingDisplay.calories
        )}
      </TableCell>
      <TableCell>
        {remainingDisplay.proteinValue < 0 ? (
          <UpArrowIndicator label={remainingDisplay.protein} />
        ) : (
          remainingDisplay.protein
        )}
      </TableCell>
      <TableCell>
        {remainingDisplay.fatValue < 0 ? <UpArrowIndicator label={remainingDisplay.fat} /> : remainingDisplay.fat}
      </TableCell>
      <TableCell>
        {remainingDisplay.sugarsValue < 0 ? (
          <UpArrowIndicator label={remainingDisplay.sugars} />
        ) : (
          remainingDisplay.sugars
        )}
      </TableCell>
      <TableCell>
        {remainingDisplay.carbohydrateValue < 0 ? (
          <UpArrowIndicator label={remainingDisplay.carbohydrate} />
        ) : (
          remainingDisplay.carbohydrate
        )}
      </TableCell>
      <TableCell>
        {remainingDisplay.saltValue < 0 ? <UpArrowIndicator label={remainingDisplay.salt} /> : remainingDisplay.salt}
      </TableCell>
      <TableCell />
    </TableRow>
  );
}
