import React from "react";
import { useIntl, defineMessages } from "react-intl";
import useTitle from "../../lib/useTitle.js";
import Card from "../Card/Card.js";

const messages = defineMessages({
  title: {
    id: "privacy.title",
    defaultMessage: "Privacy Policy of {title}",
    description: "Page title.",
  },
  services: {
    id: "privacy.services",
    defaultMessage: "Personal Data is collected for the following purposes and using the following services:",
    description: "Body text",
  },
  login: {
    id: "privacy.login",
    defaultMessage: "Registration and authentication provided directly by this Application (e-mail; name; surname)",
    description: "Body text",
  },
});

export default function Privacy() {
  const { formatMessage: t } = useIntl();
  const title = t(messages.title, { title: "MacroMealPrep" });
  useTitle(title);
  return (
    <div>
      <Card title={title}>
        <p>
          <strong>{t(messages.services)}</strong>
        </p>
        <ul>
          <li>{t(messages.login)}</li>
        </ul>
      </Card>
    </div>
  );
}
