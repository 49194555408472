import React from "react";
import Box from "@mui/material/Box";

export default function CenterContainerPage({ children }) {
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {children}
    </Box>
  );
}
