import React, { useContext, useMemo } from "react";
import { Navigate, Route, useLocation } from "react-router";
import LanguageContext from "../contexts/Language/LanguageContext.js";

export default function StripQuery(Component) {
  return () => {
    const { search } = useLocation();
    const { prefix } = useContext(LanguageContext);

    return useMemo(() => {
      if (search) {
        return (
          <Route
            render={() => (
              <Navigate
                to={{
                  pathname: `${prefix}/`,
                }}
                state={{}}
              />
            )}
          />
        );
      }
      return <Component />;
    }, [prefix, search]);
  };
}
