import { useEffect } from "react";
import useConfig from "../contexts/Config/useConfig.js";

const defaultSiteName = "MacroMealPrep";
let lastTitle = defaultSiteName;

function generateTitle(title, siteName) {
  if (!title) return siteName || defaultSiteName;
  return `${title} - ${siteName || defaultSiteName}`;
}

function setTitle(title, siteName) {
  lastTitle = generateTitle(title, siteName);
  return lastTitle;
}

function setTitleStandalone(title) {
  lastTitle = title;
  return lastTitle;
}

export function getTitle() {
  return lastTitle;
}

export function useTitleStandalone(title) {
  if (!process.env.BROWSER) {
    // Needed as useEffect will not run on server.
    setTitleStandalone(title);
  }
  useEffect(() => {
    document.title = setTitleStandalone(title);
  }, [title]);
}

export default function useTitle(title) {
  const { title: siteName } = useConfig();
  if (!process.env.BROWSER) {
    // Needed as useEffect will not run on server.
    setTitle(title, siteName);
  }
  useEffect(() => {
    document.title = setTitle(title, siteName);
  }, [title]);
}
