import Button from "@mui/material/Button";
import React, { useState, useMemo } from "react";
import { useIntl } from "react-intl";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { convertIngredientToIngredientItem } from "../Calendar/useFoodList.js";
import ActionMessages from "../messages/Actions.js";
import AddToCalendarDialog from "./AddToCalendarDialog.js";

export default function AddToPlanButton({ ingredient, variant = "contained" }) {
  const { formatMessage: t } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const onOpenClick = () => {
    setIsOpen(true);
  };

  const onCloseClick = () => {
    setIsOpen(false);
  };

  const ingredientItem = useMemo(() => {
    if (!ingredient) return null;
    return convertIngredientToIngredientItem(ingredient);
  }, [ingredient]);

  return (
    <>
      <Button
        variant={variant}
        onClick={onOpenClick}
        startIcon={<AddCircleOutlineIcon fontSize="small" />}
        size="small"
      >
        {t(ActionMessages.addToPlan)}
      </Button>
      <AddToCalendarDialog open={isOpen} onClose={onCloseClick} ingredient={ingredientItem} />
    </>
  );
}
