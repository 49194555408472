import React, { useState } from "react";
import ToastContext from "./ToastContext.js";

export default function ToastProvider({ children }) {
  const [toast, setToast] = useState([]);

  const deleteToast = (toastToDelete) => {
    setToast(toast.filter((item) => item === toastToDelete));
  };

  const displayToast = (newToast) => {
    setToast([newToast, ...toast]);

    setTimeout(() => {
      deleteToast(newToast);
    }, 3000);
  };

  const value = {
    toast,
    displayToast,
    deleteToast,
  };

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
}
