import { addCalorieBreakdown } from "./editorReducer.js";
import parseNumericInput, { parseValidNumber } from "../../lib/parseNumericInput.js";
import getEditableNutritionalProfile from "../../lib/getEditableNutritionalProfile.js";

function getValidOrNull(input) {
  if (input.empty || !input.valid) return null;
  return input.number;
}

export default function toState(ingredient) {
  const { nutritionalProfile, suggestedServingSizeNutritionalProfile, individualServingSizeNutritionalProfile } =
    ingredient;

  const suggestedServingSizeParsed = parseNumericInput(ingredient.suggestedServingSize.grams, true);
  const individualServingSizeParsed = parseNumericInput(ingredient.individualServingSize.grams, true);

  const value = {
    name: ingredient.name,
    isLiquid: ingredient.isLiquid || false,
    brand: ingredient.brand,
    isFavourite: ingredient.isFavourite === true,
    notes: ingredient.notes || "",
    description: ingredient.description,
    packSize: {
      value: `${ingredient.packSize || ""}`,
      number: parseValidNumber(ingredient.packSize, true),
      valid: true,
    },
    containerName: ingredient.containerName,
    hasUnits: ingredient.hasUnits,
    suggestedServingSizeGrams: {
      value: `${ingredient.suggestedServingSize.grams || ""}`,
      number: getValidOrNull(suggestedServingSizeParsed),
      disabled: false,
      placeholder: {
        value: "100",
        number: 100,
      },
      valid: true,
    },
    suggestedServingSizeQuantity: {
      value: `${ingredient.suggestedServingSize.quantity || ""}`,
      number: ingredient.suggestedServingSize.quantity,
      disabled: false,
      placeholder: {
        value: "1",
        number: 1,
      },
      valid: true,
    },
    individualServingSizeGrams: {
      value: `${ingredient.individualServingSize.grams || ""}`,
      number: getValidOrNull(individualServingSizeParsed),
      disabled: false,
      placeholder: {
        value: "100",
        number: 100,
      },
      valid: true,
    },
    individualServingSizeQuantity: {
      value: ingredient.hasUnits ? "1" : `${ingredient.individualServingSize.quantity || ""}`,
      number: ingredient.hasUnits ? 1 : ingredient.individualServingSize.quantity,
      disabled: false,
      placeholder: {
        value: "1",
        number: 1,
      },
      valid: true,
    },
    nutritionalProfile: getEditableNutritionalProfile(nutritionalProfile),
    suggestedServingSizeNutritionalProfile: getEditableNutritionalProfile(suggestedServingSizeNutritionalProfile),
    individualServingSizeNutritionalProfile: getEditableNutritionalProfile(individualServingSizeNutritionalProfile),
  };

  return addCalorieBreakdown(value);
}
