import { addDays, format } from "date-fns";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import Card from "../Card/Card.js";
import Link from "../Link.js";
import ActionMessages from "../messages/Actions.js";
import HomeMessages from "../messages/Home.js";

export default function CalendarSummaryCard({ setCalendarStartDate, nextWeek, thisWeek, thisWeekActual, lastWeek }) {
  const { formatMessage: t } = useIntl();
  const { locale, longDateFormat } = useContext(LanguageContext);

  return (
    <Card title={t(HomeMessages.navigation)}>
      <Link
        onClick={() => {
          setCalendarStartDate(format(nextWeek, "yyyy/M/d"));
        }}
        to={`/calendar/${format(nextWeek, "yyyy/M/d")}`}
      >{`${t(ActionMessages.next)}: ${format(nextWeek, longDateFormat, { locale })} - ${format(
        addDays(nextWeek, 6),
        longDateFormat,
        {
          locale,
        },
      )}`}</Link>
      <br />
      {`${t(ActionMessages.displayed)}: ${format(thisWeek, longDateFormat, { locale })} - ${format(
        addDays(thisWeek, 6),
        longDateFormat,
        { locale },
      )}`}
      <br />
      <Link
        onClick={() => {
          setCalendarStartDate(format(thisWeekActual, "yyyy/M/d"));
        }}
        to={`/calendar/${format(thisWeekActual, "yyyy/M/d")}`}
      >{`${t(ActionMessages.current)}: ${format(thisWeekActual, longDateFormat, { locale })} - ${format(
        addDays(thisWeekActual, 6),
        longDateFormat,
        {
          locale,
        },
      )}`}</Link>
      <br />
      <Link
        onClick={() => {
          setCalendarStartDate(format(lastWeek, "yyyy/M/d"));
        }}
        to={`/calendar/${format(lastWeek, "yyyy/M/d")}`}
      >{`${t(ActionMessages.previous)}: ${format(lastWeek, longDateFormat, { locale })} - ${format(
        addDays(lastWeek, 6),
        longDateFormat,
        {
          locale,
        },
      )}`}</Link>
    </Card>
  );
}
