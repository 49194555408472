import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers/index.js";

export default function configureStores(initialState) {
  const middlewares = applyMiddleware(thunkMiddleware);
  const enhancer = compose(middlewares);

  return createStore(rootReducer, initialState, enhancer);
}
