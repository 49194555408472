const MEALPLAN_EDITOR_ACTIONS = {
  EDIT: "EDIT",
  CREATE_LOG_ENTRY: "CREATE_LOG_ENTRY",
  DELETE_LOG_ENTRY: "DELETE_LOG_ENTRY",
  EDIT_LOG_ENTRY: "EDIT_LOG_ENTRY",
  SET_STATE: "SET_STATE",
  SORT: "SORT",
  OPEN_ADD_TO_PLAN_MODAL: "OPEN_ADD_TO_PLAN_MODAL",
  CLOSE_ADD_TO_PLAN_MODAL: "CLOSE_ADD_TO_PLAN_MODAL",
  RELOAD_DAYS: "RELOAD_DAYS",
  RELOAD_MEALPLAN: "RELOAD_MEALPLAN",
};

export default MEALPLAN_EDITOR_ACTIONS;
