import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useTheme } from "@mui/material/styles";
import { CardActionArea } from "@mui/material";
import { WidgetWrapperCard, WidgetInternal } from "./Widget.js";
import { WidgetBody } from "./WidgetWrapper.js";

const maxAtOneHundred = (value) => {
  if (value < 0) return 0;
  if (value > 100) return 100;
  return value;
};

function WidgetPieChartGraphic({ color, percentage }) {
  const {
    palette: { divider },
  } = useTheme();

  return (
    <PieChart
      data={[{ value: 1, key: 1, color }]}
      reveal={maxAtOneHundred(percentage)}
      lineWidth={20}
      background={divider}
      rounded
      animate
    />
  );
}

export function WidgetPieChartNext({ sx, title, body, percentage, color, selected, onClick, borderColor }) {
  const isButton = typeof onClick === "function";
  const sxSafe = sx || {};
  const content = (
    <WidgetWrapperCard sx={isButton ? {} : sxSafe} selected={selected} color={borderColor}>
      <WidgetBody>
        <WidgetInternal
          icon={<WidgetPieChartGraphic percentage={percentage} color={color} />}
          title={title}
          body={body}
        />
      </WidgetBody>
    </WidgetWrapperCard>
  );

  if (!isButton) {
    return content;
  }

  return (
    <CardActionArea
      onClick={onClick}
      sx={{ ...sxSafe, borderRadius: "10px", display: "flex", flexDirection: "column" }}
    >
      {content}
    </CardActionArea>
  );
}
