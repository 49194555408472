import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import messages from "../messages/Settings.js";
import AccountCard from "./AccountCard.js";
import PasskeysCard from "./PasskeysCard.js";

export default function AccountSection() {
  const { formatMessage: t } = useIntl();

  const [tab, setTab] = useState("account");

  const handleTabChange = (event, newSelectedTab) => {
    setTab(newSelectedTab);
  };

  return (
    <>
      <Tabs variant="fullWidth" value={tab} onChange={handleTabChange}>
        <Tab value="account" label={t(messages.account)} />
        <Tab value="passkeys" label={t(messages.passkeys)} />
      </Tabs>
      {tab === "account" && <AccountCard />}
      {tab === "passkeys" && <PasskeysCard />}
    </>
  );
}
