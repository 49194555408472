import AddIcon from "@mui/icons-material/Add";
import React, { useState, useMemo, useContext } from "react";
import { Button, Dialog, TextField, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import GlobalMessages from "../messages/Global.js";
import ActionMessages from "../messages/Actions.js";
import setPlan from "../../api/setPlan.js";
import { createMealPlan } from "../../actions/mealPlan.js";
import ProfileContext from "../../contexts/Profile/ProfileContext.js";

export default function CreateMealPlanModal({ onSubmit, isOpen, setIsOpen }) {
  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch();
  const { profile } = useContext(ProfileContext);

  const [name, setName] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [message, setMessage] = useState("");

  const onPrimaryClick = async () => {
    if (isBusy || !name) return;
    setIsBusy(true);
    setMessage("");
    const newMealPlan = await setPlan({
      name,
      goal: profile.goal,
    });
    dispatch(createMealPlan(newMealPlan));
    setIsBusy(false);
    setIsOpen(false);
    onSubmit();
    setName("");
  };

  const onKeyPress = (event) => {
    if (event.key !== "Enter") return;
    onPrimaryClick();
  };

  const onClose = () => {
    if (isBusy) return;
    setIsBusy(false);
    setName("");
    setMessage("");
    setIsOpen(false);
  };

  return useMemo(
    () => (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{t(ActionMessages.newMealPlan)}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t(GlobalMessages.name)}
            onKeyPress={onKeyPress}
            fullWidth
            value={name}
            disabled={isBusy}
            helperText={message}
            error={Boolean(message)}
            onChange={(event) => {
              setName(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isBusy} onClick={onClose} color="primary">
            {t(ActionMessages.cancel)}
          </Button>
          <Button
            disabled={isBusy}
            startIcon={<AddIcon fontSize="small" />}
            onClick={onPrimaryClick}
            color="primary"
            variant="contained"
          >
            {t(ActionMessages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [isOpen, name, isBusy, message],
  );
}
