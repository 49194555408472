import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import IngredientDetailsEditor from "./IngredientDetailsEditor.js";
import { createIngredient } from "../../actions/ingredient.js";
import { updateStorage } from "../../actions/storage.js";
import generateId from "../../data/generateId.js";

function IngredientCreatorContainerContent({ updateIngredient, onCancel }) {
  const ingredient = {
    name: "",
    brand: "",
    description: "",
    packSize: "",
    servingSize: "",
    containerName: "",
    isLiquid: false,
    hasUnits: false,
    readilyConsumable: false,
    nutritionalProfile: {},
    suggestedServingSizeNutritionalProfile: {},
    individualServingSizeNutritionalProfile: {},
    suggestedServingSize: {
      name: "",
      grams: null,
      quantity: null,
    },
    individualServingSize: {
      name: "",
      grams: null,
      quantity: null,
    },
  };

  return <IngredientDetailsEditor ingredient={ingredient} updateIngredient={updateIngredient} onCancel={onCancel} />;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateIngredient: (ingredient) => {
    const now = new Date().toISOString();
    const newIngredient = { ...ingredient, id: `${generateId()}`, version: 1, createdAt: now, updatedAt: now };
    dispatch(createIngredient(newIngredient));
    ownProps.navigate(`/ingredient/${newIngredient.id}`);
    dispatch(updateStorage());
  },
  onCancel: () => ownProps.navigate("/ingredient/"),
});

const ConnectedIngredientCreatorContainerContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IngredientCreatorContainerContent);

export default function IngredientCreatorContainer() {
  const navigate = useNavigate();

  return <ConnectedIngredientCreatorContainerContent navigate={navigate} />;
}
