import { MEALPLAN_DELETE, MEALPLAN_UPDATE, MEALPLAN_LOAD_FROM_STORAGE, MEALPLAN_CREATE } from "../actions/mealPlan.js";

const initialState = {
  mealPlans: [],
};

export function MealPlanReducer(mealPlan, action) {
  if (mealPlan.id !== action.id) {
    return mealPlan;
  }
  switch (action.type) {
    case MEALPLAN_UPDATE:
      if (mealPlan === action.mealPlan) {
        return mealPlan;
      }
      return action.mealPlan;
    default:
      return mealPlan;
  }
}

function mealPlans(state = initialState, action) {
  switch (action.type) {
    case MEALPLAN_DELETE:
      return {
        ...state,
        mealPlans: state.mealPlans.filter((mealPlan) => mealPlan.id !== action.id),
      };
    case MEALPLAN_UPDATE:
      return {
        ...state,
        mealPlans: state.mealPlans.map((mealPlan) => MealPlanReducer(mealPlan, action)),
      };
    case MEALPLAN_CREATE:
      return {
        ...state,
        mealPlans: [action.mealPlan, ...state.mealPlans],
      };
    case MEALPLAN_LOAD_FROM_STORAGE:
      return {
        ...state,
        mealPlans: [...action.mealPlans],
      };
    default:
      return state;
  }
}

export default mealPlans;
