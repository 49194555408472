import React from "react";
import { isValid, parse } from "date-fns";
import { useNavigate, useParams } from "react-router";
import HomeContainer from "../Home/HomeContainer.js";

const verifyInputDate = (date) => {
  const desiredDate = parse(date, "yyyyMMdd", new Date());
  if (!isValid(desiredDate)) return undefined;
  return desiredDate;
};

export default function CalendarDayContainer(props) {
  const navigate = useNavigate();
  const { date } = useParams();
  const displayDate = verifyInputDate(date);

  if (!displayDate) {
    return null;
  }

  return <HomeContainer displayDate={displayDate} navigate={navigate} isUserSelectedDate />;
}
