import React from "react";

const SessionContext = React.createContext({
  defaultLogEntryDate: undefined,
  setDefaultLogEntryDate: () => {},
  calendarStartDate: undefined,
  setCalendarStartDate: () => {},
  startDayThisWeek: null,
  startDayNextWeek: null,
  selectedMacro: "calories",
  setSelectedMacro: () => {},
});

export default SessionContext;
