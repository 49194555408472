import { useMemo } from "react";
import { useLocation } from "react-router";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  return useMemo(() => {
    if (process.env.BROWSER) window.scrollTo(0, 0);
    return null;
  }, [pathname]);
}
