import React, { useMemo } from "react";
import EditMealPlanModalContent from "./EditMealPlanModalContent.js";

export default function AddToPlanModal({ isOpen, setIsOpen, updateMealPlan, mealPlan }) {
  const onClose = () => {
    setIsOpen(false);
  };

  return useMemo(() => {
    if (!mealPlan) return null;
    return (
      <EditMealPlanModalContent updateMealPlan={updateMealPlan} open={isOpen} onClose={onClose} mealPlan={mealPlan} />
    );
  }, [isOpen, mealPlan, updateMealPlan]);
}
