import { defineMessages } from "react-intl";

const SEO = defineMessages({
  seoDescription: {
    id: "html.seoDescription",
    defaultMessage: "A free app to track your own body building diet plan, with full macro breakdown.",
    description: "Page description that appears on Google.",
  },
  seoTitle: {
    id: "html.seoTitle",
    defaultMessage: "MacroMealPrep - Free meal planning and professional calorie + macro calculator",
    description: "Page title that appears on Google.",
  },
});

export default SEO;
