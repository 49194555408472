import { useTheme } from "@mui/material/styles";
import React from "react";
import Box from "@mui/material/Box";
import { WidgetPieChartNext } from "../Home/WidgetPieChart.js";
import WidgetRangeText from "../Home/WidgetRangeText.js";
import { PercentageScaleColorByMacro } from "../MealPlanList/ArrowIndicator.js";

export default function DayWidgets({ days, value, onClick, macro }) {
  const {
    palette: { food },
  } = useTheme();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        gridColumnGap: "16px",
        gridRowGap: "16px",
        marginBottom: "16px",
      }}
    >
      {days.map((day) => {
        let macroToShow = macro || "calories";
        const percentage = day.completion[macroToShow];
        const borderColor = percentage > 0 ? PercentageScaleColorByMacro(percentage, macro, true)?.color : undefined;
        const value = day.sortableConsumed[macroToShow]; // the actual number

        let title = "-";

        if (value > 0) {
          title = <WidgetRangeText value={value} unit={macroToShow === "calories" ? "unit" : "gram"} />;
        }

        return (
          <WidgetPieChartNext
            key={day.id}
            title={title}
            percentage={percentage}
            color={food[macro] || food.calories}
            body={day.name}
            selected={day.tabId === value}
            borderColor={borderColor}
            onClick={() => {
              onClick(day.tabId);
            }}
          />
        );
      })}
    </Box>
  );
}
