import { useContext } from "react";
import { useLocation as RouterUseLocation } from "react-router";
import LanguageContext from "../contexts/Language/LanguageContext.js";

export default function useLocation() {
  const { prefix } = useContext(LanguageContext);
  const { pathname: pathnameRaw } = RouterUseLocation();

  return {
    pathname: prefix.length ? pathnameRaw.substring(prefix.length) : pathnameRaw,
  };
}
