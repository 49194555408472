import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import IngredientDetailsEditor from "./IngredientDetailsEditor.js";
import { updateIngredient as updateIngredientAction } from "../../actions/ingredient.js";
import { updateStorage } from "../../actions/storage.js";

function IngredientEditorContainerContent({ updateIngredient, onCancel, ingredients, idToLoad }) {
  const ingredient = ingredients.find((ingredientToLoad) => ingredientToLoad.id === idToLoad);

  if (!ingredient) return undefined;

  return (
    <IngredientDetailsEditor
      ingredient={ingredient}
      updateIngredient={updateIngredient}
      onCancel={() => onCancel(ingredient)}
    />
  );
}

const mapStateToProps = (state, ownProps) => ({
  ingredients: state.ingredients.ingredients,
  idToLoad: ownProps.idToLoad,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateIngredient: (ingredient) => {
    const now = new Date().toISOString();
    const updatedIngredient = {
      ...ingredient,
      version: (ingredient.version || 1) + 1,
      updatedAt: now,
      createdAt: ingredient.createdAt || now,
    };
    dispatch(updateIngredientAction(updatedIngredient));
    ownProps.navigate(`/ingredient/${updatedIngredient.id}`);
    dispatch(updateStorage());
  },
  onCancel: (ingredient) => ownProps.navigate(`/ingredient/${ingredient.id}`),
});

const ConnectedIngredientEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IngredientEditorContainerContent);

export default function IngredientEditorContainer() {
  const navigate = useNavigate();
  const { ingredient } = useParams();

  return <ConnectedIngredientEditorContainer navigate={navigate} idToLoad={ingredient} />;
}
