import { combineReducers } from "redux";
import ingredients from "./ingredients.js";
import days from "./days.js";
import storage from "./storage.js";
import mealPlans from "./mealPlans.js";
import data from "./data.js";

const reducers = combineReducers({
  mealPlans,
  ingredients,
  storage,
  days,
  data,
});

export default reducers;
