import { addDays, getDate, getMonth, getYear, startOfDay } from "date-fns";

export default function getDaysToDisplay(totalDaysToDisplay, startDate = new Date()) {
  if (totalDaysToDisplay < 0) throw new Error("Number of days must be a positive integer.");

  let runningDate = startDate;
  const daysToDisplay = [];
  let daysCounter = totalDaysToDisplay;
  let order = 0;
  while (daysCounter > 0) {
    const year = getYear(runningDate);
    const month = getMonth(runningDate);
    const day = getDate(runningDate);
    daysToDisplay.push({
      year,
      month,
      day,
      order,
      date: startOfDay(runningDate),
    });
    runningDate = addDays(runningDate, 1);
    order += 1;
    daysCounter -= 1;
  }
  return daysToDisplay;
}
