import languageDisplayOption from "./languageDisplayOption.js";
import messages from "../../components/messages/Settings.js";

function createAvailableLanguageOption(id, nameInEnglish, nameInOwnLanguage, nameInCurrentLanguage) {
  return {
    id,
    nameInEnglish,
    nameInOwnLanguage,
    nameInCurrentLanguage,
    displayOption: languageDisplayOption(nameInEnglish, nameInOwnLanguage, nameInCurrentLanguage),
  };
}
export default function getAvailableLanguages(t) {
  return [
    createAvailableLanguageOption("en", "English", "English", t(messages.english)),
    createAvailableLanguageOption("pt", "Portuguese", "Português", t(messages.portuguese)),
    // createAvailableLanguageOption("pt-br", "Portuguese (Brazil)", "Português (Brasil)", t(messages.portugueseBrazil))
  ];
}
