export default async function fetchWithTimeout(url, withCredentials = true, timeout, redirect = "error") {
  const controller = new AbortController();
  let aborted = false;
  const { signal } = controller;
  let timeoutTimer;
  if (timeout) {
    timeoutTimer = setTimeout(() => {
      if (aborted) return;
      aborted = true;
      controller.abort();
    }, timeout);
  }

  let error;
  let request;

  try {
    request = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: withCredentials ? "same-origin" : "omit",
      signal,
      redirect,
    });
  } catch (ex) {
    error = ex;
  }

  if (timeoutTimer) clearTimeout(timeoutTimer);

  if (error) throw error;

  return request;
}
