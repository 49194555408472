import { defineMessages } from "react-intl";

const Actions = defineMessages({
  iAteThis: {
    id: "actions.iAteThis",
    description: "Button Text, clicking adds selected item to food diary.",
    defaultMessage: "I Ate This",
  },
  eat1Portion: {
    id: "actions.eat1Portion",
    description: "Button Text, clicking adds one serving of the meal to the food diary.",
    defaultMessage: "Eat 1 Portion",
  },
  connect: {
    id: "actions.connect",
    description: "Button text.",
    defaultMessage: "Connect",
  },
  continue: {
    id: "actions.continue",
    description: "Button text.",
    defaultMessage: "Continue",
  },
  connected: {
    id: "actions.connected",
    description: "Button text.",
    defaultMessage: "Connected",
  },
  reconnect: {
    id: "actions.reconnect",
    description: "Button text.",
    defaultMessage: "Reconnect",
  },
  forgotPassword: {
    id: "actions.forgotPassword",
    description: "Link text",
    defaultMessage: "Forgot password?",
  },
  changePassword: {
    id: "actions.changePassword",
    description: "Button text.",
    defaultMessage: "Change Password",
  },
  verifyEmail: {
    id: "actions.verifyEmail",
    description: "Button text.",
    defaultMessage: "Verify Email",
  },
  addToDiary: {
    id: "actions.addToDiary",
    description: "Button Text, clicking adds selected item to food diary on specified date.",
    defaultMessage: "Add To Diary",
  },
  addToPlan: {
    id: "actions.addToPlan",
    description: "Button Text, clicking adds selected item to food diary on specified date.",
    defaultMessage: "Add To Plan",
  },
  markDayComplete: {
    id: "actions.markDayComplete",
    description: "Button Text, clicking adds resolves the day.",
    defaultMessage: "Mark Day Complete",
  },
  newIngredient: {
    id: "actions.newIngredient",
    description: "Button Text, clicking takes user to creation screen.",
    defaultMessage: "New Ingredient",
  },
  newMealPlan: {
    id: "actions.newMealPlan",
    description: "Button Text, clicking takes user to creation screen.",
    defaultMessage: "New Meal Plan",
  },
  newMeal: {
    id: "actions.newMeal",
    description: "Button Text, clicking takes user to creation screen.",
    defaultMessage: "New Meal",
  },
  edit: {
    id: "actions.edit",
    defaultMessage: "Edit",
    description: "Button Text, opens selected item for editing.",
  },
  managePlans: {
    id: "actions.managePlans",
    defaultMessage: "Manage Plans",
    description: "Button Text, opens selected item for editing.",
  },
  add: {
    id: "actions.add",
    defaultMessage: "Add",
    description: "Button Text, appends an additional item to a list.",
  },
  save: {
    id: "actions.save",
    defaultMessage: "Save",
    description: "Button Text, will save changes of current item.",
  },
  createPasskey: {
    id: "actions.createPasskey",
    defaultMessage: "Create Passkey",
    description: "Button Text",
  },
  send: {
    id: "actions.send",
    defaultMessage: "Send",
    description: "Button Text, will send the message.",
  },
  giveFeedbackAboutThisPage: {
    id: "actions.giveFeedbackAboutThisPage",
    defaultMessage: "Have feedback about this page?",
    description: "Call to action Text, will open feedback dialog.",
  },
  logout: {
    id: "actions.logout",
    defaultMessage: "Logout",
    description: "Button Text, will log the current user out of the session.",
  },
  cancel: {
    id: "actions.cancel",
    defaultMessage: "Cancel",
    description: "Button Text, will exit the screen without saving any changes.",
  },
  delete: {
    id: "actions.delete",
    defaultMessage: "Delete",
    description: "Button Text, will open delete confirmation dialog for current item.",
  },
  markAsConsumed: {
    id: "actions.markAsConsumed",
    defaultMessage: "Mark As Consumed",
    description: "Button Text.",
  },
  getStarted: {
    id: "actions.getStarted",
    defaultMessage: "Get Started",
    description: "Button Text, will open the next page in a multistep process.",
  },
  login: {
    id: "actions.login",
    defaultMessage: "Login",
    description: "Button Text, will log the user in.",
  },
  sendResetPasswordLink: {
    id: "actions.sendResetPasswordLink",
    defaultMessage: "Send Reset Email",
    description: "Button Text, will send reset email link",
  },
  setPassword: {
    id: "actions.setPassword",
    defaultMessage: "Set Password",
    description: "Button Text",
  },
  setNewPassword: {
    id: "actions.setNewPassword",
    defaultMessage: "Set New Password",
    description: "Button Text",
  },
  next: {
    id: "actions.next",
    defaultMessage: "Next",
    description: "Pagination Button Text, will show the next page of items.",
  },
  current: {
    id: "actions.current",
    defaultMessage: "Current",
    description: "Pagination Button Current, will show the page that reflects today.",
  },
  displayed: {
    id: "actions.displayed",
    defaultMessage: "Displayed",
    description: "Pagination Button Current, will show the page that shows the selectioon.",
  },
  previous: {
    id: "actions.previous",
    defaultMessage: "Previous",
    description: "Pagination Button Text, will show the previous page of items.",
  },
  createLogEntry: {
    id: "actions.createLogEntry",
    defaultMessage: "Create Log Entry",
    description: "Section Heading",
  },
  failedToSaveChanges: {
    id: "actions.failedToSaveChanges",
    defaultMessage: "Failed to save changes.",
    description: "Error message",
  },
  insufficientPassword: {
    id: "actions.insufficientPassword",
    defaultMessage: "Your desired password is not complex enough.",
    description: "Error message",
  },
  updateAvailable: {
    id: "actions.updateAvailable",
    defaultMessage: "Update Available",
    description: "Button text",
  },
  checkForUpdates: {
    id: "actions.checkForUpdates",
    defaultMessage: "Check For Updates",
    description: "Button text",
  },
  addToDesktop: {
    id: "actions.addToDesktop",
    defaultMessage: "Add To Desktop",
    description: "Button text",
  },
  groupByMeal: {
    id: "actions.groupByMeal",
    defaultMessage: "Group By Meal",
    description: "Switch text",
  },
  showAsTabs: {
    id: "actions.showAsTabs",
    defaultMessage: "Display in tabs",
    description: "Switch text",
  },
});

export default Actions;
