import getSampleData from "../data/getSampleData.js";
import {
  INGREDIENT_DELETE,
  INGREDIENT_UPDATE,
  INGREDIENT_LOAD_EXAMPLE_DATA,
  INGREDIENT_LOAD_FROM_STORAGE,
  INGREDIENT_CREATE,
} from "../actions/ingredient.js";

const initialState = {
  ingredients: [],
};

export function IngredientReducer(ingredient, action) {
  if (ingredient.id !== action.id) {
    return ingredient;
  }
  switch (action.type) {
    case INGREDIENT_UPDATE:
      return { ...ingredient, ...action.ingredient };
    default:
      return ingredient;
  }
}

function ingredients(state = initialState, action) {
  switch (action.type) {
    case INGREDIENT_DELETE:
      return {
        ...state,
        ingredients: state.ingredients.filter((ingredient) => ingredient.id !== action.id),
      };
    case INGREDIENT_UPDATE:
      return {
        ...state,
        ingredients: state.ingredients.map((ingredient) => IngredientReducer(ingredient, action)),
      };
    case INGREDIENT_LOAD_EXAMPLE_DATA:
      return {
        ...state,
        ingredients: [...getSampleData().ingredients],
      };
    case INGREDIENT_CREATE:
      return {
        ...state,
        ingredients: [action.ingredient, ...state.ingredients],
      };
    case INGREDIENT_LOAD_FROM_STORAGE:
      return {
        ...state,
        ingredients: [...action.ingredients],
      };
    default:
      return state;
  }
}

export default ingredients;
