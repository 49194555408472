import { STORAGE_LOAD_SUCCESS, STORAGE_LOAD, STORAGE_LOAD_FAILURE } from "../actions/storage.js";

const initialState = {
  loaded: false,
  isLoading: false,
  loadingFailed: false,
};

function storage(state = initialState, action) {
  switch (action.type) {
    case STORAGE_LOAD_FAILURE:
      return { ...state, loaded: false, isLoading: false, loadingFailed: true };
    case STORAGE_LOAD_SUCCESS:
      return { ...state, loaded: true, isLoading: false, loadingFailed: false };
    case STORAGE_LOAD:
      return { ...state, isLoading: true, loadingFailed: false };
    default:
      return state;
  }
}

export default storage;
