/* eslint-disable react/jsx-props-no-spreading */
import { Dialog, Grid, Checkbox, ListItemText } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState, useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useIntl } from "react-intl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AppMessages from "../messages/App.js";
import GlobalMessages from "../messages/Global.js";
import { DayMeals, DAY_MEALS } from "../../data/constants.js";
import ActionMessages from "../messages/Actions.js";
import parseNumericInput from "../../lib/parseNumericInput.js";
import { calculateGramsConsumed, calculateMacrosByGram } from "../../data/calculateMacros.js";
import generateId from "../../data/generateId.js";
import useFoodList from "./useFoodList.js";

const AutoCompleteListBox = styled("ul")(() => ({
  fontSize: "0.875rem",
}));

const AutoCompleteListItem = styled("li")(() => ({
  fontSize: "0.875rem",
}));

/**
 * Dialog for adding an item of food to a meal plan, or, a calendar day,
 * @param {Object} props - The component props
 * @param {Function} props.createLogEntry - Callback to save the log entries
 * @param {Boolean} props.open - Indicates if the dialog should be open
 * @param {Function} props.onClose - Callback to close the dialog
 * @param {String} [props.day] - Pre-selected day value, indicating which day of the meal plan to apply.
 * @param {String} [props.time] - Pre-selected time value, for example, "snacks" etc.
 * @param {{ id: String, name: String }[]} [props.days] - The meal plan days to select
 * @param {Object} [preSelectedIngredient] - Pre-selected ingredient
 * @return {JSX.Element}
 * @constructor
 */
export default function AddToPlanDialog({
  ingredient: preSelectedIngredient,
  createLogEntry,
  open,
  onClose,
  day,
  time,
  days,
  logEntryDate,
  onLogEntryDateChange,
  saveButtonText,
  disabledLogEntryDatePicker,
  preSetDays,
  preSetDay,
  onPreSetDayChange,
}) {
  const { formatMessage: t } = useIntl();
  const foodAutoCompleteField = useRef();
  const showDaySelector = days?.length > 0;
  const showDatePicker = logEntryDate !== undefined;
  const showPreSetDaysPicker = preSetDays?.length > 0 && Boolean(preSetDay);
  const { items } = useFoodList();
  const [ingredient, setSelectedFoodOption] = useState(preSelectedIngredient || null);
  const [logEntryTime, setLogEntryTime] = useState(time || DAY_MEALS.SNACKS);
  const [logEntryDays, setLogEntryDays] = useState([]);
  const isFixedIngredient = Boolean(preSelectedIngredient);

  useEffect(() => {
    if (preSelectedIngredient || !open) return;
    foodAutoCompleteField?.current?.focus();
  }, [open, preSelectedIngredient, foodAutoCompleteField?.current]);

  useEffect(() => {
    if (time) setLogEntryTime(time);
  }, [time]);

  useEffect(() => {
    if (day) setLogEntryDays([day]);
  }, [day]);

  const [state, setState] = useState({
    quantity: {
      value: `1`,
      valid: true,
      number: 1,
      empty: false,
    },
    grams: {
      value: `100`,
      valid: true,
      number: 100,
      empty: false,
    },
  });

  const editQuantity = (event) => {
    const parsedValue = parseNumericInput(event.target.value, true);
    let newGrams = state.grams;

    if (parsedValue.valid) {
      let grams;

      if (ingredient) {
        grams = ingredient.item.individualServingSize.grams;
      }

      const gramsConsumed = calculateGramsConsumed(parsedValue.empty ? 1 : parsedValue.number, grams);
      newGrams = {
        valid: true,
        number: gramsConsumed,
        value: `${gramsConsumed}`,
        empty: false,
      };
    }

    setState({
      ...state,
      quantity: parsedValue,
      grams: newGrams,
    });
  };
  const isBusy = false;
  const isValid = ingredient && (!showDaySelector || logEntryDays.length !== 0);
  const editWeight = (event) => {
    const parsedValue = parseNumericInput(event.target.value, true);

    setState({ ...state, grams: parsedValue });
  };

  const editFood = (event, newValue) => {
    if (!newValue) {
      setSelectedFoodOption(null);
      setState({
        ...state,
        grams: {
          value: `100`,
          valid: true,
          number: 100,
          empty: false,
        },
        quantity: {
          value: `1`,
          valid: true,
          number: 1,
          empty: false,
        },
      });
      return;
    }
    const selectedFoodOption = newValue.item;
    setSelectedFoodOption(newValue);

    if (newValue.type === "Recipe") {
      setState({
        ...state,
        grams: {
          value: `${selectedFoodOption.gramsPerPortion}`,
          valid: true,
          number: selectedFoodOption.gramsPerPortion,
          empty: false,
        },
        quantity: {
          value: `1`,
          valid: true,
          number: 1,
          empty: false,
        },
      });
      return;
    }
    let grams = state.grams.number;
    let quantity = state.quantity.number;

    if (
      selectedFoodOption.individualServingSize &&
      Object.prototype.hasOwnProperty.call(selectedFoodOption.individualServingSize, "grams")
    ) {
      grams = selectedFoodOption.individualServingSize.grams;
    } else if (
      selectedFoodOption.suggestedServingSize &&
      Object.prototype.hasOwnProperty.call(selectedFoodOption.suggestedServingSize, "grams")
    ) {
      grams = selectedFoodOption.suggestedServingSize.grams;
    }

    if (
      selectedFoodOption.individualServingSize &&
      Object.prototype.hasOwnProperty.call(selectedFoodOption.individualServingSize, "quantity")
    ) {
      quantity = selectedFoodOption.individualServingSize.quantity || 1;
    } else if (
      selectedFoodOption.suggestedServingSize &&
      Object.prototype.hasOwnProperty.call(selectedFoodOption.suggestedServingSize, "quantity")
    ) {
      quantity = selectedFoodOption.suggestedServingSize.quantity || 1;
    }

    setState({
      ...state,
      grams: {
        value: `${grams}`,
        valid: true,
        number: grams,
        empty: false,
      },
      quantity: {
        value: `${quantity}`,
        valid: true,
        number: quantity,
        empty: false,
      },
    });
  };

  const isRecipe = Boolean(ingredient?.ingredients);

  const submitLogEntry = () => {
    if (!isValid) return;
    const rawNutritionalProfile = ingredient.item.nutritionalProfile;
    const macrosConsumed = calculateMacrosByGram(rawNutritionalProfile, state.grams.number);
    const buildLogEntry = () => {
      const newEntry = {
        grams: state.grams.number,
        nutritionalProfile: macrosConsumed,
        time: logEntryTime,
        id: generateId(5),
      };

      if (ingredient.item.hasUnits || isRecipe) {
        newEntry.quantity = state.quantity.empty ? 1 : state.quantity.number;
      }

      if (isRecipe) {
        newEntry.recipeId = ingredient.id;
      } else {
        newEntry.ingredientId = ingredient.id;
      }

      return newEntry;
    };

    if (showPreSetDaysPicker || (!showDatePicker && !showDaySelector)) {
      createLogEntry(buildLogEntry(), ingredient.item);
      setSelectedFoodOption(preSelectedIngredient || null);
      return;
    }

    const multipleEntries = logEntryDays.map((logEntryDay) => ({ day: logEntryDay, logEntry: buildLogEntry() }));
    createLogEntry(multipleEntries, ingredient.item);

    setSelectedFoodOption(preSelectedIngredient || null);
  };

  const renderItem = (props2, option) => {
    if (option.item) {
      return (
        <AutoCompleteListItem {...props2} key={props2.key}>
          <Typography variant="body2">{option.item.name}</Typography>
          {option.item.brand && (
            <Typography variant="body2" color="text.disabled" paddingLeft={1} display="inline-block">
              {option.item.brand}
            </Typography>
          )}
        </AutoCompleteListItem>
      );
    }
    return (
      <AutoCompleteListItem {...props2} key={props2.key}>
        {option.title}
      </AutoCompleteListItem>
    );
  };

  const canSave = isValid && !isBusy;

  useHotkeys(
    "enter",
    (event) => {
      if (!open || !canSave) return;
      event.preventDefault(); // stops the 'add to plan' button being re-clicked by the enter key
      submitLogEntry();
    },
    [open, canSave, submitLogEntry],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t(ActionMessages.addToPlan)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {showPreSetDaysPicker && (
            <Grid item xs={12}>
              <TextField
                select
                variant="outlined"
                label={t(GlobalMessages.meal)}
                value={preSetDay}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      dense: true,
                    },
                  },
                }}
                onChange={onPreSetDayChange}
              >
                {preSetDays.map((preSetDaysValue) => (
                  <MenuItem key={preSetDaysValue.id} value={preSetDaysValue.id}>
                    {preSetDaysValue.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              select
              variant="outlined"
              label={t(GlobalMessages.meal)}
              value={logEntryTime}
              fullWidth
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    dense: true,
                  },
                },
              }}
              onChange={(event) => setLogEntryTime(event.target.value)}
            >
              {Object.keys(DayMeals).map((dayMealKey) => (
                <MenuItem key={dayMealKey} value={dayMealKey}>
                  {t(GlobalMessages[dayMealKey])}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {showDaySelector && (
            <Grid item xs={12}>
              <TextField
                select
                variant="outlined"
                SelectProps={{
                  renderValue: (selected) => {
                    if (selected.length === 7) {
                      return t(GlobalMessages.everyday);
                    }

                    return days
                      .reduce((acc, item) => {
                        if (selected.indexOf(item.id) !== -1) {
                          acc.push(item);
                        }
                        return acc;
                      }, [])
                      .map((item) => item.name)
                      .join(", ");
                  },
                  multiple: true,
                  MenuProps: {
                    MenuListProps: {
                      dense: true,
                    },
                  },
                }}
                label={t(GlobalMessages.date)}
                value={logEntryDays}
                fullWidth
                error={logEntryDays.length === 0}
                onChange={(event) => setLogEntryDays(event.target.value)}
              >
                {days.map((dayKey) => (
                  <MenuItem dense key={dayKey.id} value={dayKey.id}>
                    <Checkbox
                      sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
                      checked={logEntryDays.indexOf(dayKey.id) > -1}
                      size="small"
                      disableRipple
                    />
                    <ListItemText primary={dayKey.name} />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {showDatePicker && (
            <Grid item xs={12}>
              <DatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                inputFormat="EEE, dd LLL"
                autoOk
                error={!state.logEntryValid}
                label={t(GlobalMessages.date)}
                disabled={disabledLogEntryDatePicker}
                value={logEntryDate}
                onChange={onLogEntryDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  size: "small",
                }}
                slotProps={{
                  openPickerButton: { size: "small" },
                  openPickerIcon: { fontSize: "small" },
                  textField: {
                    fullWidth: true,
                    variant: "outlined",
                  },
                }}
              />
            </Grid>
          )}
          {!isFixedIngredient && (
            <Grid item xs={12}>
              <Autocomplete
                options={items}
                onChange={editFood}
                ListboxComponent={AutoCompleteListBox}
                value={ingredient}
                disabled={items.length === 0 || isFixedIngredient}
                renderOption={renderItem}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                blurOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={foodAutoCompleteField}
                    error={ingredient === null}
                    fullWidth
                    label={t(AppMessages.foods)}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              {(ingredient?.item?.hasUnits || isRecipe) && (
                <Box marginRight={2}>
                  <TextField
                    label={t(GlobalMessages.quantity)}
                    variant="outlined"
                    type="number"
                    min={1}
                    step={1}
                    max={100}
                    maxLength={3}
                    value={state.quantity.value}
                    onChange={editQuantity}
                    placeholder="1"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
              )}

              <Box>
                <TextField
                  label={t(ingredient?.item?.isLiquid ? GlobalMessages.volumeMl : GlobalMessages.grams)}
                  variant="outlined"
                  min={0}
                  style={{ width: "80px" }}
                  value={state.grams.value}
                  onChange={editWeight}
                  disabled={ingredient?.item?.hasUnits || isRecipe || !ingredient}
                  placeholder="100"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isBusy} onClick={onClose} size="small" color="primary">
          {t(ActionMessages.cancel)}
        </Button>
        <Button
          startIcon={<AddIcon fontSize="small" />}
          disabled={!canSave}
          onClick={submitLogEntry}
          color="primary"
          variant="contained"
          size="small"
        >
          {saveButtonText || t(ActionMessages.addToPlan)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
