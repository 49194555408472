import setPlan from "../api/setPlan.js";
import { updateStorage } from "./storage.js";

export const MEALPLAN_DELETE = "@@mealPlan/DELETE";
export const MEALPLAN_UPDATE = "@@mealPlan/UPDATE";
export const MEALPLAN_CREATE = "@@mealPlan/CREATE";
export const MEALPLAN_LOAD_FROM_STORAGE = "@@mealPlan/LOAD_FROM_STORAGE";

export const updateMealPlan = (mealPlan) => async (dispatch) => {
  dispatch({ type: MEALPLAN_UPDATE, id: mealPlan.id, mealPlan });
  dispatch(updateStorage({ mealPlan }));

  // const newPlan = await setPlan(mealPlan);
  // if (newPlan) {
  //   dispatch({ type: MEALPLAN_UPDATE, id: mealPlan.id, mealPlan: newPlan });
  // } else {
  //   // TODO: handle sync errors
  // }
};

export const createMealPlan = (mealPlan) => async (dispatch) => {
  dispatch({ type: MEALPLAN_CREATE, id: mealPlan.id, mealPlan });
  await setPlan(mealPlan);
};

export const deleteMealPlan = (id) => async (dispatch) => {
  dispatch({ type: MEALPLAN_DELETE, id });
};

export const loadFromStorage = (mealPlans) => ({ type: MEALPLAN_LOAD_FROM_STORAGE, mealPlans });
