import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function SearchIcon() {
  return (
    <SvgIcon viewBox="0 0 768 768">
      <path d="M304.5 448.5q60 0 102-42t42-102-42-102-102-42-102 42-42 102 42 102 102 42zM496.5 448.5l159 159-48 48-159-159v-25.5l-9-9q-57 49.5-135 49.5-87 0-147.75-60t-60.75-147 60.75-147.75 147.75-60.75 147 60.75 60 147.75q0 31.5-15 71.25t-34.5 63.75l9 9h25.5z" />
    </SvgIcon>
  );
}
