import { useTheme } from "@mui/material/styles";
import React from "react";
import { useIntl } from "react-intl";
import { PercentageScaleColorByMacro } from "../MealPlanList/ArrowIndicator.js";
import { WidgetPieChartNext } from "./WidgetPieChart.js";
import GlobalMessages from "../messages/Global.js";

export function WidgetPieChartSalt({ sx, title, percentage, onClick, selected }) {
  const { formatMessage: t } = useIntl();
  const {
    palette: { food },
  } = useTheme();
  const borderColor = percentage > 0 ? PercentageScaleColorByMacro(percentage, "salt", true)?.color : undefined;

  return (
    <WidgetPieChartNext
      sx={sx}
      body={t(GlobalMessages.salt)}
      title={title}
      color={food.salt}
      percentage={percentage}
      onClick={onClick}
      selected={selected}
      borderColor={borderColor}
    />
  );
}

export function WidgetPieChartSugar({ sx, title, percentage, onClick, selected }) {
  const { formatMessage: t } = useIntl();
  const {
    palette: { food },
  } = useTheme();
  const borderColor = percentage > 0 ? PercentageScaleColorByMacro(percentage, "sugars", true)?.color : undefined;

  return (
    <WidgetPieChartNext
      sx={sx}
      body={t(GlobalMessages.sugar)}
      title={title}
      color={food.sugars}
      percentage={percentage}
      onClick={onClick}
      selected={selected}
      borderColor={borderColor}
    />
  );
}

export function WidgetPieChartCalories({ sx, title, percentage, onClick, selected }) {
  const { formatMessage: t } = useIntl();
  const {
    palette: { food },
  } = useTheme();
  const borderColor = percentage > 0 ? PercentageScaleColorByMacro(percentage, "calories", true)?.color : undefined;

  return (
    <WidgetPieChartNext
      sx={sx}
      body={t(GlobalMessages.calories)}
      title={title}
      color={food.calories}
      percentage={percentage}
      onClick={onClick}
      selected={selected}
      borderColor={borderColor}
    />
  );
}

export function WidgetPieChartFat({ sx, title, percentage, onClick, selected }) {
  const { formatMessage: t } = useIntl();
  const {
    palette: { food },
  } = useTheme();
  const borderColor = percentage > 0 ? PercentageScaleColorByMacro(percentage, "fat", true)?.color : undefined;

  return (
    <WidgetPieChartNext
      sx={sx}
      body={t(GlobalMessages.fat)}
      title={title}
      color={food.fat}
      percentage={percentage}
      onClick={onClick}
      selected={selected}
      borderColor={borderColor}
    />
  );
}

export function WidgetPieChartProtein({ sx, title, percentage, onClick, selected }) {
  const { formatMessage: t } = useIntl();
  const {
    palette: { food },
  } = useTheme();
  const borderColor = percentage > 0 ? PercentageScaleColorByMacro(percentage, "protein", true)?.color : undefined;

  return (
    <WidgetPieChartNext
      sx={sx}
      body={t(GlobalMessages.protein)}
      title={title}
      color={food.protein}
      percentage={percentage}
      onClick={onClick}
      selected={selected}
      borderColor={borderColor}
    />
  );
}

export function WidgetPieChartCarbohydrate({ sx, title, percentage, onClick, selected }) {
  const { formatMessage: t } = useIntl();
  const {
    palette: { food },
  } = useTheme();
  const borderColor = percentage > 0 ? PercentageScaleColorByMacro(percentage, "carbohydrate", true)?.color : undefined;

  return (
    <WidgetPieChartNext
      sx={sx}
      body={t(GlobalMessages.carbohydrate)}
      title={title}
      color={food.carbohydrate}
      percentage={percentage}
      onClick={onClick}
      selected={selected}
      borderColor={borderColor}
    />
  );
}
