import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { startOfDay, format, addDays, subDays, startOfToday } from "date-fns";
import { useNavigate } from "react-router";
import dateToId from "../../lib/dateToId.js";
import getDay from "../../reducers/getDay.js";
import Home from "./Home.js";
import { deleteLogEntry, updateLogEntry } from "../../actions/logEntry.js";
import { updateDay } from "../../actions/day.js";
import { getEmptyNutritionalProfile } from "../../data/calculateMacros.js";
import { updateStorage } from "../../actions/storage.js";
import { sendEvent } from "../../lib/sendMetric.js";

export function constructHomeScreenData(state, displayDate, isUserSelectedDate) {
  const { days } = state.days;

  let dayData = getDay(days, displayDate);

  if (!dayData) {
    // No log entry for the desired day, create placeholder day.
    dayData = {
      id: dateToId(displayDate),
      date: startOfDay(displayDate),
      total: getEmptyNutritionalProfile(),
      consumed: getEmptyNutritionalProfile(),
      items: [],
      markedAsComplete: false,
      updateCount: 0,
    };
  }

  return {
    dayData,
    displayDate,
    ingredients: state.ingredients.ingredients,
    recipes: [],
    isUserSelectedDate,
    showMostRecentStats: !isUserSelectedDate,
  };
}

const mapStateToProps = (state, ownProps) =>
  constructHomeScreenData(state, ownProps.displayDate, ownProps.isUserSelectedDate);

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteLogEntry: (logEntry, dayReal) => {
    sendEvent({
      action: "Delete Log Entry",
      title: "Home",
      location: "/",
    });

    const item = { ...logEntry };

    if (!item.date && dayReal?.date) {
      item.date = dayReal.date;
    }
    dispatch(deleteLogEntry(item));
    dispatch(updateStorage());
  },
  updateLogEntry: (logEntry, dayReal) => {
    sendEvent({
      action: "Update Log Entry",
      title: "Home",
      location: "/",
    });

    const item = { ...logEntry };

    if (!item.date && dayReal?.date) {
      item.date = dayReal.date;
    }
    dispatch(updateLogEntry(item));
    dispatch(updateStorage());
  },
  toggleLogEntryConsumed: (logEntry) => {
    sendEvent({
      action: "Toggle Log Entry",
      label: `${!logEntry.consumed}`,
      title: "Home",
      location: "/",
    });

    dispatch(
      updateLogEntry({
        ...logEntry,
        consumed: !logEntry.consumed,
      }),
    );
    dispatch(updateStorage());
  },
  toggleMarkAsComplete: (updatedDay) => {
    dispatch(updateDay(updatedDay));
    dispatch(updateStorage());
  },
  showNextDay: (currentDay) => {
    ownProps.navigate(`/calendar/day/${format(addDays(currentDay, 1), "yyyyMMdd")}`);
  },
  showPreviousDay: (currentDay) => {
    ownProps.navigate(`/calendar/day/${format(subDays(currentDay, 1), "yyyyMMdd")}`);
  },
});

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);

export function HomeContainerToday() {
  const [displayDate] = useState(startOfToday());
  const navigate = useNavigate();

  return useMemo(() => <HomeContainer navigate={navigate} displayDate={displayDate} />, [displayDate]);
}

export default HomeContainer;
