import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import EJSON from "ejson";
import { useSelector } from "react-redux";
import useAccount from "../../contexts/Account/useAccount.js";

const fullFileName = "export.json";

export async function extractAsEJSON({ account, profile, days }) {
  // const data = await get("store", applicationPersistantStore());
  return null;
  const value = { ...data, account, profile, days: days.days };
  console.log(value);
  return EJSON.stringify(value);
}

const saveToFile = (fileContent) => {
  if (!fileContent) return;
  const fileParts = [fileContent];
  const blobLink = document.createElement("a");
  const blob = new Blob(fileParts, { type: "text/json" });
  blobLink.style.display = "none";
  const blobUrl = window.URL.createObjectURL(blob);

  blobLink.setAttribute("href", blobUrl);
  blobLink.setAttribute("download", fullFileName);
  blobLink.click();
};

export default function ExportDataMenuItem({ onClick }) {
  const state = useSelector((_) => _);
  const { account, profile } = useAccount();

  const onClickHandler = async () => {
    onClick();
    const result = await extractAsEJSON({ ...state, account, profile });
    if (!result) return;
    saveToFile(result);
  };
  return (
    <MenuItem onClick={onClickHandler}>
      <ListItemIcon>
        <DownloadIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Export Data</ListItemText>
    </MenuItem>
  );
}
