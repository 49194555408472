import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useIntl } from "react-intl";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Navigate, Route } from "react-router";
import useTitle from "../lib/useTitle.js";
import useSendPageView from "../lib/useSendPageView.js";
import ActionMessages from "./messages/Actions.js";
import AccountContext from "../contexts/Account/AccountContext.js";
import messages from "./messages/Signin.js";
import CenterContainedCardLayout from "./CenterContainedCardLayout.js";
import useWebAuth from "../contexts/WebAuth/useWebAuth.js";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
export default function Login() {
  const { login, isLoaded, isAuthenticated } = useContext(AccountContext);
  const { login: loginWithPasskey, isLoaded: isPasskeyLoaded } = useWebAuth();

  const { formatMessage: t } = useIntl();
  useTitle(t(messages.title));
  useSendPageView("Log In", "/login");
  const [isBusy, setIsBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [autoPrompted, setAutoPrompted] = useState(false);

  const loginClick = useCallback(async () => {
    if (isBusy) return;

    setIsBusy(true);
    setAutoPrompted(true);
    setErrorMessage(null);

    const loginResult = await loginWithPasskey(null, false);

    if (loginResult && !loginResult.success) {
      setErrorMessage(loginResult.message || "Login was not successfull.");
    }
    setIsBusy(false);
  }, [loginWithPasskey, isBusy]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsAvailable(true);
  //   }, 1000);
  // }, []);

  useEffect(() => {
    if (isLoaded && !isAuthenticated && isPasskeyLoaded && !autoPrompted) {
      loginClick()
        .then(() => {})
        .catch(() => {});
    }
  }, [isLoaded, isAuthenticated, isPasskeyLoaded, loginClick, autoPrompted]);

  // useEffect(() => {
  //   if (isBusy) {
  //     login("")
  //       .then((result) => {
  //         setIsBusy(false);
  //         if (result?.success) {
  //           // State update will trigger page refresh
  //           return;
  //         }
  //         const code = result?.code || "NETWORK";
  //
  //         if (code === "NO_USER_EXISTS") {
  //           setErrorMessage(t(messages.noAccountExists));
  //         } else if (code === "INVALID_EMAIL") {
  //           setErrorMessage(t(messages.emailNotValid));
  //         } else {
  //           setErrorMessage(null);
  //         }
  //       })
  //       .catch(() => {
  //         setIsBusy(false);
  //       });
  //   }
  // }, [isBusy]);

  const formDisabled = isAuthenticated || !isLoaded || !isPasskeyLoaded || isBusy;

  if (isAuthenticated) {
    return <Route render={() => <Navigate to="/" replace />} />;
  }

  return (
    <CenterContainedCardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Alert severity={Boolean(errorMessage) ? "error" : "info"}>
              {errorMessage || "Please complete the passkey login step"}
            </Alert>

            <Button
              variant="contained"
              color="primary"
              disabled={formDisabled}
              onClick={loginClick}
              sx={{ width: "100%" }}
              startIcon={<FingerprintIcon fontSize="small" />}
            >
              {t(ActionMessages.login)}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </CenterContainedCardLayout>
  );
}
