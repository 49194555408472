import React, { useEffect, useMemo, useState } from "react";
import WebAuthContext from "./WebAuthContext.js";
import useAccount from "../Account/useAccount.js";
import webauthn from "../../lib/webauthn.js";

export default function WebAuthProvider({ children }) {
  // We use one state object to consolidate our updates together.
  const [state, setState] = useState({
    isLoaded: false,
    isLoading: process.env.BROWSER,
    browserSupportsWebAuthn: false,
    platformAuthenticatorIsAvailable: false,
  });

  const { loadAccount } = useAccount();
  const loadService = async () => {
    let platformAuthenticatorIsAvailableResult = false;
    let browserSupportsWebAuthnResult = false;

    try {
      platformAuthenticatorIsAvailableResult = await webauthn.platformAuthenticatorIsAvailable();
      browserSupportsWebAuthnResult = webauthn.browserSupportsWebAuthn();
    } catch (ex) {
      // ignore
      console.log(ex);
    }

    return { platformAuthenticatorIsAvailableResult, browserSupportsWebAuthnResult };
  };

  useEffect(() => {
    loadService().then((loadResult) => {
      setState({
        isLoaded: true,
        isLoading: false,
        browserSupportsWebAuthn: loadResult.browserSupportsWebAuthnResult,
        platformAuthenticatorIsAvailable: loadResult.platformAuthenticatorIsAvailableResult,
      });
    });
  }, []);

  const register = async () => webauthn.register();

  const login = async (email, useBrowserAutofill) => {
    const loginResult = await webauthn.login(email, useBrowserAutofill);

    if (loginResult?.success && loginResult?.account) {
      await loadAccount(true);
    }

    return loginResult;
  };

  const value = useMemo(
    () => ({
      // Has the necessary libraries for accessing Passkeys been loaded normally
      isLoaded: state.isLoaded,
      isLoading: state.isLoading,
      // Does the browser support passkey functionality
      browserSupportsWebAuthn: state.browserSupportsWebAuthn,
      // Does the browser support specifically user verified credentials, i.e. Face ID, Windows Hello, etc...
      platformAuthenticatorIsAvailable: state.platformAuthenticatorIsAvailable,
      register,
      login,
    }),
    [state],
  );

  return <WebAuthContext.Provider value={value}>{children}</WebAuthContext.Provider>;
}
