export function sortItems(items, order, orderBy) {
  const defaultSortValue = -1;
  const sortDescending = (a, b) =>
    (a.sortableNutritionalProfile[orderBy] || defaultSortValue) -
    (b.sortableNutritionalProfile[orderBy] || defaultSortValue);
  const sortAscending = (a, b) =>
    (b.sortableNutritionalProfile[orderBy] || defaultSortValue) -
    (a.sortableNutritionalProfile[orderBy] || defaultSortValue);
  const itemSortFunction = order === "asc" ? sortAscending : sortDescending;
  return items.sort(itemSortFunction);
}

export function sortItemsByTime(itemsByTime, order = "asc", orderBy) {
  return itemsByTime.map((itemByTime) => ({
    ...itemByTime,
    items: sortItems(itemByTime.items, order, orderBy),
  }));
}

export function applyItemSortByDay({ day, order, orderBy }) {
  return {
    ...day,
    itemsByTime: sortItemsByTime(day.itemsByTime, order, orderBy),
    sortedItems: sortItems(day.allItems, order, orderBy),
  };
}
export default function applyItemSort({ days, order, orderBy }) {
  return days.map((day) => applyItemSortByDay({ day, order, orderBy }));
}
