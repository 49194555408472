import { format, addDays } from "date-fns";
import {
  goalCompletion,
  goalCompletionDisplay,
  goalRemaining,
  getEmptyNutritionalProfile,
} from "../../data/calculateMacros.js";
import displayMacros, { castToSortableNumbers, displayMacrosUsingLocale } from "../../data/displayMacros.js";

export function calculateRunningDailyTotalByDay({ day, goal, startDayThisWeek, locale, longDateFormat, formatNumber }) {
  const isMealPlan = !day.date;
  // consumed is the nutritional profile of items to eat in the day
  const consumed = day.diary ? day.diary.total : day.total || getEmptyNutritionalProfile();

  const consumedDisplay = displayMacros(consumed);

  // completion is the percentage of consumed vs goal
  const completion = goalCompletion(consumed, goal);
  const completionDisplay = goalCompletionDisplay(completion);

  // remaining is the nutritional profile difference between consumed and the goal
  const remaining = goalRemaining(consumed, goal);
  const remainingDisplay = displayMacrosUsingLocale(remaining, formatNumber, true);

  const sortableConsumed = castToSortableNumbers(consumed);

  let identifiers;
  if (isMealPlan) {
    const dayTitle = day.name || `${format(addDays(startDayThisWeek, day.position), "EEEE", { locale })}`;
    identifiers = {
      title: dayTitle,
      name: dayTitle,
      id: `day_${day.position + 1}`,
      tabId: `day_${day.position + 1}`,
    };
  } else {
    identifiers = {
      title: format(day.date, longDateFormat, { locale }),
      name: format(day.date, "EEEE", { locale }),
      id: format(day.date, "yyyyMMdd"),
      tabId: `day_${day.date.getDay()}`,
    };
  }

  return {
    ...identifiers,
    consumed,
    consumedDisplay,
    completion,
    completionDisplay,
    remaining,
    remainingDisplay,
    sortableConsumed,
    day,
    allItems: (day.diary ? day.diary.items : day.items) || [],
  };
}

export default function calculateRunningDailyTotal({
  days,
  goal,
  startDayThisWeek,
  locale,
  longDateFormat,
  formatNumber,
}) {
  return days.map((day) =>
    calculateRunningDailyTotalByDay({ day, goal, startDayThisWeek, locale, longDateFormat, formatNumber }),
  );
}
