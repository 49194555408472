import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import useTitle from "../../lib/useTitle.js";
import useSendPageView from "../../lib/useSendPageView.js";
import AppMessages from "../messages/App.js";
import MealPlanList from "./MealPlanList.js";

export default function MealPlanListPage() {
  const { formatMessage: t } = useIntl();
  const { plans } = useSelector((state) => ({
    plans: state.mealPlans.mealPlans,
  }));
  useTitle(t(AppMessages.mealPlan));
  useSendPageView("Meal Plan List", "/plan");
  return <MealPlanList plans={plans} />;
}
