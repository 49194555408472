export default function getPushSubscriptionObject(pushSubscription) {
  return JSON.parse(JSON.stringify(pushSubscription));
  // return {
  //   endpoint: pushSubscription.endpoint,
  //   keys: {
  //     p256dh: pushSubscription.getKeys("p256dh"),
  //     auth: pushSubscription.getKeys("auth"),
  //   },
  // };
}
