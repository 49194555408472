import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadStorage } from "../actions/storage.js";

export default function LoadAppData() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadStorage())
      .then(() => {
        // nothing
      })
      .catch(() => {
        // nothing
      });
  });

  return null;
}
