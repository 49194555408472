import React, { useState, useEffect } from "react";
import useStyles from "../../isomorphic-style-loader/useStyles.js";
import MenuOpenContext from "./MenuOpenContext.js";
import styles from "./MenuOpen.css";

export default function MenuOpenProvider({ children }) {
  useStyles(styles);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const { body } = document;
    if (isOpen) {
      body.classList.add(styles.open);
    } else {
      body.classList.remove(styles.open);
    }
  }, [isOpen]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const value = {
    isOpen,
    toggle,
  };

  return <MenuOpenContext.Provider value={value}>{children}</MenuOpenContext.Provider>;
}
