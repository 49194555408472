import React, { useState, useEffect } from "react";
import AccountContext from "./AccountContext.js";
import createProfile from "../../api/createProfile.js";
import useAppInstallation from "../AppInstallation/useAppInstallation.js";
import getViewer from "../../api/getViewer.js";
import setProfile from "../../api/setProfile.js";
import logoutCall from "../../api/logout.js";
import verifyAccountCall from "../../api/verifyAccount.js";
import requestVerificationResendCall from "../../api/requestVerificationResend.js";

export default function AccountProvider({ children }) {
  const { setServerAdvertisedVersion, subscribeToService, recachePreloads } = useAppInstallation();
  // We use one state object to consolidate our updates together.
  const [state, setState] = useState({
    account: null,
    profile: null,
    isLoaded: false,
    isLoading: process.env.BROWSER,
    isLoadingFailed: false,
    loadingError: null,
    lastChecked: null, // The last date, as returned from the server
    lastLoadAttempted: null, // The last date we locally attempted to load, either with or without success
  });

  const createAccount = async ({ name, language, referrer }) => {
    const createAccountResult = await createProfile({ name, language, referrer });
    if (createAccountResult?.account) {
      const now = new Date().toISOString();
      setState({
        account: createAccountResult.account,
        profile: createAccountResult.profile,
        isLoaded: true,
        isLoading: false,
        isLoadingFailed: false,
        loadingError: null,
        lastChecked: now,
        lastLoadAttempted: now,
      });
      await recachePreloads();
    }
    return createAccountResult;
  };

  const setAccount = (accountToSet, profileToSet, loadingError, lastChecked) => {
    setState({
      account: accountToSet || null,
      profile: profileToSet || null,
      isLoaded: true,
      isLoading: false,
      isLoadingFailed: Boolean(loadingError || false),
      loadingError: loadingError || null,
      lastChecked,
      lastLoadAttempted: state.lastLoadAttempted || new Date().toISOString(),
    });
  };

  const reportVersion = (response) => {
    if (response?.config?.app)
      setServerAdvertisedVersion(
        response.config.app.version,
        response.config.app.buildDate,
        response.config.app.hash,
        response.config.pushKey,
      );
  };

  const handleAccountUpdateNotification = ({ data }) => {
    reportVersion(data);
    if (data.isAnonymous) {
      setAccount(null, null, null, data.lastChecked);
    } else {
      setAccount(data.account, data.profile, null, data.lastChecked);
    }
  };

  useEffect(() => {
    const subscriber = subscribeToService("update:viewer", handleAccountUpdateNotification);

    return () => {
      // App is being unmounted, so stop listening for service worker changes
      subscriber();
    };
  });

  const isAuthenticated = Boolean(state.account) && Boolean(state.profile);

  const loadAndSaveAccount = async () => {
    // TODO: Will this API call return stale service worker data.
    const getViewerResponse = await getViewer();

    if (getViewerResponse?.json && !getViewerResponse.redirectUrl) {
      const response = getViewerResponse.json;
      reportVersion(response);
      if (response.isAnonymous) {
        // We are not logged in.
        setAccount(null, null, null, response.lastChecked);
      } else {
        // We have updated server info.
        setAccount(response.account, response.profile, null, response.lastChecked);
      }
    } else {
      setAccount(null, null, getViewerResponse || new Error("Unknown Error"), null);
    }
  };

  const requestVerificationResend = async () => {
    const requestVerificationResendResult = await requestVerificationResendCall();
    reportVersion(requestVerificationResendResult?.result);
    if (requestVerificationResendResult?.success) {
      setState({
        ...state,
        account: requestVerificationResendResult.result?.account,
        profile: requestVerificationResendResult.result?.profile,
      });
    }
    return requestVerificationResendResult;
  };

  const verifyAccount = async (email, token) => {
    const verifyAccountResult = await verifyAccountCall(email, token);
    if (verifyAccountResult?.success) {
      await loadAndSaveAccount();
      await recachePreloads();
    }
    return verifyAccountResult;
  };

  const updateAccount = async ({ account, profile }) => {
    const updateAccountResult = await setProfile({ account, profile });
    reportVersion(updateAccountResult?.result);
    if (updateAccountResult?.success) {
      const now = new Date().toISOString();
      setState({
        account: updateAccountResult.result.account,
        profile: updateAccountResult.result.profile,
        isLoaded: true,
        isLoading: false,
        isLoadingFailed: false,
        loadingError: null,
        lastChecked: now,
        lastLoadAttempted: now,
      });
      await recachePreloads();
    }
    return updateAccountResult;
  };

  const loadAccount = async (force) => {
    if (!state.isLoading || force) {
      setState({
        ...state,
        isLoading: true,
        lastLoadAttempted: new Date().toISOString(),
      });
    } else {
      // isLoading is true by default when in browser
      return;
    }
    await loadAndSaveAccount();
  };

  const logout = async () => {
    const logoutResult = await logoutCall();
    if (!logoutResult?.success) return;
    setState({
      account: null,
      profile: null,
      isLoaded: true,
      isLoading: false,
      isLoadingFailed: false,
      lastChecked: null,
      lastLoadAttempted: null,
    });
    await recachePreloads();
  };

  const value = {
    account: state.account,
    profile: state.profile,
    isAuthenticated,
    isLoaded: isAuthenticated || state.isLoaded,
    isLoading: state.isLoading,
    isLoadingFailed: state.isLoadingFailed,
    lastChecked: state.lastChecked,
    lastLoadAttempted: state.lastLoadAttempted,
    createAccount,
    updateAccount,
    loadAccount,
    logout,
    requestVerificationResend,
    verifyAccount,
    setAccount,
  };

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
}
