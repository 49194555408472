import React, { useState, useRef } from "react";
import DialogContext from "./DialogContext.js";
import ConfirmationModal from "./ConfirmationModal.js";

const getEmptyState = (options = {}) => ({ isOpen: false, text: null, title: null, ...options });

export default function DialogProvider({ children }) {
  const [confirmationState, setConfirmationState] = useState(getEmptyState());
  const awaitingPromiseRef = useRef();

  const closeDialog = () => setConfirmationState({ ...confirmationState, isOpen: false });

  const confirmAction = async (options) => {
    if (confirmationState.isOpen) return Promise.resolve(false);
    setConfirmationState({
      isOpen: true,
      text: options.text,
      title: options.title || null,
    });
    return new Promise((resolve) => {
      awaitingPromiseRef.current = resolve;
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current(false);
    }

    closeDialog();
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current(true);
    }

    closeDialog();
  };

  return (
    <>
      <DialogContext.Provider value={confirmAction}>{children}</DialogContext.Provider>
      <ConfirmationModal
        onSubmit={handleSubmit}
        onClose={handleClose}
        isOpen={confirmationState.isOpen}
        text={confirmationState.text}
        title={confirmationState.title}
      />
    </>
  );
}
