import React from "react";
import { Typography, Card, Box, CardActions, CardContent, CardHeader, AppBar, Toolbar } from "@mui/material";

export default function CardBox({
  toolbar,
  title,
  children,
  context,
  buttons,
  className,
  titleAction,
  dense,
  square,
  elevation,
}) {
  const hasHeader = Boolean(title || titleAction);
  const hasFooter = Boolean(context || buttons);
  const hasToolbar = Boolean(toolbar);

  return (
    <Card
      className={className}
      sx={{
        marginBottom: 2,
        display: "flex",
        flexDirection: "column",
      }}
      square={square}
      elevation={elevation}
    >
      {hasToolbar && (
        <AppBar position="static" color="transparent" elevation={1}>
          <Toolbar>
            <Typography component="div" variant="h6" color="primary" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            {toolbar}
          </Toolbar>
        </AppBar>
      )}
      {hasHeader && !hasToolbar && <CardHeader action={titleAction} title={title} />}
      {dense ? children : <CardContent>{children}</CardContent>}
      {hasFooter && (
        <CardActions>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}
          >
            {context && (
              <Typography
                variant="body2"
                sx={{
                  flex: "1 1 auto",
                }}
              >
                {context}
              </Typography>
            )}
            {buttons}
          </Box>
        </CardActions>
      )}
    </Card>
  );
}
