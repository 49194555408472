import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import IngredientListSortContainer from "./IngredientListSortContainer.js";

const ConnectedIngredientListContainer = connect((state) => ({
  ingredients: state.ingredients.ingredients,
}))(IngredientListSortContainer);

export default function IngredientListContainer() {
  const navigate = useNavigate();

  return <ConnectedIngredientListContainer navigate={navigate} />;
}
