import React, { useContext } from "react";
import cx from "classnames";
import Box from "@mui/material/Box";
import useStyles from "../../isomorphic-style-loader/useStyles.js";
import MenuOpenContext from "../../contexts/MenuOpen/MenuOpenContext.js";
import styles from "./BurgerMenu.css";

export default function BurgerMenu() {
  useStyles(styles);
  const { isOpen, toggle } = useContext(MenuOpenContext);

  const handleClick = () => {
    toggle();
  };

  return (
    <Box
      sx={{
        display: "none",
        flex: "0 1 auto",
        alignItems: "center",
        paddingRight: "15px",
        "@media screen and (max-width: 1190px)": {
          display: "flex",
        },
      }}
    >
      <div className={styles.buttonContainer}>
        <div
          className={cx(styles.container, {
            [styles.open]: isOpen,
          })}
          onClick={handleClick}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
    </Box>
  );
}
