import { createContext } from "react";

const AccountContext = createContext({
  account: undefined,
  profile: undefined,
  isAuthenticated: false,
  isLoaded: false,
  isLoading: false,
  isLoadingFailed: false,
  lastChecked: null,
  lastLoadAttempted: null,
  createAccount: async () => {},
  logout: async () => {},
  loadAccount: async () => {},
  setAccount: () => {},
  requestVerificationResend: async () => {},
  verifyAccount: async () => {},
});

export default AccountContext;
