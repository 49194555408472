import displayMacros from "../data/displayMacros.js";
import canAddCalorieBreakdown from "./canAddCalorieBreakdown.js";

export default function getEditableNutritionalProfile(nutritionalProfile) {
  const nutritionalProfileDisplay = displayMacros(nutritionalProfile, true, true);

  const editableNutritionalProfile = {
    energy: {
      value: nutritionalProfileDisplay.energy,
      number: nutritionalProfile.energy,
      disabled: false,
      placeholder: "",
      valid: true,
    },
    calories: {
      value: nutritionalProfileDisplay.calories,
      number: nutritionalProfile.calories,
      disabled: false,
      placeholder: "",
      valid: true,
    },
    fat: {
      value: nutritionalProfileDisplay.fat,
      number: nutritionalProfile.fat,
      disabled: false,
      placeholder: "",
      valid: true,
    },
    saturatedFat: {
      value: nutritionalProfileDisplay.saturatedFat,
      number: nutritionalProfile.saturatedFat,
      disabled: false,
      placeholder: "",
      valid: true,
    },
    carbohydrate: {
      value: nutritionalProfileDisplay.carbohydrate,
      number: nutritionalProfile.carbohydrate,
      disabled: false,
      placeholder: "",
      valid: true,
    },
    sugars: {
      value: nutritionalProfileDisplay.sugars,
      number: nutritionalProfile.sugars,
      disabled: false,
      placeholder: "",
      valid: true,
    },
    fiber: {
      value: nutritionalProfileDisplay.fiber,
      number: nutritionalProfile.fiber,
      disabled: false,
      placeholder: "",
      valid: true,
    },
    protein: {
      value: nutritionalProfileDisplay.protein,
      number: nutritionalProfile.protein,
      disabled: false,
      placeholder: "",
      valid: true,
    },
    salt: {
      value: nutritionalProfileDisplay.salt,
      number: nutritionalProfile.salt,
      disabled: false,
      placeholder: "",
      valid: true,
    },
  };

  if (canAddCalorieBreakdown(editableNutritionalProfile)) {
    editableNutritionalProfile.calories.disabled = true;
  }

  return editableNutritionalProfile;
}

export function editableProfileToNutritionalProfile(editableNutritionalProfile) {
  return {
    energy: editableNutritionalProfile.energy.number,
    calories: editableNutritionalProfile.calories.number,
    fat: editableNutritionalProfile.fat.number,
    saturatedFat: editableNutritionalProfile.saturatedFat.number,
    carbohydrate: editableNutritionalProfile.carbohydrate.number,
    sugars: editableNutritionalProfile.sugars.number,
    fiber: editableNutritionalProfile.fiber.number,
    protein: editableNutritionalProfile.protein.number,
    salt: editableNutritionalProfile.salt.number,
  };
}
