import { editableProfileToNutritionalProfile } from "../../lib/getEditableNutritionalProfile.js";

function usePlaceholderValue(value) {
  if (value.number !== null && value.number !== "") return value.number;
  return value.placeholder.number;
}

export default function fromState(existingIngredient, state) {
  const { id } = existingIngredient;
  const {
    name,
    description,
    packSize,
    isLiquid,
    containerName,
    nutritionalProfile,
    brand,
    suggestedServingSizeNutritionalProfile,
    individualServingSizeNutritionalProfile,
    hasUnits,
    suggestedServingSizeGrams,
    suggestedServingSizeQuantity,
    individualServingSizeGrams,
    individualServingSizeQuantity,
    breakdown,
  } = state;

  return {
    id,
    name,
    brand,
    description,
    isLiquid,
    packSize: packSize.number,
    containerName,
    hasUnits,
    suggestedServingSize: {
      name: "",
      grams: usePlaceholderValue(suggestedServingSizeGrams),
      quantity: hasUnits ? suggestedServingSizeQuantity.number : undefined,
    },
    individualServingSize: {
      name: "",
      grams: usePlaceholderValue(individualServingSizeGrams),
      quantity: hasUnits ? usePlaceholderValue(individualServingSizeQuantity) : undefined,
    },
    nutritionalProfile: editableProfileToNutritionalProfile(nutritionalProfile),
    suggestedServingSizeNutritionalProfile: editableProfileToNutritionalProfile(suggestedServingSizeNutritionalProfile),
    individualServingSizeNutritionalProfile: editableProfileToNutritionalProfile(
      individualServingSizeNutritionalProfile,
    ),
    breakdown,
  };
}
