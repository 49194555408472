import React, { useState, useContext, useMemo } from "react";
import { format, startOfWeek, addDays } from "date-fns";
import SessionContext from "./SessionContext.js";
import ProfileContext from "../Profile/ProfileContext.js";

const calendarUrlFormat = "yyyy/M/d";

export default function SessionProvider({ children }) {
  const { profile } = useContext(ProfileContext);
  const [defaultLogEntryDate, setDefaultLogEntryDate] = useState("today");
  const [selectedMacro, setSelectedMacro] = useState("calories");
  const [selectedCalendarTab, setSelectedCalendarTab] = useState(`day_${new Date().getDay()}`);

  const weekStartsOn = profile ? profile.weekStartsOn : 1; // Default to Monday, should never apply.
  const startDayThisWeek = useMemo(() => startOfWeek(new Date(), { weekStartsOn }), [weekStartsOn]);
  const startDayNextWeek = useMemo(() => addDays(startDayThisWeek, 7), [startDayThisWeek]);

  // The calendar view will also default to this week, until opened and changed.
  const [calendarStartDate, setCalendarStartDate] = useState(format(startDayThisWeek, calendarUrlFormat));

  const value = useMemo(
    () => ({
      defaultLogEntryDate,
      setDefaultLogEntryDate,
      calendarStartDate,
      setCalendarStartDate,
      startDayThisWeek,
      startDayNextWeek,
      selectedMacro,
      setSelectedMacro,
      selectedCalendarTab,
      setSelectedCalendarTab,
    }),
    [
      defaultLogEntryDate,
      startDayThisWeek,
      startDayNextWeek,
      calendarStartDate,
      weekStartsOn,
      selectedMacro,
      selectedCalendarTab,
    ],
  );

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
}
