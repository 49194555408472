import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import MealPlanDetails from "./MealPlanDetails.js";

export default function MealPlanDetailsContainer() {
  const { id } = useParams();

  // TODO: Replace redux usage with an offline DB
  const mappedState = useSelector((state) => ({
    recipes: [],
    ingredients: state.ingredients.ingredients,
    mealPlan: state.mealPlans.mealPlans.find((mealPlan) => mealPlan.id === id),
  }));

  if (!mappedState.mealPlan) {
    return null;
  }

  return (
    <MealPlanDetails
      key={mappedState.mealPlan.id}
      mealPlan={mappedState.mealPlan}
      ingredients={mappedState.ingredients}
      recipes={mappedState.recipes}
    />
  );
}
