import React from "react";

const LanguageContext = React.createContext({
  language: undefined,
  prefix: undefined,
  locale: undefined,
  longDateFormat: undefined,
  longDateTimeFormat: undefined,
  monthDayFormat: undefined,
});

export default LanguageContext;
