import React, { useContext } from "react";
import MuiLink from "@mui/material/Link";
import { Link as ReactRouterLink } from "react-router";
import LanguageContext from "../contexts/Language/LanguageContext.js";

export default function Link({ to, ...rest }) {
  const { prefix } = useContext(LanguageContext);

  return <MuiLink component={ReactRouterLink} {...rest} to={`${prefix}${to}`} />;
}
