import { DATA_LOAD_ACCOUNT_FROM_STORAGE, DATA_LOAD_PROFILE_FROM_STORAGE } from "../actions/data.js";

const initialState = {
  account: null,
  profile: null,
};

function storage(state = initialState, action) {
  switch (action.type) {
    case DATA_LOAD_ACCOUNT_FROM_STORAGE:
      return { ...state, account: action.account };
    case DATA_LOAD_PROFILE_FROM_STORAGE:
      return { ...state, profile: action.profile };
    default:
      return state;
  }
}

export default storage;
