import setDay from "../api/setDay.js";
import getDay from "../reducers/getDay.js";

export const DAY_DELETE = "@@day/DELETE";
export const DAY_UPDATE = "@@day/UPDATE";
export const DAY_CREATE = "@@day/CREATE";
export const DAY_LOAD_FROM_STORAGE = "@@day/LOAD_FROM_STORAGE";

export const updateDay = (day) => async (dispatch, getState) => {
  dispatch({ type: DAY_UPDATE, id: day.id, day });
  await setDay(getDay(getState().days.days, null, day.id));
};

export const createDay = (day) => async (dispatch) => {
  dispatch({ type: DAY_CREATE, id: day.id, day: { ...day } });
};

export const deleteDay = (id) => async (dispatch) => {
  dispatch({ type: DAY_DELETE, id });
};

export const loadFromStorage = (days) => ({ type: DAY_LOAD_FROM_STORAGE, days });
