import React from "react";

const AppIntallationContext = React.createContext({
  isInstalled: undefined,
  serverAdvertisedVersion: undefined,
  serverAdvertisedBuildDate: undefined,
  serverAdvertisedHash: undefined,
  clientVersion: undefined,
  clientBuildDate: undefined,
  installChecked: undefined,
  canBeInstalled: undefined,
  setInstalled: () => {},
  setInstalledError: () => {},
  setDesktopInstalled: () => {},
  setDesktopInstallAvailable: () => {},
  setServerAdvertisedVersion: () => {},
  appInstallState: undefined,
  recachePreloads: async () => {},
  subscribeToPushNotifications: async () => {},
  unsubscribeToPushNotifications: async () => {},
});

export default AppIntallationContext;
