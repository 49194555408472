import { castToSortableNumbers, addSortableRadioNumbers } from "../../data/displayMacros.js";

export function addSortableNutritionalProfilesToItem(item) {
  return {
    ...item,
    sortableNutritionalProfile: addSortableRadioNumbers(castToSortableNumbers(item.nutritionalProfile), item),
  };
}

export default function applySortableNutritionalProfiles(days) {
  return days.map((day) => ({
    ...day,
    allItems: day.allItems.map(addSortableNutritionalProfilesToItem),
  }));
}
