import Typography from "@mui/material/Typography";
import React, { Fragment, useState } from "react";
import { Stack, Button, Tooltip, IconButton } from "@mui/material";
import { useIntl } from "react-intl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableHead from "@mui/material/TableHead";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getEmptyNutritionalProfile } from "../../data/calculateMacros.js";
import { DayMeals, DAY_MEALS } from "../../data/constants.js";
import Card from "../Card/Card.js";
import ArrowIndicator from "../MealPlanList/ArrowIndicator.js";
import ActionMessages from "../messages/Actions.js";
import GlobalMessages from "../messages/Global.js";
import { displayMacrosUsingLocale } from "../../data/displayMacros.js";
import Link from "../Link.js";
import DayCardFoodRow from "./DayCardFoodRow.js";
import DayCardSortableHeaderRow from "./DayCardSortableHeaderRow.js";
import DayCardContextMenu from "./DayCardContextMenu.js";
import RemainingRow from "./RemainingRow.js";

/**
 * @param {Object} param - The parameters object.
 * @param {Object} param.goal - The goal object.
 * @param {Object} param.day - The day object.
 * @param {Array} param.ingredients - The ingredients array.
 * @param {Array} param.recipes - The recipes array.
 * @param {Function} param.deleteLogEntry - The deleteLogEntry function.
 * @param {Function} param.editLogEntry - The editLogEntry function.
 * @param {Boolean} param.allowNewItems - Indicates whether new items can be added.
 * @param {Boolean} param.isCalendarDate - Indicates whether the date is on the calendar.
 * @param {Function} param.onSort - The onSort function.
 * @param {String} param.orderBy - The order by parameter.
 * @param {String} param.order - The order parameter.
 * @param {Boolean} param.groupByMeal - Indicates whether to group items by meal.
 * @param {Function} param.onAddItem - The onAddItem function.
 * @param {Function} [param.toggleMarkAsComplete] - The toggleMarkAsComplete function
 * @returns {JSX.Element} - The DayCard component.
 */
export default function DayCard({
  goal,
  day,
  ingredients,
  recipes,
  deleteLogEntry,
  editLogEntry,
  allowNewItems,
  isCalendarDate,
  onSort,
  orderBy,
  order,
  groupByMeal,
  onAddItem,
  showConsumeButton,
  toggleLogEntryConsumed,
  hideToolbar,
  toggleMarkAsComplete,
}) {
  const { formatMessage: t, formatNumber } = useIntl();
  const { itemsByTime, sortedItems } = day; // day.day.diary?.items ||
  const [contextMenu, setContextMenu] = useState(null);

  const addItemToDayClick = () => {
    onAddItem({
      day: day.id,
    });
  };

  const onRowContextMenu = (event, logEntry, dayReal) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            top: event.clientY - 6,
            left: event.clientX + 2,
            logEntry,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const referenceDay = day.day.diary ? day.day.diary : day.day;

  const setMarkAsComplete = () => {
    toggleMarkAsComplete({ ...referenceDay, markedAsComplete: !referenceDay.markedAsComplete });
  };
  const toolbar = (
    <Stack direction="row" spacing={1}>
      {showConsumeButton && (
        <Button
          startIcon={
            referenceDay.markedAsComplete ? (
              <CheckCircleIcon fontSize="small" />
            ) : (
              <CheckCircleOutlineIcon fontSize="small" />
            )
          }
          disabled={!referenceDay.updateCount}
          size="small"
          color={referenceDay.markedAsComplete ? "success" : "inherit"}
          onClick={setMarkAsComplete}
        >
          {t(ActionMessages.markDayComplete)}
        </Button>
      )}
      <Button
        startIcon={<AddCircleOutlineIcon fontSize="small" />}
        size="small"
        color="inherit"
        onClick={addItemToDayClick}
      >
        {t(ActionMessages.addToPlan)}
      </Button>
    </Stack>
  );

  const onEditViaContextMenuClick = () => {
    if (!contextMenu?.logEntry) return;
    const dayReal = day.day.diary ? day.day.diary : day.day;

    editLogEntry(contextMenu.logEntry, dayReal);
  };

  const onDeleteViaContextMenuClick = () => {
    if (!contextMenu?.logEntry) return;
    const dayReal = day.day.diary ? day.day.diary : day.day;

    deleteLogEntry(contextMenu.logEntry, dayReal);
  };

  const title = isCalendarDate ? (
    <Link color="text.primary" to={`/calendar/day/${day.id}`}>
      {day.title}
    </Link>
  ) : (
    day.title
  );
  return (
    <Card title={hideToolbar ? undefined : title} key={day.id} dense toolbar={hideToolbar ? undefined : toolbar}>
      {!!contextMenu?.top && (
        <DayCardContextMenu
          anchorPosition={{ top: contextMenu.top, left: contextMenu.left }}
          handleClose={() => setContextMenu(null)}
          onEdit={onEditViaContextMenuClick}
          onDelete={onDeleteViaContextMenuClick}
        />
      )}
      <TableContainer>
        <Table size="small" sx={hideToolbar ? { marginTop: 1, marginBottom: 1 } : undefined}>
          <TableHead>
            <DayCardSortableHeaderRow order={order} orderBy={orderBy} onSort={onSort} />
          </TableHead>
          <TableBody>
            {groupByMeal &&
              Object.values(DAY_MEALS).map((dayMealKey) => {
                const daySummary = itemsByTime.find((itemByTime) => itemByTime.time === dayMealKey);
                const daySummaryProfileDisplay = displayMacrosUsingLocale(
                  daySummary ? daySummary.nutritionalProfile : getEmptyNutritionalProfile(),
                  formatNumber,
                );
                // {Object.keys(DayMeals).map((dayMealKey) => (
                // itemsByTime.map((daySummary) => {
                const addItemToDayMealClick = () => {
                  onAddItem({
                    day: day.id,
                    time: dayMealKey,
                  });
                };
                const hasItems = daySummary?.items?.length > 0;
                return (
                  <Fragment key={dayMealKey}>
                    <TableRow selected hover>
                      <TableCell>
                        {allowNewItems && (
                          <Tooltip title={t(ActionMessages.addToPlan)} arrow placement="right">
                            <IconButton
                              aria-label={t(ActionMessages.addToPlan)}
                              onClick={addItemToDayMealClick}
                              size="small"
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          {`${t(DayMeals[dayMealKey])}`}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={`${daySummaryProfileDisplay.energy} kj / ${daySummaryProfileDisplay.calories} kcal`}
                          arrow
                          placement="right"
                        >
                          <span>{daySummaryProfileDisplay.calories}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{daySummaryProfileDisplay.protein}</TableCell>
                      <TableCell>{daySummaryProfileDisplay.fat}</TableCell>
                      <TableCell>{daySummaryProfileDisplay.sugars}</TableCell>
                      <TableCell>{daySummaryProfileDisplay.carbohydrate}</TableCell>
                      <TableCell>{daySummaryProfileDisplay.salt}</TableCell>
                      <TableCell />
                    </TableRow>
                    {hasItems &&
                      daySummary.items.map((logEntry) => (
                        <DayCardFoodRow
                          key={`${dayMealKey}.${logEntry.id}`}
                          logEntry={logEntry}
                          day={day}
                          recipes={recipes}
                          deleteLogEntry={deleteLogEntry}
                          editLogEntry={editLogEntry}
                          ingredients={ingredients}
                          allowNewItems={allowNewItems}
                          onContextMenu={onRowContextMenu}
                          showConsumeButton={showConsumeButton}
                          toggleLogEntryConsumed={toggleLogEntryConsumed}
                        />
                      ))}
                  </Fragment>
                );
              })}
            {!groupByMeal &&
              sortedItems.map((logEntry) => (
                <DayCardFoodRow
                  key={`${logEntry.id}`}
                  logEntry={logEntry}
                  day={day}
                  recipes={recipes}
                  deleteLogEntry={deleteLogEntry}
                  editLogEntry={editLogEntry}
                  ingredients={ingredients}
                  allowNewItems={allowNewItems}
                  onContextMenu={onRowContextMenu}
                  showConsumeButton={showConsumeButton}
                  toggleLogEntryConsumed={toggleLogEntryConsumed}
                />
              ))}
          </TableBody>
          <TableFooter>
            <DayCardSortableHeaderRow order={order} orderBy={orderBy} onSort={onSort} />

            <TableRow hover>
              <TableCell />
              <TableCell>
                {`${t(GlobalMessages.total)}`}
                <Typography variant="body2" color="text.disabled" paddingLeft={1} display="inline-block">
                  {t(GlobalMessages.itemCount, { itemCount: sortedItems.length })}
                </Typography>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={`${day.consumedDisplay.energy} kj / ${day.consumedDisplay.calories} kcal`}
                  arrow
                  placement="right"
                >
                  <span>{day.consumedDisplay.calories}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{day.consumedDisplay.protein}</TableCell>
              <TableCell>{day.consumedDisplay.fat}</TableCell>
              <TableCell>{day.consumedDisplay.sugars}</TableCell>
              <TableCell>{day.consumedDisplay.carbohydrate}</TableCell>
              <TableCell>{day.consumedDisplay.salt}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow hover>
              <TableCell />
              <TableCell>{t(GlobalMessages.goal)}</TableCell>
              <TableCell>
                <Tooltip title={`${goal.energy} kj / ${goal.calories} kcal`} arrow placement="right">
                  <span>{goal.calories}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{goal.protein}</TableCell>
              <TableCell>{goal.fat}</TableCell>
              <TableCell>{goal.sugars}</TableCell>
              <TableCell>{goal.carbohydrate}</TableCell>
              <TableCell>{goal.salt}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow hover>
              <TableCell />
              <TableCell>{t(GlobalMessages.completion)}</TableCell>
              <TableCell>
                <ArrowIndicator
                  value={day.completion.calories}
                  label={day.completionDisplay.calories}
                  macro="calories"
                />
              </TableCell>
              <TableCell>
                <ArrowIndicator value={day.completion.protein} label={day.completionDisplay.protein} macro="protein" />
              </TableCell>
              <TableCell>
                <ArrowIndicator value={day.completion.fat} label={day.completionDisplay.fat} macro="fat" />
              </TableCell>
              <TableCell>
                <ArrowIndicator value={day.completion.sugars} label={day.completionDisplay.sugars} macro="sugars" />
              </TableCell>
              <TableCell>
                <ArrowIndicator
                  value={day.completion.carbohydrate}
                  label={day.completionDisplay.carbohydrate}
                  macro="carbohydrate"
                />
              </TableCell>
              <TableCell>
                <ArrowIndicator value={day.completion.salt} label={day.completionDisplay.salt} macro="salt" />
              </TableCell>
              <TableCell />
            </TableRow>
            <RemainingRow remainingDisplay={day.remainingDisplay} />
          </TableFooter>
        </Table>
      </TableContainer>
    </Card>
  );
}
