import React from "react";
import { useIntl, defineMessages } from "react-intl";
import useTitle from "../../lib/useTitle.js";
import Card from "../Card/Card.js";
import IngredientList from "../IngredientList/IngredientList.js";

const messages = defineMessages({
  title: {
    id: "search.title",
    defaultMessage: "Search",
    description: "Page title",
  },
  getStarted: {
    id: "search.getStarted",
    defaultMessage: "Enter a search term to get started.",
    description: "Informational message",
  },
  noResults: {
    id: "search.noResults",
    defaultMessage: 'No results were found matching "{searchTerm}".',
    description: "Informational message",
  },
});

export default function Search({ searchTerm, ingredients }) {
  const { formatMessage: t } = useIntl();
  const hasIngredientResults = ingredients.length > 0;

  const isEmpty = !searchTerm.length;

  let title = isEmpty ? t(messages.title) : `${searchTerm} - ${t(messages.title)}`;

  useTitle(title);

  if (isEmpty) {
    return (
      <div>
        <Card title={t(messages.title)}>{t(messages.getStarted)}</Card>
      </div>
    );
  }

  if (!hasIngredientResults) {
    return <Card title={t(messages.title)}>{t(messages.noResults, { searchTerm })}</Card>;
  }

  return <IngredientList ingredients={ingredients} createLogEntry={() => {}} allowCreate={false} />;
}
