import React, { useContext, useState, useMemo } from "react";
import { format, parseISO, isDate } from "date-fns";
import { useIntl } from "react-intl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { sendEvent } from "../../lib/sendMetric.js";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import useTitle from "../../lib/useTitle.js";
import Card from "../Card/Card.js";
import AppInstallationContext from "../../contexts/AppInstallation/AppInstallationContext.js";
import AccountContext from "../../contexts/Account/AccountContext.js";
import ProfileContext from "../../contexts/Profile/ProfileContext.js";
import useSendPageView from "../../lib/useSendPageView.js";
import GlobalMessages from "../messages/Global.js";
import ActionMessages from "../messages/Actions.js";
import sendMessage from "../../api/sendMessage.js";
import messages from "../messages/Support.js";
import isValidEmail from "../../lib/isValidEmail.js";

function SupportInfo() {
  const {
    // appInstallState,
    // canBeInstalledState,
    installState,
    serverAdvertisedVersion, // "v1.2.3" in prod, the hash in staging, and always undefined before the viewer call
    serverAdvertisedHash,
    checkAndInstallAnyUpdated,
    getWorkerVersion,
    clientVersion,
    clientBuildDate,
  } = useContext(AppInstallationContext);
  const { formatMessage: t } = useIntl();
  const { locale, longDateTimeFormat } = useContext(LanguageContext);

  let displayVersion = clientVersion;
  let displayVersionTitle = clientVersion;
  if (serverAdvertisedHash === clientVersion && serverAdvertisedVersion) {
    // Client and server appear to be the same
    displayVersion = serverAdvertisedVersion;
  } else if (serverAdvertisedHash && clientVersion !== serverAdvertisedHash) {
    // There will be an update available.
    displayVersion = `${clientVersion} - ${serverAdvertisedVersion} (${serverAdvertisedHash})`;
    displayVersionTitle = `Client: ${clientVersion}, Server: ${serverAdvertisedHash}`;
  }

  const checkForUpdates = async () => {
    const registration = await checkAndInstallAnyUpdated();
    const workerVersion = await getWorkerVersion();
    if (__DEV__) {
      console.log("Update check complete: ", registration, workerVersion);
    }
  };

  return (
    <Card title={t(messages.title)}>
      <span>
        {t(messages.versionInfo, {
          version: (
            <span key={displayVersion} title={displayVersionTitle}>
              {displayVersion}
            </span>
          ),
          buildDate: format(isDate(clientBuildDate) ? clientBuildDate : parseISO(clientBuildDate), longDateTimeFormat, {
            locale,
          }),
        })}
      </span>
      {installState.installChecked && installState.installed && <p>{t(messages.isInstalled)}</p>}
      {/* {canBeInstalledState.detected && */}
      {/*  canBeInstalledState.canBeInstalled && */}
      {/*  appInstallState.detected && */}
      {/*  !appInstallState.installed && <p>{t(messages.installAvailable)}</p>} */}
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Button
        disabled={!installState.installChecked}
        variant="outlined"
        onClick={() => {
          checkForUpdates();
        }}
      >
        {t(ActionMessages.checkForUpdates)}
      </Button>
    </Card>
  );
}

function ContactUsCard() {
  const { account, isAuthenticated } = useContext(AccountContext);
  const { profile } = useContext(ProfileContext);

  const { formatMessage: t } = useIntl();

  const [isBusy, setIsBusy] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [contactName, setContactName] = useState(profile ? profile.name : "");
  const [contactEmail, setContactEmail] = useState(isAuthenticated ? account.email : "");
  const [message, setMessage] = useState("");

  const handleSend = async () => {
    if (isBusy) return;
    if (!contactName || !contactEmail || !message || !isValidEmail(contactEmail)) {
      return;
    }
    setIsBusy(true);
    await sendMessage({
      email: contactEmail,
      name: contactName,
      message,
    });
    setIsComplete(true);
    sendEvent({
      action: "Send Support Message",
      label: contactName,
      title: "Support",
      location: "/support",
    });
  };

  if (isComplete) {
    return <Card title={t(messages.contact)}>{t(messages.messageSent)}</Card>;
  }
  return (
    <Card title={t(messages.contact)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="name"
            value={contactName}
            onChange={(event) => {
              setContactName(event.target.value);
            }}
            variant="outlined"
            disabled={isBusy}
            label={t(GlobalMessages.name)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="email"
            type="email"
            value={contactEmail}
            onChange={(event) => {
              setContactEmail(event.target.value.toLowerCase());
            }}
            variant="outlined"
            disabled={isBusy}
            label={t(GlobalMessages.email)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="message"
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            variant="outlined"
            disabled={isBusy}
            label={t(GlobalMessages.message)}
            rows={4}
            multiline
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button disabled={isBusy} variant="contained" color="primary" onClick={handleSend}>
            {t(ActionMessages.send)}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

function SupportPageView() {
  useSendPageView("Support", "/support");
  return null;
}

export default function Support() {
  const { formatMessage: t } = useIntl();
  useTitle(t(messages.title));

  return useMemo(
    () => (
      <Grid container spacing={1}>
        <Grid item md={6}>
          <SupportPageView />
          <SupportInfo />
        </Grid>
        <Grid item md={6}>
          <ContactUsCard />
        </Grid>
      </Grid>
    ),
    [],
  );
}
