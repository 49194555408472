import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router";
import Alert from "@mui/material/Alert";
import useTitle from "../lib/useTitle.js";
import useSendPageView from "../lib/useSendPageView.js";
import ActionMessages from "./messages/Actions.js";
import messages from "./messages/Signin.js";
import CenterContainedCardLayout from "./CenterContainedCardLayout.js";
import useQuery from "../lib/useQuery.js";
import useAccount from "../contexts/Account/useAccount.js";

export default function VerifyEmailPage() {
  const { verifyAccount } = useAccount();
  const { formatMessage: t } = useIntl();
  useTitle(t(messages.title));
  const queryParams = useQuery();
  const navigate = useNavigate();
  const requestEmail = queryParams.get("email");
  const requestToken = queryParams.get("token");
  useSendPageView("Verify Email", "/verify-email");
  const [isBusy, setIsBusy] = useState(true);
  const [message, setMessage] = useState({ text: t(messages.verificationChecking), severity: "info" });

  const continueClick = () => {
    if (isBusy) return;
    navigate("/", { replace: true });
  };

  useEffect(() => {
    verifyAccount(requestEmail, requestToken)
      .then((result) => {
        setIsBusy(false);

        if (result?.success) {
          // Success will trigger account load, and thus refresh the page
          setMessage({ text: t(messages.verificationComplete), severity: "success" });
          return;
        }
        const code = result?.code || "NETWORK";
        if (code === "TOKEN_INVALID") {
          setMessage({ text: t(messages.verificationLinkExpired), severity: "error" });
        } else if (code === "NO_USER_EXISTS") {
          setMessage({ text: t(messages.noAccountExists), severity: "error" });
        } else {
          setMessage({ text: t(messages.verificationFailed), severity: "error" });
        }
      })
      .catch(() => {
        setIsBusy(false);
      });
  }, []);

  return (
    <CenterContainedCardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity={message.severity}>{message.text}</Alert>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" disabled={isBusy} onClick={continueClick}>
            {t(ActionMessages.continue)}
          </Button>
        </Grid>
      </Grid>
    </CenterContainedCardLayout>
  );
}
