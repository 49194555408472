import React, { useContext, useMemo } from "react";
import { Route, Navigate, Routes } from "react-router";
import useAccount from "../../contexts/Account/useAccount.js";
import { HomeContainerToday } from "../Home/HomeContainer.js";
import IngredientListPage from "../IngredientList/IngredientListPage.js";
import SupportContainer from "../Support/Support.js";
import PrivacyContainer from "../Support/Privacy.js";
import NotFound from "../NotFound/NotFound.js";
import SignIn from "../SignIn.js";
import ThrowErrorPage from "../NotFound/ThrowErrorPage.js";
import SearchContainer from "../Search/SearchContainer.js";
import Settings from "../Settings/Settings.js";
import CalendarContainer, { ThisWeek, NextWeek } from "../Calendar/CalendarContainer.js";
import CalendarDayContainer from "../Calendar/CalendarDayContainer.js";
import MealPlanListPage from "../MealPlanList/MealPlanListPage.js";
import MealPlanDetailsContainer from "../MealPlanList/MealPlanDetailsContainer.js";
import IngredientCreatorContainer from "../IngredientDetailsEditor/IngredientCreatorContainer.js";
import IngredientEditorContainer from "../IngredientDetailsEditor/IngredientEditorContainer.js";
import IngredientDetailsContainer from "../IngredientDetails/IngredientDetailsContainer.js";
import ErrorBoundary from "./ErrorBoundary.js";
import SSRPlaceholder from "./SSRPlaceholder.js";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import StripQuery from "../StripQuery.js";
import AdminApp from "../Admin/AdminApp.js";
import AppLayout from "./AppLayout.js";

export default function Body({ isShellPage = false }) {
  const { isAuthenticated, isLoadingFailed, isLoaded } = useAccount();
  const isReady = isLoaded && !isLoadingFailed;
  const { prefix } = useContext(LanguageContext);

  return useMemo(() => {
    if (!isReady) {
      // SSR will always proceed down this route.
      return (
        <Routes>
          <Route path={`${prefix}/`} element={<AppLayout />}>
            <Route index element={isShellPage ? <SSRPlaceholder /> : <SignIn />} />
            <Route path={`${prefix}/shell-${process.env.APP_VERSION}`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/shell`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/search`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/plan`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/plan/manage`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/plan/:id`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/plan/:id/edit`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/support`} element={<SupportContainer />} />
            <Route path={`${prefix}/privacy`} element={<PrivacyContainer />} />
            <Route path={`${prefix}/calendar`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/calendar/day/:date`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/calendar/this-week`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/calendar/next-week`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/calendar/:year/:month/:day`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/settings`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/ingredient`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/ingredient/new`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/ingredient/:ingredient`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/ingredient/:ingredient/edit`} element={<SSRPlaceholder />} />
            <Route path={`${prefix}/signin`} element={<Navigate to={`${prefix}/`} />} />
            <Route path={`${prefix}/404`} element={<NotFound />} />
            <Route path="/admin" element={<SSRPlaceholder />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      );
    }

    // If here, a check for a user has succeeded one way or another
    if (!isAuthenticated) {
      return (
        <Routes>
          <Route path={`${prefix}/`} element={<AppLayout />}>
            <Route index element={<SignIn />} />
            <Route path={`${prefix}/support`} element={<SupportContainer />} />
            <Route path={`${prefix}/privacy`} element={<PrivacyContainer />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={{
                    pathname: `${prefix}/`,
                  }}
                  state={{}}
                />
              }
            />
          </Route>
        </Routes>
      );
    }

    const FlatHome = StripQuery(HomeContainerToday);

    return (
      <Routes>
        <Route path={`${prefix}/`} element={<AppLayout />}>
          <Route index element={<FlatHome />} />
          <Route path={`${prefix}/support`} element={<SupportContainer />} />
          <Route path={`${prefix}/privacy`} element={<PrivacyContainer />} />
          <Route path={`${prefix}/search`} element={<SearchContainer />} />
          <Route path={`${prefix}/calendar`} element={<CalendarContainer />} />
          <Route path={`${prefix}/calendar/day/:date`} element={<CalendarDayContainer />} />
          <Route path={`${prefix}/calendar/this-week`} element={<ThisWeek />} />
          <Route path={`${prefix}/calendar/next-week`} element={<NextWeek />} />
          <Route path={`${prefix}/calendar/:year/:month/:day`} element={<CalendarContainer />} />
          <Route path={`${prefix}/settings`} element={<Settings />} />
          <Route path={`${prefix}/ingredient`} element={<IngredientListPage />} />
          <Route path={`${prefix}/ingredient/new`} element={<IngredientCreatorContainer />} />
          <Route path={`${prefix}/ingredient/:ingredient`} element={<IngredientDetailsContainer />} />
          <Route path={`${prefix}/ingredient/:ingredient/edit`} element={<IngredientEditorContainer />} />
          <Route path={`${prefix}/criticalerror`} element={<ThrowErrorPage />} />
          <Route path={`${prefix}/plan`} element={<MealPlanListPage />} />
          <Route path={`${prefix}/plan/manage`} element={<MealPlanListPage />} />
          <Route path={`${prefix}/plan/:id`} element={<MealPlanDetailsContainer />} />
          <Route path={`${prefix}/signin`} element={<Navigate to={`${prefix}/`} />} />
          <Route path={`${prefix}/shell-${process.env.APP_VERSION}`} element={<Navigate to={`${prefix}/`} />} />
          <Route path={`${prefix}/shell`} element={<Navigate to={`${prefix}/`} />} />
          <Route path="/admin" element={<AdminApp />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    );
  }, [isReady, isAuthenticated, prefix, isShellPage]);
}
