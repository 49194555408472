import getDay from "../reducers/getDay.js";
import setDay from "../api/setDay.js";
import { updateStorage } from "./storage.js";

export const LOG_ENTRY_DELETE = "@@LOG_ENTRY/DELETE";
export const LOG_ENTRY_UPDATE = "@@LOG_ENTRY/UPDATE";
export const LOG_ENTRY_CREATE = "@@LOG_ENTRY/CREATE";

export const updateLogEntry = (logEntry) => async (dispatch, getState) => {
  dispatch({ type: LOG_ENTRY_UPDATE, id: logEntry.id, logEntry });
  const updatedDay = getDay(getState().days.days, logEntry.date);
  dispatch(updateStorage({ day: updatedDay }));

  // TODO: Restore write to API
  // await setDay(getDay(getState().days.days, logEntry.date));
};

export const createLogEntry = (logEntry) => async (dispatch, getState) => {
  dispatch({ type: LOG_ENTRY_CREATE, id: logEntry.id, logEntry: { ...logEntry } });
  const updatedDay = getDay(getState().days.days, logEntry.date);
  dispatch(updateStorage({ day: updatedDay }));
  // TODO: Restore write to API

  // await setDay(getDay(getState().days.days, logEntry.date));
};

export const deleteLogEntry = (logEntry) => async (dispatch, getState) => {
  dispatch({ type: LOG_ENTRY_DELETE, id: logEntry.id, logEntry });
  const updatedDay = getDay(getState().days.days, logEntry.date);
  dispatch(updateStorage({ day: updatedDay }));

  // TODO: Restore write to API
  // await setDay(getDay(getState().days.days, logEntry.date));
};
