import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";
import CheckIcon from "@mui/icons-material/Check";
import GlobalMessages from "../messages/Global.js";

export default function DayPickerTabs({ value, onChange, runningDailyTotal }) {
  const { formatMessage: t, formatNumber } = useIntl();

  return (
    <Tabs variant="fullWidth" value={value} onChange={onChange}>
      {runningDailyTotal.map((day) => {
        let label = day.name;

        const itemCount = day.allItems.length;
        const markedAsComplete = day.day?.diary?.markedAsComplete === true;
        if (markedAsComplete || itemCount) {
          label = (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
              }}
              component="span"
            >
              <span>{day.name}</span>

              {itemCount > 0 && (
                <Typography
                  component="span"
                  variant="body2"
                  color="text.disabled"
                  paddingLeft={1}
                  display="inline-block"
                >
                  {`(${formatNumber(itemCount)})`}
                </Typography>
              )}
              {markedAsComplete && <CheckIcon fontSize="small" color="success" sx={{ marginLeft: 1 }} />}
            </Box>
          );
        }

        return <Tab value={day.tabId} key={day.tabId} label={label} />;
      })}
      <Tab value="total" key="total" label={t(GlobalMessages.total)} />
    </Tabs>
  );
}
