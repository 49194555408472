import React, { useRef } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import GlobalMessages from "../messages/Global.js";

export default function MacroEditorInputRow({ nutritionalProfile, dispatch, disabled = false, name = "nutrition" }) {
  const { formatMessage: t } = useIntl();

  const calInputEl = useRef();
  const fatInputEl = useRef();
  const saturatedFatInputEl = useRef();
  const carbInputEl = useRef();
  const sugarInputEl = useRef();
  const proteinInputEl = useRef();
  const saltInputEl = useRef();
  const fiberInputEl = useRef();

  const onEnterKeyNext = (event, next, previous) => {
    if (event.key !== "Enter") return;
    if (event.shiftKey) {
      previous.current.focus();
      return;
    }
    next.current.focus();
  };

  const type = `edit_${name}`;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(8, 1fr)",
        gridGap: (theme) => theme.spacing(2),
      }}
    >
      <Grid item>
        <TextField
          inputRef={calInputEl}
          autoComplete="off"
          error={!nutritionalProfile.calories.valid}
          type="number"
          min={0}
          step={1}
          value={nutritionalProfile.calories.value}
          onChange={(event) => dispatch({ type, value: { calories: event.target.value } })}
          onKeyDown={(event) => onEnterKeyNext(event, fatInputEl, saltInputEl)}
          placeholder={t(GlobalMessages.kcal)}
          variant="outlined"
          label={t(GlobalMessages.energyKcal)}
          fullWidth
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          inputRef={fatInputEl}
          autoComplete="off"
          error={!nutritionalProfile.fat.valid}
          type="number"
          min={0}
          value={nutritionalProfile.fat.value}
          onChange={(event) => dispatch({ type, value: { fat: event.target.value } })}
          onKeyDown={(event) => onEnterKeyNext(event, saturatedFatInputEl, calInputEl)}
          placeholder={t(GlobalMessages.fat)}
          variant="outlined"
          label={t(GlobalMessages.fat)}
          fullWidth
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          inputRef={saturatedFatInputEl}
          autoComplete="off"
          error={!nutritionalProfile.saturatedFat.valid}
          type="number"
          min={0}
          value={nutritionalProfile.saturatedFat.value}
          onChange={(event) => dispatch({ type, value: { saturatedFat: event.target.value } })}
          onKeyDown={(event) => onEnterKeyNext(event, carbInputEl, fatInputEl)}
          placeholder={t(GlobalMessages.saturatedFatShort)}
          variant="outlined"
          label={t(GlobalMessages.saturatedFatShort)}
          fullWidth
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          inputRef={carbInputEl}
          autoComplete="off"
          error={!nutritionalProfile.carbohydrate.valid}
          type="number"
          min={0}
          value={nutritionalProfile.carbohydrate.value}
          onChange={(event) => dispatch({ type, value: { carbohydrate: event.target.value } })}
          onKeyDown={(event) => onEnterKeyNext(event, sugarInputEl, saturatedFatInputEl)}
          placeholder={t(GlobalMessages.carbohydrateShort)}
          variant="outlined"
          label={t(GlobalMessages.carbohydrateShort)}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          inputRef={sugarInputEl}
          autoComplete="off"
          error={!nutritionalProfile.sugars.valid}
          type="number"
          min={0}
          value={nutritionalProfile.sugars.value}
          onChange={(event) => dispatch({ type, value: { sugars: event.target.value } })}
          onKeyDown={(event) => onEnterKeyNext(event, fiberInputEl, carbInputEl)}
          placeholder={t(GlobalMessages.sugar)}
          variant="outlined"
          label={t(GlobalMessages.sugar)}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          inputRef={fiberInputEl}
          autoComplete="off"
          error={!nutritionalProfile.fiber.valid}
          type="number"
          min={0}
          value={nutritionalProfile.fiber.value}
          onChange={(event) => dispatch({ type, value: { fiber: event.target.value } })}
          onKeyDown={(event) => onEnterKeyNext(event, proteinInputEl, sugarInputEl)}
          placeholder={t(GlobalMessages.fiber)}
          variant="outlined"
          label={t(GlobalMessages.fiber)}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          inputRef={proteinInputEl}
          autoComplete="off"
          error={!nutritionalProfile.protein.valid}
          type="number"
          min={0}
          value={nutritionalProfile.protein.value}
          onChange={(event) => dispatch({ type, value: { protein: event.target.value } })}
          onKeyDown={(event) => onEnterKeyNext(event, saltInputEl, fiberInputEl)}
          placeholder={t(GlobalMessages.protein)}
          variant="outlined"
          label={t(GlobalMessages.protein)}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          inputRef={saltInputEl}
          autoComplete="off"
          error={!nutritionalProfile.salt.valid}
          type="number"
          min={0}
          value={nutritionalProfile.salt.value}
          onChange={(event) => dispatch({ type, value: { salt: event.target.value } })}
          onKeyDown={(event) => onEnterKeyNext(event, calInputEl, proteinInputEl)}
          placeholder={t(GlobalMessages.salt)}
          variant="outlined"
          label={t(GlobalMessages.salt)}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Box>
  );
}
