import { combineNutritionalProfile } from "../../data/calculateMacros.js";
import { DayMeals } from "../../data/constants.js";

export function groupItemsByTime(items) {
  if (!items) return [];
  const itemsByTime = items
    .reduce((acc, item) => {
      const existingEntry = acc.find((entry) => entry.time === item.time);

      if (existingEntry) {
        existingEntry.items.push(item);
      } else {
        acc.push({
          time: item.time,
          items: [item],
        });
      }
      return acc;
    }, [])
    .map((item) => ({
      ...item,
      nutritionalProfile: combineNutritionalProfile(item.items.map((itemToAdd) => itemToAdd.nutritionalProfile)),
    }));

  const sortedItemsByTime = [];
  Object.keys(DayMeals).forEach((time) => {
    const itemInOrder = itemsByTime.find((item) => item.time === time);
    if (itemInOrder) {
      sortedItemsByTime.push(itemInOrder);
    }
  });

  return sortedItemsByTime;
}

export default function applyItemByTimeGrouping(days) {
  return days.map((day) => ({
    ...day,
    itemsByTime: groupItemsByTime(day.allItems),
  }));
}
