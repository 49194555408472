import React, { useState, useEffect, useContext, useRef } from "react";
import { parseISO, differenceInSeconds } from "date-fns";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import useStyles from "../isomorphic-style-loader/useStyles.js";
import { useTitleStandalone } from "../lib/useTitle.js";
import useSendPageView from "../lib/useSendPageView.js";
import GlobalMessages from "./messages/Global.js";
import ActionMessages from "./messages/Actions.js";
import AccountContext from "../contexts/Account/AccountContext.js";
import LanguageContext from "../contexts/Language/LanguageContext.js";
import messages from "./messages/Signin.js";
import SEOmessages from "./messages/SEO.js";
import styles from "./SignIn.css";
import heroImage from "./SignIn/hero.png";
import heroImagePT from "./SignIn/heroPT.png";
import { loadStorage } from "../actions/storage.js";

export default function SignIn() {
  useStyles(styles);
  const dispatch = useDispatch();
  const { createAccount } = useContext(AccountContext);
  const { language, prefix } = useContext(LanguageContext);
  const { formatMessage: t, formatNumber } = useIntl();
  useTitleStandalone(t(SEOmessages.seoTitle));
  useSendPageView("Sign In", "/");
  const [isBusy, setIsBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [profileName, setProfileName] = useState("");
  const profileNameFieldEl = useRef();
  const getStartedClick = () => {
    if (isBusy) return;
    if (!profileName.trim().length) {
      setHasValidationErrors(true);
      profileNameFieldEl.current.focus();
      return;
    }
    setHasValidationErrors(false);
    setIsBusy(true);
    createAccount({
      name: profileName,
      language: language.language,
      referrer: document.referrer,
    })
      .then((result) => {
        if (result && result.profile) {
          dispatch(loadStorage());
          return;
        }
        setIsBusy(false);
      })
      .catch(() => {
        setIsBusy(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsAvailable(true);
    }, 1000);
  }, []);

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      getStartedClick();
    }
  };

  // TODO: Toggle me on and off
  const signupDisabled = true;

  const formDisabled = !isAvailable || isBusy || signupDisabled;

  const counter = differenceInSeconds(parseISO(process.env.APP_BUILDDATE), new Date(2024, 1, 1));
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateAreas: '"info hero"',
        gridColumnGap: "16px",
        gridRowGap: "16px",
        marginBottom: "32px",
      }}
    >
      <Box sx={{ gridArea: "info", padding: "10px" }}>
        <Box
          className={styles.textWrapper}
          sx={{
            fontSize: "18px",
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
        >
          <span className={styles.counter}>{`📈 ${t(messages.heroTopSub, { number: formatNumber(counter) })}`}</span>
          <h1>{t(messages.hero1)}</h1>
          <h2>{t(messages.hero2)}</h2>
          <p>{t(messages.description)}</p>
          {signupDisabled && (
            <Alert severity="error">Due to demand, we have had to turn off new registrations temporarily</Alert>
          )}
          <Box display="flex" alignItems="center">
            <Box marginRight={2} flex={1}>
              <TextField
                id="signup_name"
                inputRef={profileNameFieldEl}
                value={profileName}
                helperText={errorMessage}
                onChange={(event) => {
                  setProfileName(event.target.value);

                  if (!event.target.value.trim().length) {
                    setHasValidationErrors(true);
                  } else if (hasValidationErrors) {
                    setHasValidationErrors(false);
                  }
                }}
                variant="filled"
                disabled={formDisabled}
                label={t(GlobalMessages.name)}
                enterKeyHint="done"
                fullWidth
                onKeyPress={onKeyPress}
                error={hasValidationErrors || Boolean(errorMessage)}
              />
            </Box>
            <Button disabled={formDisabled} size="large" onClick={getStartedClick} color="primary" variant="contained">
              {t(ActionMessages.getStarted)}
            </Button>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={2}>
            <Typography variant="subtitle2" component={Box}>
              {t(messages.ctaPoint1)}
            </Typography>
            <Typography variant="subtitle2" component={Box}>
              {t(messages.ctaPoint2)}
            </Typography>
            <Typography variant="subtitle2" component={Box}>
              {t(messages.ctaPoint3)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ gridArea: "hero", padding: "10px" }}>
        <Box
          sx={{
            boxShadow: "0 0 10px 10px rgba(0, 0, 0, 0.15)",
            borderRadius: "10px",
            display: { xs: "none", sm: "block" },
          }}
        >
          <Box
            component="img"
            src={prefix === "/pt" || prefix === "/pt-br" ? heroImagePT : heroImage}
            alt={t(messages.hero1)}
            sx={{
              height: "350px",
              width: "656px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
