import Big from "big.js";
import { profileIncludesValue, convertToNumber } from "./displayMacros.js";

export function getPortionSize(totalWeightGrams, portionCount) {
  return parseFloat(new Big(totalWeightGrams).div(portionCount).toFixed(0));
}

export function getAbsolutePercentageComplete(consumed, goal) {
  if (!consumed) return 0;
  if (consumed === goal || (!consumed && !goal)) return 100;
  let minGoal = goal;
  if (minGoal === 0) {
    minGoal = consumed;
    if (consumed === 0) {
      return 100;
    }
  }
  return parseFloat(new Big(consumed).div(minGoal).times(100).toFixed(0));
}

export function getPercentageComplete(consumed, goal) {
  const consumedNumber = convertToNumber(consumed).toNumber();
  if (consumedNumber === 0) return 0;
  const goalNumber = convertToNumber(goal).toNumber();
  return getAbsolutePercentageComplete(consumedNumber, goalNumber);
}

export function goalCompletion(consumed, goal) {
  const hasValue = profileIncludesValue(consumed);
  const hasGoalValue = profileIncludesValue(goal);
  return {
    energy:
      !hasValue("energy") || !hasGoalValue("energy")
        ? undefined
        : getAbsolutePercentageComplete(consumed.energy, goal.energy),
    calories:
      !hasValue("calories") || !hasGoalValue("calories")
        ? undefined
        : getAbsolutePercentageComplete(consumed.calories, goal.calories),
    fat: !hasValue("fat") || !hasGoalValue("fat") ? undefined : getPercentageComplete(consumed.fat, goal.fat),
    saturatedFat:
      !hasValue("saturatedFat") || !hasGoalValue("saturatedFat")
        ? undefined
        : getPercentageComplete(consumed.saturatedFat, goal.saturatedFat),
    carbohydrate:
      !hasValue("carbohydrate") || !hasGoalValue("carbohydrate")
        ? undefined
        : getPercentageComplete(consumed.carbohydrate, goal.carbohydrate),
    sugars:
      !hasValue("sugars") || !hasGoalValue("sugars") ? undefined : getPercentageComplete(consumed.sugars, goal.sugars),
    fiber: !hasValue("fiber") || !hasGoalValue("fiber") ? undefined : getPercentageComplete(consumed.fiber, goal.fiber),
    protein:
      !hasValue("protein") || !hasGoalValue("protein")
        ? undefined
        : getPercentageComplete(consumed.protein, goal.protein),
    salt: !hasValue("salt") || !hasGoalValue("salt") ? undefined : getPercentageComplete(consumed.salt, goal.salt),
  };
}

export function outsideLimitsTolerance(value) {
  if (!value || value < 90 || value > 110) return true;
  return false;
}

export function goalCompletionDisplay(nutritionalProfile) {
  const hasValue = profileIncludesValue(nutritionalProfile);
  const noValueMarker = "-";
  const numberFormatter = new Intl.NumberFormat();

  const hasEnergyValue = hasValue("energy");
  const hasCaloriesValue = hasValue("calories");
  const hasFatValue = hasValue("fat");
  const hasSaturatedFatValue = hasValue("saturatedFat");
  const hasCarbohydrateValue = hasValue("carbohydrate");
  const hasSugarsValue = hasValue("sugars");
  const hasFiberValue = hasValue("fiber");
  const hasProteinValue = hasValue("protein");
  const hasSaltValue = hasValue("salt");

  return {
    energy: hasEnergyValue ? `${numberFormatter.format(nutritionalProfile.energy)}%` : noValueMarker,
    energyOutsideLimits: hasEnergyValue && outsideLimitsTolerance(nutritionalProfile.energy),

    calories: hasCaloriesValue ? `${numberFormatter.format(nutritionalProfile.calories)}%` : noValueMarker,
    caloriesHasValue: hasCaloriesValue,
    caloriesValue: nutritionalProfile.calories,
    caloriesOutsideLimits: hasCaloriesValue && outsideLimitsTolerance(nutritionalProfile.calories),

    fat: hasFatValue ? `${numberFormatter.format(nutritionalProfile.fat)}%` : noValueMarker,
    fatHasValue: hasFatValue,
    fatValue: nutritionalProfile.fat,
    fatOutsideLimits: hasFatValue && outsideLimitsTolerance(nutritionalProfile.fat),

    saturatedFat: hasSaturatedFatValue ? `${numberFormatter.format(nutritionalProfile.saturatedFat)}%` : noValueMarker,
    saturatedFatOutsideLimits: hasSaturatedFatValue && outsideLimitsTolerance(nutritionalProfile.saturatedFat),

    carbohydrate: hasCarbohydrateValue ? `${numberFormatter.format(nutritionalProfile.carbohydrate)}%` : noValueMarker,
    carbohydrateHasValue: hasCarbohydrateValue,
    carbohydrateValue: nutritionalProfile.carbohydrate,
    carbohydrateOutsideLimits: hasCarbohydrateValue && outsideLimitsTolerance(nutritionalProfile.carbohydrate),

    sugars: hasSugarsValue ? `${numberFormatter.format(nutritionalProfile.sugars)}%` : noValueMarker,
    sugarsHasValue: hasSugarsValue,
    sugarsValue: nutritionalProfile.sugars,
    sugarsOutsideLimits: hasSugarsValue && outsideLimitsTolerance(nutritionalProfile.sugars),

    fiber: hasFiberValue ? `${numberFormatter.format(nutritionalProfile.fiber)}%` : noValueMarker,
    fiberOutsideLimits: hasFiberValue && outsideLimitsTolerance(nutritionalProfile.fiber),

    protein: hasProteinValue ? `${numberFormatter.format(nutritionalProfile.protein)}%` : noValueMarker,
    proteinHasValue: hasProteinValue,
    proteinValue: nutritionalProfile.protein,
    proteinOutsideLimits: hasProteinValue && outsideLimitsTolerance(nutritionalProfile.protein),

    salt: hasSaltValue ? `${numberFormatter.format(nutritionalProfile.salt)}%` : noValueMarker,
    saltHasValue: hasSaltValue,
    saltValue: nutritionalProfile.salt,
    saltOutsideLimits: hasSaltValue && outsideLimitsTolerance(nutritionalProfile.salt),
  };
}

export function precision(a) {
  if (!Number.isFinite(a)) return 0;
  let e = 1;
  let p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p += 1;
  }
  return p;
}

export function calculatePortionSize(packWeight, packQuantity) {
  return parseFloat(new Big(packWeight).div(packQuantity).toFixed(0));
}

export function calculateGramsConsumed(quantity, weightPerQuantity) {
  return parseFloat(new Big(weightPerQuantity).times(quantity).toFixed(0));
}

/**
 * Calculates nearest rounded values using whole numbers.
 */
export function getValuePerQuantity(valuePerOneHundredGrams, gramsUsed, base = 100, minimumPrecision = 0) {
  if (!Array.isArray(valuePerOneHundredGrams)) {
    return valuePerOneHundredGrams;
  }
  if (valuePerOneHundredGrams[0] === 0 || gramsUsed === 0) return [0, 0];
  if (gramsUsed === base) return [...valuePerOneHundredGrams];

  // [702, 1] = 70.2 @ 33g = 23.166 = [23166, 3]
  // 702 / 100 * 33 // Always use whole numbers

  const multiplier = 10 ** valuePerOneHundredGrams[1];

  const originalValue = new Big(valuePerOneHundredGrams[0]).div(multiplier);
  const minimumEffectivePrecision = valuePerOneHundredGrams[1] === 0 ? minimumPrecision : valuePerOneHundredGrams[1];
  const valuePerQuantity = originalValue.div(base).times(gramsUsed).toFixed(minimumEffectivePrecision); // .round(valuePerOneHundredGrams[1])
  const valuePerQuantityAsFloat = parseFloat(valuePerQuantity);
  const valuePerQuantityPrecision = precision(valuePerQuantityAsFloat);

  if (valuePerQuantityPrecision === 0) {
    return [valuePerQuantityAsFloat, 0];
  }

  const resultMultiplier = 10 ** valuePerQuantityPrecision;
  // valuePerQuantityAsFloat * resultMultiplier < This gives the floating point errors.
  const resultAsIntegerString = new Big(valuePerQuantityAsFloat).times(resultMultiplier);
  const resultAsInteger = parseFloat(resultAsIntegerString);

  return [resultAsInteger, valuePerQuantityPrecision];
}

/**
 * Calculates absolute whole values, for items that are not divisiable, like calories.
 */
export function getAbsoluteValuePerQuantity(valuePerOneHundredGrams = 0, gramsUsed, base = 100) {
  if (
    valuePerOneHundredGrams === 0 ||
    gramsUsed === 0 ||
    valuePerOneHundredGrams === undefined ||
    valuePerOneHundredGrams === null
  )
    return 0;
  return Math.round((valuePerOneHundredGrams / base) * gramsUsed);
}

export function addValuePerQuantity(valuePerQuantityA = [0, 0], valuePerQuantityB = [0, 0], subtract = false) {
  if (valuePerQuantityA[1] === valuePerQuantityB[1]) {
    if (subtract) return [valuePerQuantityA[0] - valuePerQuantityB[0], valuePerQuantityA[1]];
    return [valuePerQuantityA[0] + valuePerQuantityB[0], valuePerQuantityA[1]];
  }

  // the max decimal multipler
  const desiredMultiplier = Math.max(valuePerQuantityA[1], valuePerQuantityB[1]);

  // how much each value must be multipled to make them the same
  const valueAMultiplier = valuePerQuantityA[1] === desiredMultiplier ? 0 : desiredMultiplier - valuePerQuantityA[1];
  const valueBMultiplier = valuePerQuantityB[1] === desiredMultiplier ? 0 : desiredMultiplier - valuePerQuantityB[1];

  // The value of each after being multipled by the necessary ammount
  const valueA = valueAMultiplier === 0 ? valuePerQuantityA[0] : valuePerQuantityA[0] * 10 ** valueAMultiplier;
  const valueB = valueBMultiplier === 0 ? valuePerQuantityB[0] : valuePerQuantityB[0] * 10 ** valueBMultiplier;

  if (subtract) return [valueA - valueB, desiredMultiplier];
  return [valueA + valueB, desiredMultiplier];
}

export function subtractValuePerQuantity(valuePerQuantityA, valuePerQuantityB) {
  return addValuePerQuantity(valuePerQuantityA, valuePerQuantityB, true);
}

export function subtractNutritionalProfile(nutritionalProfile, nutritionalProfileToAdd) {
  return {
    energy: nutritionalProfile.energy - nutritionalProfileToAdd.energy,
    calories: nutritionalProfile.calories - nutritionalProfileToAdd.calories,
    fat: subtractValuePerQuantity(nutritionalProfile.fat, nutritionalProfileToAdd.fat),
    saturatedFat: subtractValuePerQuantity(nutritionalProfile.saturatedFat, nutritionalProfileToAdd.saturatedFat),
    carbohydrate: subtractValuePerQuantity(nutritionalProfile.carbohydrate, nutritionalProfileToAdd.carbohydrate),
    sugars: subtractValuePerQuantity(nutritionalProfile.sugars, nutritionalProfileToAdd.sugars),
    fiber: subtractValuePerQuantity(nutritionalProfile.fiber, nutritionalProfileToAdd.fiber),
    protein: subtractValuePerQuantity(nutritionalProfile.protein, nutritionalProfileToAdd.protein),
    salt: subtractValuePerQuantity(nutritionalProfile.salt, nutritionalProfileToAdd.salt),
  };
}

export function addNutritionalProfile(nutritionalProfile, nutritionalProfileToAdd) {
  return {
    energy:
      (nutritionalProfile.energy === undefined ? 0 : nutritionalProfile.energy) +
      (nutritionalProfileToAdd.energy === undefined ? 0 : nutritionalProfileToAdd.energy),
    calories: nutritionalProfile.calories + nutritionalProfileToAdd.calories,
    fat: addValuePerQuantity(nutritionalProfile.fat, nutritionalProfileToAdd.fat),
    saturatedFat: addValuePerQuantity(nutritionalProfile.saturatedFat, nutritionalProfileToAdd.saturatedFat),
    carbohydrate: addValuePerQuantity(nutritionalProfile.carbohydrate, nutritionalProfileToAdd.carbohydrate),
    sugars: addValuePerQuantity(nutritionalProfile.sugars, nutritionalProfileToAdd.sugars),
    fiber: addValuePerQuantity(nutritionalProfile.fiber, nutritionalProfileToAdd.fiber),
    protein: addValuePerQuantity(nutritionalProfile.protein, nutritionalProfileToAdd.protein),
    salt: addValuePerQuantity(nutritionalProfile.salt, nutritionalProfileToAdd.salt),
  };
}

export function calculateMacrosByGram(nutritionalProfile, gramsRequired) {
  const hasValue = profileIncludesValue(nutritionalProfile);
  return {
    energy: !hasValue("energy")
      ? undefined
      : getAbsoluteValuePerQuantity(nutritionalProfile.energy, gramsRequired, nutritionalProfile.base),
    calories: !hasValue("calories")
      ? undefined
      : getAbsoluteValuePerQuantity(nutritionalProfile.calories, gramsRequired, nutritionalProfile.base),
    fat: !hasValue("fat")
      ? undefined
      : getValuePerQuantity(nutritionalProfile.fat, gramsRequired, nutritionalProfile.base, 1),
    saturatedFat: !hasValue("saturatedFat")
      ? undefined
      : getValuePerQuantity(nutritionalProfile.saturatedFat, gramsRequired, nutritionalProfile.base, 1),
    carbohydrate: !hasValue("carbohydrate")
      ? undefined
      : getValuePerQuantity(nutritionalProfile.carbohydrate, gramsRequired, nutritionalProfile.base, 1),
    sugars: !hasValue("sugars")
      ? undefined
      : getValuePerQuantity(nutritionalProfile.sugars, gramsRequired, nutritionalProfile.base, 1),
    fiber: !hasValue("fiber")
      ? undefined
      : getValuePerQuantity(nutritionalProfile.fiber, gramsRequired, nutritionalProfile.base, 1),
    protein: !hasValue("protein")
      ? undefined
      : getValuePerQuantity(nutritionalProfile.protein, gramsRequired, nutritionalProfile.base, 1),
    salt: !hasValue("salt")
      ? undefined
      : getValuePerQuantity(nutritionalProfile.salt, gramsRequired, nutritionalProfile.base, 1),
  };
}

/**
 * Given a list of ingredients, and the grams used, calculates the TOTAL macros across the list.
 */
export default function calculateMacros(ingredientsUsed) {
  const resultsMacros = {
    energy: 0,
    calories: 0,
    fat: [0, 0],
    saturatedFat: [0, 0],
    carbohydrate: [0, 0],
    sugars: [0, 0],
    fiber: [0, 0],
    protein: [0, 0],
    salt: [0, 0],
    base: undefined,
  };

  const macrosPerIngredient = ingredientsUsed.map((ingredient) => ({
    profile: calculateMacrosByGram(ingredient.ingredient.nutritionalProfile, ingredient.gramsRequired),
    gramsRequired: ingredient.gramsRequired,
  }));

  macrosPerIngredient.forEach((usage) => {
    resultsMacros.energy += usage.profile.energy || 0;
    resultsMacros.calories += usage.profile.calories || 0;
    resultsMacros.fat = addValuePerQuantity(resultsMacros.fat, usage.profile.fat);
    resultsMacros.saturatedFat = addValuePerQuantity(resultsMacros.saturatedFat, usage.profile.saturatedFat);
    resultsMacros.carbohydrate = addValuePerQuantity(resultsMacros.carbohydrate, usage.profile.carbohydrate);
    resultsMacros.sugars = addValuePerQuantity(resultsMacros.sugars, usage.profile.sugars);
    resultsMacros.fiber = addValuePerQuantity(resultsMacros.fiber, usage.profile.fiber);
    resultsMacros.protein = addValuePerQuantity(resultsMacros.protein, usage.profile.protein);
    resultsMacros.salt = addValuePerQuantity(resultsMacros.salt, usage.profile.salt);
    if (usage.gramsRequired !== undefined) {
      resultsMacros.base = (resultsMacros.base || 0) + usage.gramsRequired;
    }
  });

  return resultsMacros;
}

/**
 * Get an NutritionalProfile with all zero values.
 */
export function getEmptyNutritionalProfile() {
  return {
    energy: 0,
    calories: 0,
    fat: [0, 0],
    saturatedFat: [0, 0],
    carbohydrate: [0, 0],
    sugars: [0, 0],
    fiber: [0, 0],
    protein: [0, 0],
    salt: [0, 0],
  };
}

export function combineNutritionalProfile(nutritionalProfiles) {
  if (!nutritionalProfiles?.length) return getEmptyNutritionalProfile();
  return nutritionalProfiles.reduce(addNutritionalProfile);
}

export function combineLogEntries(logEntries) {
  return combineNutritionalProfile(logEntries.map((logEntry) => logEntry.nutritionalProfile));
}

export function getDifference(consumed, goal) {
  return addValuePerQuantity(goal, consumed, true);
}

export function goalRemaining(consumed, goal) {
  const hasValue = profileIncludesValue(consumed);
  const hasGoalValue = profileIncludesValue(goal);
  return {
    energy: !hasValue("energy") || !hasGoalValue("energy") ? undefined : goal.energy - consumed.energy,
    calories: !hasValue("calories") || !hasGoalValue("calories") ? undefined : goal.calories - consumed.calories,
    fat: !hasValue("fat") || !hasGoalValue("fat") ? undefined : getDifference(consumed.fat, goal.fat),
    saturatedFat:
      !hasValue("saturatedFat") || !hasGoalValue("saturatedFat")
        ? undefined
        : getDifference(consumed.saturatedFat, goal.saturatedFat),
    carbohydrate:
      !hasValue("carbohydrate") || !hasGoalValue("carbohydrate")
        ? undefined
        : getDifference(consumed.carbohydrate, goal.carbohydrate),
    sugars: !hasValue("sugars") || !hasGoalValue("sugars") ? undefined : getDifference(consumed.sugars, goal.sugars),
    fiber: !hasValue("fiber") || !hasGoalValue("fiber") ? undefined : getDifference(consumed.fiber, goal.fiber),
    protein:
      !hasValue("protein") || !hasGoalValue("protein") ? undefined : getDifference(consumed.protein, goal.protein),
    salt: !hasValue("salt") || !hasGoalValue("salt") ? undefined : getDifference(consumed.salt, goal.salt),
  };
}
