import { getHours } from "date-fns";
import { DAY_MEALS } from "./constants.js";

export const timesOfDay = {
  0: DAY_MEALS.SNACKS,
  1: DAY_MEALS.SNACKS,
  2: DAY_MEALS.SNACKS,
  3: DAY_MEALS.SNACKS,
  4: DAY_MEALS.BREAKFAST,
  5: DAY_MEALS.BREAKFAST,
  6: DAY_MEALS.BREAKFAST,
  7: DAY_MEALS.BREAKFAST,
  8: DAY_MEALS.BREAKFAST,
  9: DAY_MEALS.BREAKFAST,
  10: DAY_MEALS.BREAKFAST,
  11: DAY_MEALS.LUNCH,
  12: DAY_MEALS.LUNCH,
  13: DAY_MEALS.LUNCH,
  14: DAY_MEALS.LUNCH,
  15: DAY_MEALS.LUNCH,
  16: DAY_MEALS.SNACKS,
  17: DAY_MEALS.SNACKS,
  18: DAY_MEALS.DINNER,
  19: DAY_MEALS.DINNER,
  20: DAY_MEALS.DINNER,
  21: DAY_MEALS.DINNER,
  22: DAY_MEALS.DINNER,
  23: DAY_MEALS.SNACKS,
};

export function getTimeOfDayByHour(hour) {
  if (hour < 0 || hour > 23) throw new Error(`Invalid hour "${hour}" entered.`);
  return timesOfDay[`${hour}`];
}

export default function getTimeOfDay(date) {
  return getTimeOfDayByHour(getHours(date));
}
