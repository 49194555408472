import { defineMessages } from "react-intl";

const Toast = defineMessages({
  ingredientAddedToPlan: {
    id: "toast.ingredientAddedToPlan",
    description: "Toast Text, an ingredient item has been saved to the current plan.",
    defaultMessage: `"{ingredientName}" has been added to your diary as {timeOfDay} on {date}.`,
  },
  ingredientAddedToPlanMultipleDays: {
    id: "toast.ingredientAddedToPlanMultipleDays",
    description: "Toast Text, an ingredient item has been saved to the current plan.",
    defaultMessage: `"{ingredientName}" has been added to your plan on {dayCount} different days.`,
  },
  ingredientDeletedingredientDeleted: {
    id: "toast.nameHasBeenDeleted",
    description: "Toast Text, an ingredient item has been deleted.",
    defaultMessage: `"{name}" has been deleted.`,
  },
  servingOfXAdded: {
    id: "toast.servingOfXAdded",
    description: "Toast Text",
    defaultMessage: `A serving of "{name}" has been added.`,
  },
  xIngredientsForYRecipeAdded: {
    id: "toast.xIngredientsForYRecipeAdded",
    description: "Toast Text",
    defaultMessage: `{ingredientCount} ingredients for "{name}" has been added.`,
  },
  xSaved: {
    id: "toast.xSaved",
    description: "Toast Text",
    defaultMessage: `{name} saved`,
  },
  xUpdated: {
    id: "toast.xUpdated",
    description: "Toast Text",
    defaultMessage: `{name} updated`,
  },
  xDeleted: {
    id: "toast.xDeleted",
    description: "Toast Text",
    defaultMessage: `{name} deleted`,
  },
});

export default Toast;
