import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import ActionMessages from "../messages/Actions.js";

export default function DayCardContextMenu({ handleClose, anchorPosition, onEdit, onDelete }) {
  const { formatMessage: t } = useIntl();

  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition} // Use the saved anchorPosition instead of anchorEl
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      keepMounted
      open={!!anchorPosition}
      onClose={handleClose}
      MenuListProps={{
        dense: true,
      }}
    >
      <MenuItem
        dense
        onClick={(event) => {
          onEdit();
          handleClose();
          event.stopPropagation();
        }}
        selected={false}
      >
        {t(ActionMessages.edit)}
      </MenuItem>
      <MenuItem
        dense
        onClick={(event) => {
          onDelete();
          handleClose();
          event.stopPropagation();
        }}
        selected={false}
      >
        {t(ActionMessages.delete)}
      </MenuItem>
    </Menu>
  );
}
