import React, { useContext } from "react";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { Link as ReactRouterLink } from "react-router";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "./Card/Card.js";
import AppMessages from "./messages/App.js";
import LanguageContext from "../contexts/Language/LanguageContext.js";
import Link from "./Link.js";
import CenterContainerPage from "./CenterContainerPage.js";
import getAvailableLanguages from "../lib/languages/getAvailableLanguages.js";
import Logo from "./Logo.js";
import useLocation from "./useLocation.js";

export default function CenterContainedCardLayout({ children }) {
  const { language } = useContext(LanguageContext);
  const { formatMessage: t } = useIntl();
  const { pathname } = useLocation();

  return (
    <CenterContainerPage>
      <Grid container justifyContent="center">
        <Grid item xs={9} md={4}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" flexDirection="column">
                  <Typography component={Link} color="primary" to="/">
                    <Logo />
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {children}
          </Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" marginLeft={2} marginRight={2} alignItems="center">
                <TextField
                  select
                  size="small"
                  aria-label="Language"
                  value={language.language.substring(0, 2)}
                  onChange={() => {}}
                >
                  {getAvailableLanguages(t).map((availableLanguage) => (
                    <MenuItem
                      component={ReactRouterLink}
                      key={availableLanguage.id}
                      value={availableLanguage.id}
                      replace
                      to={`/${availableLanguage.id}${pathname}`}
                    >
                      {availableLanguage.nameInOwnLanguage}
                    </MenuItem>
                  ))}
                </TextField>
                <Link to="/support" color="textPrimary">
                  {t(AppMessages.help)}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CenterContainerPage>
  );
}
