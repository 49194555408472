import React, { useContext, Fragment } from "react";
import { Route } from "react-router";
import { useIntl } from "react-intl";
import { parseISO, format, isDate } from "date-fns";
import Box from "@mui/material/Box";
import ActionsMessages from "../messages/Actions.js";
import GlobalMessages from "../messages/Global.js";
import SigninMessages from "../messages/Signin.js";
import Link from "../Link.js";
import useLocation from "../useLocation.js";
import getAvailableLanguages from "../../lib/languages/getAvailableLanguages.js";
import LanguageContext from "../../contexts/Language/LanguageContext.js";

const pagesWithFooter = ["/", "/login", "/support"];

const settingsPages = ["/settings"];

export default function Footer({ name, isReady, isAuthenticated, isShellPage, lastChecked, lastLoadAttempted }) {
  const isReadyAndLoggedIn = isReady && isAuthenticated;
  const { prefix, longDateTimeFormat } = useContext(LanguageContext);
  const { pathname } = useLocation();
  const { formatMessage: t } = useIntl();
  if (!isReady && isShellPage) return null;
  const showFooter = isReadyAndLoggedIn || pagesWithFooter.includes(pathname);

  if (!showFooter) return null;

  const isSettingsPage = isReadyAndLoggedIn && settingsPages.includes(pathname);

  return (
    <Box
      sx={{
        padding: "14px 32px",
        fontSize: "14px",
        display: "flex",
        displayPrint: "none",
        borderTopColor: "divider",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
      }}
    >
      <Box sx={{ flex: "1 1 auto" }}>
        {/*{!name ? (*/}
        {/*  <Route*/}
        {/*    exact*/}
        {/*    path={`${prefix}/`}*/}
        {/*    component={() => (*/}
        {/*      <span>*/}
        {/*        {t(SigninMessages.otherLanguages)}{" "}*/}
        {/*        {getAvailableLanguages(t).map((availableLanguage) => (*/}
        {/*          <Fragment key={availableLanguage.id}>*/}
        {/*            <Link title={availableLanguage.displayOption} replace to={`/${availableLanguage.id}/`}>*/}
        {/*              {availableLanguage.nameInOwnLanguage}*/}
        {/*            </Link>{" "}*/}
        {/*          </Fragment>*/}
        {/*        ))}*/}
        {/*      </span>*/}
        {/*    )}*/}
        {/*  />*/}
        {/*) : null}*/}
        {isSettingsPage && (
          <span
            title={`${t(GlobalMessages.lastLoadAttempted)}: ${lastLoadAttempted ? format(isDate(lastLoadAttempted) ? lastLoadAttempted : parseISO(lastLoadAttempted), longDateTimeFormat) : ""}`}
          >{`${t(GlobalMessages.lastUpdated)}: ${lastChecked ? format(isDate(lastChecked) ? lastChecked : parseISO(lastChecked), longDateTimeFormat) : ""}`}</span>
        )}
      </Box>
      <Box sx={{ flex: "1 1 auto", textAlign: "right" }}>
        <Link to="/support">{t(ActionsMessages.giveFeedbackAboutThisPage)}</Link>
      </Box>
    </Box>
  );
}
