import React from "react";
import { styled } from "@mui/material/styles";
// import styles from "./WidgetWrapper.css";

const WidgetRoot = styled("div", {
  name: "MmpWidget", // The component name
  slot: "Root", // The slot name
})(({ theme }) => ({
  borderRadius: "10px",
  overflow: "hidden",
  display: "flex",
  borderColor: theme.palette.divider,
  borderWidth: "1px",
  borderStyle: "solid",
  "@media print": {
    boxShadow: "none",
    color: "black",
    border: "solid 1px black",
    background: "transparent",
  },
}));

export const WidgetBody = styled("div", {
  name: "MmpWidget", // The component name
  slot: "body", // The slot name
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "noPadding" && prop !== "isStacked" && prop !== "sx",
})((data) => {
  const { noPadding, isStacked, theme } = data; // theme is also here
  return {
    display: "flex",
    flex: 1,
    // padding: noPadding ? 0 : { xs: "24px" } // this responsiveness doesn't work
    paddingTop: noPadding ? 0 : theme.spacing(2),
    paddingLeft: noPadding ? 0 : theme.spacing(2),
    paddingBottom: noPadding ? 0 : theme.spacing(2),
    paddingRight: 0,
    ...(isStacked ? { flexDirection: "column" } : { alignItems: "center" }),
  };
});

export default function WidgetWrapper({
  children,
  title,
  sx,
  // theme = "darkDefault",
  isStacked = false,
  noPadding = false,
}) {
  return (
    <WidgetRoot
      sx={sx}
      // className={cx(
      //   {
      //     [styles.darkDefault]: theme === "darkDefault",
      //     [styles.greenGradient]: theme === "greenGradient",
      //     [styles.geekBlue]: theme === "geekBlue",
      //   },
      //   className,
      // )}
      title={title}
    >
      <WidgetBody isStacked={isStacked} noPadding={noPadding}>
        {children}
      </WidgetBody>
    </WidgetRoot>
  );
}
