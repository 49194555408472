import { defineMessages } from "react-intl";

const Global = defineMessages({
  grams: {
    id: "global.grams",
    defaultMessage: "Grams",
    description: "Label, unit of measurement",
  },
  volumeMl: {
    id: "global.volumeMl",
    defaultMessage: "Volume (ml)",
    description: "Unit of measure",
  },
  perXg: {
    id: "global.perXg",
    defaultMessage: "Per {value}{unit}",
    description: "Label, unit of measurement",
  },
  suggestedXg: {
    id: "global.suggestedXg",
    defaultMessage: "Suggested {value}{unit}",
    description: "Label, unit of measurement",
  },
  servingXg: {
    id: "global.servingXg",
    defaultMessage: "Serving {value}g",
    description: "Label, unit of measurement",
  },
  portion: {
    id: "global.portion",
    defaultMessage: "Portion",
    description: "Label, a serving of food",
  },
  portionsMade: {
    id: "global.portionsMade",
    defaultMessage: "Portions Made",
    description: "Label, a serving of food",
  },
  nutritionalProfile: {
    id: "global.nutritionalProfile",
    defaultMessage: "Nutritional Profile",
    description: "Section Heading",
  },
  breakfast: {
    id: "global.breakfast",
    defaultMessage: "Breakfast",
    description: "Label, Meal title",
  },
  lunch: {
    id: "global.lunch",
    defaultMessage: "Lunch",
    description: "Label, Meal title",
  },
  dinner: {
    id: "global.dinner",
    defaultMessage: "Dinner",
    description: "Label, Meal title",
  },
  snacks: {
    id: "global.snacks",
    defaultMessage: "Snacks",
    description: "Label, Meal title",
  },
  preworkout: {
    id: "global.preworkout",
    defaultMessage: "Pre-Workout",
    description: "Label, Meal title",
  },
  postworkout: {
    id: "global.postworkout",
    defaultMessage: "Post-Workout",
    description: "Label, Meal title",
  },
  date: {
    id: "global.date",
    defaultMessage: "Date",
    description: "Label, A day in the calendar",
  },
  meal: {
    id: "global.meal",
    defaultMessage: "Meal",
    description: "Label, example, 'Breakfast', 'Lunch', 'Dinner'.",
  },
  quantity: {
    id: "global.quantity",
    defaultMessage: "Quantity",
    description: "Label, count of items",
  },
  fiber: {
    id: "global.fiber",
    defaultMessage: "Fiber",
    description: "Label",
  },
  fat: {
    id: "global.fat",
    defaultMessage: "Fat",
    description: "Label",
  },
  saturatedFat: {
    id: "global.saturatedFat",
    defaultMessage: "Saturated Fat",
    description: "Label",
  },
  saturatedFatShort: {
    id: "global.saturatedFatShort",
    defaultMessage: "Sat. Fat",
    description: "Label",
  },
  energyKcal: {
    id: "global.energyKcal",
    defaultMessage: "Energy (kcal)",
    description: "Label, kilocalorie unit",
  },
  energyKj: {
    id: "global.energyKj",
    defaultMessage: "Energy (kj)",
    description: "Label, kilojoule unit",
  },
  kcal: {
    id: "global.kcal",
    defaultMessage: "kcal",
    description: "Label, kilocalorie",
  },
  kj: {
    id: "global.kj",
    defaultMessage: "kj",
    description: "Label, kilojoule",
  },
  calories: {
    id: "global.calories",
    defaultMessage: "Calories",
    description: "Label",
  },
  carbohydrate: {
    id: "global.carbohydrate",
    defaultMessage: "Carbohydrate",
    description: "Label",
  },
  carbohydrateShort: {
    id: "global.carbohydrateShort",
    defaultMessage: "Carb.",
    description: "Label",
  },
  protein: {
    id: "global.protein",
    defaultMessage: "Protein",
    description: "Label",
  },
  salt: {
    id: "global.salt",
    defaultMessage: "Salt",
    description: "Label",
  },
  sugar: {
    id: "global.sugar",
    defaultMessage: "Sugar",
    description: "Label",
  },
  weight: {
    id: "global.weight",
    defaultMessage: "Weight",
    description: "Label",
  },
  fatMass: {
    id: "global.fatMass",
    defaultMessage: "Fat Mass",
    description: "Label",
  },
  unknown: {
    id: "global.unknown",
    defaultMessage: "Unknown",
    description: "Label",
  },
  goal: {
    id: "global.goal",
    defaultMessage: "Goal",
    description: "Label",
  },
  remaining: {
    id: "global.remaining",
    defaultMessage: "Remaining",
    description: "Label",
  },
  dailyGoal: {
    id: "global.dailyGoal",
    defaultMessage: "Daily Goal",
    description: "Label",
  },
  macroSplitGoal: {
    id: "global.macroSplitGoal",
    defaultMessage: "Macro Split Goal",
    description: "Label",
  },
  item: {
    id: "global.item",
    defaultMessage: "Item",
    description: "Label",
  },
  totalInPlan: {
    id: "global.totalInPlan",
    defaultMessage: "Total In Plan",
    description: "Label",
  },
  total: {
    id: "global.total",
    defaultMessage: "Total",
    description: "Label",
  },
  completion: {
    id: "global.completion",
    defaultMessage: "Completion",
    description: "Label",
  },
  name: {
    id: "global.name",
    defaultMessage: "Name",
    description: "Label",
  },
  notes: {
    id: "global.notes",
    defaultMessage: "Notes",
    description: "Label",
  },
  notesPlaceholder: {
    id: "global.notesPlaceholder",
    defaultMessage: "Write any notes here…",
    description: "Placeholder description",
  },
  password: {
    id: "global.password",
    defaultMessage: "Password",
    description: "Label",
  },
  email: {
    id: "global.email",
    defaultMessage: "Email",
    description: "Label",
  },
  message: {
    id: "global.message",
    defaultMessage: "Message",
    description: "Label",
  },
  brand: {
    id: "global.brand",
    defaultMessage: "Brand",
    description: "Label",
  },
  description: {
    id: "global.description",
    defaultMessage: "Description",
    description: "Label",
  },
  packSizeGrams: {
    id: "global.packSizeGrams",
    defaultMessage: "Pack Size ({unit})",
    description: "Label",
  },
  individualSizeGrams: {
    id: "global.individualSizeGrams",
    defaultMessage: "Individual Size ({unit})",
    description: "Label",
  },
  options: {
    id: "global.options",
    defaultMessage: "Options",
    description: "Section Heading",
  },
  containerName: {
    id: "global.containerName",
    defaultMessage: "Container Name",
    description: "Field Label",
  },
  macrosPer100g: {
    id: "global.macrosPer100g",
    defaultMessage: "Macros per 100g",
    description: "Section Heading",
  },
  macrosPer100ml: {
    id: "global.macrosPer100ml",
    defaultMessage: "Macros per 100ml",
    description: "Section Heading",
  },
  macrosPerServing: {
    id: "global.macrosPerServing",
    defaultMessage: "Macros per Serving",
    description: "Section Heading",
  },
  details: {
    id: "global.details",
    defaultMessage: "Details",
    description: "Section Heading",
  },
  servingSizeGrams: {
    id: "global.servingSizeGrams",
    defaultMessage: "Serving Size ({unit})",
    description: "Field Label",
  },
  servingSizeUnits: {
    id: "global.servingSizeUnits",
    defaultMessage: "Serving Size (units)",
    description: "Field Label",
  },
  servingHasUnits: {
    id: "global.servingHasUnits",
    defaultMessage: "Has Units",
    description: "True/False Checkbox Label",
  },
  defaultPlan: {
    id: "global.defaultPlan",
    defaultMessage: "Default Plan",
    description: "True/False Checkbox Label",
  },
  yesterday: {
    id: "global.yesterday",
    defaultMessage: "Yesterday",
    description: "Selection Choice",
  },
  today: {
    id: "global.today",
    defaultMessage: "Today",
    description: "Selection Choice",
  },
  tomorrow: {
    id: "global.tomorrow",
    defaultMessage: "Tomorrow",
    description: "Selection Choice",
  },
  custom: {
    id: "global.custom",
    defaultMessage: "Custom",
    description: "Selection Choice",
  },
  everyday: {
    id: "global.everyday",
    defaultMessage: "Everyday",
    description: "Selection Choice",
  },
  weekdays: {
    id: "global.weekdays",
    defaultMessage: "Weekdays",
    description: "Selection Choice",
  },
  makesXPortions: {
    id: "global.makesXPortions",
    defaultMessage: "Makes {numberOfServings} x {gramsPerServing}g servings",
    description: "Statement, indicator of number of servings in a meal",
  },
  addIngredientsIndividually: {
    id: "global.addIngredientsIndividually",
    defaultMessage: "Add Ingredients Individually",
    description: "Checkbox Option",
  },
  ml: {
    id: "global.ml",
    defaultMessage: "ml",
    description: "Unit of measure",
  },
  created: {
    id: "global.created",
    defaultMessage: "Created",
    description: "Item label",
  },
  lastUpdated: {
    id: "global.lastUpdated",
    defaultMessage: "Last Updated",
    description: "Item label",
  },
  lastLoadAttempted: {
    id: "global.lastLoadAttempted",
    defaultMessage: "Last Load Attempted",
    description: "Item label",
  },
  itemCount: {
    id: "global.itemCount",
    defaultMessage: "{itemCount, number} {itemCount, plural, one{item} other{items}}",
    description: "Label",
  },
  dateCreated: {
    id: "global.dateCreated",
    defaultMessage: "Date Created",
    description: "Label",
  },
  dateUpdated: {
    id: "global.dateUpdated",
    defaultMessage: "Date Updated",
    description: "Label",
  },
});

export default Global;
