import { styled } from "@mui/material/styles";
import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export function PercentageScaleColorByMacro(value, macro, onlyNegative) {
  if (value === null || value === undefined || value === false) {
    return null;
  }

  if (macro === "sugars" || macro === "salt") {
    if (value >= 115) {
      return {
        color: "error",
        direction: "cross",
      };
    }
    if (value >= 100) {
      return {
        color: "error",
        direction: "up",
      };
    }

    if (value >= 95) {
      return {
        color: "warning",
        direction: "check",
      };
    }

    if (onlyNegative) {
      return null;
    }

    if (value >= 80) {
      return {
        color: "success",
        direction: "check",
      };
    }

    if (value >= 75) {
      return {
        color: "success",
        direction: "down",
      };
    }

    return null;
  }

  if (value >= 115) {
    return {
      color: "error",
      direction: "up",
    };
  }

  if (value >= 105) {
    return {
      color: "warning",
      direction: "up",
    };
  }

  if (value >= 95) {
    return {
      color: "success",
      direction: "check",
    };
  }

  if (value >= 85) {
    return {
      color: "warning",
      direction: "down",
    };
  }

  if (onlyNegative) {
    return null;
  }

  if (value >= 0 && value < 90) {
    return {
      color: "error",
      direction: "down",
    };
  }
}

export function ArrowIcon({ color, direction }) {
  if (direction === "up") {
    return <ArrowDropUpIcon fontSize="small" color={color} />;
  }
  if (direction === "down") {
    return <ArrowDropDownIcon fontSize="small" color={color} />;
  }
  if (direction === "cross") {
    return <CloseIcon fontSize="small" color={color} />;
  }
  if (direction === "check") {
    return <CheckIcon fontSize="small" color={color} />;
  }
  return null;
}
export function PercentageArrowIcon({ value, macro }) {
  const result = PercentageScaleColorByMacro(value, macro);

  if (!result) {
    return null;
  }

  return <ArrowIcon color={result.color} direction={result.direction} />;
}

const IndicatorBody = styled("div", {
  name: "MmpIndicator",
  slot: "body",
})({
  display: "inline-flex",
  alignItems: "center",
});

export function UpArrowIndicator({ label, color }) {
  return (
    <IndicatorBody>
      {label}
      <ArrowDropUpIcon fontSize="small" color={color || "error"} />
    </IndicatorBody>
  );
}

export function DownArrowIndicator({ label, color }) {
  return (
    <IndicatorBody>
      {label}
      <ArrowDropDownIcon fontSize="small" color={color || "error"} />
    </IndicatorBody>
  );
}

export default function ArrowIndicator({ value, label, macro }) {
  const icon = <PercentageArrowIcon value={value} macro={macro} />;

  if (label) {
    return (
      <IndicatorBody>
        {label}
        {icon}
      </IndicatorBody>
    );
  }

  return icon;
}
