import { createTheme } from "@mui/material/styles";
import noirProRegularWoff2 from "../../css/NoirPro-Regular.woff2";
import noirProSemiBoldWoff2 from "../../css/NoirPro-SemiBold.woff2";

export function theme(palette) {
  const printTextBlack = {
    "@media print": {
      color: "black",
    },
  };
  const printHidden = {
    "@media print": {
      display: "none",
    },
  };
  const noPrintShadow = {
    "@media print": {
      boxShadow: "none",
    },
  };

  const fontFamily = [
    "NoirPro",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
  ].join(",");

  return {
    palette,
    typography: {
      fontFamily,
      h1: {
        fontWeight: 600,
        fontSize: "2rem",
      },
      h5: {
        fontSize: "16px",
        fontWeight: 600,
      },
      h6: {
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "uppercase",
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            transition: "none",

            "& button, & a, & .MuiButton-root, & .MuiLink-root, & input": {
              appRegion: "no-drag",
              WebkitAppRegion: "no-drag",
              userSelect: "none",
            },
          },
        },
      },
      MuiSvgIcon: {
        defaultProps: {
          fontSize: "small",
        },
      },
      MuiList: {
        defaultProps: {
          dense: true,
        },
      },
      MuiTextField: {
        defaultProps: {
          size: "small",
          margin: "dense",
          autoComplete: "off",
          slotProps: {
            inputLabel: {
              shrink: true,
            },
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: "16px",
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: NoirPro;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Noir Pro Regular'), local('Noir Pro Regular'), url(${noirProRegularWoff2}) format('woff2');
        }
        @font-face {
          font-family: NoirPro;
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Noir Pro SemiBold'), local('Noir Pro SemiBold'), url(${noirProSemiBoldWoff2}) format('woff2');
        }
        html,body {
             overscroll-behavior-y: none;
        },
        html {
             -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        :root {
          --brand-accent: #fa8c16;
        }
        `,
        // "@global": {
        //   "@font-face": [noirProRegular, noirProSemiBold],
        //   html: {
        //     WebkitFontSmoothing: "auto",
        //     WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
        //   },
        //   body: {
        //     margin: 0,
        //     padding: 0,
        //     WebkitTextSizeAdjust: "100%",
        //     fontSize: "1rem",
        //     backgroundColor: palette.background.default,
        //   },
        //   "body,button,input,textarea,select": {
        //     fontFamily,
        //     WebkitFontSmoothing: "antialiased",
        //     MozOsxFontSmoothing: "grayscale",
        //   },
        //   "input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button": {
        //     WebkitAppearance: "none",
        //     margin: 0,
        //   },
        //   "@media print": {
        //     body: {
        //       padding: "20px",
        //       overflow: "visible",
        //       color: "black",
        //     },
        //     a: {
        //       color: "black",
        //     },
        //     button: {
        //       display: "none",
        //     },
        //   },
        // },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
            "@media print": {
              backgroundColor: "transparent",
              color: "black",
            },
          },
          rounded: {
            borderRadius: "10px",
          },
          elevation0: {
            boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            ...noPrintShadow,
          },
          elevation1: {
            boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            ...noPrintShadow,
          },
          elevation2: noPrintShadow,
          elevation3: noPrintShadow,
          elevation4: noPrintShadow,
        },
      },
      MuiTypography: {
        styleOverrides: {
          colorPrimary: printTextBlack,
          colorTextSecondary: printTextBlack,
          colorInherit: printTextBlack,
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(145, 158, 171, 0.32)",
            },
            "&.Mui-disabled": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(145, 158, 171, 0.24)",
              },
            },
          },
          input: {
            "&:-webkit-autofill": {
              WebkitBoxShadow: `0 0 0 100px ${palette.background.paper} inset`,
            },
            "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: printHidden,
        },
        defaultProps: {
          size: "small",
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: { ...printTextBlack, fontSize: "0.875rem" },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: printTextBlack,
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: printTextBlack,
          root: {
            borderBottom: `1px solid ${palette.background.default}`,
            fontSize: "1rem",
          },
          footer: {
            fontSize: "1rem",
          },
          head: {
            ...printTextBlack,
            backgroundColor: palette.background.heading,
            borderBottom: `1px solid ${palette.background.default}`,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: palette.action.selected,
              "&:hover": {
                backgroundColor: palette.action.hover,
              },
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: printTextBlack,
          outlinedPrimary: {
            "@media print": {
              color: "black",
              border: "solid 1px black",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: printHidden,
        },
      },
      MuiTooltip: {
        defaultProps: {
          arrow: true,
        },
        styleOverrides: {
          tooltip: {
            backgroundColor: "#454F5B",
          },
          arrow: {
            color: "#454F5B",
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            ...printTextBlack,
            "&.Mui-active": printTextBlack,
          },
        },
      },
    },
  };
}

export function darkerPalette() {
  return {
    mode: "dark",
    primary: {
      name: "orange",
      lighter: "#FEF4D4",
      light: "#FED680",
      main: "#F56E0F",
      dark: "#B66816",
      darker: "#793908",
      contrastText: "#212B36",
    },
    secondary: {
      lighter: "#D6E4FF",
      light: "#84A9FF",
      main: "#3366FF",
      dark: "#1939B7",
      darker: "#091A7A",
      contrastText: "#fff",
    },
    info: {
      lighter: "#D0F2FF",
      light: "#74CAFF",
      main: "#1890FF",
      dark: "#0C53B7",
      darker: "#04297A",
      contrastText: "#fff",
    },
    success: {
      lighter: "#E9FCD4",
      light: "#AAF27F",
      main: "#54D62C",
      dark: "#229A16",
      darker: "#08660D",
      contrastText: "#212B36",
    },
    warning: {
      lighter: "#FFF7CD",
      light: "#FFE16A",
      main: "#FFC107",
      dark: "#B78103",
      darker: "#7A4F01",
      contrastText: "#212B36",
    },
    error: {
      lighter: "#FFE7D9",
      light: "#FFA48D",
      main: "#FF4842",
      dark: "#B72136",
      darker: "#7A0C2E",
      contrastText: "#fff",
    },
    grey: {
      0: "#FFFFFF",
      50: "#fafafa",
      100: "#F9FAFB",
      200: "#F4F6F8",
      300: "#DFE3E8",
      400: "#C4CDD5",
      500: "#919EAB",
      600: "#637381",
      700: "#454F5B",
      800: "#212B36",
      900: "#161C24",
      5008: "rgba(145, 158, 171, 0.08)",
      50012: "rgba(145, 158, 171, 0.12)",
      50016: "rgba(145, 158, 171, 0.16)",
      50024: "rgba(145, 158, 171, 0.24)",
      50032: "rgba(145, 158, 171, 0.32)",
      50048: "rgba(145, 158, 171, 0.48)",
      50056: "rgba(145, 158, 171, 0.56)",
      50080: "rgba(145, 158, 171, 0.8)",
      A100: "#f5f5f5",
      A200: "#eeeeee",
      A400: "#bdbdbd",
      A700: "#616161",
    },
    gradients: {
      primary: "linear-gradient(to bottom, #5BE584, #00AB55)",
      info: "linear-gradient(to bottom, #74CAFF, #1890FF)",
      success: "linear-gradient(to bottom, #AAF27F, #54D62C)",
      warning: "linear-gradient(to bottom, #FFE16A, #FFC107)",
      error: "linear-gradient(to bottom, #FFA48D, #FF4842)",
    },
    food: {
      calories: "#13c2c2",
      sugars: "#B72136",
      protein: "#826AF9",
      carbohydrate: "#fa8c16",
      salt: "#3366FF",
      fat: "#eb2f96",
    },
    chart: {
      violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
      blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
      green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
      yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
      red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
    },
    divider: "rgba(145, 158, 171, 0.24)",
    action: {
      active: "#FBFBFB",
      hover: "rgba(145, 158, 171, 0.08)",
      selected: "#262626", // "rgba(145, 158, 171, 0.16)",
      disabled: "rgba(145, 158, 171, 0.8)",
      disabledBackground: "rgba(145, 158, 171, 0.24)",
      focus: "rgba(145, 158, 171, 0.24)",
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
      selectedOpacity: 0.16,
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    text: {
      primary: "#fff",
      secondary: "#FBFBFB",
      disabled: "#878787",
      icon: "#FBFBFB", // "rgba(255, 255, 255, 0.5)",
    },
    background: {
      paper: "#1B1B1E", // the card background
      default: "#0B0A0D", // the page background
      neutral: "rgba(145, 158, 171, 0.16)", // unknown
    },

    // The contrastText token is calculated using the contrastThreshold value,
    // to maximize the contrast between the background and the text.
    contrastThreshold: 3,

    // The light and dark tokens are calculated using the tonalOffset value,
    // to shift the main color's luminance. A higher tonal offset value will
    // make light tokens lighter, and dark tokens darker.
    tonalOffset: 0.2,
  };
}

export default function darkTheme() {
  return createTheme(theme(darkerPalette()));
}
