import { connect } from "react-redux";
import Search from "./Search.js";

const mapStateToProps = (state, ownProps) => {
  const values = new URLSearchParams(ownProps.location.search);
  const searchTerm = (values.get("q") || "").trim()?.substring(0, 100);
  let ingredientResults = [];

  if (searchTerm.length >= 1) {
    const searchMatch = new RegExp(searchTerm, "i");
    ingredientResults = state.ingredients.ingredients.filter((ingredient) =>
      `${ingredient.name || ""} ${ingredient.brand || ""}`.match(searchMatch),
    );
  }

  return {
    ingredients: ingredientResults,
    searchTerm,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
