import { defineMessages } from "react-intl";

export const Home = defineMessages({
  itemsEaten: {
    id: "home.itemsEaten",
    defaultMessage: "Items Eaten",
    description: "Label",
  },
  navigation: {
    id: "home.navigation",
    defaultMessage: "Navigation",
    description: "Section Heading",
  },
  noItemsLogged: {
    id: "home.noItemsLogged",
    defaultMessage: "No foods have been recorded or planned.",
    description: "A feedback message.",
  },
  logNow: {
    id: "home.logNow",
    defaultMessage: "Log a food now",
    description: "Button Text",
  },
  xOfY: {
    id: "home.xOfY",
    defaultMessage: "{x} of {y}",
    description: "Status message, as in '2 of 6'",
  },
  weightAtX: {
    id: "home.weightAtX",
    defaultMessage: "Weight at {timeOfEntry}",
    description: "Status message, as in 'Weight at 10:12am'",
  },
  welcomeMessage: {
    id: "home.welcomeMessage",
    defaultMessage: "Hi, I'm Sam! This is your dashboard, where you can view the days planned foods and macros.",
    description: "Greeting message for new users.",
  },
  requestConsultation: {
    id: "home.requestConsultation",
    defaultMessage:
      "I'd love to hear your feedback about my app, so I invite you {name} to a video call if you have a few spare minutes.",
    description: "Greeting message for new users.",
  },
  sayHello: {
    id: "home.sayHello",
    defaultMessage: "Say Hello!",
    description: "Greeting message button for new users.",
  },
});

export default Home;
