import { defineMessages } from "react-intl";

const Signin = defineMessages({
  title: {
    id: "signin.title",
    defaultMessage: "Sign In",
    description: "Page Title",
  },
  heading: {
    id: "signin.heading",
    defaultMessage: "Welcome to MacroMealPrep",
    description: "Section Title Heading",
  },
  heroTopSub: {
    id: "signin.heroTopSub",
    defaultMessage: "{number} calories counted",
    description: "Title Heading",
  },
  hero1: {
    id: "signin.hero1",
    defaultMessage: "Build your body by planning the foods you eat",
    description: "Title Heading",
  },
  hero2: {
    id: "signin.hero2",
    defaultMessage: "Planning your daily nutrient intake is the first steps on the path to build the body you want",
    description: "Title Heading",
  },
  ctaPoint1: {
    id: "signin.ctaPoint1",
    defaultMessage: "Free + No Card Required",
    description: "CTA 1",
  },
  ctaPoint2: {
    id: "signin.ctaPoint2",
    defaultMessage: "Privacy, No Spam",
    description: "CTA 2",
  },
  ctaPoint3: {
    id: "signin.ctaPoint3",
    defaultMessage: "673 people joined last week",
    description: "CTA 3",
  },
  description: {
    id: "signin.description",
    defaultMessage:
      "MacroMealPrep is a modern and sleek web app built to help you achieve your goals. Get started instantly just by entering your name.",
    description: "Guidance text",
  },
  otherLanguages: {
    id: "signin.otherLanguages",
    defaultMessage: "Other languages are available:",
    description: "Guidance text",
  },
  emailRequired: {
    id: "signin.emailRequired",
    defaultMessage: "Enter your email address",
    description: "Guidance text",
  },
  passwordRequired: {
    id: "signin.passwordRequired",
    defaultMessage: "Enter your password",
    description: "Guidance text",
  },
  incorrectPassword: {
    id: "signin.incorrectPassword",
    defaultMessage: "Incorrect password",
    description: "Guidance text",
  },
  verificationFailed: {
    id: "signin.verificationFailed",
    defaultMessage: "Could not complete verification due to a technical error, please try again.",
    description: "Guidance text",
  },
  noAccountExists: {
    id: "signin.noAccountExists",
    defaultMessage: "No such account exists",
    description: "Guidance text",
  },
  emailNotValid: {
    id: "signin.emailNotValid",
    defaultMessage: "Email address is not valid",
    description: "Guidance text",
  },
  requestPasswordLinkSentSuccess: {
    id: "signin.requestPasswordLinkSentSuccess",
    defaultMessage: "A password reset link has been sent to your email address",
    description: "Guidance text",
  },
  requestPasswordLinkSentRecently: {
    id: "signin.requestPasswordLinkSentRecently",
    defaultMessage:
      "We recently send a password reset link to your email address. Please wait a few minutes before attempting to send it again.",
    description: "Guidance text",
  },
  passwordLinkExpired: {
    id: "signin.passwordLinkExpired",
    defaultMessage: "Your recovery link has expired, please request a new one.",
    description: "Guidance text",
  },
  verificationLinkExpired: {
    id: "signin.verificationLinkExpired",
    defaultMessage: "Your verification link has expired, please request a new one.",
    description: "Guidance text",
  },
  verificationComplete: {
    id: "signin.verificationComplete",
    defaultMessage: "Your account has been successfully verified.",
    description: "Guidance text",
  },
  verificationChecking: {
    id: "signin.verificationChecking",
    defaultMessage: "Checking your email, one moment.",
    description: "Guidance text",
  },
});

export default Signin;
