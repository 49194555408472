import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Card from "../Card/Card.js";
import GlobalMessages from "../messages/Global.js";
import parseNumericInput from "../../lib/parseNumericInput.js";
import getEditableNutritionalProfile, {
  editableProfileToNutritionalProfile,
} from "../../lib/getEditableNutritionalProfile.js";

export default function MacroGoal({ goal, onChange, onlyForm }) {
  const editableGoal = getEditableNutritionalProfile(goal);
  const { formatMessage: t } = useIntl();
  const [protein, setProtein] = useState(editableGoal.protein);
  const [fat, setFat] = useState(editableGoal.fat);
  const [carbohydrate, setCarbohydrate] = useState(editableGoal.carbohydrate);
  const [calories, setCalories] = useState(editableGoal.calories);
  const [sugars, setSugars] = useState(editableGoal.sugars);
  const [salt, setSalt] = useState(editableGoal.salt);

  const proteinChangeHandler = (event) => {
    const parsedValue = parseNumericInput(event.target.value, false);
    setProtein(parsedValue);
  };

  const carbohydrateChangeHandler = (event) => {
    const parsedValue = parseNumericInput(event.target.value, false);
    setCarbohydrate(parsedValue);
  };

  const fatChangeHandler = (event) => {
    const parsedValue = parseNumericInput(event.target.value, false);
    setFat(parsedValue);
  };

  const calorieChangeHandler = (event) => {
    const parsedValue = parseNumericInput(event.target.value, true);
    setCalories(parsedValue);
  };

  const sugarsChangeHandler = (event) => {
    const parsedValue = parseNumericInput(event.target.value, false);
    setSugars(parsedValue);
  };

  const saltChangeHandler = (event) => {
    const parsedValue = parseNumericInput(event.target.value, false);
    setSalt(parsedValue);
  };

  useEffect(() => {
    const valueToSave = editableProfileToNutritionalProfile({
      ...editableGoal,
      protein,
      fat,
      carbohydrate,
      calories,
      sugars,
      salt,
    });

    const { energy, saturatedFat, ...remainingProfile } = valueToSave;
    onChange(remainingProfile);
  }, [protein, fat, carbohydrate, calories, sugars, salt]);

  const content = (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextField
          value={calories.value}
          type="number"
          onChange={calorieChangeHandler}
          variant="outlined"
          error={!calories.valid || calories.empty}
          label={t(GlobalMessages.calories)}
          min={0}
          max={5000}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={protein.value}
          type="number"
          onChange={proteinChangeHandler}
          variant="outlined"
          error={!protein.valid || protein.empty}
          label={t(GlobalMessages.protein)}
          min={0}
          max={500}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          value={fat.value}
          type="number"
          onChange={fatChangeHandler}
          variant="outlined"
          error={!fat.valid || fat.empty}
          label={t(GlobalMessages.fat)}
          min={0}
          max={500}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          value={sugars.value}
          type="number"
          onChange={sugarsChangeHandler}
          variant="outlined"
          error={!sugars.valid || sugars.empty}
          label={t(GlobalMessages.sugar)}
          min={0}
          max={500}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          value={carbohydrate.value}
          type="number"
          onChange={carbohydrateChangeHandler}
          variant="outlined"
          error={!carbohydrate.valid || carbohydrate.empty}
          label={t(GlobalMessages.carbohydrate)}
          min={0}
          max={900}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          value={salt.value}
          type="number"
          onChange={saltChangeHandler}
          variant="outlined"
          error={!salt.valid || salt.empty}
          label={t(GlobalMessages.salt)}
          min={0}
          max={50}
          step={0.01}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );

  if (onlyForm) {
    return content;
  }

  return <Card title={t(GlobalMessages.dailyGoal)}>{content}</Card>;
}
