import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "../Card/Card.js";
import GlobalMessages from "../messages/Global.js";
import parseNumericInput from "../../lib/parseNumericInput.js";

export default function MacroSplitGoal({ goalSplit, onChange }) {
  const { formatMessage: t } = useIntl();
  const [protein, setProtein] = useState({
    empty: false,
    value: `${goalSplit.protein}`,
    number: goalSplit.protein,
    valid: true,
  });
  const [fat, setFat] = useState({ empty: false, value: `${goalSplit.fat}`, number: goalSplit.fat, valid: true });
  const [carbohydrate, setCarbohydrate] = useState({
    empty: false,
    value: `${goalSplit.carbohydrate}`,
    number: goalSplit.carbohydrate,
    valid: true,
  });

  const proteinValue = protein.number;
  const fatValue = fat.number;
  const carbohydrateValue = carbohydrate.number;

  const total = (proteinValue || 0) + (fatValue || 0) + (carbohydrateValue || 0);

  const hasFormError = total !== 100 || !protein.valid || !fat.valid || !carbohydrate.valid;

  const proteinChangeHandler = (event) => {
    const parsedValue = parseNumericInput(event.target.value, true);
    setProtein(parsedValue);
  };

  const carbohydrateChangeHandler = (event) => {
    const parsedValue = parseNumericInput(event.target.value, true);
    setCarbohydrate(parsedValue);
  };

  const fatChangeHandler = (event) => {
    const parsedValue = parseNumericInput(event.target.value, true);
    setFat(parsedValue);
  };

  useEffect(() => {
    if (hasFormError) return;
    onChange({
      protein: proteinValue,
      fat: fatValue,
      carbohydrate: carbohydrateValue,
    });
  }, [hasFormError, proteinValue, fatValue, carbohydrateValue]);

  return (
    <Card title={t(GlobalMessages.macroSplitGoal)}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            value={proteinValue}
            type="number"
            onChange={proteinChangeHandler}
            variant="outlined"
            error={!protein.valid}
            label={t(GlobalMessages.protein)}
            min={0}
            max={100}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={fatValue}
            type="number"
            onChange={fatChangeHandler}
            variant="outlined"
            error={!fat.valid}
            label={t(GlobalMessages.fat)}
            min={0}
            max={100}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={carbohydrateValue}
            type="number"
            onChange={carbohydrateChangeHandler}
            variant="outlined"
            error={!carbohydrate.valid}
            label={t(GlobalMessages.carbohydrate)}
            min={0}
            max={100}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={`${total}%`}
            onChange={() => {}}
            variant="outlined"
            disabled
            error={hasFormError}
            label={t(GlobalMessages.total)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
