import { addDays, format, isAfter, isEqual, parse } from "date-fns";
import React, { useState, useMemo, useContext } from "react";
import { useIntl } from "react-intl";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import SessionContext from "../../contexts/Session/SessionContext.js";
import getTimeOfDay from "../../data/getTimeOfDay.js";
import AddToPlanDialog from "../Calendar/AddToPlanDialog.js";
import getDaysToDisplay from "../Calendar/getDaysToDisplay.js";
import ActionMessages from "../messages/Actions.js";
import AppMessages from "../messages/App.js";
import GlobalMessages from "../messages/Global.js";
import useCreateLogEntry from "./useCreateLogEntry.js";

function defaultLogEntryDateIsPresetOption(defaultLogEntryDate) {
  if (!defaultLogEntryDate) return "today";
  if (defaultLogEntryDate === "today" || defaultLogEntryDate === "tomorrow" || defaultLogEntryDate === "yesterday") {
    return defaultLogEntryDate;
  }
  return "custom";
}

export default function AddToCalendarDialog({ open, onClose, ingredient, date, time: preSelectedTime }) {
  const { formatMessage: t } = useIntl();
  const isFixedDate = Boolean(date);
  const { locale, longDateFormat } = useContext(LanguageContext);
  const { defaultLogEntryDate, setDefaultLogEntryDate, calendarStartDate, startDayThisWeek, startDayNextWeek } =
    useContext(SessionContext);
  const createLogEntryService = useCreateLogEntry();

  const now = useMemo(() => new Date(), []);

  const activeCalendarStartDate = parse(calendarStartDate, "yyyy/M/d", new Date()); // calendarStartDate is string
  const activeCalendarInFuture =
    isAfter(activeCalendarStartDate, now) &&
    !isEqual(activeCalendarStartDate, startDayNextWeek) &&
    !isEqual(activeCalendarStartDate, startDayThisWeek);

  const [logEntryDate, setLogEntryDate] = useState(isFixedDate ? date : now); // TODO: grab this from a session config

  const sessionLogEntryDate = defaultLogEntryDateIsPresetOption(defaultLogEntryDate);
  const [selectedDateOption, setSelectedDateOption] = useState(sessionLogEntryDate);
  const isPlanBasedDateRange =
    selectedDateOption === "plan-weekday" || // The currently viewed calendar week
    selectedDateOption === "plan-everyday" ||
    selectedDateOption === "nextweek-weekday" || // Fixed date for next week
    selectedDateOption === "nextweek-everyday" ||
    selectedDateOption === "thisweek-weekday" || // Fixed date for this week
    selectedDateOption === "thisweek-everyday";
  if (sessionLogEntryDate === "custom") {
    // TODO: Reload the session date (from somewhere)
    // startDate = parse(defaultLogEntryDate, pickerFormat, new Date());
  }

  const isFuture = isAfter(logEntryDate, now);

  const time = preSelectedTime || getTimeOfDay(logEntryDate);
  const createLogEntry = (newEntry, ingredientAdded) => {
    const buildLogEntry = (entryDate) => {
      const result = {
        ...newEntry,
        date: entryDate || logEntryDate,
      };

      result.consumed = result.date ? !isFuture && !isPlanBasedDateRange : false;

      return result;
    };
    if (selectedDateOption === "custom") {
      setDefaultLogEntryDate(logEntryDate);
    }

    if (isPlanBasedDateRange) {
      if (selectedDateOption === "plan-everyday") {
        const days = getDaysToDisplay(7, activeCalendarStartDate).map((day) => buildLogEntry(day.date));
        createLogEntryService(days, ingredientAdded, t);
      } else if (selectedDateOption === "plan-weekday") {
        const days = getDaysToDisplay(5, activeCalendarStartDate).map((day) => buildLogEntry(day.date));
        createLogEntryService(days, ingredientAdded, t);
      } else if (selectedDateOption === "thisweek-everyday") {
        const days = getDaysToDisplay(7, startDayThisWeek).map((day) => buildLogEntry(day.date));
        createLogEntryService(days, ingredientAdded, t);
      } else if (selectedDateOption === "thisweek-weekday") {
        const days = getDaysToDisplay(5, startDayThisWeek).map((day) => buildLogEntry(day.date));
        createLogEntryService(days, ingredientAdded, t);
      } else if (selectedDateOption === "nextweek-everyday") {
        const days = getDaysToDisplay(7, startDayNextWeek).map((day) => buildLogEntry(day.date));
        createLogEntryService(days, ingredientAdded, t);
      } else if (selectedDateOption === "nextweek-weekday") {
        const days = getDaysToDisplay(5, startDayNextWeek).map((day) => buildLogEntry(day.date));
        createLogEntryService(days, ingredientAdded, t);
      }
    } else {
      // Create just one entry
      createLogEntryService([buildLogEntry()], ingredientAdded, t);
    }
    onClose();
  };

  const calculatePreSetDays = () => {
    if (isFixedDate) return null;
    const result = [
      { id: "yesterday", label: t(GlobalMessages.yesterday) },
      { id: "today", label: t(GlobalMessages.today) },
      { id: "tomorrow", label: t(GlobalMessages.tomorrow) },
    ];

    if (selectedDateOption === "custom") {
      result.push({
        id: "custom",
        label: t(GlobalMessages.custom),
      });
    }

    if (activeCalendarInFuture) {
      result.push({
        id: "plan-everyday",
        label: `${t(GlobalMessages.everyday)}: ${format(activeCalendarStartDate, longDateFormat, {
          locale,
        })} - ${format(addDays(activeCalendarStartDate, 6), longDateFormat, { locale })}`,
      });
      result.push({
        id: "plan-weekday",
        label: `${t(GlobalMessages.weekdays)}: ${format(activeCalendarStartDate, longDateFormat, {
          locale,
        })} - ${format(addDays(activeCalendarStartDate, 4), longDateFormat, { locale })}`,
      });
    }

    result.push({
      id: "thisweek-everyday",
      label: `${t(GlobalMessages.everyday)} - ${t(AppMessages.thisWeek)}: ${format(startDayThisWeek, longDateFormat, {
        locale,
      })} - ${format(addDays(startDayThisWeek, 6), longDateFormat, { locale })}`,
    });

    result.push({
      id: "thisweek-weekday",
      label: `${t(GlobalMessages.weekdays)} - ${t(AppMessages.thisWeek)}: ${format(startDayThisWeek, longDateFormat, {
        locale,
      })} - ${format(addDays(startDayThisWeek, 4), longDateFormat, { locale })}`,
    });

    result.push({
      id: "nextweek-everyday",
      label: `${t(GlobalMessages.everyday)} - ${t(AppMessages.nextWeek)}: ${format(startDayNextWeek, longDateFormat, {
        locale,
      })} - ${format(addDays(startDayNextWeek, 6), longDateFormat, { locale })}`,
    });

    result.push({
      id: "nextweek-weekday",
      label: `${t(GlobalMessages.weekdays)} - ${t(AppMessages.nextWeek)}: ${format(startDayNextWeek, longDateFormat, {
        locale,
      })} - ${format(addDays(startDayNextWeek, 4), longDateFormat, { locale })}`,
    });

    return result;
  };

  const preSetDays = calculatePreSetDays();

  const onLogEntryDateChange = (newLogEntryDate) => {
    setLogEntryDate(newLogEntryDate);
    setSelectedDateOption("custom");
  };

  const onPreSetDayChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedDateOption(selectedOption);

    const setPickerDate = (newDate) => setLogEntryDate(newDate);

    if (selectedOption === "today") {
      setPickerDate(now);
    } else if (selectedOption === "yesterday") {
      setPickerDate(addDays(now, -1));
    } else if (selectedOption === "tomorrow") {
      setPickerDate(addDays(now, 1));
    } else if (selectedOption === "plan-everyday" || selectedOption === "plan-weekday") {
      setPickerDate(activeCalendarStartDate);
    } else if (selectedOption === "thisweek-everyday" || selectedOption === "thisweek-weekday") {
      setPickerDate(startDayThisWeek);
    } else if (selectedOption === "nextweek-everyday" || selectedOption === "nextweek-weekday") {
      setPickerDate(startDayNextWeek);
    }
  };

  const saveButtonText = isFuture || isPlanBasedDateRange ? t(ActionMessages.addToPlan) : t(ActionMessages.addToDiary);
  return (
    <AddToPlanDialog
      createLogEntry={createLogEntry}
      open={open}
      onClose={onClose}
      ingredient={ingredient}
      time={time}
      logEntryDate={isFixedDate ? undefined : logEntryDate}
      onLogEntryDateChange={onLogEntryDateChange}
      saveButtonText={saveButtonText}
      disabledLogEntryDatePicker={isPlanBasedDateRange || isFixedDate}
      preSetDays={preSetDays}
      preSetDay={selectedDateOption}
      onPreSetDayChange={onPreSetDayChange}
    />
  );
}
