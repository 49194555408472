import { defineMessages } from "react-intl";

const Settings = defineMessages({
  withingsPrompt: {
    id: "settings.withingsPrompt",
    defaultMessage: "Connect your Withings Scales to sync your weight measurements automatically",
    description: "Guidance Text",
  },
  options: {
    id: "settings.options",
    defaultMessage: "Options",
    description: "Section Title",
  },
  account: {
    id: "settings.account",
    defaultMessage: "Account",
    description: "Section Title",
  },
  passkeys: {
    id: "settings.passkeys",
    defaultMessage: "Passkeys",
    description: "Section Title",
  },
  profile: {
    id: "settings.profile",
    defaultMessage: "Profile",
    description: "Section Title",
  },
  language: {
    id: "settings.language",
    defaultMessage: "Language",
    description: "Section Title",
  },
  english: {
    id: "settings.english",
    defaultMessage: "English",
    description: "Name of the 'English' language",
  },
  portuguese: {
    id: "settings.portuguese",
    defaultMessage: "Portuguese",
    description: "Name of the 'Portuguese' language",
  },
  portugueseBrazil: {
    id: "settings.portugueseBrazil",
    defaultMessage: "Portuguese (Brazil)",
    description: "Name of the 'Portuguese (Brazil)' language",
  },
  weekStartsOn: {
    id: "settings.weekStartsOn",
    defaultMessage: "Week Starts On",
    description: "Section Title",
  },
  weekStartsOnInfo: {
    id: "settings.weekStartsOnInfo",
    defaultMessage: "Your meal plan runs through one week cycles, this is the day you prefer to start each new week.",
    description: "Section guidance text",
  },
  lastUsed: {
    id: "settings.lastUsed",
    defaultMessage: "Last Used",
    description: "Section guidance text",
  },
  lastUsedX: {
    id: "settings.lastUsedX",
    defaultMessage: "Last used {date}",
    description: "Section guidance text",
  },
  newPassword: {
    id: "settings.newPassword",
    defaultMessage: "New Password",
    description: "Field label",
  },
  confirmEmailVerify: {
    id: "settings.confirmEmailVerify",
    defaultMessage:
      "We'll send you an email that contains a link for you to click, that will verify your email address.",
    description: "Popup help text",
  },
  emailInUse: {
    id: "settings.emailInUse",
    defaultMessage: "Your changes were not saved as that email address is in use with another account.",
    description: "Popup error text",
  },
  errorSaving: {
    id: "settings.errorSaving",
    defaultMessage: "Your changes were not saved due to an error, please try again.",
    description: "Popup error text",
  },
  status: {
    id: "settings.status",
    defaultMessage: "Status",
    description: "Item label",
  },
  disconnected: {
    id: "settings.disconnected",
    defaultMessage: "Disconnected",
    description: "Item label",
  },
  never: {
    id: "settings.never",
    defaultMessage: "Never",
    description: "Item label",
  },
});

export default Settings;
