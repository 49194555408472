/* eslint-disable react/jsx-props-no-spreading */
import { Dialog, Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import GlobalMessages from "../messages/Global.js";
import ActionMessages from "../messages/Actions.js";
import parseNumericInput from "../../lib/parseNumericInput.js";
import { calculateGramsConsumed, calculateMacrosByGram } from "../../data/calculateMacros.js";
import useFoodList from "../Calendar/useFoodList.js";

export default function EditLogEntryModalContent({ editLogEntry, open, onClose, day, logEntry }) {
  const { formatMessage: t } = useIntl();
  const { findIngredientById } = useFoodList();
  const ingredient = findIngredientById(logEntry?.ingredientId);

  const [state, setState] = useState({
    quantity: {
      value: `1`,
      valid: true,
      number: 1,
      empty: false,
    },
    grams: {
      value: `100`,
      valid: true,
      number: 100,
      empty: false,
    },
  });

  useEffect(() => {
    if (!logEntry) return;
    setState({
      quantity: {
        value: logEntry.quantity?.toString() || "",
        valid: true,
        number: logEntry.quantity,
        empty: (logEntry.quantity?.toString() || "").length === 0,
      },
      grams: {
        value: logEntry.grams?.toString() || "",
        valid: true,
        number: logEntry.grams,
        empty: (logEntry.grams?.toString() || "").length === 0,
      },
    });
  }, [logEntry]);

  const editQuantity = (event) => {
    const parsedValue = parseNumericInput(event.target.value, true);

    if (parsedValue.empty) {
      setState({
        ...state,
        quantity: parsedValue,
      });
      return;
    }
    let newGrams = state.grams;

    if (parsedValue.valid) {
      let grams;

      if (ingredient) {
        grams = ingredient.individualServingSize.grams;
      }

      const gramsConsumed = calculateGramsConsumed(parsedValue.number, grams);
      newGrams = {
        valid: true,
        number: gramsConsumed,
        value: `${gramsConsumed}`,
      };
    }

    setState({
      ...state,
      quantity: parsedValue,
      grams: newGrams,
    });
  };
  const isBusy = false;
  const isValid = true;
  const editWeight = (event) => {
    const parsedValue = parseNumericInput(event.target.value, true);

    setState({ ...state, grams: parsedValue });
  };

  const isRecipe = Boolean(ingredient?.ingredients);

  const onSave = () => {
    if (!ingredient) return;

    const buildLogEntry = () => {
      const rawNutritionalProfile = ingredient.nutritionalProfile;

      const macrosConsumed = calculateMacrosByGram(rawNutritionalProfile, state.grams.number);

      const newEntry = {
        grams: state.grams.number,
        nutritionalProfile: macrosConsumed,
        time: logEntry.time,
        id: logEntry.id,
      };

      if (ingredient.hasUnits || isRecipe) {
        newEntry.quantity = state.quantity.empty ? 1 : state.quantity.number;
      }

      if (isRecipe) {
        newEntry.recipeId = ingredient.id;
      } else {
        newEntry.ingredientId = ingredient.id;
      }

      return newEntry;
    };

    editLogEntry(buildLogEntry(), day);
  };

  const showUnitPicker = ingredient?.hasUnits || isRecipe;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{t(ActionMessages.edit)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {showUnitPicker && (
            <Grid item xs={6}>
              <TextField
                label={t(GlobalMessages.quantity)}
                variant="outlined"
                type="number"
                min={1}
                step={1}
                max={100}
                maxLength={3}
                autoFocus
                value={state.quantity.value}
                onChange={editQuantity}
                placeholder="1"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}
          <Grid item xs={showUnitPicker ? 6 : 12}>
            <TextField
              label={t(ingredient?.isLiquid ? GlobalMessages.volumeMl : GlobalMessages.grams)}
              variant="outlined"
              min={0}
              autoFocus={!showUnitPicker}
              value={state.grams.value}
              onChange={editWeight}
              disabled={ingredient?.hasUnits || isRecipe || !ingredient}
              placeholder="100"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isBusy} onClick={onClose} color="primary" size="small">
          {t(ActionMessages.cancel)}
        </Button>
        <Button
          size="small"
          startIcon={<CheckIcon fontSize="small" />}
          disabled={!isValid || isBusy}
          onClick={onSave}
          color="primary"
          variant="contained"
        >
          {t(ActionMessages.edit)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
