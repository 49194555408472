export default async function setApi(url, payload, method = "POST") {
  let results;
  try {
    const request = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      method,
      body: JSON.stringify(payload, null, 0),
    });
    const contentType = request.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      results = await request.json();
    } else if (contentType && contentType.includes("text")) {
      results = await request.text();
    }
  } catch (err) {
    results = null;
  }
  return results;
}
