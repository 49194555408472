import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link as RouterLink } from "react-router";
import Badge from "@mui/material/Badge";
import SettingsIcon from "@mui/icons-material/Settings";
import messages from "../messages/App.js";
import ActionsMessages from "../messages/Actions.js";
import useDialog from "../../contexts/Dialog/useDialog.js";
import useAccount from "../../contexts/Account/useAccount.js";
import useAppInstallation from "../../contexts/AppInstallation/useAppInstallation.js";
import CheckForUpdatesMenuItem from "./CheckForUpdatesMenuItem.js";
import ExportDataMenuItem from "./ExportDataMenuItem.js";
import ImportDataMenuItem from "./ImportDataMenuItem.js";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
export default function AppSubMenuButton() {
  const { formatMessage: t } = useIntl();
  const { logout, account } = useAccount();
  const { installState, skipWait, desktopInstallPrompting, triggerDesktopInstallPrompt } = useAppInstallation();

  const [anchorEl, setAnchorEl] = useState(null);
  const confirmAction = useDialog();

  const performLogout = async () => {
    await logout();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const accountMenuOpen = Boolean(anchorEl);

  const onLogoutClick = async () => {
    handleClose();
    const proceed = await confirmAction({ text: t(messages.confirmLogout) });
    if (!proceed) return null;
    return performLogout();
  };

  let button = <SettingsIcon fontSize="small" />;

  const hasActions = installState.updateAvailable;

  if (hasActions) {
    button = (
      <Badge color="secondary" variant="dot">
        {button}
      </Badge>
    );
  }

  return (
    <>
      <Tooltip title={t(messages.settings)} arrow placement="bottom-end">
        <IconButton
          aria-label={t(messages.settings)}
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="primary"
          size="small"
        >
          {button}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        // transitionDuration={0}
        open={accountMenuOpen}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              appRegion: "no-drag",
              WebkitAppRegion: "no-drag",
            },
          },
          root: {
            slotProps: {
              backdrop: {
                sx: {
                  appRegion: "no-drag",
                  WebkitAppRegion: "no-drag",
                },
                invisible: true,
              },
            },
          },
        }}
      >
        {installState.desktopInstallAvailable && (
          <MenuItem
            onClick={() => {
              triggerDesktopInstallPrompt();
            }}
            disabled={desktopInstallPrompting}
          >
            <ListItemIcon>
              <InstallDesktopIcon />
            </ListItemIcon>
            <ListItemText>{t(ActionsMessages.addToDesktop)}</ListItemText>
          </MenuItem>
        )}
        {installState.updateAvailable && (
          <MenuItem
            onClick={() => {
              skipWait();
            }}
            disabled={installState.updateInstalling}
          >
            <ListItemIcon>
              <BrowserUpdatedIcon />
            </ListItemIcon>
            <ListItemText>{t(ActionsMessages.updateAvailable)}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleClose} component={RouterLink} to="/settings">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText>{t(messages.settings)}</ListItemText>
        </MenuItem>

        <ExportDataMenuItem onClick={handleClose} />
        <ImportDataMenuItem onClick={handleClose} />
        <CheckForUpdatesMenuItem onClick={handleClose} />
        {account?.isAdmin === true && (
          <MenuItem onClick={handleClose} component={RouterLink} to="/admin">
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText>{t(messages.admin)}</ListItemText>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={onLogoutClick} disabled>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>{t(ActionsMessages.logout)}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
