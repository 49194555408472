import { defineMessages } from "react-intl";

const App = defineMessages({
  home: {
    id: "nav.home",
    description: "Nav bar title.",
    defaultMessage: "Home",
  },
  ingredients: {
    id: "nav.ingredients",
    description: "Nav bar title.",
    defaultMessage: "Ingredients",
  },
  foods: {
    id: "nav.foods",
    description: "Nav bar title.",
    defaultMessage: "Foods",
  },
  mealPlan: {
    id: "nav.mealPlan",
    description: "Nav bar title.",
    defaultMessage: "Meal Plan",
  },
  meals: {
    id: "nav.meals",
    description: "Nav bar title.",
    defaultMessage: "Meals",
  },
  thisWeek: {
    id: "nav.thisWeek",
    description: "Nav bar title.",
    defaultMessage: "This Week",
  },
  nextWeek: {
    id: "nav.nextWeek",
    description: "Nav bar title.",
    defaultMessage: "Next Week",
  },
  calendar: {
    id: "nav.calendar",
    description: "Nav bar title.",
    defaultMessage: "Calendar",
  },
  confirmLogout: {
    id: "nav.confirmLogout",
    description: "Confirm logout message.",
    defaultMessage: "Are you sure you want to logout?",
  },
  settings: {
    id: "nav.settings",
    description: "Nav bar title.",
    defaultMessage: "Settings",
  },
  admin: {
    description: "Nav bar title.",
    defaultMessage: "Admin",
    id: "59UKjZ",
  },
  help: {
    id: "nav.help",
    description: "Nav bar title.",
    defaultMessage: "Help",
  },
  searchPlaceholder: {
    id: "layout.searchPlaceholder",
    description: "Search field placeholder.",
    defaultMessage: "Search",
  },
  printHeader: {
    id: "layout.printHeader",
    description: "Appear in printed page headers.",
    defaultMessage: "Generated for {name} from {url}",
  },
  printHeaderAnonymous: {
    id: "layout.printHeaderAnonymous",
    description: "Appear in printed page headers.",
    defaultMessage: "Generated from {url}",
  },
});

export default App;
