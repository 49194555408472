import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getEmptyNutritionalProfile } from "../../data/calculateMacros.js";
import displayMacros from "../../data/displayMacros.js";
import Card from "../Card/Card.js";
import Link from "../Link.js";
import CreateMealPlanModal from "./CreateMealPlanModal.js";
import ActionMessages from "../messages/Actions.js";
import GlobalMessages from "../messages/Global.js";
import AppMessages from "../messages/App.js";

export default function MealPlanList({ plans }) {
  const { formatMessage: t } = useIntl();
  const [createMealPlanModalOpen, setCreateMealPlanModalOpen] = useState(false);

  const onMealPlanCreated = () => {};

  const titleAction = (
    <Button
      startIcon={<AddCircleOutlineIcon fontSize="small" />}
      size="small"
      onClick={() => setCreateMealPlanModalOpen(true)}
      variant="outlined"
    >
      {t(ActionMessages.newMealPlan)}
    </Button>
  );
  return (
    <Card title={t(AppMessages.mealPlan)} titleAction={titleAction} dense>
      <CreateMealPlanModal
        isOpen={createMealPlanModalOpen}
        setIsOpen={setCreateMealPlanModalOpen}
        onSubmit={onMealPlanCreated}
      />
      <TableContainer>
        <Table>
          <colgroup>
            <col width="150px" />
            <col />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>{t(GlobalMessages.name)}</TableCell>
              <TableCell>{t(GlobalMessages.calories)}</TableCell>
              <TableCell>{t(GlobalMessages.fat)}</TableCell>
              <TableCell>{t(GlobalMessages.carbohydrate)}</TableCell>
              <TableCell>{t(GlobalMessages.sugar)}</TableCell>
              <TableCell>{t(GlobalMessages.protein)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.map((mealPlan) => {
              const mealPlanMacrosDisplay = displayMacros(mealPlan.goal || getEmptyNutritionalProfile());
              return (
                <TableRow key={mealPlan.id} hover>
                  <TableCell>
                    <Link to={`/plan/${mealPlan.id}`}>{mealPlan.name}</Link>
                  </TableCell>
                  <TableCell>{`${mealPlanMacrosDisplay.calories}`}</TableCell>
                  <TableCell>{`${mealPlanMacrosDisplay.fat}`}</TableCell>
                  <TableCell>{`${mealPlanMacrosDisplay.carbohydrate}`}</TableCell>
                  <TableCell>{`${mealPlanMacrosDisplay.sugars}`}</TableCell>
                  <TableCell>{`${mealPlanMacrosDisplay.protein}`}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
