import React from "react";

/**
 * Will cause a rendering error.r
 */
export default function ThrowErrorPage() {
  let something = {};
  something = undefined;
  return <span>{something.something}</span>;
}
