import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useIntl } from "react-intl";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, Tooltip, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import ActionMessages from "../messages/Actions.js";
import { displayMacrosUsingLocale } from "../../data/displayMacros.js";
import { DayMeals } from "../../data/constants.js";
import Link from "../Link.js";

/**
 * Render a row for a food entry in a day card.
 *
 * @param {object} params - The parameters for the method.
 * @param {object} params.logEntry - The log entry for the food.
 * @param {array} params.ingredients - The list of available ingredients.
 * @param {array} params.recipes - The list of available recipes.
 * @param {function} params.deleteLogEntry - The function to delete a log entry.
 * @param {function} params.editLogEntry - The function to edit a log entry.
 * @param {object} params.day - The current day.
 * @param {boolean} params.allowNewItems - Flag to allow new items.
 * @param {boolean} params.showConsumeButton - Flag to show the consume button.
 * @param {function} params.toggleLogEntryConsumed - The function to toggle the consume status of a log entry.
 * @param {function} params.onContextMenu - The function to handle the context menu event.
 *
 * @returns {ReactElement} - The rendered table row component.
 */
export default function DayCardFoodRow({
  logEntry,
  ingredients,
  recipes,
  deleteLogEntry,
  editLogEntry,
  day,
  allowNewItems,
  showConsumeButton,
  toggleLogEntryConsumed,
  onContextMenu,
}) {
  const { formatMessage: t, formatNumber } = useIntl();

  let link;
  let quantity;
  let ingredient;
  const logEntryProfileDisplay = displayMacrosUsingLocale(logEntry.nutritionalProfile, formatNumber);

  if (logEntry.ingredientId) {
    ingredient = ingredients.find((_ingredient) => _ingredient.id === logEntry.ingredientId);
    let ingredientName = "[Deleted]";
    if (ingredient) {
      ingredientName = ingredient.name;
    }
    let greeting;
    if (logEntry.quantity === 1) {
      quantity = "1x";
      greeting = `${ingredientName} (${logEntry.grams}${ingredient.isLiquid ? "ml" : "g"})`;
    } else if (logEntry.quantity > 1) {
      quantity = `${logEntry.quantity}x`;
      greeting = `${ingredientName} (${logEntry.grams}${ingredient.isLiquid ? "ml" : "g"})`;
    } else {
      quantity = `${logEntry.grams}${ingredient.isLiquid ? "ml" : "g"}`;
      greeting = `${ingredientName}`;
    }
    if (ingredient) {
      link = (
        <Link color="textPrimary" title={t(DayMeals[logEntry.time])} to={`/ingredient/${ingredient.id}`}>
          {greeting}
        </Link>
      );
    } else {
      link = { greeting };
    }
  } else if (logEntry.recipeId) {
    const recipe = recipes.find((recipe_) => recipe_.id === logEntry.recipeId);
    const recipeName = recipe?.name || "[Deleted]";

    let greeting;
    if (logEntry.quantity === 1) {
      greeting = `${recipeName} (${logEntry.grams}g)`;
      quantity = "1x";
    } else if (logEntry.quantity > 1) {
      greeting = `${logEntry.quantity} x ${recipeName} (${logEntry.grams}g)`;
      quantity = `${logEntry.quantity}x`;
    } else {
      greeting = `${logEntry.grams}g of ${recipeName}`;
      quantity = `${logEntry.grams}g`;
    }
    if (recipe) {
      link = (
        <Link color="textPrimary" title={t(DayMeals[logEntry.time])} to={`/meals/${recipe.id}`}>
          {greeting}
        </Link>
      );
    } else {
      link = { greeting };
    }
  }

  const dayReal = day.day.diary ? day.day.diary : day.day;

  const onEditClick = () => {
    editLogEntry(logEntry, dayReal);
  };

  const onDeleteClick = () => {
    if (allowNewItems) {
      deleteLogEntry({ ...logEntry, date: dayReal?.date }, dayReal);
      return;
    }
    deleteLogEntry({ ...logEntry, date: dayReal.date }, dayReal);
  };

  const onContextMenuClick = (event) => {
    onContextMenu(event, logEntry, dayReal);
  };

  return (
    <TableRow onContextMenu={onContextMenuClick} hover>
      <TableCell padding="none">
        <Button
          onClick={onEditClick}
          color="inherit"
          size="medium"
          sx={{
            minWidth: "80px",
            justifyContent: "start",
            textTransform: "none",
            marginLeft: 0,
            paddingLeft: 2,
            paddingRight: 2,
            fontSize: "1rem",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          {quantity}
        </Button>
      </TableCell>
      <TableCell>
        {link}
        {ingredient?.brand && (
          <Typography variant="body2" color="text.disabled" paddingLeft={1} display="inline-block">
            {ingredient.brand}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Tooltip
          arrow
          placement="right"
          title={`${logEntryProfileDisplay.energy} kj / ${logEntryProfileDisplay.calories} kcal`}
        >
          <span>{logEntryProfileDisplay.calories}</span>
        </Tooltip>
      </TableCell>
      <TableCell>{logEntryProfileDisplay.protein}</TableCell>
      <TableCell>{logEntryProfileDisplay.fat}</TableCell>
      <TableCell>{logEntryProfileDisplay.sugars}</TableCell>
      <TableCell>{logEntryProfileDisplay.carbohydrate}</TableCell>
      <TableCell>{logEntryProfileDisplay.salt}</TableCell>
      <TableCell align="right">
        {showConsumeButton && (
          <Tooltip title={t(ActionMessages.markAsConsumed)} arrow placement="left">
            <IconButton
              onClick={() => {
                toggleLogEntryConsumed({ ...logEntry, date: day.day.date });
              }}
              size="small"
            >
              {logEntry.consumed && <CheckCircleIcon fontSize="small" />}
              {!logEntry.consumed && <CheckCircleOutlineIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t(ActionMessages.delete)} arrow placement="left">
          <IconButton size="small" onClick={onDeleteClick}>
            <HighlightOffIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
