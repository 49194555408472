import Big from "big.js";

// https://www.nal.usda.gov/fnic/how-many-calories-are-one-gram-fat-carbohydrate-or-protein
const caloriesPerGramOfProtein = 4;
const caloriesPerGramOfCarbohydrate = 4;
const caloriesPerGramOfFat = 9;

export function calculateCalorieBreakdownPercentage({
  total,
  protein: caloriesOfProtein,
  carbohydrate: caloriesOfCarbohydrate,
}) {
  const protein = parseFloat(new Big(caloriesOfProtein).div(total).times(100).toFixed(0));
  const carbohydrate = parseFloat(new Big(caloriesOfCarbohydrate).div(total).times(100).toFixed(0));
  return {
    protein,
    fat: 100 - protein - carbohydrate,
    carbohydrate,
  };
}

export default function calculateCalorieBreakdown({ gramsOfProtein, gramsOfFat, gramsOfCarbohydrate }) {
  const protein = parseFloat(new Big(gramsOfProtein).times(caloriesPerGramOfProtein).toFixed(0));
  const fat = parseFloat(new Big(gramsOfFat).times(caloriesPerGramOfFat).toFixed(0));
  const carbohydrate = parseFloat(new Big(gramsOfCarbohydrate).times(caloriesPerGramOfCarbohydrate).toFixed(0));
  return {
    protein,
    fat,
    carbohydrate,
    total: protein + fat + carbohydrate,
  };
}
