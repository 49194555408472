import { Dexie } from "dexie";

export class MacroMealPrepDB extends Dexie {
  constructor() {
    super("MacroMealPrepDB");
    this.version(1).stores({
      days: "id, date",
      ingredients: "id",
      account: "id",
      profile: "id",
      mealPlans: "id",
    });
  }
}

/**
 * @type MacroMealPrepDB
 */
let _db;

/**
 * Access the database instance. Created on first call.
 * @return {MacroMealPrepDB}
 */
export default function getDb() {
  if (_db) return _db;
  _db = new MacroMealPrepDB();
  return _db;
}
