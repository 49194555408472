import React from "react";
import { useIntl } from "react-intl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useNavigate, Link as ReactRouterLink } from "react-router";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import displayMacros from "../../data/displayMacros.js";
import Card from "../Card/Card.js";
import ActionMessages from "../messages/Actions.js";
import IngredientListSortableHeaderRow from "./IngredientListSortableHeaderRow.js";
import Link from "../Link.js";

export default function IngredientList({ ingredients, order, orderBy, onSort }) {
  const { formatMessage: t } = useIntl();
  const navigate = useNavigate();

  const titleAction = (
    <Button
      startIcon={<AddCircleOutlineIcon fontSize="small" />}
      size="small"
      variant="outlined"
      component={ReactRouterLink}
      to="/ingredient/new"
      onClick={() => {}}
    >
      {t(ActionMessages.newIngredient)}
    </Button>
  );

  return (
    <Card titleAction={titleAction} dense square elevation={0}>
      <TableContainer>
        <Table size="small">
          <IngredientListSortableHeaderRow order={order} orderBy={orderBy} onSort={onSort} />
          <TableBody>
            {ingredients.map((ingredient) => {
              const hasBreakdown = ingredient.breakdown && ingredient.breakdown.available;
              const ingredientMacrosDisplay = displayMacros(ingredient.nutritionalProfile);
              const onRowClick = () => {
                navigate(`/ingredient/${ingredient.id}`);
              };

              return (
                <TableRow key={ingredient.id} onClick={onRowClick} hover>
                  <TableCell>
                    <Link to={`/ingredient/${ingredient.id}`}>{ingredient.name || `Ingredient ${ingredient.id}`}</Link>
                    {ingredient.brand && (
                      <Typography color="textSecondary" variant="body2" display="block">
                        {ingredient.brand}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>{`${ingredientMacrosDisplay.calories}`}</TableCell>
                  <TableCell>{`${ingredientMacrosDisplay.protein}`}</TableCell>
                  <TableCell>{`${hasBreakdown ? `${ingredient.breakdown.ratio.protein}%` : ""}`}</TableCell>
                  <TableCell>{`${ingredientMacrosDisplay.fat}`}</TableCell>
                  <TableCell>{`${hasBreakdown ? `${ingredient.breakdown.ratio.fat}%` : ""}`}</TableCell>
                  <TableCell>{`${ingredientMacrosDisplay.sugars}`}</TableCell>
                  <TableCell>{`${ingredientMacrosDisplay.carbohydrate}`}</TableCell>
                  <TableCell>{`${hasBreakdown ? `${ingredient.breakdown.ratio.carbohydrate}%` : ""}`}</TableCell>
                  <TableCell>{`${ingredientMacrosDisplay.salt}`}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
