import React from "react";
import { useIntl } from "react-intl";
import useTitle from "../../lib/useTitle.js";
import AppMessages from "../messages/App.js";
import IngredientListContainer from "./IngredientListContainer.js";

export default function IngredientListPage() {
  const { formatMessage: t } = useIntl();
  useTitle(t(AppMessages.ingredients));
  return <IngredientListContainer />;
}
