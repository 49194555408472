import React, { useMemo } from "react";
import EditLogEntryModalContent from "./EditLogEntryModalContent.js";

export default function EditQuantityModal({ isOpen, onClose, day, logEntry, editLogEntry }) {
  return useMemo(
    () => (
      <EditLogEntryModalContent
        editLogEntry={editLogEntry}
        open={isOpen}
        onClose={onClose}
        day={day}
        logEntry={logEntry}
      />
    ),
    [isOpen, day, logEntry, editLogEntry, onClose],
  );
}
