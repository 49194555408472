import React, { useReducer, useEffect } from "react";
import IngredientList from "./IngredientList.js";
import ingredientListReducer from "./ingredientListReducer.js";
import IngredientListReducerInitialiser from "./IngredientListReducerInitialiser.js";

export default function IngredientListSortContainer({ ingredients }) {
  const [state, dispatch] = useReducer(
    ingredientListReducer,
    {
      order: "asc",
      orderBy: "calories",
      ingredients,
    },
    IngredientListReducerInitialiser,
  );

  useEffect(() => {
    dispatch({
      type: "INGREDIENTS",
      value: ingredients,
    });
  }, [ingredients]);

  const onSort = (value) => {
    dispatch({
      type: "SORT",
      value,
    });
  };

  const { order, orderBy } = state;

  return <IngredientList ingredients={state.ingredients} order={order} orderBy={orderBy} onSort={onSort} />;
}
