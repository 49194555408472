import { useContext } from "react";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { createLogEntry } from "../../actions/logEntry.js";
import { updateStorage } from "../../actions/storage.js";
import ToastContext from "../../contexts/Toast/ToastContext.js";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import ToastMessages from "../messages/Toast.js";
import GlobalMessages from "../messages/Global.js";

export default function useCreateLogEntry() {
  const dispatch = useDispatch();
  const { displayToast } = useContext(ToastContext);
  const { locale, longDateFormat } = useContext(LanguageContext);

  return (logEntries, item, t) => {
    if (logEntries.length === 1) {
      displayToast(
        t(ToastMessages.ingredientAddedToPlan, {
          ingredientName: item.name,
          date: format(logEntries[0].date, longDateFormat, {
            locale,
          }),
          timeOfDay: t(GlobalMessages[logEntries[0].time]),
        }),
      );
      dispatch(createLogEntry(logEntries[0]));
    } else {
      displayToast(
        t(ToastMessages.ingredientAddedToPlanMultipleDays, {
          ingredientName: item.name,
          dayCount: logEntries.length,
        }),
      );
      logEntries.forEach((logEntry) => {
        dispatch(createLogEntry(logEntry));
      });
    }
    // TODO: Do a sync operation after saving locally?
    // dispatch(updateStorage());
  };
}
