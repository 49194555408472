import data from "./data.json";
import generateId from "./generateId.js";

let uniqueData;

export default function getSampleData() {
  if (!uniqueData) {
    uniqueData = { ...data };

    uniqueData.ingredients = uniqueData.ingredients.map((ingredient) => {
      const uniqueIngredient = {
        ...ingredient,
        id: generateId(),
      };

      uniqueData.recipes = uniqueData.recipes.map((recipe) => ({
        ...recipe,
        ingredients: recipe.ingredients.map((recipeIngredient) => {
          if (recipeIngredient.ingredientId === ingredient.id) {
            return {
              ...recipeIngredient,
              ingredientId: uniqueIngredient.id,
            };
          }
          return recipeIngredient;
        }),
      }));

      return uniqueIngredient;
    });
  }

  return uniqueData;
}
