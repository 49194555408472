import setIngredient from "../api/setIngredient.js";
import { updateStorage } from "./storage.js";

export const INGREDIENT_DELETE = "@@ingredient/DELETE";
export const INGREDIENT_UPDATE = "@@ingredient/UPDATE";
export const INGREDIENT_CREATE = "@@ingredient/CREATE";
export const INGREDIENT_LOAD_EXAMPLE_DATA = "@@ingredient/LOAD_EXAMPLE_DATA";
export const INGREDIENT_LOAD_FROM_STORAGE = "@@ingredient/LOAD_FROM_STORAGE";

export const updateIngredient = (ingredient) => async (dispatch) => {
  dispatch({ type: INGREDIENT_UPDATE, id: ingredient.id, ingredient });
  dispatch(updateStorage({ ingredient }));

  // await setIngredient(ingredient);
};

export const createIngredient = (ingredient) => async (dispatch) => {
  dispatch({ type: INGREDIENT_CREATE, id: ingredient.id, ingredient });
  dispatch(updateStorage({ ingredient }));
  // await setIngredient(ingredient);
};

export const deleteIngredient = (id) => async (dispatch) => {
  dispatch({ type: INGREDIENT_DELETE, id });
};

export const loadExampleData = () => ({ type: INGREDIENT_LOAD_EXAMPLE_DATA });

export const loadFromStorage = (ingredients) => ({ type: INGREDIENT_LOAD_FROM_STORAGE, ingredients });
