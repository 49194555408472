import { useEffect } from "react";
import { useSelector } from "react-redux";
import useAccount from "../contexts/Account/useAccount.js";

/**
 * Will do the startup tasks of loading initially the data from local (if available), and then the server.
 * IF not logged in any more, will force a logout.
 */
export default function LoadStartupData() {
  const { setAccount, isLoaded } = useAccount();

  const data = useSelector((state) => {
    // storage: {loaded: false, isLoading: false, loadingFailed: false}
    return {
      account: state.data.account,
      profile: state.data.profile,
      storage: state.storage,
    };
  });

  useEffect(() => {
    if (!data.storage.loaded) return; // don't run if storage not finished loading
    if (isLoaded) return; // don't set again if setAccount already called

    if (data.account && data.profile) {
      setAccount(data.account, data.profile, undefined, new Date());
    } else {
      // Triggers unauthenticated state
      setAccount(null, null, null, new Date());
    }
  }, [data, setAccount, isLoaded]);

  return null;
}
