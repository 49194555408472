import React, { useContext, useReducer, useState, useEffect } from "react";
import { format, addDays, isEqual, parse } from "date-fns";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router";
import { useIntl } from "react-intl";
import SessionContext from "../../contexts/Session/SessionContext.js";
import AddToCalendarDialog from "../IngredientDetails/AddToCalendarDialog.js";
import useTitle from "../../lib/useTitle.js";
import DayPickerTabs from "../MealPlanList/DayPickerTabs.js";
import EditQuantityModal from "../MealPlanList/EditQuantityModal.js";
import AppMessages from "../messages/App.js";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import ProfileContext from "../../contexts/Profile/ProfileContext.js";
import CalendarSummaryCard from "./CalendarSummaryCard.js";
import DayCard from "./DayCard.js";
import MacroWidgets from "./MacroWidgets.js";
import DayWidgets from "./DayWidgets.js";
import mealPlanEditorReducer from "../MealPlanList/mealPlanEditorReducer.js";
import mealPlanEditorReducerInitializer from "../MealPlanList/mealPlanEditorReducerInitializer.js";
import mealPlanEditorReducerInitialState from "../MealPlanList/mealPlanEditorReducerInitialState.js";
import MEALPLAN_EDITOR_ACTIONS from "../MealPlanList/mealPlanEditorReducerActions.js";
import WeekSummaryCard from "../MealPlanList/WeekSummaryCard.js";
import GlobalMessages from "../messages/Global.js";

export default function Calendar({
  days,
  ingredients,
  deleteLogEntry,
  updateLogEntry,
  nextWeek,
  lastWeek,
  recipes,
  thisWeek,
  thisWeekActual,
  mode,
  toggleLogEntryConsumed,
  toggleMarkAsComplete,
}) {
  const { formatMessage: t, formatNumber } = useIntl();
  const navigate = useNavigate();
  const isFixedDate = mode === "this" || mode === "next";
  const { locale, longDateFormat } = useContext(LanguageContext);
  const {
    setCalendarStartDate,
    startDayThisWeek,
    startDayNextWeek,
    selectedMacro,
    setSelectedMacro,
    selectedCalendarTab,
    setSelectedCalendarTab,
  } = useContext(SessionContext);
  const { profile } = useContext(ProfileContext);
  const { goal } = profile;
  const [addToCalendarDialogOpen, setAddToCalendarDialogOpen] = useState(null);
  const [logEntryBeingEdited, setLogEntryBeingEdited] = useState(null);

  const displayDate = addToCalendarDialogOpen ? parse(addToCalendarDialogOpen.day, "yyyyMMdd", new Date()) : new Date();

  const editLogEntry = (logEntry, day) => {
    setLogEntryBeingEdited({ logEntry, day });
  };

  const closeLogEntry = () => {
    setLogEntryBeingEdited(null);
  };

  const onUpdateLogEntry = (logEntry, day) => {
    updateLogEntry(logEntry, day);
    closeLogEntry();
  };

  const onAddItem = (params) => {
    setAddToCalendarDialogOpen(params);
  };

  const closeAddToCalendarDialog = () => {
    setAddToCalendarDialogOpen(null);
  };

  const dateRange = `${format(thisWeek, longDateFormat, { locale })} - ${format(addDays(thisWeek, 6), longDateFormat, {
    locale,
  })}`;
  let title = `${dateRange} - ${t(AppMessages.calendar)}`;

  if (mode === "this") {
    title = `${t(AppMessages.thisWeek)} - ${title}`;
  } else if (mode === "next") {
    title = `${t(AppMessages.nextWeek)} - ${title}`;
  }

  useTitle(title);

  const navigateToDate = (date) => {
    if (isEqual(date, startDayNextWeek)) {
      navigate(`/calendar/next-week`, { replace: true });
    } else if (isEqual(date, startDayThisWeek)) {
      navigate(`/calendar/this-week`, { replace: true });
    } else {
      setCalendarStartDate(format(date, "yyyy/M/d"));
      navigate(`/calendar/${format(date, "yyyy/M/d")}`, { replace: true });
    }
  };

  useHotkeys(
    "n",
    () => {
      navigateToDate(nextWeek);
    },
    [nextWeek],
  );

  useHotkeys(
    "p",
    () => {
      navigateToDate(lastWeek);
    },
    [lastWeek],
  );

  useHotkeys(
    "o",
    () => {
      navigateToDate(thisWeekActual);
    },
    [thisWeekActual],
  );

  const [state, dispatch] = useReducer(
    mealPlanEditorReducer,
    mealPlanEditorReducerInitialState({
      days,
      weekStartsOn: profile.weekStartsOn,
      goal,
    }),
    mealPlanEditorReducerInitializer(locale, longDateFormat, formatNumber),
  );

  useEffect(() => {
    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.RELOAD_DAYS,
      value: days,
    });
  }, [days]);

  const onSort = (value) => {
    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.SORT,
      value,
    });
  };

  const {
    consumedMacrosAllDaysDisplay,
    remainingMacrosAllDaysDisplay,
    goalAllDaysCompletionResult,
    goalAllDaysCompletionResultDisplay,
    runningDailyTotal,
    goalMacrosDisplay,
    goalAllDaysDisplay,
    order,
    orderBy,
  } = state;

  const onDeleteLogEntry = (logEntry, day) => {
    const reEmitEntry = { ...logEntry, date: day?.date || logEntry.date };
    delete reEmitEntry.sortableNutritionalProfile;

    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.DELETE_LOG_ENTRY,
      value: reEmitEntry,
      day,
      locale,
      longDateFormat,
    });

    deleteLogEntry(reEmitEntry, day);
  };

  const setTab = (newSelectedTab) => {
    setSelectedCalendarTab(newSelectedTab);
  };

  const handleTabChange = (event, newSelectedTab) => {
    setTab(newSelectedTab);
  };

  return (
    <>
      <MacroWidgets
        consumedMacrosDisplay={consumedMacrosAllDaysDisplay}
        completionResult={goalAllDaysCompletionResult}
        completionResultDisplay={goalAllDaysCompletionResultDisplay}
        onClick={setSelectedMacro}
        value={selectedMacro}
        noTopMargin
      />

      <DayWidgets days={runningDailyTotal} value={selectedCalendarTab} onClick={setTab} macro={selectedMacro} />

      <DayPickerTabs runningDailyTotal={runningDailyTotal} value={selectedCalendarTab} onChange={handleTabChange} />

      {selectedCalendarTab !== "total" && (
        <DayCard
          goal={goalMacrosDisplay}
          day={runningDailyTotal.find((item) => item.tabId === selectedCalendarTab)}
          ingredients={ingredients}
          recipes={recipes}
          deleteLogEntry={onDeleteLogEntry}
          editLogEntry={editLogEntry}
          isCalendarDate
          groupByMeal
          order={order}
          orderBy={orderBy}
          onSort={onSort}
          onAddItem={onAddItem}
          allowNewItems
          showConsumeButton={mode === "this"}
          toggleLogEntryConsumed={toggleLogEntryConsumed}
          toggleMarkAsComplete={toggleMarkAsComplete}
        />
      )}

      {selectedCalendarTab === "total" && (
        <WeekSummaryCard
          title={`${t(GlobalMessages.total)}: ${dateRange}`}
          onSort={onSort}
          consumedMacrosAllDaysDisplay={consumedMacrosAllDaysDisplay}
          remainingMacrosAllDaysDisplay={remainingMacrosAllDaysDisplay}
          goalAllDaysCompletionResultDisplay={goalAllDaysCompletionResultDisplay}
          goalAllDaysCompletionResult={goalAllDaysCompletionResult}
          runningDailyTotal={runningDailyTotal}
          order={order}
          orderBy={orderBy}
          goalAllDaysDisplay={goalAllDaysDisplay}
          onDaySelected={setTab}
          showMarkedAsComplete
        />
      )}

      {!isFixedDate && (
        <CalendarSummaryCard
          setCalendarStartDate={setCalendarStartDate}
          nextWeek={nextWeek}
          thisWeek={thisWeek}
          thisWeekActual={thisWeekActual}
          lastWeek={lastWeek}
        />
      )}
      <EditQuantityModal
        isOpen={Boolean(logEntryBeingEdited)}
        day={logEntryBeingEdited?.day}
        logEntry={logEntryBeingEdited?.logEntry}
        onClose={closeLogEntry}
        editLogEntry={onUpdateLogEntry}
      />
      <AddToCalendarDialog
        open={Boolean(addToCalendarDialogOpen)}
        onClose={closeAddToCalendarDialog}
        date={displayDate}
        time={addToCalendarDialogOpen?.time}
        key={displayDate.toISOString()}
      />
    </>
  );
}
