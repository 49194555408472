import React, { useContext } from "react";
import cx from "classnames";
import { useIntl } from "react-intl";
import Button from "@mui/material/Button";
import { Link as ReactRouterLink } from "react-router";
import useStyles from "../../isomorphic-style-loader/useStyles.js";
import WidgetWrapper from "./WidgetWrapper.js";
import SigninMessages from "../messages/Signin.js";
import HomeMessages from "../messages/Home.js";
import styles from "./WelcomeCard.css";
import ProfileContext from "../../contexts/Profile/ProfileContext.js";

export default function WelcomeCard({ sx }) {
  const { formatMessage: t } = useIntl();
  const { profile } = useContext(ProfileContext);
  useStyles(styles);
  return (
    <WidgetWrapper sx={sx} className={cx(styles.root)} theme="greenGradient" isStacked>
      <h3>{t(SigninMessages.heading)}</h3>
      <p>{t(HomeMessages.welcomeMessage)}</p>
      <p>{t(HomeMessages.requestConsultation, { name: <strong key={profile.name}>{profile.name}</strong> })}</p>
      <Button component={ReactRouterLink} variant="contained" to="/support">
        {t(HomeMessages.sayHello)}
      </Button>
    </WidgetWrapper>
  );
}
