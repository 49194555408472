import React, { useContext, useMemo } from "react";
import { useMatch, useLocation } from "react-router";
import { IntlProvider } from "react-intl";
import LanguageContext from "./LanguageContext.js";
import AccountContext from "../Account/AccountContext.js";
import determineLanguage from "../../lib/languages/determineLanguage.js";

export default function LanguageProvider({ children, browserLanguages, cookieLanguage, onLanguageDetermined }) {
  const { account } = useContext(AccountContext);
  const { pathname } = useLocation();
  const match = useMatch("/:lang([a-z]{2}|[a-z]{2}-[a-z]{2})");
  const userLanguage = account?.language;

  /**
   * Get current language
   * Set default locale to en if base path is used without a language
   */
  const params = match ? match.params : {};
  const { lang } = params;
  const langSafe = lang ? lang.toLowerCase() : undefined;
  const determinedLanguage = determineLanguage({
    urlLanguage: langSafe,
    browserLanguages,
    userLanguage,
    cookieLanguage,
  });

  let redirectTo;

  if (determinedLanguage.redirectTo) {
    let nextPrefix = `/${determinedLanguage.redirectTo}`;
    if (determinedLanguage.redirectTo === "en" || determinedLanguage.redirectTo === "en-GB") {
      nextPrefix = "";
    }
    if (!langSafe) {
      redirectTo = `${nextPrefix}${pathname}`;
    } else {
      redirectTo = `${nextPrefix}${pathname.substring(3)}`;
    }
  }
  if (onLanguageDetermined) {
    onLanguageDetermined(determinedLanguage);
  }

  const onIntlProviderError = (error) => {
    console.log(error.message);
  };

  const next = children;

  if (process.env.BROWSER && redirectTo) {
    console.warn(`Want to redirect to ${redirectTo}`);
    // next = <Route exact path={`${prefix}/login-link`} render={() => <Navigate to={redirectTo} />} />
  }

  const value = useMemo(
    () => ({
      language: determinedLanguage.language,
      prefix: determinedLanguage.language.prefix,
      locale: determinedLanguage.language.locale,
      longDateFormat: determinedLanguage.language.longDateFormat,
      longDateTimeFormat: determinedLanguage.language.longDateTimeFormat,
      monthDayFormat: determinedLanguage.language.monthDayFormat,
    }),
    [determinedLanguage],
  );

  /**
   * Return Intl provider with default language set
   */
  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider
        locale={determinedLanguage.language.language}
        messages={determinedLanguage.language.messages}
        onError={onIntlProviderError}
        defaultLocale="en-GB"
      >
        {next}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}
