import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import useAccount from "../../contexts/Account/useAccount.js";
import useWebAuth from "../../contexts/WebAuth/useWebAuth.js";
import Card from "../Card/Card.js";
import ActionMessages from "../messages/Actions.js";

export default function PasskeysCard() {
  const { formatMessage: t, formatDate } = useIntl();
  const { account } = useAccount();
  const { register, isLoading, isLoaded, browserSupportsWebAuthn, platformAuthenticatorIsAvailable } = useWebAuth();

  const { passkeys } = account;

  const hasPasskeyRegistered = passkeys?.length > 0;

  const [isBusy, setIsBusy] = useState(false);
  const [message, setMessage] = useState(null);
  const onCreatePasskeyClick = async () => {
    setIsBusy(true);
    let result;
    try {
      result = await register();
    } catch (ex) {
      // ignore
    }

    if (result && !result.silent && !result.success) {
      setMessage({
        message: result.message,
        color: "error",
      });
    } else if (result && result.success) {
      setMessage({
        message: "Your new Passkey has been created",
        color: "success",
      });
    }
    setIsBusy(false);
  };

  let prompt = null;

  if (message) {
    prompt = <Alert severity={message.color || "info"}>{message.message}</Alert>;
  } else if (isLoading) {
    prompt = <Alert severity="info">Your browser is being checked for Passkey support.</Alert>;
  } else if (isLoaded && !browserSupportsWebAuthn) {
    prompt = <Alert severity="info">Passkeys are not available in your current browser.</Alert>;
  } else if (isLoaded && platformAuthenticatorIsAvailable && !hasPasskeyRegistered) {
    prompt = <Alert severity="info">Passkeys are available and we recommend you create one.</Alert>;
  }

  return (
    <Card dense>
      <CardContent>
        {prompt}
        <List dense>
          {passkeys.map((passkey) => (
            <ListItem key={passkey.id}>
              <ListItemText
                primary={`${[passkey.createdOnBrowserDeviceVendor, passkey.createdOnBrowserOS, passkey.createdOnBrowser].join(" ")}`}
                secondary={formatDate(passkey.createdAt)}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions>
        <Button
          disabled={isBusy || !isLoaded || !browserSupportsWebAuthn}
          variant="contained"
          color="primary"
          onClick={onCreatePasskeyClick}
          startIcon={<FingerprintIcon fontSize="small" />}
        >
          {t(ActionMessages.createPasskey)}
        </Button>
      </CardActions>
    </Card>
  );
}
