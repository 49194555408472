import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router";
import useAccount from "../../contexts/Account/useAccount.js";
import useConfig from "../../contexts/Config/useConfig.js";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import SessionContext from "../../contexts/Session/SessionContext.js";
import SearchIcon from "../Icons/SearchIcon.js";
import Link from "../Link.js";
import ActionMessages from "../messages/Actions.js";
import messages from "../messages/App.js";
import AppSubMenuButton from "./AppSubMenuButton.js";
import BurgerMenu from "./BurgerMenu.js";

const SearchInput = styled("input", {
  name: "MmpSearchInput", // The component name
})(() => ({
  appearance: "none",
  margin: 0,
  width: "100%",
  borderRadius: "4px",
  height: "46px",
  padding: "10px 18px 10px 40px",
  color: "#e0e0e0",
  fontSize: "16px",
  lineHeight: "36px",
  border: "none",
  background: "rgb(250 250 250 / 10%)",
  "&::placeholder": {
    color: "#e0e0e0",
  },
  "&:focus": {
    outline: 0,
  },
}));

const CommandContainer = styled("nav")(() => ({
  display: "flex",
  flex: "1 1 auto",
  alignItems: "center",
  justifyContent: "flex-end",
  "& > .MuiLink-root:not(:last-child)": {
    marginRight: "22px",
  },
}));

const NavLink = styled(Link)(() => ({
  textTransform: "uppercase",
  fontSize: "16px",
  padding: "5px",
  transition: "color 0.3s",
  cursor: "pointer",
  outline: "none",
  touchAction: "manipulation",
  ":hover": {
    color: "var(--brand-accent)",
  },
}));

function MealPlanNavLink() {
  const { formatMessage: t } = useIntl();
  const mealPlan = useSelector((state) => {
    const activeMealPlan = state.mealPlans?.mealPlans?.find((item) => item.isActive)?.id;

    return activeMealPlan || null;
  });

  return (
    <NavLink underline="none" to={mealPlan ? `/plan/${mealPlan}` : "/plan"}>
      {t(messages.mealPlan)}
    </NavLink>
  );
}

export default function AppHeaderBar() {
  const { calendarStartDate } = useContext(SessionContext);
  const { isAuthenticated, isLoadingFailed, isLoaded, profile } = useAccount();
  const navigate = useNavigate();
  const { url } = useConfig();
  const { prefix } = useContext(LanguageContext);
  const [searchValue, setSearchValue] = useState("");
  const isReady = isLoaded && !isLoadingFailed;
  const { formatMessage: t } = useIntl();
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    navigate(`${prefix}/search?q=${event.target.value}`, { replace: true });
  };

  let applicationPrintHeader;
  const isReadyAndLoggedIn = isReady && isAuthenticated;
  const isReadyAndNotLoggedIn = isReady && !isAuthenticated;
  const hasName = isReadyAndLoggedIn && profile.name && profile.name.length;
  if (hasName) {
    applicationPrintHeader = (
      <p>
        {t(messages.printHeader, {
          url: (
            <a key={url} href={url}>
              {url}
            </a>
          ),
          name: <strong key={profile.name}>{profile.name}</strong>,
        })}
      </p>
    );
  } else {
    applicationPrintHeader = (
      <p>
        {t(messages.printHeaderAnonymous, {
          url: (
            <a key={url} href={url}>
              {url}
            </a>
          ),
        })}
      </p>
    );
  }

  return (
    <AppBar
      position="fixed"
      color="default"
      square
      sx={{
        backgroundImage: "none",
        borderBottomColor: "divider",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        backgroundColor: (theme) => theme.palette.background.default,
        // For the PWA Window
        appRegion: "drag",
        WebkitAppRegion: "drag",

        paddingTop: "env(titlebar-area-height, 0px)",
      }}
      elevation={0}
    >
      <Toolbar variant="dense" disableGutters sx={{ minHeight: (theme) => theme.mixins.toolbar.minHeight }}>
        {isReadyAndLoggedIn && (
          <Box
            sx={{
              flex: "1 1 auto",
              display: "none",
              padding: 0,
              justifyContent: "flex-end",
              alignItems: "center",
              "& a": {
                color: "black",
              },
              "& p": {
                color: "black",
                fontWeight: 600,
              },
              "@media print": {
                display: "flex",
              },
            }}
          >
            {applicationPrintHeader}
          </Box>
        )}
        <Box
          sx={{
            flex: "1 1 auto",
            display: "flex",
            alignItems: "center",
            padding: "0 32px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              maxWidth: "350px",
              overflow: "hidden",
              width: "100%",
            }}
          >
            {isReadyAndLoggedIn && (
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    width: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SearchIcon />
                </Box>
                <SearchInput
                  type="search"
                  aria-label={t(messages.searchPlaceholder)}
                  placeholder={t(messages.searchPlaceholder)}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </Box>
            )}
          </Box>

          <CommandContainer>
            {isReadyAndLoggedIn && (
              <NavLink underline="none" to="/">
                {t(messages.home)}
              </NavLink>
            )}
            {isReadyAndLoggedIn && (
              <NavLink underline="none" to="/ingredient">
                {t(messages.foods)}
              </NavLink>
            )}
            {/* {isReadyAndLoggedIn && ( */}
            {/*  <NavLink underline="none" to="/meals"> */}
            {/*    {t(messages.meals)} */}
            {/*  </NavLink> */}
            {/* )} */}
            {isReadyAndLoggedIn && (
              <NavLink underline="none" to="/calendar/this-week">
                {t(messages.thisWeek)}
              </NavLink>
            )}
            {isReadyAndLoggedIn && (
              <NavLink underline="none" to="/calendar/next-week">
                {t(messages.nextWeek)}
              </NavLink>
            )}
            {isReadyAndLoggedIn && <MealPlanNavLink />}
            {isReadyAndLoggedIn && (
              <Tooltip title={t(messages.calendar)} arrow placement="bottom-end">
                <IconButton
                  component={RouterLink}
                  to={`/calendar/${calendarStartDate}`}
                  aria-label={t(messages.calendar)}
                  color="primary"
                  sx={{ marginRight: 2 }}
                  size="small"
                >
                  <CalendarMonthIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {isReadyAndLoggedIn && <AppSubMenuButton />}
            {isReadyAndNotLoggedIn && (
              <NavLink
                underline="none"
                to="/login"
                title={t(ActionMessages.login)}
                aria-label={t(ActionMessages.login)}
              >
                {t(ActionMessages.login)}
              </NavLink>
            )}
          </CommandContainer>
        </Box>
        <BurgerMenu />
      </Toolbar>
    </AppBar>
  );
}
