import {
  browserSupportsWebAuthn,
  platformAuthenticatorIsAvailable,
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";
import getLocaleInformation from "../api/getLocaleInformation.js";
import setApi from "../api/setApi.js";

const register = async () => {
  const request = await setApi("/api/webauthn-register-request", {});

  let credentials;

  if (!request) {
    return {
      success: false,
      message: "Internet connection seems down or server unresponsive.",
    };
  }

  if (!request.success) {
    return {
      success: false,
      message: "Server reported an error",
    };
  }

  try {
    credentials = await startRegistration({ optionsJSON: request.challenge });
  } catch (error) {
    if (error.name === "InvalidStateError") {
      // Error: Authenticator was probably already registered by user
      return {
        success: false,
        silent: true,
        message: error.name,
      };
    }
    if (error.name === "NotAllowedError") {
      return {
        success: false,
        silent: true,
        message: error.name,
      };
    }
    return {
      success: false,
      message: `An error ${error.toString()}`,
    };
  }

  if (!credentials) {
    return {
      success: false,
      silent: true,
    };
  }

  return setApi("/api/webauthn-register", {
    credentials,
    challenge: request.challenge.challenge,
  });
};

async function login(email, useBrowserAutofill) {
  const hasEmail = email?.length > 0;
  const request = await setApi("/api/webauthn-login-request", { email });
  if (!request)
    return {
      success: false,
      message: "Failed to request login due a network error.",
    };

  let credentials;
  if (!request.challenge) {
    if (hasEmail) {
      return {
        success: true,
        failOver: true,
        message: null,
      };
    }
    return {
      success: false,
      failOver: true,
      message: request.message || "Failed to create a login challenge, please try again.",
    };
  }
  try {
    credentials = await startAuthentication({
      optionsJSON: request.challenge,
      useBrowserAutofill: useBrowserAutofill === true,
    });
  } catch (error) {
    if (error.name === "AbortError") {
      return {
        success: false,
        failOver: true,
        message: "You passkey login session expired, please try again.",
      };
    }

    if (error.name === "NotAllowedError") {
      return {
        success: false,
        failOver: true,
        message: "You cancelled Passkey login.",
      };
    }

    console.log(error);
  }
  if (!credentials) {
    return {
      success: false,
      message: "Your Passkey authentication failed.",
    };
  }

  return setApi("/api/webauthn-login", {
    email,
    credentials,
    challenge: request.challenge.challenge,
    ...getLocaleInformation(),
  });
}

const webauthn = {
  register,
  login,
  // This calls and just sees if the browser can do passkeys (which includes USB keys etc.), not if there is a built-in provider
  browserSupportsWebAuthn,
  // This calls browserSupportsWebAuthn, but also checks if a good passkey provider such as Face ID is available
  platformAuthenticatorIsAvailable,
};

export default webauthn;
