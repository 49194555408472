import { calculateMacrosByGram, calculatePortionSize } from "../../data/calculateMacros.js";
import getEditableNutritionalProfile, {
  editableProfileToNutritionalProfile,
} from "../../lib/getEditableNutritionalProfile.js";
import calculateCalorieBreakdown, {
  calculateCalorieBreakdownPercentage,
} from "../../data/calculateCalorieBreakdown.js";
import { convertToNumber } from "../../data/displayMacros.js";
import calculateEnergy from "../../data/calculateEnergy.js";
import parseNumericInput from "../../lib/parseNumericInput.js";
import canAddCalorieBreakdown from "../../lib/canAddCalorieBreakdown.js";

export function getCalorieBreakdown(nutritionalProfile) {
  let breakdown = {
    available: false,
  };

  if (canAddCalorieBreakdown(nutritionalProfile)) {
    const breakdownResults = calculateCalorieBreakdown({
      gramsOfProtein: convertToNumber(nutritionalProfile.protein.number),
      gramsOfFat: convertToNumber(nutritionalProfile.fat.number),
      gramsOfCarbohydrate: convertToNumber(nutritionalProfile.carbohydrate.number),
    });

    breakdown = {
      available: true,
      ...breakdownResults,
      ratio: calculateCalorieBreakdownPercentage(breakdownResults),
    };
  }

  return breakdown;
}

export function addCalorieBreakdown(state) {
  return {
    ...state,
    breakdown: getCalorieBreakdown(state.nutritionalProfile),
  };
}

function isAbsoluteReading(reading) {
  return reading === "energy" || reading === "calories";
}

function editNutritionalProfile(nutritionalProfile, action) {
  const reading = Object.keys(action.value)[0];
  const target = nutritionalProfile[reading];
  const enteredValue = action.value[reading];
  const asAbsolute = isAbsoluteReading(reading);
  const inputParseResult = parseNumericInput(enteredValue, asAbsolute);
  const update = {
    ...target,
    value: inputParseResult.value,
    valid: inputParseResult.valid,
    number: inputParseResult.number,
  };
  const toApply = {};
  toApply[reading] = update;

  let updatedProfile = { ...nutritionalProfile, ...toApply };
  let updateEnergy = reading === "calories";

  const overRideCaloriesWhenNotNull = true; // TODO: Make this a setting?
  const canAddCalorieBreakdownResult = canAddCalorieBreakdown(updatedProfile);
  if ((updatedProfile.calories.number === undefined || overRideCaloriesWhenNotNull) && canAddCalorieBreakdownResult) {
    // We can aproxmiate the calories from the macros
    const breakdownResults = calculateCalorieBreakdown({
      gramsOfProtein: convertToNumber(updatedProfile.protein.number),
      gramsOfFat: convertToNumber(updatedProfile.fat.number),
      gramsOfCarbohydrate: convertToNumber(updatedProfile.carbohydrate.number),
    });

    updateEnergy = true;
    updatedProfile = {
      ...updatedProfile,
      calories: {
        ...updatedProfile.calories,
        disabled: true,
        valid: true,
        number: breakdownResults.total,
        value: `${breakdownResults.total}`,
      },
    };
  } else if (
    (updatedProfile.calories.number !== undefined || overRideCaloriesWhenNotNull) &&
    !canAddCalorieBreakdownResult
  ) {
    // There is a calorie value, and we cannot calculate it automatically.
    updatedProfile = {
      ...updatedProfile,
      calories: {
        ...updatedProfile.calories,
        disabled: false,
      },
    };
  }

  if (updateEnergy) {
    // Calculate energy and save directly
    const energy = {
      ...updatedProfile.energy,
      value: "",
      number: undefined,
      valid: update.valid,
    };

    if (updatedProfile.calories.valid && updatedProfile.calories.number >= 0) {
      const energyAsKcal = calculateEnergy(updatedProfile.calories.number);
      energy.valid = true;
      energy.number = energyAsKcal;
      energy.value = `${energyAsKcal}`;
    }

    updatedProfile = { ...updatedProfile, energy };
  }

  return updatedProfile;
}

function editServingSize(servingSize, action) {
  const parsedValue = parseNumericInput(action.value, true);
  return {
    ...servingSize,
    value: parsedValue.value,
    valid: parsedValue.valid,
    number: parsedValue.number,
  };
}

export default function editorReducer(state, action) {
  let newState = state;
  if (action.type === "edit") {
    newState = { ...newState, ...action.value };
  } else if (action.type === "edit_packSize") {
    const packSizeParsed = parseNumericInput(action.value, true);
    const packSize = {
      value: packSizeParsed.value,
      valid: packSizeParsed.valid,
      number: packSizeParsed.number,
    };
    newState = { ...newState, packSize };
  }

  switch (action.type) {
    case "edit_suggestedServingSizeGrams":
      newState = {
        ...newState,
        suggestedServingSizeGrams: editServingSize(newState.suggestedServingSizeGrams, action),
      };
      break;
    case "edit_individualServingSizeGrams":
      newState = {
        ...newState,
        individualServingSizeGrams: editServingSize(newState.individualServingSizeGrams, action),
      };
      break;
    case "edit_suggestedServingSizeQuantity":
      newState = {
        ...newState,
        suggestedServingSizeQuantity: editServingSize(newState.suggestedServingSizeQuantity, action),
      };
      break;
    case "edit_individualServingSizeQuantity":
      newState = {
        ...newState,
        individualServingSizeQuantity: editServingSize(newState.individualServingSizeQuantity, action),
      };
      break;
    case "edit_nutrition":
      newState = { ...newState, nutritionalProfile: editNutritionalProfile(newState.nutritionalProfile, action) };
      break;
    case "edit_suggestedServingSizeNutrition":
      newState = {
        ...newState,
        suggestedServingSizeNutritionalProfile: editNutritionalProfile(
          newState.suggestedServingSizeNutritionalProfile,
          action,
        ),
      };
      break;
    default:
      break;
  }

  let newIndividualServingSizeNutritionalProfile = newState.individualServingSizeNutritionalProfile;
  let newSuggestedServingSizeNutritionalProfile = newState.suggestedServingSizeNutritionalProfile;

  if (newState.hasUnits) {
    // Calculate by quantity
    if (!newState.packSize.number) {
      // Reset to zero
    } else {
      const hasSuggestedQuantity =
        newState.suggestedServingSizeQuantity.valid && newState.suggestedServingSizeQuantity.number > 0;
      let targetSuggestedQuantity = 0;
      if (hasSuggestedQuantity) {
        targetSuggestedQuantity = newState.suggestedServingSizeQuantity.number;
      } else {
        targetSuggestedQuantity = newState.suggestedServingSizeQuantity.placeholder.number;
      }
      const suggestedPortionServingSize = {
        ...newState.suggestedServingSizeGrams,
        value: newState.packSize.value,
        valid: newState.packSize.valid,
        number: newState.packSize.number,
      };

      const weightPerIndividualQuantity = calculatePortionSize(newState.packSize.number, targetSuggestedQuantity);
      const weightPerIndividualQuantityNumber = parseNumericInput(weightPerIndividualQuantity, true);

      const individualPortionServingSize = {
        ...newState.individualServingSizeGrams,
        value: weightPerIndividualQuantityNumber.value,
        valid: weightPerIndividualQuantityNumber.valid,
        number: weightPerIndividualQuantityNumber.number,
      };

      newState = {
        ...newState,
        suggestedServingSizeGrams: suggestedPortionServingSize,
        individualServingSizeGrams: individualPortionServingSize,
      };
    }
  }

  // Calculate by weight
  const hasSuggestedWeight = newState.suggestedServingSizeGrams.valid && newState.suggestedServingSizeGrams.number > 0;
  let targetSuggestedWeight = 0;

  if (hasSuggestedWeight) {
    // User has entered a custom weight, override the individual place holder if one exists.
    newState.individualServingSizeGrams = {
      ...newState.individualServingSizeGrams,
      placeholder: {
        number: newState.suggestedServingSizeGrams.number,
        value: newState.suggestedServingSizeGrams.value,
      },
    };
    targetSuggestedWeight = newState.suggestedServingSizeGrams.number;
  } else {
    newState.individualServingSizeGrams = {
      ...newState.individualServingSizeGrams,
      placeholder: {
        number: 100,
        value: "100",
      },
    };
    targetSuggestedWeight = newState.suggestedServingSizeGrams.placeholder.number;
  }

  const hasIndividualWeight =
    newState.individualServingSizeGrams.valid && newState.individualServingSizeGrams.number > 0;
  let targetIndividualWeight = 0;

  if (hasIndividualWeight) {
    targetIndividualWeight = newState.individualServingSizeGrams.number;
  } else {
    targetIndividualWeight = newState.individualServingSizeGrams.placeholder.number;
  }

  const hasIndividualWeightConversion = targetIndividualWeight !== 100; // Use the standard weight without changes.
  const hasSuggestedWeightConversion = targetSuggestedWeight !== 100;

  if (hasSuggestedWeightConversion) {
    // Convert the current form state back to data, calculate it, and send it back to form state.
    const nutrionalValueData = editableProfileToNutritionalProfile(newState.nutritionalProfile);
    const updatedValues = calculateMacrosByGram(nutrionalValueData, targetSuggestedWeight);
    newSuggestedServingSizeNutritionalProfile = getEditableNutritionalProfile(updatedValues);
  } else {
    newSuggestedServingSizeNutritionalProfile = newState.nutritionalProfile;
  }

  if (hasIndividualWeightConversion) {
    // Convert the current form state back to data, calculate it, and send it back to form state.
    const nutrionalValueData = editableProfileToNutritionalProfile(newState.nutritionalProfile);
    const updatedValues = calculateMacrosByGram(nutrionalValueData, targetIndividualWeight);
    newIndividualServingSizeNutritionalProfile = getEditableNutritionalProfile(updatedValues);
  } else {
    newIndividualServingSizeNutritionalProfile = newState.nutritionalProfile;
  }

  // By here, the change will be applied, so we recalcualte the individual
  newState = {
    ...newState,
    individualServingSizeNutritionalProfile: {
      ...newIndividualServingSizeNutritionalProfile,
    },
    suggestedServingSizeNutritionalProfile: {
      ...newSuggestedServingSizeNutritionalProfile,
    },
  };

  return addCalorieBreakdown(newState);
}
