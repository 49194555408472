import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { format, parseISO } from "date-fns";
import React, { useContext, useReducer, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Checkbox, Stack, Toolbar, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Link as ReactRouterLink } from "react-router";
import ViewListIcon from "@mui/icons-material/ViewList";
import Chip from "@mui/material/Chip";
import SyncIcon from "@mui/icons-material/Sync";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip from "@mui/material/Tooltip";
import SessionContext from "../../contexts/Session/SessionContext.js";
import useTitle from "../../lib/useTitle.js";
import DayCard from "../Calendar/DayCard.js";
import ActionMessages from "../messages/Actions.js";
import AppMessages from "../messages/App.js";
import ProfileContext from "../../contexts/Profile/ProfileContext.js";
import MacroWidgets from "../Calendar/MacroWidgets.js";
import DayWidgets from "../Calendar/DayWidgets.js";
import DayCardsList from "../Calendar/DayCardsList.js";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import AddToPlanModal from "./AddToPlanModal.js";
import DayPickerTabs from "./DayPickerTabs.js";
import EditQuantityModal from "./EditQuantityModal.js";
import mealPlanEditorReducer from "./mealPlanEditorReducer.js";
import MEALPLAN_EDITOR_ACTIONS from "./mealPlanEditorReducerActions.js";
import { updateMealPlan } from "../../actions/mealPlan.js";
import { updateStorage } from "../../actions/storage.js";
import mealPlanEditorReducerInitializer from "./mealPlanEditorReducerInitializer.js";
import mealPlanEditorReducerInitialState from "./mealPlanEditorReducerInitialState.js";
import PrintStateMenuOption from "./PrintStateMenuOption.js";
import WeekSummaryCard from "./WeekSummaryCard.js";
import EditMealPlanModal from "./EditMealPlanModal.js";

export default function MealPlanDetails({ mealPlan: mealPlanFromStorage, ingredients, recipes }) {
  const { formatMessage: t, formatNumber } = useIntl();
  const { profile } = useContext(ProfileContext);
  const storageDispatch = useDispatch();
  const { locale, longDateFormat, longDateTimeFormat } = useContext(LanguageContext);
  const { selectedMacro, setSelectedMacro, selectedCalendarTab, setSelectedCalendarTab } = useContext(SessionContext);
  const [state, dispatch] = useReducer(
    mealPlanEditorReducer,
    mealPlanEditorReducerInitialState({
      mealPlan: mealPlanFromStorage,
      weekStartsOn: profile.weekStartsOn,
    }),
    mealPlanEditorReducerInitializer(locale, longDateFormat, formatNumber),
  );

  const [logEntryBeingEdited, setLogEntryBeingEdited] = useState(null);
  const [optionsMenuAnchor, setOptionsMenuAnchor] = useState(null);
  const [editMealPlanOpen, setEditMealPlanOpen] = useState(false);
  const optionsMenuOpen = Boolean(optionsMenuAnchor);

  const {
    mealPlan,
    consumedMacrosAllDaysDisplay,
    remainingMacrosAllDaysDisplay,
    goalAllDaysCompletionResult,
    goalAllDaysCompletionResultDisplay,
    runningDailyTotal,
    goalMacrosDisplay,
    goalAllDaysDisplay,
    order,
    orderBy,
    groupByMeal,
    addToPlanModalOpen,
  } = state;

  const title = `${mealPlan.name} - ${t(AppMessages.mealPlan)}`;
  useTitle(title);

  useEffect(() => {
    if (mealPlanFromStorage === mealPlan) {
      return;
    }

    storageDispatch(updateMealPlan(mealPlan));
    storageDispatch(updateStorage());
  }, [mealPlan]);

  useEffect(() => {
    if (mealPlanFromStorage === mealPlan) {
      return;
    }

    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.RELOAD_MEALPLAN,
      value: mealPlanFromStorage,
    });
  }, [mealPlanFromStorage]);

  const deleteLogEntry = (logEntry, day) => {
    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.DELETE_LOG_ENTRY,
      value: logEntry,
      day,
    });
  };

  const editLogEntry = (logEntry, day) => {
    setLogEntryBeingEdited({ logEntry, day });
  };

  const closeLogEntry = () => {
    setLogEntryBeingEdited(null);
  };

  const closeEditMealPlan = () => {
    setEditMealPlanOpen(false);
  };

  const updateLogEntry = (logEntry, day) => {
    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.EDIT_LOG_ENTRY,
      value: logEntry,
      day,
    });
    closeLogEntry();
    storageDispatch(updateMealPlan(mealPlan));
  };

  const updateMealPlanState = (delta) => {
    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.EDIT,
      value: delta,
    });
  };

  const closeAddToPlanModal = () => {
    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.CLOSE_ADD_TO_PLAN_MODAL,
    });
  };

  const createLogEntry = (multipleEntries) => {
    const entriesWithDay = multipleEntries.map((entry) => ({
      day: state.runningDailyTotal.find((_day) => _day.id === entry.day).day,
      logEntry: entry.logEntry,
    }));

    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.CREATE_LOG_ENTRY,
      value: entriesWithDay,
    });

    closeAddToPlanModal();
  };

  const onSort = (value) => {
    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.SORT,
      value,
    });
  };

  const onAddItem = (value) => {
    dispatch({
      type: MEALPLAN_EDITOR_ACTIONS.OPEN_ADD_TO_PLAN_MODAL,
      value,
    });
  };

  const closeOptionsMenu = () => {
    setOptionsMenuAnchor(null);
  };

  const handleOptionsMenuClick = (event) => {
    setOptionsMenuAnchor(event.currentTarget);
  };

  const handleEditMealPlanClick = () => {
    setEditMealPlanOpen(true);
  };
  const handleShowStateClick = () => {
    closeOptionsMenu();

    console.log(state);
  };

  const setTab = (selectedTab) => {
    setSelectedCalendarTab(selectedTab);
  };

  const handleTabChange = (event, selectedTab) => {
    setTab(selectedTab);
  };

  const handleGroupByMealToggle = () => {
    // closeOptionsMenu();
    dispatch({ type: MEALPLAN_EDITOR_ACTIONS.SET_STATE, value: { groupByMeal: !state.groupByMeal } });
  };

  const handleToggleDisplayByTab = () => {
    // closeOptionsMenu();
    dispatch({ type: MEALPLAN_EDITOR_ACTIONS.SET_STATE, value: { showAsTabs: !state.showAsTabs } });
  };

  const inSync = state.mealPlan.localUpdateCount === state.mealPlan.updateCount;

  const updatedAt = parseISO(mealPlan.updatedAt);

  return (
    <div>
      <Toolbar disableGutters variant="dense">
        <Typography component="div" variant="h1" color="inherit" sx={{ flexGrow: 1 }}>
          {mealPlan.name}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Tooltip arrow placement="left" title={`Updated: ${format(updatedAt, longDateTimeFormat, { locale })}`}>
            <span>
              {inSync ? (
                <Chip color="success" variant="outlined" icon={<CheckIcon fontSize="small" />} label="Synced" />
              ) : (
                <Chip color="info" variant="outlined" icon={<SyncIcon fontSize="small" />} label="Syncing" />
              )}
            </span>
          </Tooltip>

          <Button
            aria-controls={optionsMenuOpen ? "options" : undefined}
            aria-haspopup="true"
            aria-expanded={optionsMenuOpen ? "true" : undefined}
            variant="outlined"
            disableElevation
            onClick={handleOptionsMenuClick}
            size="small"
            endIcon={<KeyboardArrowDownIcon fontSize="small" />}
          >
            Options
          </Button>

          <Button
            variant="outlined"
            onClick={handleEditMealPlanClick}
            startIcon={<EditIcon fontSize="small" />}
            size="small"
          >
            {t(ActionMessages.edit)}
          </Button>
        </Stack>
        <Menu
          anchorEl={optionsMenuAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={optionsMenuOpen}
          onClose={closeOptionsMenu}
          MenuListProps={{
            dense: true,
          }}
        >
          <MenuItem selected={state.groupByMeal} onClick={handleGroupByMealToggle}>
            <ListItemIcon>
              <Checkbox
                sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
                checked={state.groupByMeal}
                size="small"
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(ActionMessages.groupByMeal)} />
          </MenuItem>

          <MenuItem selected={state.showAsTabs} onClick={handleToggleDisplayByTab}>
            <ListItemIcon>
              <Checkbox
                sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
                checked={state.showAsTabs}
                size="small"
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(ActionMessages.showAsTabs)} />
          </MenuItem>
          <Divider />
          <MenuItem component={ReactRouterLink} to="/plan">
            <ListItemIcon>
              <ViewListIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t(ActionMessages.managePlans)} />
          </MenuItem>
          <PrintStateMenuOption onClick={handleShowStateClick} />
        </Menu>
      </Toolbar>
      <MacroWidgets
        consumedMacrosDisplay={consumedMacrosAllDaysDisplay}
        completionResult={goalAllDaysCompletionResult}
        value={selectedMacro}
        onClick={setSelectedMacro}
      />
      <DayWidgets days={runningDailyTotal} value={selectedCalendarTab} onClick={setTab} macro={selectedMacro} />

      {state.showAsTabs && (
        <DayPickerTabs runningDailyTotal={runningDailyTotal} value={selectedCalendarTab} onChange={handleTabChange} />
      )}
      {state.showAsTabs && selectedCalendarTab !== "total" && (
        <DayCard
          goal={goalMacrosDisplay}
          ingredients={ingredients}
          recipes={recipes}
          deleteLogEntry={deleteLogEntry}
          editLogEntry={editLogEntry}
          allowNewItems
          day={runningDailyTotal.find((item) => item.tabId === selectedCalendarTab)}
          order={order}
          orderBy={orderBy}
          onSort={onSort}
          groupByMeal={groupByMeal}
          onAddItem={onAddItem}
          hideToolbar
        />
      )}

      {!state.showAsTabs && (
        <DayCardsList
          goal={goalMacrosDisplay}
          days={runningDailyTotal}
          ingredients={ingredients}
          recipes={recipes}
          deleteLogEntry={deleteLogEntry}
          editLogEntry={editLogEntry}
          allowNewItems
          onSort={onSort}
          order={order}
          orderBy={orderBy}
          groupByMeal={groupByMeal}
          onAddItem={onAddItem}
        />
      )}

      {(!state.showAsTabs || (state.showAsTabs && selectedCalendarTab === "total")) && (
        <WeekSummaryCard
          onSort={onSort}
          consumedMacrosAllDaysDisplay={consumedMacrosAllDaysDisplay}
          remainingMacrosAllDaysDisplay={remainingMacrosAllDaysDisplay}
          goalAllDaysCompletionResultDisplay={goalAllDaysCompletionResultDisplay}
          goalAllDaysCompletionResult={goalAllDaysCompletionResult}
          runningDailyTotal={runningDailyTotal}
          order={order}
          orderBy={orderBy}
          goalAllDaysDisplay={goalAllDaysDisplay}
          hideToolbar={state.showAsTabs}
          onDaySelected={setTab}
        />
      )}

      <AddToPlanModal
        isOpen={addToPlanModalOpen}
        day={state.addToPlanModalOpenDay}
        time={state.addToPlanModalOpenTime}
        onClose={closeAddToPlanModal}
        days={state.addToPlanModalDays}
        createLogEntry={createLogEntry}
      />
      <EditQuantityModal
        isOpen={Boolean(logEntryBeingEdited)}
        day={logEntryBeingEdited?.day}
        logEntry={logEntryBeingEdited?.logEntry}
        onClose={closeLogEntry}
        editLogEntry={updateLogEntry}
      />
      <EditMealPlanModal
        isOpen={editMealPlanOpen}
        setIsOpen={closeEditMealPlan}
        updateMealPlan={updateMealPlanState}
        mealPlan={mealPlan}
      />
    </div>
  );
}
