import React, { useContext, useState } from "react";
import { format, parseISO, isSameDay, isFuture } from "date-fns";
import { useHotkeys } from "react-hotkeys-hook";
import { useIntl } from "react-intl";
import DayCard from "../Calendar/DayCard.js";
import useStyles from "../../isomorphic-style-loader/useStyles.js";
import AddToCalendarDialog from "../IngredientDetails/AddToCalendarDialog.js";
import { applyItemSortByDay } from "../MealPlanList/applyItemSort.js";
import calculateItemsDependentStateValues from "../MealPlanList/calculateItemsDependentStateValues.js";
import { calculateRunningDailyTotalByDay } from "../MealPlanList/calculateRunningDailyTotal.js";
import useTitle from "../../lib/useTitle.js";
import useSendPageView from "../../lib/useSendPageView.js";
import EditQuantityModal from "../MealPlanList/EditQuantityModal.js";
import styles from "./Home.css";
import { displayMacrosUsingLocale } from "../../data/displayMacros.js";
import LanguageContext from "../../contexts/Language/LanguageContext.js";
import ProfileContext from "../../contexts/Profile/ProfileContext.js";

import WidgetContainer from "./WidgetContainer.js";

const fixedState = {
  order: "asc",
  orderBy: "calories",
};

export default function Home({
  isUserSelectedDate,
  showNextDay,
  showPreviousDay,
  dayData,
  displayDate,
  ingredients,
  deleteLogEntry,
  updateLogEntry,
  toggleLogEntryConsumed,
  toggleMarkAsComplete,
  recipes,
  showMostRecentStats,
}) {
  useStyles(styles);
  const { formatNumber } = useIntl();
  const showConsumeButton = !isFuture(displayDate);

  const [addToCalendarDialogOpen, setAddToCalendarDialogOpen] = useState(null);
  const [logEntryBeingEdited, setLogEntryBeingEdited] = useState(null);

  const closeAddToCalendarDialog = () => {
    setAddToCalendarDialogOpen(null);
  };

  const isAddToCalendarDialogOpen = Boolean(addToCalendarDialogOpen);
  const isEditQuantityDialogOpen = Boolean(logEntryBeingEdited);

  const isAnyModalOpen = isAddToCalendarDialogOpen || isEditQuantityDialogOpen;
  const { locale, longDateFormat, monthDayFormat } = useContext(LanguageContext);
  const { profile } = useContext(ProfileContext);
  const { goal } = profile;
  useTitle(format(displayDate, longDateFormat, { locale }));
  useSendPageView(isUserSelectedDate ? "Calendar Day" : "Home", isUserSelectedDate ? "/calendar/day" : "/", [
    format(displayDate, "yyyyMMdd"),
  ]);
  useHotkeys(
    "n",
    () => {
      if (isAnyModalOpen) return;
      showNextDay(displayDate);
    },
    [displayDate, isAnyModalOpen],
  );
  useHotkeys(
    "p",
    () => {
      if (isAnyModalOpen) return;
      showPreviousDay(displayDate);
    },
    [displayDate, isAnyModalOpen],
  );
  const consumedAndPlannedMacros = dayData.total;
  const recipeMacrosDisplay = displayMacrosUsingLocale(consumedAndPlannedMacros, formatNumber);
  const goalMacrosDisplay = displayMacrosUsingLocale(goal, formatNumber);
  const { stats } = profile;
  let hasStats = stats && stats.date;
  let statsMatchesDisplay = false;
  const statsDate = hasStats ? parseISO(stats.date) : undefined;
  if (hasStats) {
    statsMatchesDisplay = isSameDay(statsDate, displayDate);
  }
  if (!showMostRecentStats && !statsMatchesDisplay) {
    hasStats = false;
  }

  const editLogEntry = (logEntry, day) => {
    setLogEntryBeingEdited({ logEntry, day });
  };

  const closeLogEntry = () => {
    setLogEntryBeingEdited(null);
  };

  const onUpdateLogEntry = (logEntry, day) => {
    updateLogEntry(logEntry, day);
    closeLogEntry();
  };

  const onAddItem = (params) => {
    setAddToCalendarDialogOpen(params);
  };

  const onSort = () => {};
  const onContextMenu = () => {};

  const { order, orderBy } = fixedState;
  // console.log(dayData);

  const dataWithGroup = applyItemSortByDay({ day: { ...dayData, itemsByTime: [], allItems: [] }, order, orderBy });
  // console.log(dataWithGroup);

  const v2 = calculateItemsDependentStateValues(
    { ...fixedState, goal, days: [dayData], goalAllDays: goal },
    locale,
    longDateFormat,
    formatNumber,
  );
  // console.log(v2);

  const calculateRunningDailyTotalByDayResult = calculateRunningDailyTotalByDay({
    day: dataWithGroup,
    goal,
    locale,
    longDateFormat,
    formatNumber,
  });

  // console.log(calculateRunningDailyTotalByDayResult);

  const day = v2.runningDailyTotal[0];
  // console.log(day);

  return (
    <>
      <WidgetContainer
        dayData={dayData}
        showMostRecentStats={showMostRecentStats}
        isUserSelectedDate={isUserSelectedDate}
        displayDate={displayDate}
      />
      <DayCard
        goal={goalMacrosDisplay}
        ingredients={ingredients}
        recipes={recipes}
        deleteLogEntry={deleteLogEntry}
        editLogEntry={editLogEntry}
        allowNewItems
        day={day}
        isCalendarDate={false}
        order={order}
        orderBy={orderBy}
        onSort={onSort}
        groupByMeal
        onAddItem={onAddItem}
        onContextMenu={onContextMenu}
        showConsumeButton={showConsumeButton}
        toggleLogEntryConsumed={toggleLogEntryConsumed}
        toggleMarkAsComplete={toggleMarkAsComplete}
      />
      <EditQuantityModal
        isOpen={isEditQuantityDialogOpen}
        day={logEntryBeingEdited?.day}
        logEntry={logEntryBeingEdited?.logEntry}
        onClose={closeLogEntry}
        editLogEntry={onUpdateLogEntry}
      />
      <AddToCalendarDialog
        open={isAddToCalendarDialogOpen}
        onClose={closeAddToCalendarDialog}
        date={displayDate}
        time={addToCalendarDialogOpen?.time}
        key={displayDate.toISOString()}
      />
    </>
  );
}
